import React, { memo, PureComponent } from "react";
import { toJS } from "mobx";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Surface,
  Symbols,
} from "recharts";
import "../../App.css";
import { DataArrayTwoTone } from "@mui/icons-material";

const Pie_Chart = (props) => {
  const { data, id, showPercent } = props;
  
  const COLORS = [
    "#663567",
    "#B04E6F",
    "#E77866",
    "#607d8b",
    "#ffeb3b",
    "#1273DE",
    "#388e3c",
    "#7b64ff",
    "#86442d",
    "#7bdcb5",
  ];
  // const COLORS = ["#1273DE", "#7b64ff", "#ffeb3b", "#607d8b", "#f57c00", "#f44e3b", "#388e3c", "#f06292", "#86442d", "#7bdcb5"];

  const pieData = data?.map((v) => {
    return {
      name: v.name,
      value: parseInt(v.value),
      unit: v.unit || "kWh",
    };
  });


  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
            color: '#717171'
          }}
        >
          <label>{`${payload[0].name} : ${payload[0].value?.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )} ${payload[0].unit || pieData[0].unit || "kWh"}`}</label>
        </div>
      );
    }
    return null;
  };

  const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
}) => {
 
  
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentVal = (percent * 100).toFixed(0);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percentVal > 5 &&
      `${percentVal}%`}
    </text>
  );
};

  //   console.log('payload ====> ', payload)
  //   return (
  //     <div className="customized-legend border ">
  //       {payload.map(entry => {
  //         const { value, color } = entry;
  //         const style = {
  //           marginRight: 10
  //         };

  //         return (
  //           <div className="p-0 m-0">
  //             <span
  //               className="legend-item"
  //               onClick={() => this.handleClick(value)}
  //               style={style}
  //             >
  //               <Surface width={10} height={10} viewBox="0 0 10 10">
  //                 <Symbols cx={5} cy={5} type={'square'} size={50} fill={color} />
  //               </Surface>
  //               <span>{value} </span>
  //             </span>
  //          </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };
  const RenderCustomizedLabel = memo(renderCustomizedLabel);
  const sum = pieData?.reduce((acc, value) => { return acc + value?.value}, 0);
  return (
    <PieChart className="pieChart" width={730} height={300}>
      {
        sum === 0 ?
        <Pie data={[{name: 'No Data', value: 1}]} stroke={'rgba(216, 216, 216, 0.7)'} fill='transparent' isAnimationActive={false} />
        :
        <Pie
        label={<RenderCustomizedLabel/>}
        data={pieData}
        color="#000000"
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={120}
        fill="#8884d8"
        labelLine={false}
        isAnimationActive={false}
        animationDuration={1000}
      >
        {pieData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      }
      {
        sum !== 0 ?
        <Tooltip content={<CustomTooltip />} />
        : null
      }
      {/* <Legend 
        layout="horizontal" 
        verticalAlign="bottom" 
        align="left"
        content={renderCusomizedLegend}
      /> */}
    </PieChart>
  );
};
export default Pie_Chart;
