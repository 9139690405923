import * as API from "./URL";
import i18n from "../i18n";

export const fetchCompanyList = async (callback) => {
    return fetch(API.getCompanyList, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };

  export const fetchAuditOverviewList = async (companyName,callback) => {
    return fetch(API.getAuditOverviewList+'?companyName='+companyName, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };

  export const fetchCompanyVerification = async (isGhgFw,params,callback) => {
    const param = isGhgFw 
                  ? `?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&year=${params.year}&scope=${params.scope}` 
                  : `?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}`
    return fetch(API.getCompanyVerification+param, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": i18n.resolvedLanguage
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };

  export const updateAuditorAction = async (isGhgFw,params,data,callback) => {
    const param = isGhgFw 
                    ? `?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&year=${params.year}&scope=${params.scope}&actionId=${params.actionId}&actionType=${params.actionType}&month=${params.month}&category=${params.category}`
                    : `?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&actionId=${params.actionId}&actionType=${params.actionType}`
    return fetch(API.updateAuditorAction+param, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
      body:  JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };

  export const fetchSurveyHeaderInfo = async(buildingId, callback) => {
    return fetch(API.surveyList+`${buildingId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  }

  export const fetchGroupList = async (companyId, year, callback) => {
    return fetch(API.GroupListAPI(companyId, year), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then((data) => callback(null, data))
      .catch((err) => callback(err.toString(), null));
  };
  
  export const fetchBaseLineYear = async(companyId,callback) => {
    return fetch(API.getBaseLineYear(companyId),{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
        }
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch(err => callback(err.toString(),null));
}

export const fetchGroupDetail = async(groupId, year, callback) => {
  return fetch(API.getGroupDetail(groupId, year), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    }
  })
  .then((res) => res.json())
  .then((data) => callback(null,data))
  .catch(err => callback(err.toString(),null));
}

export const updateAssetAction = async(params,data, callback) => {
  return fetch(API.updateAssetAction(params), {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body:  JSON.stringify({audition: data})
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));
}