import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const Scope2SetUpModal = inject("store")(
  observer((props) => {
    const { getCalApproaches } = props.store.survey;
    const { theme, siteModuleData, tableColumns, yearOptions, _handleGetYearOptions, minBaselineYear } = props.store.common;
    const { activeDialog, selectedGroupId, _handleGetEmissionByCalculatedMethod, clearData, assetEmissionFactorValue, _handleChangeAssetData, listGroup, _handleCreateCompanySetup, companyAssetData } = props.store.groupmanagement;
    const {
      // yearOptions,
      emissionFactorOptions,
      unitOptions,
      calculationApproachOptions,
      sourceDataList,
      _getEnergySourceList
    } = props.store.quickOrganizationSetUp;
    const { t } = useTranslation("common");
    const t1 = useTranslation("country");

    const scope = tableColumns[0]?.dataColumns.filter(c => c.cat === activeDialog)[0]?.scope;

    useEffect(()=> {
      _getEnergySourceList();
      if(siteModuleData){
        if(siteModuleData.baselineYear){
            _handleGetYearOptions(siteModuleData.baselineYear);
        }else{
            _handleGetYearOptions(minBaselineYear);
        }                
    }
    }, [])

    useEffect(()=> {
      getCalApproaches("", companyAssetData[0].companyAsset[0].assets[0].emissionFactor, "scope2")
    }, [companyAssetData[0].companyAsset[0].assets[0].emissionFactor])

    useEffect(() => {
      companyAssetData[0].companyAsset[0].assets[0].calculationApproach = '';
      companyAssetData[0].companyAsset[0].assets[0].calculateMethod = '';
    },[companyAssetData[0].companyAsset[0].assets[0].emissionFactor])

    useEffect(() => {
      if(
        activeDialog && scope === 2 &&
        companyAssetData[0].companyAsset[0].assets[0].calculationApproach &&
        companyAssetData[0].companyAsset[0].assets[0].emissionFactor &&
        companyAssetData[0].companyAsset[0].assets[0].calculateMethod &&
        companyAssetData[0].companyAsset[0].assets[0].unit
      ){
      _handleGetEmissionByCalculatedMethod('scope2', activeDialog);
      }
    },[activeDialog,companyAssetData[0].companyAsset[0].assets[0].unit, companyAssetData[0].companyAsset[0].assets[0].calculationApproach,companyAssetData[0].companyAsset[0].assets[0].emissionFactor,companyAssetData[0].companyAsset[0].assets[0].calculateMethod])

    return (
      <div
        className={`modal fade ${theme}`}
        id="scope2SetUpModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addEquipmentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme)
            }}
          >
            <div className="modal-header">
              <h4>{t("Add Data for Scope 2")}</h4>
              <i
                onClick={() => clearData()}
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
              <div className="row py-1">
              <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>                            
                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        disabled
                        label={t("Category")}
                        menuListColor={Colors.Dark[0]}
                        options={categoryOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                        value={[{value: activeDialog, label: activeDialog}].filter((v) => v.label === activeDialog)}
                        placeholder={t("Select Category")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        disabled
                        label={t("Record Period")}
                        menuListColor={Colors.Dark[0]}
                        options={recordPeriodOption}
                        value={recordPeriodOption.filter((v) => v.label === "Monthly")}
                        placeholder={t("Select Record Period")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-1">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <MyInput
                        label={t("Supplier")}
                        required={true}
                        id={"supplier"}
                        type={"text"}
                        value={companyAssetData[0].companyAsset[0].assets[0].supplierName}
                        onChange={(e) => _handleChangeAssetData(2, selectedGroupId, activeDialog, e.target.value, "supplierName")}
                        placeHolder={t("Supplier Name")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Emission Factor")}
                        menuListColor={Colors.Dark[0]}
                        options={(emissionFactorOptions.filter(dt => dt.category === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                        value={emissionFactorOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].emissionFactor)}
                        placeholder={t("Select Emission Factor")}
                        _handleSelect={(e) => _handleChangeAssetData(2, selectedGroupId, activeDialog, e.label, "emissionFactor")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-1 align-items-end">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Calculation Approach")}
                        menuListColor={Colors.Dark[0]}
                        options={calculationApproachOptions?.sort((a, b) => b.label > a.label ? -1 : 0)}
                        value={calculationApproachOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].calculationApproach)}
                        placeholder={t("Select Calculation Approach")}
                        _handleSelect={(e) => _handleChangeAssetData(2, selectedGroupId, activeDialog, e.label, "calculationApproach")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>                            
                            <span style={{ marginLeft: 5 }}>{ companyAssetData[0].companyAsset[0].assets[0].calculationApproach === "Purchased Electricity(Location Based)" ? t1.t(e.label) : t(e.label)}</span>
                          </div>
                        )}
                        label={t("Calculate Method")}
                        menuListColor={Colors.Dark[0]}
                        options={(sourceDataList.filter(o => o.title === activeDialog))[0]?.list.filter(o => o.sourceKey === "calculateMethod" && o.sourceType === companyAssetData[0].companyAsset[0].assets[0].calculationApproach)}
                        value={(sourceDataList.filter(o => o.title === activeDialog))[0]?.list.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].calculateMethod && v.sourceType === companyAssetData[0].companyAsset[0].assets[0].calculationApproach)}
                        placeholder={t("Select Calculate Method")}
                        _handleSelect={(e) => _handleChangeAssetData(2, selectedGroupId, activeDialog, e.label, "calculateMethod")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-1">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        label={t("Year")}
                        menuListColor={Colors.Dark[0]}
                        options={yearOptions}
                        value={yearOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].year)}
                        placeholder={t("Select Year")}
                        _handleSelect={(e) => _handleChangeAssetData(2, selectedGroupId, activeDialog, e.label, "year")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Unit")}
                        menuListColor={Colors.Dark[0]}
                        options={(unitOptions.filter(v => v.activityType === 'scope2'))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                        value={unitOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].unit)}
                        placeholder={t("Select Unit")}
                        _handleSelect={(e) => _handleChangeAssetData(2, selectedGroupId, activeDialog, e.label, "unit")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-1">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <MyInput
                        disabled
                        label={t("View")}
                        id={"group"}
                        type={"text"}
                        value={listGroup.filter(g => g._id === selectedGroupId)[0]?.groupName}
                      />
                    </div>
                  </div>
                </div>

                {
                activeDialog &&
                companyAssetData[0].companyAsset[0].assets[0].calculationApproach && companyAssetData[0].companyAsset[0].assets[0].unit &&
                companyAssetData[0].companyAsset[0].assets[0].emissionFactor &&
                companyAssetData[0].companyAsset[0].assets[0].calculateMethod ? 
                (                  
                  <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                      <div className="row d-flex">
                          <div className="col">
                          <MyInput
                              disabled={true}
                              label={"Emission Factor Value"}
                              required={true}
                              type={"number"}
                              id={"emissionFactorValue"}
                              value={assetEmissionFactorValue}
                              placeHolder={"Emission Factor Value"}
                          />
                          </div>
                      </div>
                  </div>   
                ) : ''
              }                
              </div>
              
            </div>
            <div className="modal-footer mx-3 d-flex justify-content-end">
              <span data-bs-dismiss="modal" aria-label="Close">
                <MyButton
                  style={{ width: 200 }}
                  text={t("Cancel")}
                  customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                  customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                  onClick={() => clearData()}
                />
              </span>
              <span id={'create_asset_scope2'}>                
                <MyButton
                  onClick={() => _handleCreateCompanySetup(siteModuleData._id, "create_asset_scope2")}
                  style={{ width: 200 }}
                  text={t("Add")}
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const recordPeriodOption = [
  { value: 1, label: "Yearly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Quarterly" },
];

const categoryOption = [
  { value: 1, label: "Purchased Electricity" },
  { value: 2, label: "Purchased Heat and Steam" },
];

