import trackingDatas from "../../Assets/targetTrackingData.json";
import { Tracking_Table, Tracking_Table_KW } from "./TrackingTable";
import { MyButton, ProgressBar, ProgressCircle, Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyCircularSlider } from "../../Tools";
import { Cookies } from "react-cookie";
import kingWanData from "../../Assets/kingWan.json";
import Store from "../../Store";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Navigate, useNavigate } from "react-router-dom";
import dummyTracking from "../../Assets/tracking.json";
import Pie_Chart from "../../Assets/Charts/PieChart";
import { RadialBarChart } from "../../Assets/Charts/RadialBarChart";

export const Tracking = inject("store")(
  observer((props) => {
    const { theme, companyName, _handleGetYearOptions, yearOptions } = props.store.common;
    const { fetchTrackingData, individualTrackingData, selectedTrackingTblYear, _handleSelectTrackingTblYear, getY2yTrackingEnergy, y2yTrackingEnergy, _handleGetCountry_n_SiteOptions, countryOptions, selectedCountry, siteOptions, selectedSite, _handleSelectSelector, _handleGetYeartoYearComparison, all_y2yTrackingEnergy, getY2yTrackingS1Emissions, y2yTrackingS1Emissions, all_y2yTrackingS1Emissions } = props.store.tracking;
    const { _goToTargetSetting } = props.store.target;
    const tracking = props.trakingValue;
    const columns = props.columNames;
    const key = props.index;
    const { index, focusArea } = props;
    const permittedBuilding = props.permittedBuilding;
    const cookies = new Cookies();
    const userName = cookies.get("userName");
    const { t } = useTranslation("tracking");
    const navigate = useNavigate();
    const [y2yEnergyData, setY2yEnergyData] = useState([]);
    const [Y2yS1EmissionsData, setY2yS1EmissionsData] = useState([]);
    //console.log("permittedBuilding",toJS(permittedBuilding));

    useEffect(() => {
      permittedBuilding &&
        focusArea !== "Waste" &&
        focusArea !== "Green Material" &&
        fetchTrackingData(permittedBuilding, selectedTrackingTblYear);
    }, [permittedBuilding, selectedTrackingTblYear]);

    useEffect(() => {
      const minBaselineYear = individualTrackingData?.map(v=> String(v.baseline_year)||String(v.baselineYear))?.filter(f=> f!== undefined).sort((a,b)=> a-b)?.[0] || new Date().getFullYear();
      if(minBaselineYear){
        _handleGetYearOptions(minBaselineYear)
      }
    }, [individualTrackingData])

    useEffect(()=> {
      if(permittedBuilding?.length > 0){
        _handleGetCountry_n_SiteOptions(permittedBuilding)
      }
    }, [permittedBuilding])

    useEffect(() => {
      if(focusArea === "Energy"){
        getY2yTrackingEnergy();
      }
      // else if(focusArea === "Scope 1 Emission"){
      //   getY2yTrackingS1Emissions();
      // }
    }, []);

    useEffect(() => {
      if (y2yTrackingEnergy) {
        const y2yTemp = toJS(y2yTrackingEnergy)?.filter((v, k) => k > 0);

        const y2yEnergyFilterData =
          companyName === "Digital Edge" || companyName == "ClientA" && focusArea === "Energy"
            ? y2yTemp
              ?.reduce((r, c) => {
                const R = [...r];
                for (let i = 0; i < c.data.length; i++) {
                  const index = R.findIndex((v) => v.year == c.data[i].year);
                  if (index > -1) {
                    R[index].data.push({
                      name: c.label,
                      value: Number(c.data[i].value),
                      percentage: Number(c.data[i].percentage),
                      unit: "%",
                    });
                  } else {
                    R.push({
                      year: c.data[i].year,
                      data: [
                        {
                          name: c.label,
                          value: Number(c.data[i].value),
                          percentage: Number(c.data[i].percentage),
                          unit: "%",
                        },
                      ],
                    });
                  }
                }
                return R;
              }, [])
              ?.filter((v) => v.year !== undefined)
            : null;

        const sortedYear = y2yEnergyFilterData?.sort((a, b) => a.year - b.year);

        const largestYear = y2yEnergyFilterData?.reduce((r, c) => {
          return Math.max(r, c.year);
        }, []);

        if (y2yEnergyFilterData && sortedYear /*&& largestYear*/) {
          // const temp = y2yEnergyFilterData.filter((v) => v.year == largestYear);
          const temp = sortedYear.slice(0, 3);
          setY2yEnergyData(temp || []);
        }
      }
    }, [y2yTrackingEnergy]);

    useEffect(()=> {
      _handleGetYeartoYearComparison(focusArea)
    }, [selectedSite, all_y2yTrackingEnergy, all_y2yTrackingS1Emissions])
    
    useEffect(() => {
      if (y2yTrackingS1Emissions) {
        const y2yTemp = toJS(y2yTrackingS1Emissions)?.filter((v, k) => k > 0);

        const y2yS1EmissionsFilterData = [2020, 2021, 2022].map(v=> {
          const data = y2yTemp.reduce((r,c)=> {
            const R = [...r];
            const dt = c.data.find(f=> Number(f.year) === v) || {};
            if(Object.keys(dt)?.length > 0){
              R.push({
                name: c.label,
                percentage: dt.percentage,
                unit: "%",
                value: Number(dt.value).toFixed(2)
              })
            }else{
              R.push({
                name: c.label,
                percentage: 0,
                unit: "%",
                value: 0
              })
            }

            return R
          }, [])
          return(
            {
              year: v,
              data: data
            }
          )
        })

        const sortedYear = y2yS1EmissionsFilterData?.sort((a, b) => a.year - b.year);

        const largestYear = y2yS1EmissionsFilterData?.reduce((r, c) => {
          return Math.max(r, c.year);
        }, []);

        if (y2yS1EmissionsFilterData && sortedYear /*&& largestYear*/) {
          // const temp = y2yS1EmissionsFilterData.filter((v) => v.year == largestYear);
          const temp = sortedYear.slice(0, 3);
          setY2yS1EmissionsData(temp || []);
        }
      }
    }, [y2yTrackingS1Emissions]);

    // console.log('companyName-----------> ', companyName, toJS(permittedBuilding), toJS(individualTrackingData))
    const pieChartData =
      (companyName === "Digital Edge" || companyName == "ClientA")
        ? permittedBuilding?.filter(f=> f.type.building_type !== 'Other')?.reduce((r, c) => {
          const R = [...r];
          const i = R.findIndex(
            (v) => v.buildingType === c.type?.building_type
          );
          const currentYearVal = individualTrackingData?.find(
            (v1) => (v1?.building).trim() === c.buildingName?.trim()
          )?.current;
          if (i > -1) {
            R[i].totalCount += 1;
            R[i].filledCount += currentYearVal > 0 ? 1 : 0;
          } else {
            R.push({
              buildingType: c.type?.building_type,
              totalCount: 1,
              filledCount: currentYearVal > 0 ? 1 : 0,
            });
          }
          return R;
        }, [])
        : null;

    const consumptionLastYr =
      tracking.title === "energy"
        ? kingWanData[new Date().getFullYear() - 1]?.monthlyConsumption.reduce(
          (cur, acc) => {
            return cur + acc.energyConsumption;
          },
          0
        )
        : kingWanData[new Date().getFullYear() - 1]?.monthlyConsumption.reduce(
          (cur, acc) => {
            return cur + acc.waterConsumption;
          },
          0
        );


    const consumptionCurrentYr =
      tracking.title === "energy"
        ? kingWanData[new Date().getFullYear()]?.monthlyConsumption.reduce(
          (cur, acc) => {
            return cur + acc.energyConsumption;
          },
          0
        )
        : kingWanData[new Date().getFullYear()]?.monthlyConsumption.reduce(
          (cur, acc) => {
            return cur + acc.waterConsumption;
          },
          0
        );

    const siteData = permittedBuilding?.map((b, index) => ({
      siteName: b.buildingName.trim(),
      consumptionLastYr:
        tracking.title === "energy"
          ? energyProgress[key][b.buildingName.trim()]?.consumptionLastYr
          : tracking.title === "water"
            ? waterProgress[key][b.buildingName.trim()]?.consumptionLastYr
            : "",
      consumptionCurrentYr:
        tracking.title === "energy"
          ? energyProgress[key][b.buildingName.trim()]?.consumptionCurrentYr
          : tracking.title === "water"
            ? waterProgress[key][b.buildingName.trim()]?.consumptionCurrentYr
            : "",
      progress:
        tracking.title === "waste" || tracking.title === "Waste"
          ? plasticProgress[key][b.buildingName.trim()]?.progress
          : tracking.title === "green" || tracking.title === "Green Material"
            ? greenProgress[key][b.buildingName.trim()]?.progress
            : tracking.title === "energy" || tracking.title === "Energy"
              ? energyProgress[key][b.buildingName.trim()]?.progress
              : tracking.title === "water" || tracking.title === "Water"
                ? waterProgress[key][b.buildingName.trim()]?.progress
                : "40",
      image: `${b.buildingName.replace(/\s/g, "").toLowerCase() + ".png"}`,
      allItem:
        tracking.title === "green" || tracking.title === "Green"
          ? greenProgress[key][b.buildingName.trim()]?.allItem
          : "40",
      greenItem:
        tracking.title === "green" || tracking.title === "Green"
          ? greenProgress[key][b.buildingName.trim()]?.greenItem
          : "25",
      estDate:
        tracking.title === "waste" || tracking.title === "Waste"
          ? plasticProgress[key][b.buildingName.trim()]?.estDate
          : "Q1 2023",
    }));

    const siteDataKW = [
      {
        siteName: "King Wan",
        consumptionLastYr: consumptionLastYr?.toFixed(2),
        consumptionCurrentYr: consumptionCurrentYr?.toFixed(2),
        progress: 40,
        image: "kingwan.png",
        allItem: 31,
        greenItem: 25,
        estDate: "Q1 2023",
      },
    ];

    const energyConsumptionLastYr =
      tracking.title === "energy" && siteData?.map((v) => v.consumptionLastYr);
    const energyConsumptionCurrentYr =
      tracking.title === "energy" &&
      siteData?.map((v) => v.consumptionCurrentYr);

    //const totalBaselineYr = energyConsumptionLastYr && energyConsumptionLastYr?.reduce((a, b) => numOr0(a) + numOr0(b), 0)
    // const totalCurrentYr = energyConsumptionCurrentYr && energyConsumptionCurrentYr?.reduce((a, b) => numOr0(a) + numOr0(b), 0)

    // console.log("tracking title >> ", siteData);
    var filledUpCount = 0;
    individualTrackingData.map(
      (v) => (v.current !== 0 || v.baseline !== 0) && filledUpCount++
    );
    const totalSites =
      individualTrackingData.length === 0 ? 1 : individualTrackingData.length;
    var progressPercentage = (filledUpCount * 100) / totalSites;

    const unitNullBuilding = [];

    individualTrackingData?.map(
      (v) => v.current !== 0 && v.baseline !== 0 && v.unit === null && unitNullBuilding.push(v.building)
    );
    // console.log('individual tracking data is', toJS(individualTrackingData))
    //console.log("site data....", toJS(siteData));
    const AdminProgressPercentage =
      (userName === "Pontiac Admin" || userName === "Admin") &&
        (focusArea === "Waste" || focusArea === "Green Material")
        ? dummyTracking.find((v) => v.title === focusArea)?.targets[index]
          ?.progress
        : 0;


    return (
      <>
        <div className="row m-0" style={{ color: invertDark(theme) }}>
          {/* Terget */}
          {
            (companyName !== "Digital Edge" && companyName !== "ClientA") ?
              <div className="d-flex justify-content-start text-center ">
                <div className="py-3">
                  <span className="fw-bold"> {tracking.target} : </span>{" "}
                  {tracking.description}
                </div>
                <div className="ps-5">
                  {/* <ProgressBar noLabel noTarget progress={tracking.progress} /> */}
                </div>
              </div>
              : null
          }
          
          {/* Chart & Table */}
          {
            (companyName !== "Digital Edge" && companyName !== "ClientA") ?
              <div className="d-flex align-self-stretch col-lg-3 col-md-4 col-sm-12 col-12  my-1">
                <div
                  className="cardStyle me-1 row d-flex align-items-center w-100"
                  style={{
                    background: Colors[theme].secondBackground,
                    // minWidth: 300,
                    color: Colors[theme].textColor,
                  }}
                >
                  <div
                    className={`d-flex flex-row align-self-start align-items-center justify-content-between flex-wrap`}
                    style={{ fontSize: 23, color: invertDark(theme) }}
                  >
                    <div>{t("Overall Tracking")}</div>
                    {
                      companyName === "Enterprise SG" ? (
                        <div className="w-100" style={{ fontSize: 17 }}>{t("Total Emission")}</div>
                      )
                        : companyName === 'Digital Edge' || companyName == "ClientA" ? (
                          <div style={{ fontSize: 17 }}>{t("Current Year: ") + (new Date()).getFullYear()}</div>
                        )
                          : null
                    }
                  </div>

                  <div className="text-center py-2 d-flex align-self-start flex-wrap justify-content-center align-items-center">
                    {(companyName === "Digital Edge" || companyName == "ClientA") ? (
                      <>
                        <div className="row d-flex justify-content-center">
                          <div className="col-12">
                            {/* <Pie_Chart
                          id={"overall_tracking"}
                          data={pieChartData?.map((v) => ({
                            name: v.buildingType,
                            value: v?.totalCount,
                          }))}
                        /> */}
                            <RadialBarChart
                              colors={COLORS}
                              data={pieChartData?.map((v) => ({
                                name: v.buildingType,
                                value: v?.totalCount,
                                percent: ((v.filledCount / v.totalCount) * 100)
                              }))}
                            />
                          </div>
                        </div>
                        <span
                          className="text-start"
                          style={{ fontSize: 16, width: "auto" }}
                        >
                          {/* pieChartData?.filter(v=>v.buildingType != "Other").map((dt, key) */}
                          {pieChartData?.map((dt, key) => {
                            return (
                              <div className="d-flex" key={key}>
                                <span
                                  className="me-1 mt-2"
                                  style={{
                                    width: "12px",
                                    height: "12px",
                                    // maxWidth: 12,
                                    // display: 'inline-block',
                                    background: COLORS[key],
                                  }}
                                ></span>
                                <span className="col">
                                  {dt.filledCount === 0
                                    ? `${dt.buildingType} : ` + t("no site fill up data")
                                    : t(dt.buildingType) + ` : ${dt.filledCount} ` + t("out of") + ` ${dt.totalCount} ` + t("sites fill up data")}
                                </span>
                              </div>
                            )
                          })}
                        </span>
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <MyCircularSlider
                            label=" "
                            value={
                              AdminProgressPercentage || progressPercentage || 0
                              // (siteDataKW[0].consumptionCurrentYr /
                              //   siteDataKW[0].consumptionLastYr) *
                              // 100 +
                              // (parseInt(index) * 8)

                              // :  companyName === "Digital Edge"
                              //     ? tracking.title === "energy" ||
                              //       tracking.title === "water"
                              //       ? 54
                              //       : 0
                              //     : companyName === "Syscom"
                              //       ? tracking.title === "green" ||
                              //         tracking.title === "waste" ||
                              //         tracking.title === "scope1"
                              //         ? 0
                              //         : 100
                              //       : tracking.title === "green" ||
                              //         tracking.title === "waste"
                              //         ? 100
                              //         : 75
                            }
                            color={tracking.progressBarColor}
                            knobSize={0}
                            theme={theme}
                          />
                        </div>

                        <div className="pt-3 col-12" style={{ fontSize: 16 }}>
                          {AdminProgressPercentage
                            ? "4 " + t("site(s) filled up data")
                            : filledUpCount === 0
                              ? t("No site filled up data")
                              : `${filledUpCount} ` + t("site(s) filled up data")}{" "}
                          {/* {companyName === "Digital Edge"
                  ? "2 site Filled up data"
                  : "1 site Filled up data"} */}
                          {/* {companyName === "Digital Edge"
                  ? (tracking.title === "water" || tracking.title === "energy") ? "12 sites Filled up data" : "0 site Filled up data"
                  : companyName === "Syscom" ? (tracking.title === 'waste'||tracking.title ==='scope1' )? "No site filled up data" : "1 site filled up data" :
                    (companyName !== "Digital Edge" && (tracking.title === "green" || tracking.title === "waste" || tracking.title === "scope"))
                      ? "4 sites filled up data"
                      : "3 sites filled up data"} */}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {(tracking.title === "energy"&& companyName==='Enterprise SG') &&
            <div className="mb-3">
              <h6 className="fw-bold">Total</h6>
              <div className="row">
              <span className="col-7">Scope 3 Emission Kg CO2 (Baseline Year)</span> <span className="col-1">:</span> <span className="col-4"> {totalBaselineYr?.toLocaleString('en-US')} kWh</span><br/>
              <span className="col-7">Scope 2 Emission Kg CO2 (Current Year)</span> <span className="col-1">:</span> <span className="col-4"> {totalCurrentYr?.toLocaleString('en-US')} kWh</span><br/>
              </div>
            </div>
          } */}
                </div>
              </div>
              : null
          }
          
          <div className={` ${(companyName !== "Digital Edge" && companyName !== "ClientA") ? "col-lg-9 col-md-8 col-sm-12 col-12" : "col-12"} my-1`}>
            <div className="cardStyle me-1 row d-flex align-items-center">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div
                  className=""
                  style={{ fontSize: 23, color: invertDark(theme) }}
                >
                  {t("Individual Tracking")}
                </div>
                {
                  companyName === 'Digital Edge' || companyName === "ClientA" ?
                    <div className="me-2">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>

                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        options={yearOptions}
                        menuListColor={Colors.Dark[0]}
                        value={yearOptions?.find(v=> v.value === selectedTrackingTblYear)}
                      _handleSelect={e=>_handleSelectTrackingTblYear(e.value)}
                      />
                    </div>
                    : null
                }
              </div>

              {siteData ? (
                // userName === "King Wan" ? (
                //   <Tracking_Table_KW
                //     CommulativeConsumption={true}
                //     column={columns}
                //     data={siteData}
                //     focusArea={props.focusArea}
                //   />
                // ) : (
                  <>
                    <Tracking_Table
                      // totalBaselineYr={totalBaselineYr}
                      // totalCurrentYr={totalCurrentYr}
                      countryOptions={countryOptions}
                      companyName={companyName}
                      CommulativeConsumption={true}
                      column={columns}
                      siteData={
                        companyName === "Syscom" &&
                          (tracking.title === "scope1" ||
                            tracking.title === "waste")
                          ? companyName === "All"
                            ? siteData
                            : null
                          : focusArea === "Water" ||
                            focusArea === "Energy" ||
                            focusArea === "Scope 2 Emission"
                            ? null
                            : siteData
                      }
                      focusArea={props.focusArea}
                    />
                    {individualTrackingData &&
                      unitNullBuilding.length !== 0 && (
                        <span
                          className="text-center py-3"
                          style={{
                            fontSize: 16,
                            background:
                              theme === "dark"
                                ? "rgb(0,0,0,0.1)"
                                : "rgb(0,0,0,0.01)",
                            borderRadius: 5,
                            color: invertDark(theme),
                          }}
                        >
                          <i
                            className="bi bi-exclamation-triangle-fill pe-2"
                            style={{ color: "orange", fontSize: 20 }}
                          ></i>
                          Please set data for baseline year and unit in Target
                          Setting for the building(s) :
                          <span className="fw-bold ps-2">
                            {unitNullBuilding.map(
                              (v, i) =>
                                v +
                                `${i !== unitNullBuilding.length - 1 ? "," : "."
                                }`
                            )}
                          </span>
                          <div className="d-flex justify-content-center pt-3">
                            <MyButton
                              onClick={() =>
                                _goToTargetSetting(
                                  navigate,
                                  unitNullBuilding[0]
                                )
                              }
                              text="Go to Target Setting"
                              rightIcon={<i class="bi bi-arrow-right"></i>}
                              leftIcon={<i class="bi bi-gear-fill"></i>}
                            />
                          </div>
                        </span>
                      )}{" "}
                  </>
                // )
              ) : (
                <div className="text-center mt-5">
                  <i className="spinner-border mt-5" />
                </div>
              )}
            </div>
          </div>
        </div>
        {(companyName === "Digital Edge" || companyName == "ClientA") ?
          (focusArea === "Energy" ?
          (y2yEnergyData.length > 0 && (
            <div
              className="cardStyle my-2 mx-1 row"
              style={{
                background: Colors[theme].secondBackground,
                // minWidth: 300,
                color: Colors[theme].textColor,
              }}
            >
              <div className="m-1 d-flex justify-content-between align-items-center">
                <div
                  className=""
                  style={{ fontSize: 23, color: invertDark(theme) }}
                >
                  {t("Year-to-Year Comparison")}
                </div>
                  <div className="d-flex">
                    <div className="mx-1" style={{ width: 150 }}>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>

                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        options={countryOptions}
                        menuListColor={Colors.Dark[0]}
                        value={countryOptions?.find(v => v.value === selectedCountry)}
                        _handleSelect={e => _handleSelectSelector(e.value, 'selectedCountry')}
                        label={"Country "}
                      />
                    </div>
                    <div className="mx-1" style={{ width: 150 }}>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>

                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        options={siteOptions?.filter(v=> v.country === selectedCountry)}
                        menuListColor={Colors.Dark[0]}
                        value={siteOptions?.find(v => v.value === selectedSite)}
                        _handleSelect={e => _handleSelectSelector(e.value, 'selectedSite')}
                        label={"Site"}
                      />
                    </div>
                  </div>
                </div>

              <div className="row d-flex">
                {y2yEnergyData?.length > 0 &&
                  y2yEnergyData.map((v, key) => {
                    const totalValue = toJS(y2yTrackingEnergy)?.[0].data.find(y => y.year == v.year)?.value;
                    console.log("Year2Year", v);
                    return (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="d-flex align-self-start flex-wrap justify-content-center align-items-center">
                          <div
                            className="row m-1"
                            style={{ fontSize: 20, color: invertDark(theme) }}
                          >
                            {`Electricity Consumption( ${v.year} )`}
                          </div>
                          <div className="row d-flex flex-wrap justify-content-center">
                            <div className="container d-flex justify-content-center">
                              <Pie_Chart
                                id={"year-to-year-chart"}
                                data={
                                  v.data
                                }
                              />
                            </div>
                          </div>
                          <div className="text-start">
                            {v.data.length > 0 &&
                              v.data.map((v, k) => {
                                return (
                                  <div className="d-flex" key={k}>
                                    <span
                                      className="me-2"
                                      style={{
                                        height: 12,
                                        width: 12,
                                        minWidth: 12,
                                        background: COLORS[k % COLORS.length],
                                      }}
                                    >
                                      {" "}
                                    </span>
                                    <span className="col">
                                      {v.name} :{" "}
                                      {v.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}{" "}
                                      {"kWh"}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="d-flex flex-row w-100 justify-content-center">
                            {`Total energy consumed: ${totalValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="d-flex align-self-start flex-wrap justify-content-center align-items-center">
                    <div
                      className="row m-1"
                      style={{ fontSize: 20, color: invertDark(theme) }}
                    >
                      {`Electricity Consumption (  )`}
                    </div>
                    <div className="row d-flex flex-wrap justify-content-center">
                      <div className="container d-flex justify-content-center">
                        <Pie_Chart
                          id={"year-to-year-chart"}
                          data={
                            y2yEnergyData[0].data.length > 0 &&
                            y2yEnergyData[0].data
                          }
                        />
                      </div>
                    </div>
                    <span className="text-start">
                      {y2yEnergyData[0].data.length > 0 &&
                        y2yEnergyData[0].data.map((v, k) => {
                          return (
                            <div className="d-flex" key={k}>
                              <span
                                className="me-2"
                                style={{
                                  height: 12,
                                  width: 12,
                                  minWidth: 12,
                                  background: COLORS[k % COLORS.length],
                                }}
                              >
                                {" "}
                              </span>
                              <span className="col">
                                {v.name} :{" "}
                                {v.value?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) || 0}{" "}
                                {"kg CO₂"}
                              </span>
                            </div>
                          );
                        })}
                    </span>
                  </div>
                </div> */}
              </div>

              {/* <div className="row d-flex align-items-center">
              <div className="col-lg-4 col-12 col-md-5 col-sm-12 d-flex flex-wrap justify-content-center">
                <div className="container d-flex justify-content-center">
                  <Pie_Chart
                    id={"year-to-year-chart"}
                    data={y2yEnergyData[0].data.length > 0 && y2yEnergyData[0].data}
                  />
                </div>
              </div>
              <div className="col-lg-8 col-md-7 col-12">
                {y2yEnergyData[0].data.length > 0 && y2yEnergyData[0].data.map((v,k) => {
                  
                  return (
                    <div
                    className="d-flex"
                    key={k}
                  >
                    <div
                      className="me-2"
                      style={{
                        height: 12,
                        width: 12,
                        minWidth: 12,
                        background: COLORS[k % COLORS.length],
                      }}
                    >
                      {" "}
                    </div>
                    {v.name} : {v.value?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || 0} {"kg CO₂"}
                  </div>
                  )
                })}
              </div>
              
              </div> */}
            </div>
          ))
          : focusArea === "Scope 1 Emission" ?
          (Y2yS1EmissionsData.length > 0 && (
            <div
              className="cardStyle my-2 mx-1 row"
              style={{
                background: Colors[theme].secondBackground,
                // minWidth: 300,
                color: Colors[theme].textColor,
              }}
            >
              <div className="m-1 d-flex justify-content-between align-items-center">
                    <div
                      className=""
                      style={{ fontSize: 23, color: invertDark(theme) }}
                    >
                      {t("Year-to-Year Comparison")}
                    </div>
                    <div className="d-flex">
                      <div className="mx-1" style={{ width: 150 }}>
                        <Selector
                          getOptionLabel={(e) => (
                            <div style={{ display: "flex", textAlign: "left" }}>

                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                            </div>
                          )}
                          options={countryOptions}
                          menuListColor={Colors.Dark[0]}
                          value={countryOptions?.find(v => v.value === selectedCountry)}
                          _handleSelect={e => _handleSelectSelector(e.value, 'selectedCountry')}
                          label={"Country "}
                        />
                      </div>
                      <div className="mx-1" style={{ width: 150 }}>
                        <Selector
                          getOptionLabel={(e) => (
                            <div style={{ display: "flex", textAlign: "left" }}>

                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                            </div>
                          )}
                          options={siteOptions?.filter(v => v.country === selectedCountry)}
                          menuListColor={Colors.Dark[0]}
                          value={siteOptions?.find(v => v.value === selectedSite)}
                          _handleSelect={e => _handleSelectSelector(e.value, 'selectedSite')}
                          label={"Site"}
                        />
                      </div>
                    </div>
              </div>
              <div className="row d-flex">
                {Y2yS1EmissionsData?.length > 0 &&
                  Y2yS1EmissionsData.map((v, key) => {
                    const totalValue = toJS(y2yTrackingS1Emissions)?.[0].data.find(y => y.year == v.year)?.value;
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex align-self-start flex-wrap justify-content-center align-items-center">
                          <div
                            className="row m-1"
                            style={{ fontSize: 20, color: invertDark(theme) }}
                          >
                            {`Scope 1 Emission( ${v.year} )`}
                          </div>
                          <div className="row d-flex flex-wrap justify-content-center">
                            <div className="container d-flex justify-content-center">
                              <Pie_Chart
                                id={"year-to-year-chart"}
                                data={
                                  v.data
                                }
                              />
                            </div>
                          </div>
                          <div className="text-start">
                            {v.data.length > 0 &&
                              v.data.map((v, k) => {
                                return (
                                  <div className="d-flex" key={k}>
                                    <span
                                      className="me-2"
                                      style={{
                                        height: 12,
                                        width: 12,
                                        minWidth: 12,
                                        background: COLORS[k % COLORS.length],
                                      }}
                                    >
                                      {" "}
                                    </span>
                                    <span className="col">
                                      {v.name} :{" "}
                                      {v.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}{" "}
                                      {"Kg CO2"}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="d-flex flex-row w-100 justify-content-center">
                            {`Total scope 1 emission: ${totalValue !== undefined && totalValue !==null ? (totalValue).toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} Kg CO2`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))
          // null //tempory close @seine
          : null)
          : null
        }
      </>
    );
  })
);

const COLORS = [
  "#663567",
  "#B04E6F",
  "#E77866",
  "#607d8b",
  "#ffeb3b",
  "#1273DE",
  "#388e3c",
  "#7b64ff",
  "#86442d",
  "#7bdcb5",
];

const energyProgress = [
  {
    Conrad: {
      progress: "100",
      consumptionLastYr: 7760379,
      consumptionCurrentYr: "-",
    },
    Regent: {
      progress: "100",
      consumptionLastYr: 11551046,
      consumptionCurrentYr: "-",
    },
    "Ritz Carlton": {
      progress: "0",
      consumptionLastYr: "-",
      consumptionCurrentYr: "-",
    },
    "Avon Park": {
      progress: "0",
      consumptionLastYr: "-",
      consumptionCurrentYr: "-",
    },
    Capella: {
      progress: "100",
      consumptionLastYr: 13774869.7,
      consumptionCurrentYr: "-",
    },
  },
];

const waterProgress = [
  {
    Conrad: {
      progress: "100",
      consumptionLastYr: 115628,
      consumptionCurrentYr: "-",
    },
    Regent: {
      progress: "100",
      consumptionLastYr: 144193,
      consumptionCurrentYr: "-",
    },
    "Ritz Carlton": {
      progress: "0",
      consumptionLastYr: "-",
      consumptionCurrentYr: "-",
    },
    "Avon Park": {
      progress: "0",
      consumptionLastYr: "-",
      consumptionCurrentYr: "-",
    },
    Capella: {
      progress: "100",
      consumptionLastYr: 144435.6,
      consumptionCurrentYr: "-",
    },
  },
];

const greenProgress = [
  {
    Conrad: { progress: "80", allItem: "31", greenItem: "25" },
    Regent: { progress: "30", allItem: "36", greenItem: "11" },
    "Ritz Carlton": { progress: "65", allItem: "37", greenItem: "24" },
    "Avon Park": { progress: "0", allItem: "-", greenItem: "-" },
    Capella: { progress: "53", allItem: "28", greenItem: "15" },
  },
];

const plasticProgress = [
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "50", estDate: "Q4 2023" },
    "Ritz Carlton": { progress: "75", estDate: "Q4 2022" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "75", estDate: "Q4 2022" },
  },
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "100", estDate: "Completed" },
    "Ritz Carlton": { progress: "100", estDate: "Completed" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "100", estDate: "Completed" },
  },
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "75", estDate: "Q4 2022" },
    "Ritz Carlton": { progress: "75", estDate: "Q4 2022" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "75", estDate: "Q4 2022" },
  },
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "50", estDate: "Q3 2023" },
    "Ritz Carlton": { progress: "75", estDate: "Q4 2022" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "75", estDate: "Q4 2022" },
  },
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "100", estDate: "Completed" },
    "Ritz Carlton": { progress: "100", estDate: "Completed" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "100", estDate: "Completed" },
  },
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "85", estDate: "Q3 2022" },
    "Ritz Carlton": { progress: "75", estDate: "Q4 2022" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "75", estDate: "Q4 2022" },
  },
  {
    Conrad: { progress: "100", estDate: "Completed" },
    Regent: { progress: "75", estDate: "Q4 2022" },
    "Ritz Carlton": { progress: "100", estDate: "Completed" },
    "Avon Park": { progress: "0", estDate: "-" },
    Capella: { progress: "75", estDate: "Q4 2022" },
  },
];
