import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import { Colors } from "../Assets/Config/color.config";

const NavButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: '1.2rem',
    color: Colors[theme].primaryColor,
    // border: '1px solid transparent',
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: Colors[theme].secondBackground,
    //   border: '1px solid '+ Colors[theme].secondaryColor
    },
}));

export default NavButton;