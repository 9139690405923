import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton, MyInput } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const AddNewGroupModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { newGroupName , _handleNewGroupName,addNewGroup } = props;

        const { t } = useTranslation("assetSetupAndGroup");

        return (
            <div
                className={`modal fade ${theme}`}
                id="addNewGroupModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addNewGroupModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                        }}
                    >
                        <div className="modal-header">
                            <h4 style={{ color: 'rgb(198, 198, 198)' }}>{t("Add New View")}</h4>
                            <i
                                // onClick={() => clearData()}  
                                style={{ cursor: "pointer",color: 'rgb(198, 198, 198)' }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <MyInput
                                // disabled={props.isNewDailyData}
                                label={t("View Name")}
                                required={true}
                                id={"groupName"}
                                type={"text"}
                                value={newGroupName}
                                onChange={(e) => _handleNewGroupName(e)}
                                placeHolder={t("View Name")}
                            />
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span data-bs-dismiss="modal" aria-label="Close">
                                <MyButton
                                    text={t("Cancel")}
                                    customColor={theme === "dark" ? Colors.primaryBule : '#6c757d'}
                                    customHoverColor={theme === "dark" ? Colors.lightBlue : '#6c757d'}
                                    // onClick={() => clearData()}
                                />
                            </span>
                            <span id={'add_group'} >
                                <MyButton
                                    onClick={() => addNewGroup()}
                                    text={t("Add")}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);


