import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton, MyInput } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const ReportComNameModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const {
            componentName,
            _handleComponentName,
            _handleModifyComponent
        } = props.store.reportConfigure;
        
        

        const { t } = useTranslation("assetSetupAndGroup");

        return (
            <div
                className={`modal fade ${theme}`}
                id="updateReportComModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="updateReportTempComNameLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                        }}
                    >
                        <div className="modal-header">
                            <h4 style={{ color: 'rgb(198, 198, 198)' }}>{t("Add New Component")}</h4>
                            <i
                                // onClick={() => clearData()}  
                                style={{ cursor: "pointer",color: 'rgb(198, 198, 198)' }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                        
                              <MyInput
                                    id={"comName"}
                                    required={true}
                                    
                                    type={"text"}
                                    value={componentName ? componentName : ""}
                                    onChange={(e) => _handleComponentName(e)}
                                    placeHolder={t("Component Name")}
                                />
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span data-bs-dismiss="modal" aria-label="Close">
                                <MyButton
                                    text={t("Cancel")}
                                    customColor={theme === "dark" ? Colors.primaryBule : '#6c757d'}
                                    customHoverColor={theme === "dark" ? Colors.lightBlue : '#6c757d'}
                                    // onClick={() => clearData()}
                                />
                            </span>
                            <span id={'add_com'} >
                                <MyButton
                                    onClick={() => _handleModifyComponent()}
                                    text={t("Save")}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);


