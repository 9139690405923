import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { PillarSetupFormValidation } from "../util/formValidation";

import {
  createNewPillar,
  editPillarData,
  fetchPillars,
  deletePillar,
  fetchAllPillars,
  fetchAllSubPillars,
  fetchESGTrackingCount,
  averageFrameworkProtocol,
  fetchAllESGKPI,
  fetchCreateESGKPI,
  fetchUpdateESGKPI,
  fetchDeleteESGKPI
} from "../API/api.esg";
import { getKPIById } from "../API/api.kpiManagement";

import kplChartData from "../Assets/kplData.json";
import { toast } from "react-toastify";

class ESG {
  subPillarOwnerTitle = "";
  subPillarOwnerName = "";

  subPillar = "";
  editSubPillarOwnerName = "";
  editSubPillarOwnerTitle = "";
  editPillar = "";
  editSubpillar = "";
  pillars = [];

  pillarOption = [];
  subPillarOption = [];

  selectedPillarId = ""
  pillarId = "";
  subPillarId = "";

  pillar = {};
  selectedPillarIndex = {};
  selectedKplInfo = {};

  editPillarSetup;

  editKpi = {};

  esgTrackingData = [];

  tabIndex = 0;

  selectedSummaryRow = 0;

  selectedESGYear=new Date().getFullYear();

  avgFrameworkProtocol =[];

  esgKpiT_R = null;
  filterEsgKpiT_R = null;
  editEsgKpis = {};
  isEditEsgKpi = true;
  actionMode = null;
  esgKpiListEdit = {};
  createEsgKpiList = {
    pillar: null,
    subPillar: null,
  };
  expandESGKpiList={
    baseYear: null,
    establishedTarget: "",
    yearlyResult: []
    // result: "",
    // year: null
  };

  constructor(props) {
    makeObservable(this, {
      subPillarOwnerName: observable,
      subPillarOwnerTitle: observable,
      pillar: observable,
      subPillar: observable,
      pillarOption: observable,
      subPillarOption: observable,
      editPillar: observable,
      editSubpillar: observable,
      editSubPillarOwnerName: observable,
      editSubPillarOwnerTitle: observable,
      selectedKplInfo: observable,
      pillars: observable,
      pillarId: observable,
      editKpi: observable,
      selectedPillarId: observable,
      esgTrackingData: observable,
      tabIndex: observable,
      selectedSummaryRow: observable,
      avgFrameworkProtocol: observable,
      esgKpiT_R: observable,
      editEsgKpis: observable,
      isEditEsgKpi: observable,
      actionMode: observable,
      esgKpiListEdit: observable,
      createEsgKpiList: observable,
      expandESGKpiList: observable,
      selectedESGYear: observable,
      filterEsgKpiT_R: observable,

      _handleOwnerTitleChange: action.bound,
      _handleOwnerNameChange: action.bound,
      _handlePillarChange: action.bound,
      _handleSubPillarChange: action.bound,
      _handleSankeyChartClick: action.bound,
      _createPillar: action.bound,
      _fetchAllPillarSetup: action.bound,
      _fetchAvgFrameworkProtocol: action.bound,
      _editPillar: action.bound,
      _deletePillar: action.bound,
      _handleConfirmReset: action.bound,
      _fetchAllPillars: action.bound,
      _fetchAllSubPillars: action.bound,
      _handleEditPillarSetup: action.bound,
      _fetchESGTrackingData: action.bound,
      setTabIndex: action.bound,
      _handleSummaryRowClick: action.bound,
      _fetchAllESGKPI: action.bound,
      _handleEditEsgKPIs: action.bound,
      _handleCreateEsgKpi: action.bound,
      _handleInputChange: action.bound,
      _handleSelectChange: action.bound,
      _handleCreateESGKpi: action.bound,
      _updateEsgKPI: action.bound,
      _deleteEsgKpi: action.bound,
      _handleESGYear: action.bound,

    });
  }

  _handleEditPillarSetup = (setupPillar) => {
    this.editPillarSetup = setupPillar;
    this.editPillar = setupPillar.pillar._id;
    this.editSubpillar = setupPillar.subPillar._id;
    this.editSubPillarOwnerName = setupPillar.ownerName;
    this.editSubPillarOwnerTitle = setupPillar.ownerTitle;
  };

  _handleOwnerTitleChange = (e, type) => {
    if (type === "edit") this.editSubPillarOwnerTitle = e.target.value;
    else this.subPillarOwnerTitle = e.target.value;
  };
  _handleOwnerNameChange = (e, type) => {
    if (type === "edit") this.editSubPillarOwnerName = e.target.value;
    else this.subPillarOwnerName = e.target.value;
  };
  _handlePillarChange = (e, type) => {
    if (type === "edit") this.editPillar = e.value;
    else {
      this.selectedPillarId = e.value;
      this.pillar = e.label;
      this.pillarId = e.label;
      this.selectedPillarIndex = e.index;
      console.log("_handlePillarChange", kplChartData[this.pillar][0]);
      this.selectedKplInfo = kplChartData[this.pillar][0];
    }
  };
  _handleSubPillarChange = (e, type) => {
    if (type === "edit") this.editSubpillar = e.value;
    else this.subPillar = e.value;
    this.subPillarId = e.id;
  };
  _handleSankeyChartClick = (source, target, chartData) => {
    // let keyArr = target.sector_name.split("-");
    // let key = keyArr[1].trim();
    
    

    //let result = chartData.filter((value) => value.sector_name === key);

    // let result = kplChartData[this.pillar].filter((value) => value.key === key);

    //    "title": "GHG Emission",
    //   "key": "Scope 1 and 2",
    //   "target": "GHG Protocol Scope 1 and 2",
    //   "objectives": "Be a carbon-neutral data center organization by 2030.  To support this, perform carbon accounting per GHG protocol & Science Based Target.",
    //   "established_target": "Measure and reduce absolute scope 1 and scope 2 GHG emissions by 95% by 2030.",
    //   "dependencies": "ESG Director, Site Ops and Facility",
    //   "kpis_owner": "VP Operations, Thiam Poh",
    //   "base_year": "2020",
    //   "freq_of_measurement": "Quarterly",
    //   "unit_of_measurement": "MtCO2e",
    //   "status": "",
    //   "notes": "",
    //   "standard_alignment" : ""
    if (chartData ) {
        getKPIById(chartData.id, (err,data) => {
            if (err) {
                toast.error(err);
            } else {
                if (data.error) {
                    toast.error(data.error);
                } else {
                    
                    let collectedList = data.payload.map(v=>{
                        let target = v.kpi
                        
                        // if(target.length >=2){
                        //     target = target[1];
                        // }else{
                            target= target.replace(/ *\([^)]*\) */g, "");
                        // }
                        let collectedData = {
                            "_id": v._id,
                            "title": v.subPillar.subPillarName,
                            "key": v.kpi,
                            "target": target,
                            "objectives": v.objectives,
                            "established_target": v?.establishedTarget,
                            "dependencies": v?.keyDependency.dependencyName,
                            "kpis_owner": v?.kpiOwnerTitle.ownerTitle,
                            "base_year": v?.baseYear.yearName,
                            "freq_of_measurement": v?.freqMeasurement.unitName,
                            "unit_of_measurement": v?.unitOfMeasurement.unitName,
                            "status": v.status,
                            "notes": v.notes,
                            "standard_alignment" : v?.standardAlignment.standardName,
                            "progress": v.progress,
                            "result": v.result,
                            // pillar & subpillar added
                            "pillar": v?.pillar.pillarName,
                            "subPillar": v?.subPillar.subPillarName
                        }
                        return collectedData;
                    }).filter(v=>v._id == chartData.id);
                    //collectedList = collectedList.filter(v=>v._id== result[0].id);
                    if(collectedList){
                        this.editKpi = data.payload.filter(v=>v._id==chartData.id)[0];
                        //console.log("SelectedKplInfo", collectedList[0])
                        this.selectedKplInfo = collectedList[0];
                    }
                    
                }
            }
        });
    }
  };

  _fetchAllPillars = () => {
    fetchAllPillars((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.pillarOption = [];
          data.payload?.map((v) => {
            this.pillarOption.push({
              value: v._id,
              label: v.pillarName,
            });
          });

          this.pillarId = this.pillarOption[0].label;
          this.pillar = this.pillarOption[0].label;
          this.selectedKplInfo = kplChartData[this.pillarId][0];
          this.selectedPillarIndex = this.pillarOption[0].value;
        }
      }
    });
  };

  _fetchAllSubPillars = () => {
    fetchAllSubPillars((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.subPillarOption = [];
          data.payload?.map((v) => {
            this.subPillarOption.push({
              value: v._id,
              label: v.subPillarName,
            });
          });
        }
      }
    });
  };

  _deletePillar = () => {
    deletePillar(10, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success(data.payload);
        }
      }
    });
  };

  _fetchAllPillarSetup = () => {
    fetchPillars((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.pillars = data.payload;
        }
      }
    });
  };

  _fetchAvgFrameworkProtocol = () => {
    averageFrameworkProtocol((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.avgFrameworkProtocol = data.payload;
        }
      }
    });
  };

  _editPillar = () => {
    const pillarData = {
      pillar: this.editPillar,
      subPillar: this.editSubpillar,
      ownerTitle: this.editSubPillarOwnerTitle,
      ownerName: this.editSubPillarOwnerName,
    };

    const ValidatedErr = PillarSetupFormValidation(pillarData);

    if (ValidatedErr.pillarErr) {
      document.getElementById("editPillar").focus();
      toast.error(ValidatedErr.pillarErr);
    } else if (ValidatedErr.subPillarErr) {
      document.getElementById("editSubPillar").focus();
      toast.error(ValidatedErr.subPillarErr);
    } else if (ValidatedErr.subOwnerTitleErr) {
      document.getElementById("editSubPillarOwnerTitle").focus();
    } else if (ValidatedErr.subOwnerNameErr) {
      document.getElementById("editSubPillarOwnerName").focus();
    } else {
      editPillarData(pillarData, this.editPillarSetup._id, (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (!data.error) {
            toast.success(data.payload);
            this._fetchAllPillarSetup();
          } else {
            toast.error(data.error);
          }
        }
      });
    }
  };

  _createPillar = () => {
    const pillarData = {
      pillar: this.selectedPillarId,
      subPillar: this.subPillar,
      ownerTitle: this.subPillarOwnerTitle,
      ownerName: this.subPillarOwnerName,
    };

    const ValidatedErr = PillarSetupFormValidation(pillarData);
    if (ValidatedErr.pillarErr) {
      document.getElementById("pillar").focus();
      toast.error(ValidatedErr.pillarErr);
    } else if (ValidatedErr.subPillarErr) {
      document.getElementById("subPillar").focus();
      toast.error(ValidatedErr.subPillarErr);
    } else if (ValidatedErr.subOwnerTitleErr) {
      document.getElementById("subPillarOwnerTitle").focus();
    } else if (ValidatedErr.subOwnerNameErr) {
      document.getElementById("subPillarOwnerName").focus();
    } else {
      createNewPillar(pillarData, (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (!data.error) {
            toast.success(data.payload);
            this._fetchAllPillarSetup();
            this._handleConfirmReset();
          } else {
            toast.error(data.error);
          }
        }
      });
    }
  };


  _handleConfirmReset = () => (
    (this.pillar = ""),
    (this.subPillar = ""),
    (this.subPillarOwnerName = ""),
    (this.subPillarOwnerTitle = "")
  );
  
  _fetchESGTrackingData = () => {
    fetchESGTrackingCount((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          this.esgTrackingData = data.payload;
          
          this.esgTrackingData.sort((a,b) =>{
            if(a.kpi < b.kpi){
              return -1;
            }

            if(a.kpi > b.kpi){
              return 1;
            }

            return 0;
          })
          
        }
      }
    });
  }

  setTabIndex = (value) => {
    this.tabIndex = value
  }

  _handleSummaryRowClick = (id) => {
    this.selectedSummaryRow = id;
  }

  _fetchAllESGKPI = () => {
    this.esgKpiT_R = null
    fetchAllESGKPI((err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
          this.esgKpiT_R = [];
        } else {
          this.esgKpiT_R = data.payload || [];
          this.filterEsgKpiT_R = data.payload || [];
        }
      }
    });
  };

  _handleEditEsgKPIs = (data) => {
    this.editEsgKpis = data;
    runInAction(() => {
      this.isEditEsgKpi = true;
      this.actionMode = "edit";
      this.esgKpiListEdit = {
        pillar: data.pillar.pillarName,
        subPillar: data.subPillar.subPillarName
      }

      this.createEsgKpiList = {
        pillar: data.pillar._id,
        subPillar: data.subPillar._id,
      };
      
      this.expandESGKpiList = {
        _id: data._id,
        baseYear: data.baseYear._id,
        establishedTarget: data.establishedTarget,
        yearlyResult: data.yearlyResult
        // result: data.result,
        // year: data.year
      };
    });
  }

  _handleCreateEsgKpi(){
    this.isEditEsgKpi = false;
    this.editEsgKpis={};
    this.actionMode = "create";
    this.esgKpiListEdit = {};
    this.createEsgKpiList = {
      pillar: null,
      subPillar: null,
    };
    this.expandESGKpiList={
      baseYear: null,
      establishedTarget: "",
      yearlyResult: [{result: "",year: null}]
      // result: "",
      // year: null
    }
  }

  _handleSelectChange = (e, type, index) => {
    const createObj = { ...this.createEsgKpiList };
    const expandObj = { ...this.expandESGKpiList };

    if (type === "pillar") {
      createObj["pillar"] = e.value;
    } else if (type === "subPillar") {
      createObj["subPillar"] = e.value;
    } else if (type === "baseYear") {
      expandObj["baseYear"] = e.value;
    } else if( type === "year" ) {
      expandObj.yearlyResult[index]["year"] = e.value;
    }
    runInAction(() => {
      if (createObj !== this.createEsgKpiList) {
        this.createEsgKpiList = createObj;
      }
      if (expandObj !== this.expandESGKpiList) {
        this.expandESGKpiList = expandObj;
      }
    });
  };

  _handleInputChange = (e, type, k, index) => {
    const key = k;
    const val = e.target.value;
    const newObj =
      type === "expandForm"
        ? { ...this.expandESGKpiList }
        : { ...this.createEsgKpiList };
     if (key == "establishedTarget") {
      newObj["establishedTarget"] = val;
    }  else if (key === "result") {
      newObj.yearlyResult[index]["result"] = val;
    }

    runInAction(() => {
      if (type === "expandForm") {
        this.expandESGKpiList = newObj;
      } else {
        this.createEsgKpiList = newObj;
      }
    });
  };

  _handleCreateESGKpi (){
    const kpiManagementData = {
      baseYear: this.expandESGKpiList.baseYear,
      establishedTarget: this.expandESGKpiList.establishedTarget,
      yearlyResult: this.expandESGKpiList.yearlyResult
      // result: this.expandESGKpiList.result,
      // year: this.expandESGKpiList.year
    };
    const yearlyResult = this.expandESGKpiList.yearlyResult;

    const duplicateYears = yearlyResult.reduce((accumulator, current) => {
      const year = current.year;

      if (accumulator.includes(year)) {
        return accumulator;
      }
      const yearCount = yearlyResult.filter(item => item.year === year).length;
      if (yearCount > 1) {
        accumulator.push(year);
      }
      return accumulator;
    }, []);
    const duplicateResult = yearlyResult.reduce((accumulator, current) => {
      const result = current.result;

      if (accumulator.includes(result)) {
        return accumulator;
      }
      const resultCount = yearlyResult.filter(item => item.result === result).length;
      if (resultCount > 1) {
        accumulator.push(result);
      }
      return accumulator;
    }, []);
    if (!this.createEsgKpiList.pillar) {
      toast.error("Please select ESG Pillar.");
    } else if (!this.createEsgKpiList.subPillar) {
      toast.error('Please select ESG Sub Pillar.');
    } else if (!kpiManagementData.baseYear) {
      toast.error('Please select Base Year.');
    }  else if (kpiManagementData.yearlyResult.some(v => v.year == null || v.year == '')) {
    toast.error('Please select Year.');
  } else if (duplicateYears.length > 0) {  
    toast.error('Duplicate  Year.');
  } else if (duplicateResult.length > 0) {  
    toast.error('Duplicate  Result.');
   } else if (kpiManagementData.yearlyResult.some(v => v.result == '')) {
      toast.error('Please add Result.');
  } else {

      fetchCreateESGKPI(
        kpiManagementData,
        this.createEsgKpiList.pillar,
        this.createEsgKpiList.subPillar,
        (err, data) => {
          if (err) {
            toast.error(err);
          } else {
            if (!data.error) {
              toast.success(data.payload);
              this.isExpand = false;
              this._fetchAllESGKPI();

              document
                .getElementById("esgKpiModal")
                .setAttribute("data-bs-dismiss", "modal");
              let clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
              });
              document.getElementById("esgKpiModal").dispatchEvent(clickEvent);
              document
                .getElementById("esgKpiModal")
                .removeAttribute("data-bs-dismiss");
            } else {
              toast.error(data.error);
            }
          }
        }
      );
    }
  }

  _updateEsgKPI(){
    const kpiManagementData = {
      baseYear: this.expandESGKpiList.baseYear,
      establishedTarget: this.expandESGKpiList.establishedTarget,
      yearlyResult: this.expandESGKpiList.yearlyResult
      // result: this.expandESGKpiList.result,
      // year: this.expandESGKpiList.year
    };
    const yearlyResult = this.expandESGKpiList.yearlyResult;

    const duplicateYears = yearlyResult.reduce((accumulator, current) => {
      const year = current.year;

      if (accumulator.includes(year)) {
        return accumulator;
      }
      const yearCount = yearlyResult.filter(item => item.year === year).length;
      if (yearCount > 1) {
        accumulator.push(year);
      }
      return accumulator;
    }, []);
    const duplicateResult = yearlyResult.reduce((accumulator, current) => {
      const result = current.result;

      if (accumulator.includes(result)) {
        return accumulator;
      }
      const resultCount = yearlyResult.filter(item => item.result === result).length;
      if (resultCount > 1) {
        accumulator.push(result);
      }
      return accumulator;
    }, []);
    
    if (!this.createEsgKpiList.pillar) {
      toast.error("Please select ESG Pillar.");
    } else if (!this.createEsgKpiList.subPillar) {
      toast.error('Please select ESG Sub Pillar.');
    } else if (!kpiManagementData.baseYear) {
      toast.error('Please select Base Year.');
    }  else if (this.expandESGKpiList.yearlyResult.some(v => v.year === null || v.year === '')) {
      toast.error('Please select Year.');
     } else if (duplicateYears.length > 0) {  
        toast.error('Duplicate  Year.');
      } else if (duplicateResult.length > 0) {  
        toast.error('Duplicate  Result.');
     } else if (this.expandESGKpiList.yearlyResult.some(v => v.result == '')) {
        toast.error('Please add Result.');
    } else {

      fetchUpdateESGKPI(kpiManagementData, this.editEsgKpis._id, (err, data) => {
          if (err) {
            toast.error(err);
          } else {
            if (!data.error) {
              toast.success(data.payload);
              this.isExpand = false;
              this._fetchAllESGKPI();

              document
                .getElementById("esgKpiModal")
                .setAttribute("data-bs-dismiss", "modal");
              let clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
              });
              document.getElementById("esgKpiModal").dispatchEvent(clickEvent);
              document
                .getElementById("esgKpiModal")
                .removeAttribute("data-bs-dismiss");

            } else {
              toast.error(data.error);
            }
          }
        }
      );
    }
  }

  _deleteEsgKpi(){
    fetchDeleteESGKPI(this.editEsgKpis._id, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          toast.success(data.payload);
          this.isExpand = false;
          this._fetchAllESGKPI();

        } else {
          toast.error(data.error);
        }
      }
    })
  }

  _handleESGYear(e){
    this.selectedESGYear = e.value
    }

}
export default new ESG();
