import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Colors } from "../Assets/Config/color.config";
import Store from "../Store";

export const ProgressBar = (props) => {
  const { progress, noLabel, noTarget, color, width } = props;
  const { theme } = Store.common;
  // 
  // const Gradient = `linear-gradient(45deg,red,${Colors.energy},#ffd996)`

  return (
    <div className="row justify-content-center d-flex align-items-center m-0" >
      {/* <div className="d-flex"> */}
      <div
        className="progress col-10 p-0"
        style={{
          width: width || 125,
          height: "8px",
          borderRadius: 10,
          background: theme === "dark" ? ' rgb(255, 255, 255,0.1) ' : Colors.Dark[4]
        }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          aria-valuenow="1"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            marginTop: 2,
            marginLeft: 2.5,
            marginRight: 2.5,
            height: 4,
            width: `${progress}%`,
            background: color || Colors.water,
            transition: "width .5s",
            borderRadius: 10,
          }}
        >

          {/* </div> */}

          {/* {noTarget || <div
          className="progress-bar-marker"
          role="progressbar"
          style={{ width: "24px" }}
          aria-valuenow="46.573611111111106"
          aria-valuemin="0"
          aria-valuemax="100"
        />} */}
        </div>

      </div>
      {noLabel || <div className="col-2" style={{ color: progress < 49 ? Colors.low : Colors.greenMaterial, fontSize: 12 }}>{progress}%</div>}
    </div>
  );
};

export const HoverProgressBar = (props) => {
  const { progress } = props;
  const { theme } = Store.common;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme === "dark" ? theme.palette.grey[100]:theme.palette.grey[700],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme === "dark" ? '#308fe8' : '#1a90ff',
    },
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <BorderLinearProgress variant="determinate" value={progress} style={{width:200}}/>
      <p style={{textAlign: "center", margin: 0, color: theme === "dark" ? "#000": "#fff", fontSize: 18 }}>{progress}%</p>
    </Box>
  );
};