import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import SiteManagementTable from "../../Components/SiteManagement/SiteManagementTable";
import SiteManagementForm from "../../Components/SiteManagement/SiteManagementForm";
import { useTranslation } from 'react-i18next';

const SiteManagementContainer = (props) => {
    const { getBuildingList, permittedBuilding, getAllBuildingType, allBuildingType, theme } = props.store.common;
    const { editFormList } = props.store.siteManagement;
    const [buildingTypeOption, setBuildingTypeOption] = useState([]);
    const { t } = useTranslation("site_management");

    useEffect(() => {
        getBuildingList();
        getAllBuildingType();
    }, []);

    useEffect(() => {
        if(allBuildingType){
            const temp = allBuildingType.map(v => {
                return {
                    value: v._id,
                    label: v.building_type
                }
            });
            setBuildingTypeOption(temp);
        }
    }, [allBuildingType]);

    return (
        <div className="m-3 mt-0" style={{ color: invertDark(theme) }}>
            <div className="" style={{ fontSize: 25 }}>
                { t("Site Management") }
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-lg-8 col-md-7 col-sm-12 my-2 p-4">
                    <div className="p-3" style={{background: theme === 'dark' ? Colors[theme].secondBackground : Colors.Dark[5], borderRadius: 4, boxShadow: '2px 4px 11px rgba(0,0,0,0.25)'}}>
                        <SiteManagementTable />
                        {/* <hr className="mt-0 mb-2" />
                        <div className="d-flex flex-row justify-content-between mx-3 pb-2">
                            <div style={{ color: invertDark(theme) }}>
                                <span className={`fw-bold`}>Building Count:</span> {permittedBuilding?.length}
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12 my-2 p-4">

                    {Object.values(editFormList)?.length > 0 ?
                        <div className="row d-flex justify-content-center" style={{ background: Colors.primaryBule, borderRadius: 4 }}>
                            <SiteManagementForm siteTypeOption={buildingTypeOption}/>
                        </div>

                        : (
                            <div
                                className="d-flex align-items-center text-secondary justify-content-center p-3 h-100 text-center"
                                style={{ background: theme==='dark'? Colors[theme].secondBackground:Colors.Dark[5], borderRadius: 5, boxShadow:'2px 4px 11px rgba(0,0,0,0.25)'}}
                            >
                                <h5 className="mb-0">
                                    { t("Edit Site Data") }
                                </h5>
                            </div>

                        )
                    }
                </div>
            </div>

        </div >
    );
}

export default inject("store")(observer(SiteManagementContainer));