import { Colors,invertDark } from "../Assets/Config/color.config";
import { MyButton } from "../Tools";
import { MyInput } from "../Tools";
import Store from "../Store";
import { inject, observer } from "mobx-react";
import { Cookies } from "react-cookie";
import { ThemeSwitch } from "../Tools";
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const { _handleEmailChange, _handlePwdChange, eMail, password, _handleView, visible, _handleSubmit, isDisabled } =
    Store.login;
  const { theme, onChangeTheme } = Store.common;
  const cookies = new Cookies();
  const { t } = useTranslation("authentication");

  cookies.remove("siteName");
  cookies.remove("Token")

  return (
    <form
      className="col-lg-3 col-md-8 col-sm-10"
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        borderRadius: "15px",
        boxShadow: Colors.shadow
      }}
    >
      <div className="row p-4 mb-0 mx-0" style={{ borderBottom: `1px solid ${Colors.Dark[3]}`, background: Colors.lightBlue, borderRadius: "15px 15px 0 0" }}>
        <div className="col-12">
          <img src='./evercomm-singapore-new.png' height={38} alt={ t("Company Logo") }/>
          <div className="pt-2">
            { t("ESG Assurance Login") }
          </div>
        </div>

      </div>
      <div>
        <div className="mx-5 my-3" style={{ color: invertDark(theme)}}>
          <div className="py-3">
            { t("Email") }
            <MyInput
              required={true}
              id={"email"}
              type={"email"}
              value={eMail}
              onChange={(e) => _handleEmailChange(e)}
              placeHolder={ t("Email") }
            />
          </div>
          { t("Password") }
          <MyInput
            required={true}
            id={"password"}
            type={"password"}
            value={password}
            onChange={(e) => _handlePwdChange(e)}
            placeHolder={ t("Password") }
            _handleView={_handleView}
            visible={visible}
          />
          <hr
            className="mt-3"
            style={{
              color: "#dadde1",
              backgroundColor: Colors.Dark[3],
              height: 0.5,
              borderColor: "#dadde1",
            }}
          />
          <div className="pb-3">
            <MyButton
              text={ t("Log In") }
              type="submit"
              id={"Login"}
              onClick={(e) => _handleSubmit(e)}
              style={{ width: "100%" }}
              customHoverColor={'#35469b'}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default inject("store")(observer(LoginForm));
