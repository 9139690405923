import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { Breadcrumb } from "../../Components/SiteRelevant/BreadCrumb";
import FileListTable from "../../Components/SiteRelevant/FileListTable";
import TitleBar from "../../Components/SiteRelevant/TitleBar";
import Store from "../../Store";
import SiteRelevantModal from "../../Components/SiteRelevant/SiteRelevantModal";
import { baseURLImage } from "../../API/URL";
import { MyButton } from "../../Tools";
import ConfirmDelteModal from "../../Components/SiteRelevant/ConfirmDeleteModal";
import PDF_ from "../../Assets/images/pdf.png";
import Text from "../../Assets/images/text.png";
import Excel from "../../Assets/images/excel.png";
import { LoadingPage } from "../../Page/Loading";
import { useTranslation } from 'react-i18next';

const SiteRelevantContainer = () => {
  const {
    getSiteList,
    siteList,
    previewFile,
    _handleDeleteSite,
    _handleDownloadFile,
  } = Store.siteRelevant;
  const { addDefaultSrc,theme } = Store.common;

  const params = new URLSearchParams(window.location.search);
  const frameworkId = params.get("frameworkId");
  const buildingId = params.get("buildingId");

  const excelFormats = ["xlsx", "xls", "xlsm", "xml"];
  const pdfFormats = ["pdf"];
  const textFormats = ["txt"];
  const { t } = useTranslation("target_setting");

  useEffect(() => {
    getSiteList(buildingId, frameworkId);
  }, []);
  
  // 

  const previewFileType = toJS(previewFile)?.fileName?.split(".").pop();
  const fileType_ = previewFileType
    ? excelFormats.some((v) => v === previewFileType)
      ? "excel"
      : pdfFormats.some((v) => v === previewFileType)
      ? "pdf"
      : textFormats.some((v) => v === previewFileType)
      ? "text"
      : "img"
    : null;
  return (
    <div className="mx-4 my-2">
      <Breadcrumb />

      <TitleBar frameworkId={frameworkId} buildingId={buildingId} />
      {(siteList===null||siteList.length==0)?
      <div className="d-flex justify-content-center align-items-center" style={{color:invertDark(theme), height:'60vh'}}>
        <h5><i className="bi bi-exclamation-triangle-fill pe-3"></i>{ t("No relevant data") }</h5>
      </div>:
      siteList.length > 0 ? (
        <div className="row d-flex justify-content-center my-3" style={{ }}>
          <div
            id={"tbl_container"}
            className="col-lg-8 col-md-7 col-sm-12 my-1 "
          >
            <div
              className="tableStyle d-flex justify-content-center p-3 text-secondary text-center"
              style={{ background: theme === "dark"? Colors[theme].secondBackground: Colors.Dark[5], borderRadius: 4}}
            >
              <div className="w-100">
                <FileListTable />
                <hr className="mt-0 mb-2" />
                <div className="d-flex flex-row justify-content-between mx-1">
                  <div>
                    <span className="fw-bold" style={{color: Colors[theme].textColor}}>{ t("Files") }:</span>{" "}
                    {siteList.length}
                  </div>
                  <div>
                    <span className="fw-bold" style={{color: Colors[theme].textColor}}>{ t("Total Size") }: </span>
                    {toJS(siteList).length > 0
                      ? toJS(siteList)
                          ?.reduce((r, c) => {
                            return Number(r) + (c?.fileSize ? Number(c.fileSize) : 0);
                          }, 0)
                          ?.toFixed(2)
                      : 0}{" "}
                    MB
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id={"preview_parent"}
            className={`col-lg-4 col-md-5 col-sm-12 my-1`}
          >
            <div
              className="tableStyle d-flex align-items-center justify-content-center p-3 text-secondary h-100 text-center"
              style={{ background: theme === "dark"? Colors[theme].secondBackground: Colors.Dark[5], borderRadius: 4 }}
            >
              <div className="w-100 h-100 mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="text-start"
                    style={{ fontSize: 20, color: Colors[theme].textColor }}
                  >
                    Preview
                  </div>
                  <div className="">
                    {Object.keys(previewFile).length > 0 ? (
                      <>
                        <MyButton
                          className="m-1"
                          text={ t("Download") }
                          onClick={() => _handleDownloadFile()}
                        />
                        <MyButton
                          className="m-1"
                          text={ t("Delete") }
                          customColor={"rgb(255 0 0 / 50%)"}
                          dataToggle="modal"
                          dataTarget="#confirmModal"
                          // onClick={()=>_handleDeleteSite(frameworkId, buildingId)}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div
                  className="d-flex align-items-center justify-content-center mx-auto mt-2"
                  style={{
                    width: "95%",
                    height: "80%",
                    border: "1px solid",
                    borderColor: theme === "dark"? "gray": Colors.Dark[4],
                    minHeight: 80,
                  }}
                >
                  {Object.keys(previewFile).length > 0 ? (
                    fileType_ === "img" ? (
                      <img
                        className=""
                        onError={addDefaultSrc}
                        src={baseURLImage + previewFile?.filePath}
                        alt={ t("Preview File") }
                        width={"70%"}
                        height={"70%"}
                        style={{ borderRadius: 5, objectFit: "contain" }}
                      />
                    ) : (
                      <div>
                        <img
                          className=""
                          onError={addDefaultSrc}
                          src={
                            fileType_ === "excel"
                              ? Excel
                              : fileType_ === "pdf"
                              ? PDF_
                              : fileType_ === "text"
                              ? Text
                              : ""
                          }
                          alt={ t("Preview File") }
                          height={"60px"}
                          onContextMenu={() => {
                            return false;
                          }}
                          style={{ borderRadius: 5, objectFit: "contain" }}
                        />
                        <div style={{color: theme === "dark"? "gray": "#000"}}>{previewFile.fileName}</div>
                      </div>
                    )
                  ) : (
                    <h6 className="mb-0" style={{color: theme === "dark"? "gray": "#000"}}>{ t("No File Selected") }</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingPage/>
      )}
      <SiteRelevantModal frameworkId={frameworkId} buildingId={buildingId} />
      <ConfirmDelteModal frameworkId={frameworkId} buildingId={buildingId} />
    </div>
  );
};

export default inject("store")(observer(SiteRelevantContainer));

export const EmptyData=({text})=>{
  const { t } = useTranslation("target_setting");
    return(
        <div className="text-center my-2 text-secondary">
          <div className="rounded p-4" style={{ /*background: Colors.darkBlue,*/fontSize:18 }}>
            <i className="bi bi-file-earmark-fill pe-1" />
            {t(text || 'No file uploaded yet.')}
          </div>
        </div>
    )
}
