import { observable, action, makeObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';
import {
  LoginFormValidation,
  RegisterFormValidation,
} from "../util/formValidation";
import { FetchLogin, FetchRegister, FetchVerifyOTP,fetchSiteModule } from "../API/api.user";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Store from ".";
import jwtDecode from "jwt-decode";

class Login {
  cookies = new Cookies();
  token = this.cookies.get("Token");
  theme = localStorage.getItem("theme") === "light" ? "light" : "dark";
  eMail = "";
  password = "";
  visible = false;
  regEmail = "";
  regName = "";
  regPassword = "";
  confirmPassword = "";
  err = {};
  isDisabled = false;
  showDropdown = false;
  selectedSite = 1;

  constructor() {
    makeObservable(this, {
      password: observable,
      token: observable,
      eMail: observable,
      visible: observable,
      err: observable,
      isDisabled: observable,
      theme: observable,
      showDropdown: observable,
      regEmail: observable,
      regName: observable,
      regPassword: observable,
      confirmPassword: observable,
      selectedSite: observable,
      //   onChangeTheme: action.bound,
      _handleEmailChange: action.bound,
      _handlePwdChange: action.bound,
      _handleView: action.bound,
      _handleSubmit: action.bound,
      _handleLogout: action.bound,
      _handleRegister: action.bound,
      setShowDropdown: action.bound,
      _handleRegEmailChange: action.bound,
      _handleRegNameChange: action.bound,
      _handleRegPasswordChange: action.bound,
      _handleConfirmPasswordChange: action.bound,
      setSelectedSite: action.bound,
      // _handleVerifyOtp: action.bound
    });
  }

  //   onChangeTheme() {
  //     this.theme = this.theme === "dark" ? "light" : "dark";
  //     localStorage.setItem("theme", this.theme);
  //     var x = document.getElementsByTagName("BODY")[0];
  //     this.theme === "dark"
  //       ? (x.style.backgroundImage = "linear-gradient(-60deg,#101841,#202b60)")
  //       : (x.style.background = "white");
  //   }
  _handleEmailChange = (e) => {
    runInAction(() => {
      this.eMail = e.target.value;
    });
  };
  _handlePwdChange = (e) => {
    runInAction(() => {
      this.password = e.target.value;
    });
  };
  _handleView = () => {
    this.visible = !this.visible;
  };
  _handleRegEmailChange = (e) => {
    runInAction(() => {
      this.regEmail = e.target.value;
    });
  };
  _handleRegNameChange = (e) => {
    runInAction(() => {
      this.regName = e.target.value;
    });
  };
  _handleRegPasswordChange = (e) => {
    runInAction(() => {
      this.regPassword = e.target.value;
    });
  };
  _handleConfirmPasswordChange = (e) => {
    runInAction(() => {
      this.confirmPassword = e.target.value;
    });
  };

  _handleSubmit = (e, props) => {
    
    e.preventDefault();
    const data = { eMail: this.eMail, password: this.password };

    const ValidatedErr = LoginFormValidation(data);
    this.err = ValidatedErr;
    if (ValidatedErr.userErr) {
      document.getElementById("email").focus();
      toast.error(this.err.userErr);
    } else if (ValidatedErr.passwordErr) {
      document.getElementById("password").focus();
      toast.error(this.err.passwordErr);
    }
    if (Object.keys(ValidatedErr).length === 0) {
      runInAction(() => {
        this.isDisabled = true;
      });
      FetchLogin(data, async(err, data) => {
        if (data) {
          if (!data.error) {
            const { role, userName, email, id, token, buildingCount, companyAssets } = data.payload;

            this.cookies.set("Token", token,{ path: '/' });
            this.cookies.set("userId", id,{ path: '/' });
            this.cookies.set("role", role,{ path: '/' });
            this.cookies.set("userName", userName,{ path: '/' });
            this.cookies.set("buildingCount", buildingCount,{ path: '/' });
            this.cookies.set("companyAssets", companyAssets,{ path: '/' });
            
            
            //toast.success("Success Login!");
            // window.location.pathname = "/otp-verification";
            
            await this.getSiteModule().then(async(result)=>{
              await this.cookies.set("siteModuleData", result,{ path: '/' });
              role === 'auditor' ? (window.location.pathname = "/auditor-overview") 
              : buildingCount === 0 && role !== 'user' && companyAssets === 0
              ? (window.location.pathname = "/building-and-user-registration") //building-and-user-registration
              : buildingCount === 0 && role !== 'user' && companyAssets > 0 ? (window.location.pathname = "/home-group")
              : userName === 'Kumo Admin' ? (window.location.pathname = "/home-group")
              : result.modules.Group_Report ? (window.location.pathname = "/home-group") : (window.location.pathname = "/home");
          });
   
          } else {
            toast.error(data.error);
            this.isDisabled = false;
            document.getElementById("email").focus();
          }
        } else {
          toast.error(err.toString());
          this.isDisabled = false;
        }
      });
    }
  };


  getSiteModule = async() => {
    let result = null
    await fetchSiteModule((err, data) => {
      
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          result = data.payload;
          let focusArea = data.payload.modules.FocusArea.map((v) => v.name);

        //  [{
        //     "title":"Energy",
        //     "key":"energy",
        //     "targets":[
        //         {
        //             "name" : "To reduce energy consumption by 3.5% p.a. relative to 2019",
        //             "progress" : "75"
        //         }
        //     ]
    
        //   }]


          let targets = data.payload.modules.FocusArea.map((v) => {
            let result = {
              "title": v.name,
              "key": v.name,
              "targets": []
            };

            result.targets =  v.data.map(v1=>{
              let targetDetail = {
                name: v1.label,
                progress: v1.percent,
              };
              return targetDetail;
            });
            
            return result;
          });
          result.modules.targets = targets;
          result.modules.FocusArea = focusArea;
        }
      }
    });
    return result;
  };

  _handleRegister = (e) => {
    
    e.preventDefault();
    const data = {
      userName: this.regName,
      role: "user",
      eMail: this.regEmail,
      password: this.regPassword,
      confirmPassword: this.confirmPassword,
      phone: "",
      buildingId: this.selectedSite,
    };

    const ValidatedErr = RegisterFormValidation(data);
    
    this.err = ValidatedErr;
    if (ValidatedErr.userErr) {
      document.getElementById("userName").focus();
      toast.error(this.err.userErr);
    } else if (ValidatedErr.passwordErr) {
      document.getElementById("password").focus();
      toast.error(this.err.passwordErr);
    }
    if (Object.keys(ValidatedErr).length === 0) {
      runInAction(() => {
        this.isDisabled = true;
      });
      FetchRegister(data, (err, data) => {
        if (data) {
          if (!data.error) {
            toast.success("Success Register!");
            window.location.pathname = "/login";
          } else {
            toast.error(data.error);
            this.isDisabled = false;
            document.getElementById("email").focus();
          }
        } else {
          toast.error(err.toString());
          this.isDisabled = false;
        }
      });
    }
  };

  _handleLogout = (navigate) => {
    this.cookies.remove("Token",'/');
    this.cookies.remove("siteModuleData",'/');
    this.cookies.remove("siteName");
    this.token = null;
    navigate('/login');
    // window.location.pathname = "/login";
    // navigate('/login',{replace:true})
  };

  setShowDropdown = () => {
    this.showDropdown = !this.showDropdown;
  };

  setSelectedSite = (siteId) => {
    this.selectedSite = siteId;
  };

  // _handleVerifyOtp = (data) => {
  //   FetchVerifyOTP(data, (err, data) => {
  //     if (data) {
  //       if (!data.error) {
  //         const buildingCount =jwtDecode(Store.common.token).buildingId.length;       
  //         
  //         toast.success("Success Login!");
  //         // buildingCount === 0
  //         //   ? (window.location.pathname = "/building-and-user-registration")
  //         //   : (window.location.pathname = "/home");
  //       } else {
  //         toast.error(data.error);
  //       }
  //     } else {
  //       toast.error(err.toString());
  //     }
  //   })
  // }
}

export default new Login();
