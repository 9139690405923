import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const RadialBarChart = inject("store")(
    observer((props)=> {
        const { colors, data } = props;
        const { theme } = props.store.common;
        const [options, setOptions] = useState(null);
        const [series, setSeries] = useState([])
        
        useEffect(()=> {
          if(data){
            const plotOptions={
              chart: {
                height: 350,
                type: 'radialBar',
              },
              colors: colors,
              tooltip: {
                theme: theme === 'dark' ? "dark" : "white",
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                  return '<div class="arrow_box">' +
                    '<span>' + w.config.labels[seriesIndex] +': '+ series[seriesIndex] +'%'  + '</span>' +
                    '</div>'
                }
              },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      show: false
                    },
                    value: {
                      show: false
                    },
                    total: {
                      show: false,
                    }
                  },
                  track: {
                    background: '#D8D8D8'
                  }
                }
              },
              labels: data.map(v=> v.name),
            }
          setOptions(plotOptions);
          const series = data.map(v=> v.percent)
          setSeries(series)
          }
            
        },[data])

        return(
            <div>
              {
                options ?
                <ReactApexChart options={options} series={series} type="radialBar" height={250} />
                : null
              }
            </div>

        )
    })
)
const 
plotOptions= {
  radialBar: {
    startAngle: -135,
    endAngle: 225,
     hollow: {
      margin: 0,
      size: '70%',
      background: '#fff',
      image: undefined,
      imageOffsetX: 0,
      imageOffsetY: 0,
      position: 'front',
      dropShadow: {
        enabled: true,
        top: 3,
        left: 0,
        blur: 4,
        opacity: 0.24
      }
    },
    track: {
      background: '#fff',
      strokeWidth: '67%',
      margin: 0, // margin is in pixels
      dropShadow: {
        enabled: true,
        top: -3,
        left: 0,
        blur: 4,
        opacity: 0.35
      }
    },
  }
}