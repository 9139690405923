import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyCreatableSelector, MyInput } from "../../Tools";
import { MyFileInput } from "../../Tools";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

const SurveyFileUploadingModal = inject("store")(
  observer((props) => {
    const { frameworkId, buildingId } = props;
    const { theme } = props.store.common;
    const {
      categoryList,
      selectedCategory,
      file,
      _handleUploadFileAndAddDescription,
      _handleChangeFile,
      _handleCloseModal,
      _handleSelectCategory,    
      _handleChangeText,
      uploadDescription,
      _handleDescriptionChange
    } = props.store.siteRelevant;

    const { selectedMonth, selectedYear, selectedField, selectedCarbonTab} = props.store.survey;

    const scopeType = selectedField === "energyConsumption" ? "energy" : selectedField === "waterConsumption"? "water": selectedField === "carbonEmission"? selectedCarbonTab.split("Emission")[0] : null;

    const creatableSelectOption = toJS(selectedCategory) && {
      value: categoryList.length + 1,
      label: selectedCategory,
    };

    const { modalId , btnId } = props;
    const { t } = useTranslation("target_setting");

    return (
      <div
        className={`modal fade`}
        id={modalId}
        tabIndex="-1"
        aria-labelledby="surveyFileUploadLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div
            className="modal-content mx-3"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header mx-3 px-0">
              <h4>{t("Upload New File")}</h4>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={_handleCloseModal}
              ></i>
            </div>
            <div className="modal-body mx-3">
              <div className="row py-1">
                <div className="col-3">{t("File")}</div>
                <div className="col-9">
                  <MyFileInput
                    text="(.xlsx, .pdf, .txt, image/*)"
                    theme={theme}
                    fontSize={"15px"}
                    file={file}
                    onChange={(e) => _handleChangeFile(e)}
                  />
                </div>
              </div>
              <div className="row py-1">
                <div className="col-3">{t("Category")}</div>
                <div className="col-9">
                  <MyCreatableSelector                    
                    theme={theme}
                    id={"siteRelevantUpload"}
                    value={categoryList.find((option) => option.value === selectedCategory) || creatableSelectOption}
                    options={categoryList}
                    menuListColor={Colors.Dark[0]}
                    _handleSelect={(e) => _handleSelectCategory(e)}
                    placeholder={ t("Select Category") }
                    textChange={(e) => _handleChangeText(e)}
                  />
                </div>
              </div>
              <div className="row py-1">
                <div className="col-3">{t("Description")}</div>
                <div className="col-9">
                  <MyInput
                    id={selectedMonth+"_"+selectedYear}
                    required={true}
                    isTextArea={true}
                    value={uploadDescription?uploadDescription:""}
                    onChange={(e) => _handleDescriptionChange(e, selectedMonth+"_"+selectedYear)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center mx-3" id={btnId}>
              <MyButton
                style={{ width: "100%" }}
                text={t("Upload Now")}
                customColor={Colors.water}
                onClick={() => _handleUploadFileAndAddDescription(buildingId, frameworkId, selectedMonth+"_"+selectedYear,btnId, scopeType)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);
export default SurveyFileUploadingModal;
