import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { CreateNewKPI } from './CreateNewKPI';
import { useEffect, useState } from "react";

export const EditKPIModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleCloseKPIModal } = props.store.kpiManagement;
        useEffect(() => {

        }, []);

        return (
            <div
                className={`modal fade ${theme}`}
                id="kpiEditModal"
                tabIndex="-1"
                aria-labelledby="kpiEditModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div 
                        className="modal-content m-1"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h5>Editing KPI</h5>
                            <i
                                onClick={()=> _handleCloseKPIModal()}
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body px-4" style={{fontSize: 14}}>
                            <CreateNewKPI />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);