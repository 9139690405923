import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseURLImage } from "../../API/URL";
import { MyButton } from "../../Tools";
import { FrameworkModal } from "../TargetSetting/FrameworkModal";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FrameworkDeleteConfirmationModel } from "./FrameworkDeleteConfirmationModel";
import { FrameworkEditModal } from "./FrameworkEditModal";
import { GetBaselineModal } from "../SpecialOneTime/GetBaselineModal";

export const SurveyHeaderbar = inject("store")(
    observer((props) => {
        const { _handleSurveyClick, frameWorkIndex, surveyFramework, isDisabled, _deleteCurrentFramework,buildingId  } =
            props.store.survey;
        const { getBuildingList, permittedBuilding, theme, accessmentList,showBaseLineModal } = props.store.common;
        const { _handlePageClick, selectedIndex, _handleCurrentBuildingId } =
            props.store.target;
        // const [buildingId, setBuildingId] = useState(null);
        // const [frameworkId, setFrameworkId] = useState(null);
        const frameworkName = surveyFramework?.[frameWorkIndex]?.name;
        const { t } = useTranslation("survey");
        const frameworkId = toJS(surveyFramework[frameWorkIndex]?.framework_id);

        useEffect(() => {
            if (selectedIndex !== null && permittedBuilding !== null) {
                // const buildingId = permittedBuilding[selectedIndex]?._id;
                // const frameworkId = surveyFramework?.[frameWorkIndex]?.framework_id;
                // setBuildingId(buildingId);
                // setFrameworkId(frameworkId);
                _handleCurrentBuildingId(buildingId);
            }
        }, [selectedIndex, permittedBuilding]);

        useEffect(() => {
            getBuildingList();
        }, [isDisabled])
        const selectedStyle = {
            background: theme === "dark" ? Colors.lightBlue : Colors.white,
            borderLeft: `4px solid ${Colors.orange}`
        };
        // 
        const defaultStyle = (id, active) => {
            return {
                color: theme === "dark" ? Colors.dark[4] : Colors.light.textColor,
                cursor: active ? "pointer" : 'not-allowed',
                transition: "0.1s",
                opacity: 0.7,
                borderLeft: `4px solid ${Colors.darkBlue}`,
            }

        };
        const navigate = useNavigate();
        const removeDuplicate = surveyFramework.filter((v, i, a) => a.findIndex(v2 => (v2.name === v.name)) === i)

        return (
            <div
                className="me-2 text-center"
                style={{
                    background: theme === "dark" ? Colors.darkBlue : Colors['light'].active,
                    // minWidth:300,
                    minHeight: "100vh",
                }}>

                {/* Floating Button */}
                <Fab
                    mainButtonStyles={{
                        width: 50,
                        height: 50,
                        backgroundColor: Colors.water
                    }}
                    style={{
                        bottom: 0,
                        left: 0
                    }}
                    icon={<i className="bi bi-gear-fill"></i>}
                    alwaysShowTitle={false}
                >
                    <Action
                        style={{
                            backgroundColor: Colors.water,
                            color: Colors.white
                        }}
                        text={t("Delete Current Framework")}
                        onClick={() => { }}
                    >   <span
                        className="col-6"
                        data-bs-toggle="modal"
                        data-bs-target="#confirmFrameworkDeleteModel"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                            <i className="bi bi-trash3-fill" />
                        </span>

                    </Action>

                    <Action
                        style={{
                            backgroundColor: Colors.water,
                            color: Colors.white
                        }}
                        text={t("Add Framework")}
                        data-bs-toggle="modal"
                        data-bs-target="#frameworkEditModal"
                    ><i className="bi bi-file-earmark-plus" /></Action>

                    <Action
                        style={{
                            backgroundColor: Colors.water,
                            color: Colors.white
                        }}
                        text={t("Site Relevant Data")}
                        onClick={() => navigate(
                            `/site-relevant/?frameworkId=${frameworkId}&buildingId=${buildingId}`
                        )}
                    ><i className="bi bi-bar-chart-fill" /></Action>

                </Fab>

                {
                    removeDuplicate.map((v, k) => (
                        <div
                            key={k}
                            className="py-3"
                            title={(!v.id || !v.active) ? t("Survey not allowed") : v.name}
                            style={k === frameWorkIndex ? selectedStyle : defaultStyle(v.framework_id, v.active)}
                            onClick={() => (v?.active === true && v.framework_id) && _handleSurveyClick({ index: k, surveyTitle: v.name, navigate: navigate, accessmentList: accessmentList })}
                        >
                            {/* process.env.PUBLIC_URL + "/sites/" + v.image.toLowerCase() + ".png" */}
                            <img src={baseURLImage + v.imageURL} alt={v.name} width={45} style={{ borderRadius: 5 }} />

                        </div>
                    ))
                }
                {/* <div style={{ position: 'fixed', bottom: 90, left: 0, }}>
                    <MyButton
                        text={t("Site Relevant Data")}
                        type={"button"}
                        small
                        id={"siteRelevantBtn"}
                        onClick={() => navigate(
                            `/site-relevant/?frameworkId=${frameworkId}&buildingId=${buildingId}`
                        )}
                        customHoverColor={Colors.water}
                        style={{
                            color: invertDark(theme), fontSize: 12, width: '92px'
                        }}
                    />
                </div>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="d-flex justify-content-center align-items-center p-4" title='Add New Framework'
                    data-bs-toggle="modal"
                    data-bs-target="#frameworkModal"
                    style={{ cursor: 'pointer', position: 'fixed', bottom: 10 }}
                >
                    <span style={{ position: 'fixed', bottom: 15, fontSize: 12, color: invertDark(theme) }}>{t('Add framework')}</span>
                    <div className="d-flex justify-content-center align-items-center" style={{ boxShadow: hover ? '' : '0 0 8px black', height: 40, width: 40, background: !hover ? Colors.water : 'rgb(41,130,225,90%)', fontSize: 20, borderRadius: '50%' }}>
                        <i className="bi bi-plus-lg"></i>
                    </div>
                    
                </div> */}
                {<FrameworkEditModal  buildingId={buildingId} />}
                {showBaseLineModal && <GetBaselineModal />}
                {<FrameworkDeleteConfirmationModel frameworkName={frameworkName} _handleConfirmDelete={_deleteCurrentFramework} theme={theme} frameworkId={frameworkId}/>}
            </div>
        );
    })
)