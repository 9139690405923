import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import { fetchAnalysisReport } from "../API/api.analysisReport";

class AnalysisReport {
    yearOptions = [];
    selectedYear = null;
    analysisReportData = [];
    reportTblLoading = false;

    constructor() {
        makeObservable(this, {
            yearOptions: observable,
            selectedYear: observable,
            analysisReportData: observable,
            reportTblLoading: observable,

            _handleGetYearOptions: action.bound,
            _handleSelectedYear: action.bound,
            getAnalysisReportData: action.bound,
        })
    }

    _handleGetYearOptions = () => {
        let years = [];
        const currentYear = new Date().getFullYear()
        for (let i = 2018; i <= currentYear; i++) {
            years.push({
                label: String(i),
                value: String(i)
            })
        }
        this.yearOptions = years;
        this.selectedYear = String(currentYear)
    }

    _handleSelectedYear = (e) => {
        this.selectedYear = e.value;
    }

    getAnalysisReportData = () => {
        this.reportTblLoading = true;
        fetchAnalysisReport(this.selectedYear,(err, data) => {
            if (data && data.success) {
              this.analysisReportData = data.payload || [];
            } else {
              toast.error(err);
            }

            this.reportTblLoading = false;
          }); 
        
    }
}

export default new AnalysisReport();
