import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector, MyDatepicker } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const TransportationModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const {
            _handleCreateEquipmentSetup,
            createTransportationList,
            _handleChangeInput,
            _handleSelectEquip,
            _handleChangeDate,
            _handleGetEmissionByCalculatedMethod,
            isEdit,
            _handleUpdateEquipmentSetup
        } = props.store.survey;

        const { t } = useTranslation("transport");

        const clearData = () => {
            createTransportationList.category= "";
            createTransportationList.year = "";
            createTransportationList.transportMode= "";
            createTransportationList.vehicleType= "";
            createTransportationList.activityType= true;
            createTransportationList.active= "";
            createTransportationList.unit= "";
            createTransportationList.name= "";
            createTransportationList.date = new Date();
            createTransportationList.distance = "";
            createTransportationList.carbonEmission = "";
        }

        useEffect(() => {
            if(
                createTransportationList.category &&
                createTransportationList.vehicleType && 
                createTransportationList.unit
            ){ 
                _handleGetEmissionByCalculatedMethod('scope3');
            }
        },[createTransportationList.category, createTransportationList.vehicleType, createTransportationList.unit])

        return (
            <div
                className={`modal fade ${theme}`}
                id="addEquipmentModal"
                tabIndex="-1"
                aria-labelledby="addEquipmentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>{t("Add Data for Transportation")}</h4>
                            <i
                                onClick={() => clearData()}  
                                style={{ cursor: "pointer",color: 'rgb(198, 198, 198)' }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                disabled={props.isNewDailyData}
                                                label={t("Year")}
                                                menuListColor={Colors.Dark[0]}
                                                options={yearOption}
                                                value={
                                                    yearOption.filter(
                                                        (v) => v.label === createTransportationList.year
                                                    ) || ""
                                                }
                                                placeholder={t("Select year")}
                                                _handleSelect={(e) =>
                                                    _handleSelectEquip("year", e, "transportation")
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Category")}
                                                menuListColor={Colors.Dark[0]}
                                                options={categoryOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                                                value={
                                                    categoryOption.filter(
                                                        (v) => v.label === createTransportationList.category
                                                    ) || ""
                                                }
                                                placeholder={t("Select Category")}
                                                _handleSelect={(e) => _handleSelectEquip("category", e, "transportation")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Mode of transport")}
                                                menuListColor={Colors.Dark[0]}
                                                options={transportOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                                                value={
                                                    transportOption.filter(
                                                        (v) => v.label === createTransportationList.transportMode
                                                    ) || ""
                                                }
                                                placeholder={t("Select Mode of transport")}
                                                _handleSelect={(e) =>
                                                    _handleSelectEquip("transportMode", e, "transportation")
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Vehicle Type")}
                                                menuListColor={Colors.Dark[0]}
                                                options={(vehicleOption.filter(dt => dt.transportMode === createTransportationList.transportMode))?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                                                value={
                                                    vehicleOption.filter(
                                                        (v) => v.label === createTransportationList.vehicleType
                                                    ) || ""
                                                }
                                                placeholder={t("Select Vehicle Type")}
                                                _handleSelect={(e) => _handleSelectEquip("vehicleType", e, "transportation")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Activity Type")}
                                                menuListColor={Colors.Dark[0]}
                                                options={activityOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                                                value={
                                                    activityOption.filter(
                                                        (v) => v.label === createTransportationList.activityType
                                                    ) || ""
                                                }
                                                placeholder={t("Activity Type")}
                                                _handleSelect={(e) => _handleSelectEquip("activityType", e, "transportation")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>                                                    
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                    </div>
                                                )}
                                                disabled={props.isNewDailyData}
                                                label={t("Unit")}
                                                menuListColor={Colors.Dark[0]}
                                                options={unitOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                                                value={
                                                    unitOption.filter(
                                                        (v) => v.label === createTransportationList.unit
                                                    ) || ""
                                                }
                                                placeholder={t("Unit")}
                                                _handleSelect={(e) => _handleSelectEquip("unit", e, "transportation")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-end">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <MyInput                                            
                                                disabled={props.isNewDailyData}
                                                label={t("Name")}
                                                required={true}
                                                id={"name"}
                                                type={"text"}
                                                value={createTransportationList.name}
                                                onChange={(e) => _handleChangeInput(e, "transportation")}
                                                placeHolder={t("Transportation Name")}
                                            />
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            <MyDatepicker
                                                label={t("Date")}
                                                _handleChange={(e) => _handleChangeDate(e, "transportation")}
                                                value={createTransportationList.date}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-end">
                                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                                    <div className="row d-flex">
                                        <div className="col">
                                            {
                                                createTransportationList.category &&
                                                createTransportationList.vehicleType && 
                                                createTransportationList.unit ? 
                                                (
                                                    <MyInput
                                                        disabled={true}
                                                        label={"Emission Factor Value"}
                                                        required={true}
                                                        type={"number"}
                                                        id={"emissionFactorValue"}
                                                        value={createTransportationList.emissionFactorValue}
                                                        placeHolder={"Emission Factor Value"}
                                                    />
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                </div> 
                                
                            </div>                   
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span data-bs-dismiss="modal" aria-label="Close">
                                <MyButton
                                    style={{ width: 200 }}
                                    text={t("Cancel")}
                                    customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                                    customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                                    onClick={() => clearData()}
                                />
                            </span>
                            <span id={'create_setUp'}>
                                <MyButton
                                onClick={() => isEdit ? _handleUpdateEquipmentSetup("transportation") : _handleCreateEquipmentSetup("transportation")}
                                style={{ width: 200 }}
                                text={isEdit ? t("Update") : t("Add")}
                                customColor="#066CFF"
                                customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);


const categoryOption = [
    { value: 1, label: "Upstream T&D" },
    { value: 2, label: "Business Travel" },
    { value: 3, label: "Employee Commute" },
];

const unitOption = [
    { value: 1, label: "mile" },
    { value: 2, label: "km" },
    { value: 3, label: "passenger-mile" },
    { value: 4, label: "passenger-km" }
];

const yearOption = [
    { value: 1, label: "2018" },
    { value: 2, label: "2019" },
    { value: 3, label: "2020" },
    { value: 4, label: "2021" },
    { value: 5, label: "2022" },
    { value: 6, label: "2023" },
]

const activityOption = [
    { value: 1, label: "Distance" },
    { value: 2, label: "Passenger Distance" },
    { value: 3, label: "Vehicle Distance" },
    { value: 4, label: "Weight Distance" },
]

const transportOption = [
    { value: 1, label: "Car" },
    { value: 2, label: "Air" },
    { value: 3, label: "Bus" },
    { value: 4, label: "Rail" },
    { value: 5, label: "Ferry" },
]

const vehicleOption = [
    { value: 1, transportMode: "Air", label: "Air Travel - Short Haul (< 300 miles)" },
    { value: 2, transportMode: "Air", label: "Air Travel - Medium Haul (>= 300 miles, < 2300 miles)" },
    { value: 3, transportMode: "Air", label: "Air Travel - Long Haul (>= 2300 miles)" },
    { value: 4, transportMode: "Car", label: "Passenger Car A" },
    { value: 5, transportMode: "Car", label: "Light-Duty Truck B" },
    { value: 6, transportMode: "Car", label: "Motorcycle" },
    { value: 7, transportMode: "Bus", label: "Bus" },
    { value: 8, transportMode: "Rail", label: "Intercity Rail (i.e. Amtrak) C" },
    { value: 9, transportMode: "Rail", label: "Commuter Rail D" },
    { value: 10, transportMode: "Rail", label: "Transit Rail (i.e. Subway, Tram) E" },
]
