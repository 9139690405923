import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyDatepicker } from "../../Tools";

export const GetBaselineModal = inject("store")(
    observer((props) => {
        const { theme, hideBaseLineModal } = props.store.common;
        const { currentBuildingId } = props.store.target;
        const { baselineYear, setBaselineYear, _createBaselineYear, _handleSetGHGBaseline } = props.store.specialOneTime;
        const {showGHGModal} = props.store.building;
        const {from} = props;
        return (
            <div
                className={`modal fade ${theme} show`}
                id="getBaselineModal"
                tabIndex="-1"
                aria-labelledby="getBaselineModal"
                aria-hidden="true"
                role="dialog"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog modal-md">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors.buttonHoverColor,
                            borderRadius: 10,
                            border:'none',
                            boxShadow:'0 4px 20px #121212'
                        }}
                    >
                        <div className="modal-header mx-3 px-0 d-flex align-items-start">
                            <span>
                                <h6
                                    className="modal-title"
                                    style={{ color: theme === "dark" ? "#fff" : "#000" }}
                                    id="SelectLocationModalLabel"
                                >
                                    Please select "Baseline Year" to add GHG framework
                                </h6>

                                <i class="bi bi-info-circle mt-3 me-2"></i>Baseline year is a minimum year or starting year used for comparisons.
                            </span>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => hideBaseLineModal()}
                            ></i>
                        </div>
                        <div className="modal-body" style={{ color: invertDark(theme) }}>

                            <div className="my-4">
                                <MyDatepicker
                                    background={Colors.lightBlue}
                                    value={baselineYear} _handleChange={setBaselineYear}
                                    inputFormat="yyyy"
                                    views={["year"]}
                                    label="Baseline Year"
                                />
                            </div>
                        </div>

                        <div className="modal-footer d-flex justify-content-center w-100">
                            <button
                                type="button"
                                // onClick={_handleSetGHGBaseline}
                                onClick={() => from==='buildingRegistration'?_handleSetGHGBaseline():_createBaselineYear(currentBuildingId)}
                                className="btn btn-primary w-100"
                                data-bs-dismiss="modal"
                                style={{ cursor: "pointer" }}

                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
