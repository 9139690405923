import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useEffect } from "react";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Selector } from "../../Tools";
import { GroupListTable } from "../../Components/AuditGroup/GroupListTable";

export const AuditGroupListContainer = inject("store")(
  observer((props) => {

    const { theme,yearOptions } = props.store.common;
    const { getGroupListData, groupList, setYearSelectorDatum, selectedYear, _handleSelectSelector, groupListTblLoading,getBaseLineYear } = props.store.audit;
    const { t } = useTranslation("audit");
    const t1 = useTranslation("one_time");
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const companyId = params.get("companyId");


    useEffect(() => {
      if (selectedYear) getGroupListData(companyId, selectedYear)
    }, [selectedYear])

    useEffect(() => {
      // setYearSelectorDatum();
      if (!selectedYear) {
        _handleSelectSelector('year', 2022)
      }
      getGroupListData(companyId)
      getBaseLineYear(companyId)
    }, [])

    return (
      <div className="m-4 mt-3">
        <div className="d-flex justify-content-end pb-3">
          <span
            style={{
              fontSize: 20,
              color: invertDark(theme),
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => navigate("/auditor-overview")}
          >
            <i className="bi bi-arrow-left-circle-fill pe-3"></i>
            {t('Back')}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="d-flex align-items-center">
            <div style={{ color: Colors[theme].textColor }}>
              <div className="h5 mb-1">
                {t('Emission Data Verification')}
                <i className="bi bi-exclamation-circle ms-3" style={{ fontSize: 13 }}></i>
              </div>
              <div className="text-secondary" style={{ fontSize: 15 }}>
                {t('Please Select To Verify Group')}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{}}>
            {/* <span className="mx-2">Year :</span> */}
            <div style={{ width: 100 }}>
              <Selector
                placeholder={t("Year")}
                menuListColor={Colors.Dark[0]}
                options={yearOptions}
                value={yearOptions?.filter(
                  (f) => f.value === selectedYear
                )}
                _handleSelect={(e) => _handleSelectSelector('year', e.value)}
              />
            </div>
          </div>
        </div>
        {
          !groupListTblLoading ?
            (
              groupList?.length === 0 ?
              <div className="text-center text-muted"><h5><i className="bi bi-file-earmark-fill pe-1" />{t1.t("No Data")}</h5></div>
                :
                <GroupListTable data={groupList} />
            )
            :
            <div className="d-flex justify-content-center">
              <i className="spinner-border mt-2" />
            </div>
        }

      </div>
    );
  })
);

const dummyData = [
  {
    "group": "Group 1",
    "totalAsset": 140,
    "verifiedCount": 100,
    "notVerifiedCount": 40,
    "rejectCount": 0,
    "notApplicableCount": 0,
    "actionCount": 140,
    "attachFileCount": 100,
    "auditStatus": "Certified",
    "isCertifed": true,
    "downloadLink": "/certified/{groupId}"
  },
  {
    "group": "Group 2",
    "totalAsset": 140,
    "verifiedCount": 100,
    "notVerifiedCount": 40,
    "rejectCount": 0,
    "notApplicableCount": 0,
    "actionCount": 140,
    "attachFileCount": 100,
    "auditStatus": "Not Ready",
    "isCertifed": false,
    "downloadLink": null
  }
]