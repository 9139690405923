import { toJS } from "mobx";
import React, { useEffect } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dataKW from "../../Assets/kingWan.json";
import { Colors, invertDark } from "../Config/color.config";


const DetailChart = (props) => {
  const { userName, title, consumptionChartData, theme, companyName, trackingChart } = props;
  const data = [
    { name: "Jan", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 600, plasticUsage: 500 },
    { name: "Feb", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 447, plasticUsage: 656 },
    { name: "Mar", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 559, plasticUsage: 725 },
    { name: "Apr", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 356, plasticUsage: 828 },
    { name: "May", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 631, plasticUsage: 539 },
    { name: "Jun", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 538, plasticUsage: 343 },
    { name: "Jul", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 443, plasticUsage: 663 },
    { name: "Aug", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 0, plasticUsage: 0 },
    { name: "Sep", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 0, plasticUsage: 0 },
    { name: "Oct", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 0, plasticUsage: 0 },
    { name: "Nov", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 0, plasticUsage: 0 },
    { name: "Dec", Target: 900, EnergyConsumption: 0, waterConsumption: 0, greenMaterialUsage: 0, plasticUsage: 0 },
  ];
  // const data = [
  //   { name: "Jan", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  //   { name: "Feb", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  //   { name: "Mar", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  //   { name: "Apr", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0 },
  //   { name: "May", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  //   { name: "Jun", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  //   { name: "Jul", Target: 90, EnergyConsumption: 0,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  //   { name: "Aug", Target: 90, EnergyConsumption: 0 ,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0 },
  //   { name: "Sep", Target: 90, EnergyConsumption: 0 ,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0 },
  //   { name: "Oct", Target: 90, EnergyConsumption: 0 ,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0 },
  //   { name: "Nov", Target: 90, EnergyConsumption: 0 ,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0 },
  //   { name: "Dec", Target: 90, EnergyConsumption: 0 ,waterConsumption: 0,greenMaterialUsage: 0,plasticUsage: 0},
  // ];

  const plasticData = [
    { name: "Janurary", Target: 10000, EnergyConsumption: 0 },
    { name: "Feburary", Target: 10000, EnergyConsumption: 0 },
    { name: "March", Target: 10000, EnergyConsumption: 0 },
    { name: "April", Target: 10000, EnergyConsumption: 0 },
    { name: "May", Target: 10000, EnergyConsumption: 0 },
    { name: "June", Target: 10000, EnergyConsumption: 0 },
    { name: "July", Target: 10000, EnergyConsumption: 0 },
    { name: "August", Target: 10000, EnergyConsumption: 0 },
    { name: "September", Target: 10000, EnergyConsumption: 0 },
    { name: "October", Target: 10000, EnergyConsumption: 0 },
    { name: "November", Target: 10000, EnergyConsumption: 0 },
    { name: "December", Target: 10000, EnergyConsumption: 0 },
  ];

  const greenMaterialData = [
    { name: "Janurary", Target: 10000, EnergyConsumption: 0 },
    { name: "Feburary", Target: 10000, EnergyConsumption: 0 },
    { name: "March", Target: 10000, EnergyConsumption: 0 },
    { name: "April", Target: 10000, EnergyConsumption: 0 },
    { name: "May", Target: 10000, EnergyConsumption: 0 },
    { name: "June", Target: 10000, EnergyConsumption: 0 },
    { name: "July", Target: 10000, EnergyConsumption: 0 },
    { name: "August", Target: 10000, EnergyConsumption: 0 },
    { name: "September", Target: 10000, EnergyConsumption: 0 },
    { name: "October", Target: 10000, EnergyConsumption: 0 },
    { name: "November", Target: 10000, EnergyConsumption: 0 },
    { name: "December", Target: 10000, EnergyConsumption: 0 },
  ];

  const energyData = [
    { name: "Janurary", Target: 10000, EnergyConsumption: 0 },
    { name: "Feburary", Target: 10000, EnergyConsumption: 0 },
    { name: "March", Target: 10000, EnergyConsumption: 0 },
    { name: "April", Target: 10000, EnergyConsumption: 0 },
    { name: "May", Target: 10000, EnergyConsumption: 0 },
    { name: "June", Target: 10000, EnergyConsumption: 0 },
    { name: "July", Target: 10000, EnergyConsumption: 0 },
    { name: "August", Target: 10000, EnergyConsumption: 0 },
    { name: "September", Target: 10000, EnergyConsumption: 0 },
    { name: "October", Target: 10000, EnergyConsumption: 0 },
    { name: "November", Target: 10000, EnergyConsumption: 0 },
    { name: "December", Target: 10000, EnergyConsumption: 0 },
  ];

  const waterData = [
    { name: "Janurary", Target: 500, watrConsumption: 0 },
    { name: "Feburary", Target: 500, watrConsumption: 0 },
    { name: "March", Target: 500, watrConsumption: 0 },
    { name: "April", Target: 500, watrConsumption: 0 },
    { name: "May", Target: 500, watrConsumption: 0 },
    { name: "June", Target: 500, watrConsumption: 0 },
    { name: "July", Target: 500, watrConsumption: 0 },
    { name: "August", Target: 500, watrConsumption: 0 },
    { name: "September", Target: 500, watrConsumption: 0 },
    { name: "October", Target: 500, watrConsumption: 0 },
    { name: "November", Target: 500, watrConsumption: 0 },
    { name: "December", Target: 500, watrConsumption: 0 },
  ];
  const kwData = dataKW[new Date().getFullYear()]?.monthlyConsumption.map((v, k) => {
    return {
      name: v.month,
      Target: title === "energy" ? 10000 : 500,
      EnergyConsumption: v.energyConsumption,
      waterConsumption: v.waterConsumption,
    };
  });

  const data_KW =
    title === "energy"
      ? energyData.map((obj) => kwData?.find((o) => o.name === obj.name) || obj)
      : waterData.map((obj) => kwData?.find((o) => o.name === obj.name) || obj);

  const AxisLabel = ({ axisType, x, y, width, height, children }) => {
    const rot = -90;
    const cx = -300 / 2;
    const cy = (15);
    return (
      <text fill={invertDark(theme)} x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle">
        {children}
      </text>
    );
  };

  const YaxisLabel = (title === 'energy' || title === "Energy") ? 'kWh' :
    title === 'water' ? 'm³/h' :
      title === 'Water' ? "CuM" :
        title?.includes("Emission") ? "kg CO2" :
          'tonnes'

  // useEffect(() => {

  // }, [consumptionChartData])

  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <ComposedChart
        width={700}
        height={300}
        data={consumptionChartData?.length > 0 ? consumptionChartData : (companyName === 'Digital Edge' || companyName === 'Far East Orchard' ) ? null : data}
        margin={{
          top: 35,
          right: 30,
          left: 20,
          // bottom: 5
        }}
      >
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en').format(value)}
          dataKey={title === "Energy" ? "EnergyConsumption" : title === 'Water' ? "waterConsumption" : title === 'Green Material' ? 'greenMaterialUsage' : title === 'Waste' ? 'plasticUsage' : title === 'Scope 1 Emission' ? 'scope1Emission' : title === 'Scope 3 Emission' ? 'scope3Emission' : "scope2Emission"}
          // domain= {title === "Energy" ? [0, 1000000] : title === "Scope 1 Emission" && companyName === 'Enterprise SG' ? [0, 10] : title === "Scope 3 Emission" && companyName === 'Enterprise SG' ? [0, 10] : title === "Scope 2 Emission" ? companyName === 'Syscom' ? [0, 110000] : [0, 32000] : (title === "Water" && companyName !== 'Syscom') ? [0, 100] : [0, 1000]} 
          label={<AxisLabel axisType='yAxis'>{YaxisLabel}</AxisLabel>} 
        />
        <CartesianGrid vertical={false} stroke={ theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.2)' } />
        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
        <Legend />
        <Bar
          dataKey={title === "Energy" ? "EnergyConsumption" : title === 'Water' ? "waterConsumption" : title === 'greenMaterial' ? 'greenMaterialUsage' : title === 'Waste' ? 'plasticUsage' : title === 'Scope 1 Emission' ? 'scope1Emission' : title === 'Scope 3 Emission' ? 'scope3Emission' : "scope2Emission"}
          barSize={20}
          // fill={title === "energy" ? Colors.energy : Colors.water}
          fill={Colors.water}
        />
        {
          (((consumptionChartData?.length > 0 ? consumptionChartData : (companyName === 'Digital Edge' || companyName === 'Far East Orchard') ? null : data)?.[0]?.Target) && trackingChart !== "Monthly")
            ?
            <Line
              dataKey="Target"
              stroke="#ff0000"
              strokeWidth="1"
              strokeDasharray="3 5"
              dot={false}
            />
            : null
        }

      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default DetailChart;
