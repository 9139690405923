import * as React from "react";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import "./groupview.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Colors } from "../../Assets/Config/color.config";

export const AccordionViewHeader = inject("store")(
  observer((props) => {
    const { t } = useTranslation("groupView");
    const { selectedView, data, selectedGroupId, enableDelete } = props;
    const { editTypes, _handleEdits, _handleDialog, getAllGroups, _handleCancelEdits, _handleSetDelete, deleteAssetTbls, _handleCancelDelete, _handleDeleteAssets } = props.store.groupmanagement;
    const { _handleGetTableColumns, tableColumns } = props.store.common;
    const {setEditMode} = props.store.building;

    useEffect(() => {
      _handleGetTableColumns();
      getAllGroups();
    }, []);

    const dataLog = tableColumns.length > 0 ? tableColumns[0] : null;

    return (
      <div className="accordion-summary-main-div">
        {data ?
          (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Stack direction="row" spacing={1}>
                    <i className={data.icon} style={{ fontSize: "22px" }} />
                    <p>{t(data.title)}</p>
                    <div style={{ paddingTop: "5px" }} onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()} >
                      {
                        data.title == "Building" ? 
                        (
                          <span data-bs-toggle="modal"
                          data-bs-target="#buildingEditModal">
                            <Button
                              id={"addEquipment"}
                              title={t("Add New")}
                              size="small"
                              variant="contained"
                              onClick={() => setEditMode("")}
                              sx={{ padding: "5px 2px 2px", minWidth: "20px !important" }}>
                              <AddIcon className="edit-save-icon-btn" />
                            </Button>
                          </span>
                        ) :
                        (
                          <span data-bs-toggle="modal"
                            data-bs-target={"#scope" + dataLog?.dataColumns.filter(c => c.cat === data.title)[0]?.scope + "SetUpModal"}>
                            <Button
                              id={"addEquipment"}
                              title={t("Add New")}
                              size="small"
                              variant="contained"
                              onClick={(event) => _handleDialog(data.title)}
                              sx={{ padding: "5px 2px 2px", minWidth: "20px !important" }}>
                              <AddIcon className="edit-save-icon-btn" />
                            </Button>
                          </span>
                        )
                      }
                      
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={data?.title === "Building" ? 12 : 6} md={data?.title === "Building" ? 12 : 6} style={{ textAlign: "right" }}>
                        <p>{data?.title == "Building" ? data?.data.length+t("Buildings found") : t("Assets found",{count: data?.data.length})}</p>
                      </Grid>
                      {
                        data?.title === "Building" ? null : 
                        (
                          <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                            {editTypes.includes(selectedGroupId + selectedView + data.title) ?
                              (
                                <div className="" onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}  >
                                  <Button
                                    className="me-1"
                                    onClick={e => _handleCancelEdits(selectedGroupId + selectedView + data.title, data.title)}
                                    size="small"
                                    variant="contained"
                                    endIcon={<i className="bi bi-x"></i>}
                                    style={{
                                      background: '#f44e3b'
                                    }}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {t("CANCEL")}
                                  </Button>

                                  <Button
                                    onClick={e => _handleEdits(selectedGroupId + selectedView + data.title, data.title)}
                                    size="small"
                                    variant="contained"
                                    endIcon={<i className="bi bi-save2"></i>}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {t("SAVE")}
                                  </Button>

                                </div>
                              )
                              :
                              deleteAssetTbls.includes(selectedGroupId + selectedView + data.title) ?
                                (<div className="" onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}  >
                                  <Button
                                    className="me-1"
                                    onClick={e => _handleCancelDelete(selectedGroupId + selectedView + data.title, data.title)}
                                    size="small"
                                    variant="contained"
                                    endIcon={<i className="bi bi-x"></i>}
                                    style={{
                                      background: '#f44e3b'
                                    }}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {t("CANCEL")}
                                  </Button>
                                  <Button
                                    onClick={e => _handleDeleteAssets(selectedGroupId + selectedView + data.title, data.title)}
                                    className="me-1"
                                    size="small"
                                    variant="contained"
                                    color="error"
                                    endIcon={<i className="bi bi-trash"></i>}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {t("Delete")}
                                  </Button>

                                </div>)
                                :
                                selectedView !== "Emission View" ?
                                (
                                  <div onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}  >
                                    {
                                      selectedView === 'Emission Activity' ?
                                        <Button
                                          onClick={e => _handleSetDelete(selectedGroupId + selectedView + data.title)}
                                          className="me-1"
                                          size="small"
                                          variant="contained"
                                          color="error"
                                          endIcon={<i className="bi bi-trash"></i>}
                                          sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                          {t("Delete")}
                                        </Button>
                                        : null
                                    }

                                    <Button
                                      onClick={e => _handleEdits(selectedGroupId + selectedView + data.title, data.title)}
                                      size="small"
                                      variant="contained"
                                      endIcon={<i className="bi bi-pencil-square"></i>}
                                      sx={{ paddingTop: 0, paddingBottom: 0 }}
                                    >
                                      {t("EDIT")}
                                    </Button>
                                  </div>
                                )
                                : null
                            }
                          </Grid>
                        )
                      }
                      
                      {/* {
                        data?.title == "Building" ? null :
                        (
                          <Grid item xs={7} md={7} style={{ textAlign: "right" }}>
                            {editTypes.includes(selectedView + data.title) ?
                              (
                                <div className="d-flex justify-content-between align-items-center" onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}  >
                                  <Button
                                    className="me-1"
                                    onClick={e => _handleCancelEdits(selectedView + data.title, data.title)}
                                    size="small"
                                    variant="contained"
                                    endIcon={<i className="bi bi-x"></i>}
                                    style={{
                                      background: '#f44e3b'
                                    }}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {t("CANCEL")}
                                  </Button>
                                  <Button
                                    onClick={e => _handleEdits(selectedView + data.title, data.title)}
                                    size="small"
                                    variant="contained"
                                    endIcon={<i className="bi bi-save2"></i>}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {t("SAVE")}
                                  </Button>
                                </div>
                              )
                              :
                              (
                                <div onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}  >
                                  <Button
                                    onClick={e => _handleEdits(selectedView + data.title, data.title)}
                                    size="small"
                                    variant="contained"
                                    endIcon={<i className="bi bi-pencil-square"></i>}
                                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                                  >
                                    {t("EDIT")}
                                  </Button>
                                </div>
                              )
                            }
                          </Grid>
                        )
                      } */}
                    </Grid>
                  </Box>

                </Grid>
              </Grid>
            </Box>
          )
          :
          (
            ""
          )
        }
      </div>
    )
  })
)