import { ProgressBar, HoverProgressBar } from "../../Tools/ProgressBar";
import { useState, useEffect } from "react";
import { MySlider } from "../../Tools";
import { Selector } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { baseURLImage } from "../../API/URL";
import { toJS } from "mobx";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { ThousandSeparator } from "../../Assets/Config/thousandSeparator.config";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";

export const Tracking_Table = inject("store")(
  observer((props) => {

    const { column, siteData, isDetail, targetSettingData, focusArea, companyName, countryOptions } = props;
    const { _openNewTab, individualTrackingData, changesTotal, totalBaselineYr, totalCurrentYr, _handleBuildingTypeChange, selectedBuildingType, selectedTrackingTblYear, selectedTrackingTblCountry } = props.store.tracking;
    const { addDefaultSrc, permittedBuilding, theme, getBuildingList } = props.store.common;
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t } = useTranslation("tracking");

    const navigate = useNavigate();
    useEffect(() => {
      if (window.innerHeight > 900) {
        setRowsPerPage(8);
      }
    }, [window.innerHeight]);

    useEffect(() => {
      changesTotal(individualTrackingData);
    }, [individualTrackingData]);

    const headCell = column.map(v => {
      return {
        id: v === "Site" ? "siteName" : v,
        numberic: false,
        label: v
      }
    });

    const draftData = (individualTrackingData?.length > 0 ? individualTrackingData?.map((v, k) => {
      return {
        allItem: "40",
        consumptionCurrentYr: v.current?.toFixed(2),
        baselineYear: v.baseline_year,
        consumptionLastYr: v.baseline?.toFixed(2),
        estDate: "Q1 2023",
        greenItem: "25",
        image: v.photo,
        key: 0,
        progress: Number.isInteger(v.total_progress) ? v.total_progress : v.total_progress?.toFixed(2) || 0,
        siteName: v.building,
        buildingType: v.buildingType,
        category: v.category,
        country: v.country
      }
    }) :
      companyName === 'Enterprise SG' ?
        focusArea === 'Scope 1 Emission' ?
          ([{
            category: "Mobile Combustion",
            consumptionCurrentYr: 4.04,
            consumptionLastYr: 20.88,
            progress: 180
          }]) :
          (focusArea !== 'Scope 3 Emission' ?
            permittedBuilding?.map((v, k) => {
              return {
                allItem: "40",
                consumptionCurrentYr: '-',
                consumptionLastYr: '-',
                estDate: "Q1 2023",
                greenItem: "25",
                image: v.photo,
                key: 0,
                progress: 0,
                siteName: v.buildingName,
                buildingType: v.buildingType,
              }
            }) :
            siteData?.map((v, k) => {
              return {
                ...v,
                key: k
              }
            })
          ) :
        (companyName === 'All' || companyName === 'EverComm') ? siteData?.map((v, k) => {
          return {
            ...v,
            key: k
          }
        })
          : companyName === 'PONTIAC' ?
            siteData?.map((v, k) => {
              return {
                ...v,
                key: k
              }
            })
            : permittedBuilding?.map((v, k) => {
              return {
                allItem: "0",
                consumptionCurrentYr: '-',
                consumptionLastYr: '-',
                estDate: "Q1 2023",
                greenItem: "0",
                image: v.photo,
                key: 0,
                progress: 0,
                siteName: v.buildingName,
                buildingType: v.type.building_type,
              }
            }))?.filter(v2 => v2.buildingType !== 'Other');

    let data;

    if (selectedBuildingType === 'All' && selectedTrackingTblCountry === "All") {
      data = draftData;
      changesTotal(data);
    } else if (selectedBuildingType === "All" && selectedTrackingTblCountry !== "All") {
      data = draftData.filter(v => v.siteName.split(', ')[v.siteName.split(', ').length - 1] === selectedTrackingTblCountry)?.sort((a, b) => b?.progress - a?.progress)
      changesTotal(data);
    }
    else if (selectedBuildingType !== "All" && selectedTrackingTblCountry === "All") {
      data = draftData.filter((v) => v.buildingType === selectedBuildingType)?.sort((a, b) => b?.progress - a?.progress)
      changesTotal(data);
    } else if (selectedBuildingType !== "All" && selectedTrackingTblCountry !== "All") {
      data = draftData.filter((v) => v.buildingType === selectedBuildingType && v.siteName.split(', ')[v.siteName.split(', ').length - 1] === selectedTrackingTblCountry)?.sort((a, b) => b?.progress - a?.progress)
      changesTotal(data);
    }

  // const data = (selectedBuildingType === 'All' && selectedTrackingTblCountry === "All"  ? draftData : draftData.filter((v) => v.buildingType === selectedBuildingType ))?.sort((a, b) => b?.progress - a?.progress);

    const buildingTypeOptions = [...new Set(draftData?.map(v => v.buildingType))]?.map(v1 => ({
      value: v1,
      label: v1
    }))

    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    };

    const descendingComparator = (a, b, orderBy) => {
      orderBy = orderBy === 'Electricity Consumption kWh (Baseline Year)' ? 'consumptionLastYr' :
                orderBy === 'Electricity Consumption kWh (Current Year)' ? 'consumptionCurrentYr' :
                orderBy === '2025Target Progress' ? 'progress' : orderBy
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };

    const handleChangePage = (newPage) => {
      setPage(newPage);

    };

    const handleChangeRowsPerPage = (e) => {
      setRowsPerPage(e.target.value);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const useStyles = makeStyles({
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0px 3px",
      },
    });

    const classes = useStyles();

    const StyledTableRow = withStyles((theme) => ({
      root: {
        maxHeight: "50px",
        borderRadius: "10px",
        margin: "20px",
        "&:hover": {
          // backgroundColor: `${Colors[theme].active} !important`,
        },
      },
    }))(TableRow);

    const style = {
      color: invertDark(theme),
      textAlign: "left",
      fontSize: 14,
      padding: "5px 0px",
      borderBottom: `1px solid ${Colors.Dark[2]}`,
      paddingLeft: 5,
    };

    
    if (data || targetSettingData) {
      return (
        <div style={{ position: "relative" }}>
          <TableContainer
            component={Paper}
            id="tblTrackingContainer"
            style={{ background: "transparent", boxShadow: "none", overflow: "auto", height: companyName !== 'Digital Edge' && companyName !== "ClientA" ? null : '51vh' }}
          >
            <Table
              id="custom_tracking_tbl"
              className={classes.table}
              stickyHeader
              aria-label="customized table"
            > {
            }
              <SortableTHead
                id={"id"}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCell}
                theme={theme}
                _handleBuildingTypeChange={_handleBuildingTypeChange}
                selectedBuildingType={selectedBuildingType}
                companyName={companyName}
                buildingTypeOptions={buildingTypeOptions}
                focusArea={focusArea}
                selectedYear={selectedTrackingTblYear}
                selectedCountry={selectedTrackingTblCountry}
                countryOptions={countryOptions}
              />
              <TableBody>
                {isDetail ?
                  (stableSort(data, getComparator(order, orderBy)))
                    // .slice((page * rowsPerPage - rowsPerPage), page * rowsPerPage))
                    .map((v, k) => {
                      return (
                        <StyledTableRow
                          key={k}
                          hover
                        >
                          <TableCell style={style}>
                            {v.year}
                          </TableCell>
                          <TableCell style={style}>
                            {v.energyConsumption.toFixed(2)}
                          </TableCell>
                          <TableCell style={style}>
                            {v.delta}
                            {v.delta !== "-" && "%"}
                          </TableCell>
                          <TableCell style={style}>
                            {" "}
                            {v.success ? (
                              <i
                                className="bi bi-check2"
                                style={{ color: Colors.greenMaterial, fontSize: 20 }}
                              ></i>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </StyledTableRow>
                      )
                    })
                  : targetSettingData ?
                    stableSort(targetSettingData, getComparator(order, orderBy))
                      .slice((page * rowsPerPage - rowsPerPage), page * rowsPerPage)
                      .map((v, i) => {
                        return (
                          <StyledTableRow
                            key={i}
                            hover
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              style={style}
                            >
                              <img
                                src={`dataSources/${v.image}`}
                                width={25}
                                height={25}
                              ></img>
                            </TableCell>
                            <TableCell style={style}>
                              {v.dataSource}
                            </TableCell>
                            <TableCell style={style}>
                              <span title={`Baseline Year: ${v.baselineYear || "-"}`}>{v.baseLine}</span>
                            </TableCell>
                            <TableCell style={style}>
                              <MySlider
                                baseLine={v.baseLine}
                                value={v.progressTarget}
                                theme={theme}
                              />
                            </TableCell>
                          </StyledTableRow>
                        );
                      })
                    : data &&
                    stableSort(data, getComparator(order, orderBy))
                      .slice(((page * (companyName !== 'Digital Edge' && companyName !== "ClientA" ? rowsPerPage : data.length)) - (companyName !== 'Digital Edge' && companyName !== "ClientA" ? rowsPerPage : data.length)), page * (companyName !== 'Digital Edge' && companyName !== "ClientA" ? rowsPerPage : data.length))
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((v, k) => (
                        <StyledTableRow
                          key={k}
                          style={{
                            cursor: "pointer",
                            border: "0px solid rgba(255, 255, 255, 0.1)",
                          }}
                          onClick={() => {
                            // const buildingId = toJS(permittedBuilding).find(v=> v.buildingName ===  v?.siteName)?._id;
                            // navigate(`/tracking-detail?buildingId=${buildingId}`);
                            _openNewTab({ siteName: v?.siteName, category: v?.category, focusArea: focusArea, siteImage: v.image, permittedBuilding: permittedBuilding, navigate: navigate });
                          }}
                        >
                          <TableCell style={style} colSpan={2}>
                            <img
                              onError={addDefaultSrc}
                              className="me-2"
                              src={(companyName === 'Enterprise SG' && focusArea === 'Scope 3 Emission') ? '/sites/plane1.png' : (companyName === 'Enterprise SG' && focusArea === 'Scope 1 Emission') ? '/sites/car.png' : baseURLImage + v.image}
                              width={20}
                            // height={30}
                            />
                            {v?.siteName || v?.category}
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                          {(focusArea === "waste" || focusArea === "Waste") ? null : (focusArea ===
                            "greenMaterial" || focusArea === "Green Material") ? (
                            <>
                              <TableCell style={style} className="text-center">{v?.allItem}</TableCell>
                              <TableCell style={style} className="text-center">{v?.greenItem}</TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell style={style} className="text-center">
                                <span title={`Baseline Year: ${v.baselineYear || "-"}`}>
                                  {ThousandSeparator(v?.consumptionLastYr?.toLocaleString('en-US'))}
                                </span>
                              </TableCell>
                              <TableCell style={style} className="text-center">
                                {ThousandSeparator(v?.consumptionCurrentYr)}
                              </TableCell>
                            </>
                          )}
                          {
                            companyName !== 'Digital Edge' && companyName !== "ClientA" ?
                              <TableCell align="center" style={style} className="p-0">
                                {/* <div className="mt-2"> */}
                                <ReactTooltip id={k + "-"} effect="float" backgroundColor={theme === "dark" ? "#fff" : "#000"}>
                                  <HoverProgressBar progress={v?.progress} />
                                </ReactTooltip>
                                <div data-tip data-for={k + "-"}>
                                  <ProgressBar
                                    noTarget
                                    // progress={Math.floor(Math.random() * 100) + 1}
                                    progress={v?.progress}
                                  />
                                </div>
                                {/* </div> */}
                              </TableCell>
                              : null
                          }
                          
                          {(focusArea === "waste" || focusArea === "Waste") ? (
                            <TableCell style={style} className="text-center">{v?.estDate}</TableCell>
                          )
                            : null}
                          {/* <td>
                    <BarChart />
                  </td> */}
                        </StyledTableRow>
                      ))}
              </TableBody>
              {data && focusArea !== 'Waste' && focusArea !== 'Green Material' && <TotalRow totalCurrentYr={totalCurrentYr} totalBaselineYr={totalBaselineYr} theme={theme} companyName={companyName} />}
            </Table>
          </TableContainer>
          {/* <TablePagination
        // className={`${theme}`}
        style={{ color: invertDark(theme), paddingBottom: 0, marginBottom: 0 }}
        rowsPerPageOptions={[]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
          {
            (companyName !== "Digital Edge" && companyName !== "ClientA") ?
              <div className="d-flex justify-content-between">
                <div style={{ color: Colors[theme].textColor }}>{t("Total Sites:") + " " + (data ? data.length : targetSettingData?.length) || 0}</div>
                <div className="d-flex justify-content-between">
                  {/* <div> */}
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={data?.length || targetSettingData?.length}
                    pageRangeDisplayed={5}
                    activeClass="bg-primary active_item"
                    linkClass={`pagination_item_${theme}`}
                    onChange={handleChangePage}
                  />
                  {/* </div> */}
                </div>
              </div>
              : null
          }
        </div>
      )
    }
    else
      return (<div className="text-center" style={{ paddingTop: 200 }}><i className="spinner-border mt-2" /></div>)

  }));

export const Tracking_Table_KW = ({
  column,
  data,
  isDetail,
  targetSettingData,
  focusArea,
}) => {
  const { _openNewTab } = Store.tracking;
  const { permittedBuilding, theme } = Store.common;
  return (
    data && (
      <div style={{ width: "100%", fontSize: 14 }}>
        <table className="table">
          <thead style={{ color: Colors.Dark[5] }}>
            <tr>
              {column.map((v) => (
                <th
                  className={`py-4 ${v === "Target (2022)" ||
                    (v === "Est. Date of Completion​" && "text-center")
                    }`}
                >
                  {v}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ color: invertDark("dark") }}>
            {isDetail
              ? data.map((v) => (
                <tr style={{ border: "0px solid rgba(255, 255, 255, 0.1)" }}>
                  <td style={{ height: 50, paddingTop: 15 }}>{v.year}</td>
                  <td style={{ height: 50, paddingTop: 15 }}>
                    {v.energyConsumption || v.waterConsumption}
                  </td>
                  <td style={{ height: 50, paddingTop: 15 }}>
                    {v.delta}
                    {v.delta !== "-" && "%"}
                  </td>
                  <td style={{ height: 50, paddingTop: 15 }}>
                    {" "}
                    {v.success ? (
                      <i
                        className="bi bi-check2"
                        style={{ color: Colors.greenMaterial, fontSize: 20 }}
                      ></i>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))
              : targetSettingData
                ? targetSettingData.map((v, i) => {
                  return (
                    <tr
                      style={{
                        border:
                          targetSettingData.length !== i + 1
                            ? "0px solid rgba(255, 255, 255, 0.05)"
                            : "hidden",
                        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <td
                        className="col-1"
                        style={{ paddingTop: 22, paddingRight: 0 }}
                      >
                        <img
                          src={`dataSources/${v.image}`}
                          width={25}
                          height={25}
                        ></img>
                      </td>
                      <td className="col-3" style={{ paddingTop: 25 }}>
                        {v.dataSource}
                      </td>
                      <td className="col-3" style={{ paddingTop: 25 }}>
                        {v.baseLine}
                      </td>
                      <td className="col-5">
                        <MySlider
                          baseLine={v.baseLine}
                          value={v.progressTarget}
                        />
                      </td>
                    </tr>
                  );
                })
                : data.map((v, dataIndex) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      border: "0px solid rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={() => {

                      _openNewTab({ siteName: v.siteName, focusArea: focusArea, permittedBuilding: permittedBuilding })
                    }

                    }
                  >
                    <td>
                      <img
                        className="me-2"
                        src={`sites/${v.image}`}
                        width={30}
                        height={30}
                      />
                      {v.siteName}
                    </td>
                    {focusArea === "waste" ? null : focusArea ===
                      "greenMaterial" ? (
                      <>
                        <td className="text-center">{v.allItem}</td>
                        <td className="text-center">{v.greenItem}</td>
                      </>
                    ) : (
                      <>
                        <td className="text-center">'{v.consumptionLastYr}'</td>
                        <td className="text-center">
                          {v.consumptionCurrentYr}
                        </td>
                      </>
                    )}

                    <td className="p-0">
                      {/* <div className="mt-2"> */}
                      <ReactTooltip id={dataIndex + "_"} effect="float" backgroundColor={theme === "dark" ? "#fff" : "#000"}>
                        <HoverProgressBar progress={(focusArea === "greenMaterial"
                          ? (100 * v.greenItem) / v.allItem
                          : Math.abs(
                            v.consumptionCurrentYr - v.consumptionLastYr
                          ) / v.consumptionCurrentYr || 0
                        ).toFixed(2)} />
                      </ReactTooltip>
                      <div data-tip data-for={dataIndex + "_"}>
                        <ProgressBar
                          noTarget
                          progress={(focusArea === "greenMaterial"
                            ? (100 * v.greenItem) / v.allItem
                            : Math.abs(
                              v.consumptionCurrentYr - v.consumptionLastYr
                            ) / v.consumptionCurrentYr || 0
                          ).toFixed(2)}
                        />
                      </div>
                      {/* </div> */}
                    </td>
                    {focusArea === "waste" ? (
                      <td className="text-center">{v.estDate}</td>
                    ) : null}
                    {/* <td>
                    <BarChart />
                  </td> */}
                  </tr>
                ))}
          </tbody>
          <totalRow />
        </table>
      </div>
    )
  );
};

const SortableTHead = (props) => {
  const { order, orderBy, onRequestSort, headCells, theme, _handleBuildingTypeChange, selectedBuildingType, companyName, buildingTypeOptions, focusArea, selectedYear,selectedCountry, countryOptions } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const typeOptions = [{ label: "All", value: "All" }].concat(buildingTypeOptions)
  // [{ label: "All", value: "All" }, { label: "Office", value: "Office" }, { label: "Operational Datacenter", value: "Operational Datacenter" }]
  const styles = makeStyles({
    // Fully visible for active icons
    activeSortIcon: {
      opacity: '1!important',
      color: `${invertDark(theme)}!important`
    },
  });
  const classes = styles();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => { console.log("headCell", headCell); 
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                backgroundColor:
                  theme === "dark" ? Colors.primaryBule : Colors.blue,
                //color: invertDark(theme),
                color: Colors.white,
                fontWeight: "bold",
                padding: "8px 13px 8px 13px",
                textAlign: headCell.id === "siteName" || headCell.id === "Country" ? "left" : "center",
                position: companyName !== 'Digital Edge' && companyName !== "ClientA" ? "static" : "sticky",
                // textAlign: "left",
              }}
            >
              {
                // index === 0 ? (<TableSortLabel
                //   active={orderBy === headCell.id}
                //   direction={orderBy === headCell.id ? order : "asc"}
                //   onClick={createSortHandler(headCell.id)}
                //   style={{
                //     color: Colors.white,
                //   }}
                // >
                //   { headCell.label}
                // </TableSortLabel>)
                //   : 
                <TableSortLabel
                  active={orderBy === headCell.id ? true : false}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{
                    color: invertDark(theme),
                  }}
                  classes={{
                    icon: ((orderBy === headCell.id) ? classes.activeSortIcon : null)
                  }}
                >
                  {headCell.label === "Scope 1 Emission Kg CO2 " ? `${headCell.label} (${selectedYear})` : headCell.label}
                </TableSortLabel>}
              {(index === 0 && (companyName === "Digital Edge" || companyName == "ClientA")) ?
                <>
                <Selector
                    options={focusArea === "Scope 1 Emission" ? typeOptions.filter(v=> v.label !== "Office"):typeOptions }
                    menuListColor={Colors.Dark[0]}
                    value={typeOptions?.find((v) => v?.label === selectedBuildingType)}
                    _handleSelect={(e) => _handleBuildingTypeChange(e.value, "building")}
                    isMenuPortalTarget={true}
                  />
                 </>
                : ""}
                 {(index === 1 && (companyName === "Digital Edge" || companyName == "ClientA")) ?
                <>
                  <Selector
                    options={countryOptions}
                    menuListColor={Colors.Dark[0]}
                   value={countryOptions?.find(v => v.value === selectedCountry)}
                   _handleSelect={(e) => _handleBuildingTypeChange(e.value, "country")}
                   isMenuPortalTarget={true}
                        />
                        </>
                        : ""}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
};

const TotalRow = (props) => {
  const { t } = useTranslation("tracking");

  const { order, orderBy, onRequestSort, headCells, theme, totalCurrentYr, totalBaselineYr, companyName } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableFooter sx={{position: 'sticky', bottom: 0, left: 0, zIndex:2, background: '#16204D' }}>
      <TableRow>
        <TableCell style={{ color: theme === "light" ? Colors.Dark[0] : Colors.white, fontSize: 14}} colSpan={2}>
          {t("Total")}
        </TableCell>
        <TableCell style={{ color: theme === "light" ? Colors.Dark[0] : Colors.white, textAlign: 'center', fontSize: 14}}>
          {ThousandSeparator(totalBaselineYr)}
        </TableCell>
        <TableCell style={{ color: theme === "light" ? Colors.Dark[0] : Colors.white, textAlign: 'center', fontSize: 14 }}>
          {ThousandSeparator(totalCurrentYr)}
        </TableCell>
        {
          companyName !== 'Digital Edge' && companyName !== "ClientA" ?
            <TableCell style={{ color: theme === "light" ? Colors.Dark[0] : Colors.white }}>
            </TableCell>
            : null
        }
        
      </TableRow>
    </TableFooter>
  );
};
