import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyButton, NumericInput } from "../../Tools";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import "../../App.css";

export const ManualEntryNormalForm = inject("store")(
  observer((props) => {
    const {
      theme,
      manualEntryData,
      _handleManualInputChange,
      _handleAddManualEntry,
      selectedField,
      isNewVal,
      _handleResetManualEntry,
      selectedCarbonTab,
      manualEditMode,
      selectedUnit,
    } = props;

    const { t } = useTranslation("common");

    return (
      <div className="mx-2 my-1 px-1" style={{ color: invertDark(theme) }}>
        <div className="row d-flex flex-wrap align-items-center pb-2">
          {manualEntryData.map((data, key) => {
            return (
              <div key={key} style={{ position: 'relative' }} className="col-lg-3 col-md-4 col-sm-6 mt-1">
                <p className="input-adornment">{selectedUnit}</p>
                <label style={{ fontSize: "12px" }}>{data.month}</label>
                <NumericInput
                  id={data.month + "-" + selectedField}
                  readOnly={!manualEditMode}       
                  value={
                    selectedField === "carbonEmission"
                      ? (data[selectedCarbonTab] ? Number(data[selectedCarbonTab]) : 0)
                      : (data[selectedField] ? Number(data[selectedField]) : 0)
                  }
                  onChange={(e) =>
                    _handleManualInputChange(
                      e,
                      selectedField === "carbonEmission"
                        ? selectedCarbonTab
                        : selectedField
                    )
                  }
                />
              </div>
            );
          })}
        </div>
        {manualEditMode && <hr />}
        {manualEditMode && (
          <div className="d-flex justify-content-end pt-2">
            <MyButton
              text={t("Cancel")}
              rounded
              fontSize={15}
              onClick={() => _handleResetManualEntry()}
              customColor={theme === "dark" ? Colors.lightBlue : '#919191'}
            />
            <div className="mx-2" />
            <MyButton
              disabled={!isNewVal}
              text={t("Save Progress")}
              customColor={Colors.water}
              onClick={() => _handleAddManualEntry()}
            />
          </div>
        )}
      </div>
    );
  })
);
