import fetchIntercept from "fetch-intercept";

import Cookies from 'universal-cookie';
import { toast } from "react-toastify";

export const AuthInterceptor = () => {
  const cookies = new Cookies();
  
  // console.log(token)
  fetchIntercept.register({
    request: async function (url, config) {
      let token = await cookies.get("Token",{ path: '/' });
      // Modify the url or config here
      if (token) {
        if(config.headers){
          config.headers.token = token;
        }
       
      }
      if (!config.headers?.["Accept-Language"]) {
        if(config.headers?.["Accept-Language"]){
          config.headers["Accept-Language"] = "en";
        }
        
      }

      return [url, config];
    },

    requestError: function (error) {
      
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if (response.status === 401) {
        toast.error("Token Expired!");
        const interval = setInterval(() => {
          window.location.pathname = "/";
        }, 3000);
        return () => clearInterval(interval);
      } else {
        return response;
      }
    },

    responseError: function (error) {
      // Handle an fetch error
      
      return Promise.reject(error);
    },
  });
};
