import React, { useState, useRef, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import ReactToPrint from "react-to-print";
import { MyButton } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { MultiLineApexChart } from "../../Assets/Charts/MultiLineApexChart";
import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from 'node-html-markdown'
// import './report.css'

import { Cookies } from "react-cookie";
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import { toJS } from 'mobx';

import { DynamicComponents } from "./DynamicComponents";

export const ReportTempPreviewModal = inject("store")(
    observer((props) => {

        const componentRef = useRef();
        const { headers, thousandSeparator } = props;
        const { reportCustomConfigList, handleDownloadPdf, editingContent, dynEnergyConsumption } = props.store.reportConfigure;
        const { theme } = props.store.common;
        const { t } = useTranslation("dashboard");

        const [content, setContent] = useState("");

        const cookies = new Cookies();

        useEffect(() => {
            console.log('reportCustomConfigList',toJS(reportCustomConfigList))
            if (reportCustomConfigList && reportCustomConfigList?.componentList?.length) {
                let mdContent = ''
                reportCustomConfigList.componentList.forEach((element, index) => {

                    let { content } = element

                    //// preview accepts both html and markdown
                    // if (content === Node.TEXT_NODE) {

                    //     content =
                    //         NodeHtmlMarkdown.translate(
                    //     /* html */ content,
                    //     /* options (optional) */ {},
                    //     /* customTranslators (optional) */ undefined,
                    //     /* customCodeBlockTranslators (optional) */ undefined
                    //         );
                    // }
                    mdContent += content?` \n ` + content + ` \n <div className="page-break"></div>`:''

                    
                    if (index === reportCustomConfigList.componentList.length - 1) {
                        setContent(mdContent)
                    }

                });
            }
        }, [reportCustomConfigList?.componentList]);


        const converter = new Showdown.Converter({
            tables: true,
            simplifiedAutoLink: true,
            strikethrough: true,
            tasklists: true
        });


        // console.log("content >> ", toJS(reportCustomConfigList));


        return (
            <div
                className={`modal fade ${theme}`}
                id="reportTempPreviewModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{


                }}
            >
                <div className=" modal-dialog" style={{ maxWidth: 858 }}>
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,

                        }}
                    >
                        <div className="modal-header mx-3 px-0 d-flex justify-content-between">
                            <span style={{ fontSize: 24, color: invertDark(theme) }}>{`${reportCustomConfigList?.templateName} Report Preview`}</span>
                            <div className="d-flex justify-content-between">

                                <ReactToPrint
                                    trigger={() => (
                                        <div style={{ marginRight: 30 }}>
                                            <MyButton
                                                id={"downloadTempPDF"}
                                                noTxtLight
                                                style={{ width: "100%", fontSize: 14, fontWeight: "bold", color: (theme === "light") ? "#303030" : "#f8f9fa" }}
                                                text={t("Download PDF")}
                                                customHoverColor={'#35469b'}
                                                onClick={() => null}>
                                            </MyButton>
                                        </div>
                                    )}
                                    content={() => componentRef.current}
                                    pageStyle="{size: A4 landscape;}"
                                    documentTitle={`Report Template`}
                                />

                                <i
                                    style={{ cursor: "pointer",color:'white' }}
                                    className="bi bi-x-lg "
                                    data-bs-dismiss="modal"
                                    aria-label="Close"


                                ></i>

                            </div>

                        </div>

                        <div className={`m-3 modal-body row d-flex justify-content-center flex-wrap m-0 p-0`} style={{ maxHeight: 800, overflow: 'auto' }}>
                            <div id="reactMde-noHeader" ref={componentRef} style={{border:'none',padding:0}}>
                                {/* <DynamicComponents dynEnergyConsumption={dynEnergyConsumption} /> */}
                                <ReactMde

                                    style={{width:2480}}
                                    value={content}
                                    // toolbarCommands={[saveButton]}
                                    selectedTab={"preview"}
                                    hideToolbar={true}
                                    disablePreview={true}
                                    disabled={true}
                                    generateMarkdownPreview={(markdown) =>
                                        Promise.resolve(converter.makeHtml(markdown))
                                    }
                                // childProps={{
                                //     writeButton: {
                                //         tabIndex: -1
                                //     }
                                // }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

