import { inject, observer } from "mobx-react";
import { Label, Pie, PieChart, Tooltip } from "recharts";
import { invertDark } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';

export const SummaryDonutChart = inject("store")(
    observer((props) => {
        const { data } = props;
        const { theme } = props.store.common;
        const { t } = useTranslation("audit");

        const pieData = data?.map((v) => {
            return {
                ...v,
                value: parseInt(v.value)
            };
        });
        const CustomLabel = ({ viewBox, value1, value2 }) => {
            const { cx, cy } = viewBox;
            return (
                <>
                    <text
                        x={cx}
                        y={cy - 5}
                        fill={invertDark(theme)}
                        className="recharts-text recharts-label"
                        textAnchor="middle"
                        dominantBaseline="central"
                    >
                        <tspan alignmentBaseline="middle" fontSize="16px">
                            {value2}
                        </tspan>
                    </text>
                    <text
                        x={cx}
                        y={cy + 20}
                        fill={invertDark(theme)}
                        className="recharts-text recharts-label"
                        textAnchor="middle"
                        dominantBaseline="central"
                    >
                        <tspan fontSize="14px">
                            {value1}
                        </tspan>
                    </text>
                </>
            );
        }
        const CustomTooltip = ({ active, payload, label }) => {
            if (active) {
              return (
                <div
                  className="custom-tooltip"
                  style={{
                    backgroundColor: "#ffff",
                    padding: "5px",
                    border: "1px solid #cccc",
                  }}
                >
                  <label>
                    {`${t(payload[0].name)} : ${payload[0].value}`}
                  </label>
                </div>
              );
            }
            return null;
          };
        return (
            <PieChart className="pieChart" width={200} height={200} padding={10}>
                <Pie
                    data={pieData}
                    color="#000000"
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={65}
                    outerRadius={90}
                    fill="#8884d8"
                    startAngle={90}
                    endAngle={-360}
                    
                >
                    <Label width={30} position="center"
                        content={<CustomLabel value1={pieData.reduce((r, c) => r + c.value, 0)} value2={t('Total')} />}>
                    </Label>
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        )
    })
)