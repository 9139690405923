import { Colors } from "../../Assets/Config/color.config";
import { ProgressBar } from "../../Tools/ProgressBar";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";

export const AssessmentTracking = inject("store")(
  observer((props) => {
    const { rightArrow } = props;
    const { frameworkProgress, theme } = props.store.common;
    const [expand, setExpand] = useState({});
    const { t } = useTranslation("dashboard");

    const _handleExpand = (title) => {
      var obj = { ...expand };
      obj[title] = !obj[title];
      setExpand(obj);      
    };
    return (
      <div className="cardStyle col my-2" style={{ color: Colors[theme].textColor }}>
        <span className="d-flex justify-content-between">
          <span className="cardTitle">{ t("Assessment Tracking") }</span>
          {/* <span style={{ color: Colors.water }}>
            See More
            <img src={rightArrow} className="ps-1" />
          </span> */}
        </span>
        <hr className="my-2" />
        <div style={{ height: 200, overflowY: 'scroll' }}>
        {frameworkProgress?.length !== 0 ? (
          frameworkProgress.map((a, i) => (
            <div
              key={i}
              className="mb-2"
              style={{ background: theme === "dark" ? "rgb(255,255,255,0.03)" : "rgb(0,0,0,0.05)", borderRadius:5 }}
            >
              <div>
                <span
                  style={{
                    background: theme === "dark" ?"rgb(255,255,255,5%)":"rgb(0,0,0,10%)",
                    cursor: a.active?"pointer":"not-allowed",
                    opacity: a.active? 1 : 0.6 ,
                    borderTopRightRadius: 5,borderTopLeftRadius: 5,borderBottomLeftRadius: !expand[i] && 5,borderBottomRightRadius: !expand[i] && 5,
                  }}
                  onClick={() => {                                        
                    if(a.active){
                      _handleExpand(i)
                    }                    
                    
                  }}
                  className="fw-bold d-flex justify-content-between p-2"
                >
                  {a.buildingName}{" "}
                  <i
                    style={{
                      color: Colors.water,
                      transform: expand[i] ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 0.2s linear",
                    }}
                    className="bi bi-caret-down-fill"
                  ></i>
                </span>

                {expand[i] &&
                  a.framework.map((a1) => (
                    <div className="row mx-3 py-1">
                      <span className="col-3">{a1.name}</span>
                      <span className="col-9 text-end">
                        <ProgressBar
                          theme={theme}
                          noTarget
                          width={"70%"}
                          progress={a1.totalProgress}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <i className="spinner-border mt-5" />
          </div>
        )}
      </div>
      </div>
    );
  })
);
