import { inject, observer } from "mobx-react";
import { ESGBreadCrumb } from "../../Components/ESG/ESGBreadCrumb";
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
import { Colors } from "../../Assets/Config/color.config";
import { CreateNewKPI } from "../../Components/KPIManagement/CreateNewKPI";
import { KPIListTable } from "../../Components/KPIManagement/KPIListTable";
import { EditKPIModal } from "../../Components/KPIManagement/EditKPIModal";
import { useEffect } from "react";
import { EditNewKPIModal } from "../../Components/KPIManagement/EditNewKPIModal";

export const KPIManagement = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _fetchBaseYears, _fetchKPIOwnerNames, _fetchKPIOwnerTitles, 
            _fetchUnitOfMeasurement, _fetchFreqOfMeasurement, _fetchStandardAligment, 
            _fetchKeyDependencies, _fetchAllKPIManagement} = props.store.kpiManagement;
        const cardStyle = {
            background: Colors[theme].secondBackground,
            boxShadow: "0 4px 13px rgb(21,21,21,25%)",
            borderRadius: 10,
        };

        useEffect(() => {
            _fetchBaseYears();
            _fetchKPIOwnerNames();
            _fetchKPIOwnerTitles();
            _fetchUnitOfMeasurement();
            _fetchFreqOfMeasurement();
            _fetchStandardAligment();
            _fetchKeyDependencies();
            _fetchAllKPIManagement();
        },[])

        return (
            <div className="mx-4">
                <div className="d-flex justify-content-between mb-3">
                    <ESGBreadCrumb page={"kpiManagement"} />
                    <img className="pe-2" width={160} src={digitalEdgeIcon} />
                </div>
                <div className="mt-2 mb-3 mx-2">
                    <div className="p-3" style={cardStyle}>
                        <CreateNewKPI />
                    </div>
                </div>
                <div className="my-3 mx-2">
                    <div className="p-3" style={cardStyle}>
                        <KPIListTable />
                    </div>
                </div>
                <EditNewKPIModal />
            </div>
        );
    })
);

