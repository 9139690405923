import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useEffect, useState } from "react";
import { MyInput } from "../../Tools";
import { toJS } from "mobx";

const EquipmentQtySetup = inject("store")(
    observer((props) => {
        const { theme,companyName } = props.store.common;
        const { _handleQtyChange, selectedEquipments } = props.store.quickOrganizationSetUp;
        const [equipment, setEquipment] = useState([]);
  
        const { t } = useTranslation("assetSetupAndGroup");
        const t1 = useTranslation("equipment");
        const t2 = useTranslation("transport");
        const t3 = useTranslation("country");

        useEffect(() => {
            setEquipment(selectedEquipments)
            
            if (companyName !== 'ITRI' && !selectedEquipments.length) return;
            const sEquipments = JSON.parse(JSON.stringify(selectedEquipments));
            let list = sEquipments.filter(s => s.name === "Mobile Combustion")
            if (list.length) {
                const [mobile] = list;
                const Stationary = {
                    catKey: "category",
                    catValue: "Stationary Combustion",
                    equipments: [],
                    id: "1_Stationary Combustion",
                    name: "Stationary Combustion",
                    nameKey: "fuelType",
                    scope: 1,
                    sourceKey: "fuelType",
                    type: "Fuel Type"
                }
                Stationary.equipments = mobile.equipments.filter(e => e.itSecTitle === "Stationary Combustion")
                
                if(!Stationary.equipments.length) return
                mobile.equipments = mobile.equipments.filter(e => e.itSecTitle === "Mobile Combustion")
                list = sEquipments.filter(s => s.name !== "Mobile Combustion")
                const rindex = list.findIndex(t=> t.name === "Refrigerants and Fugitives")
                
                if(rindex> -1) {
                    const rList=mobile.equipments.length? [list[rindex],Stationary, mobile] :[list[rindex],Stationary]
                    list.splice(rindex, 1);
                    list = [...rList].concat([...list])
                }else{
                    list = mobile.equipments.length? [Stationary, mobile].concat([...list]):[Stationary].concat([...list])
                }
               
                setEquipment(list)
            }

        }, [companyName, selectedEquipments]);

        return (
            <div className="d-flex justify-content-center">
                <div
                    className="w-100"
                    style={{ color: invertDark(theme), borderRadius: 10, background: Colors[theme]?.background }}
                >
                    <div    
                        style={{ 
                            background : Colors[theme].headerBgColor, 
                            padding : '15px 25px' , 
                            borderTopLeftRadius : 10 , 
                            borderTopRightRadius : 10 ,
                            fontSize: 20 , 
                            color: Colors.white
                        }}
                    >
                        <h5>
                            {t("Selected Equipment(s)")}
                        </h5>
                    </div>

                    <div className="m-4">
                    <div
                        className="d-flex row"
                        style={{
                            maxHeight: '400px',
                            overflowY: 'scroll'
                        }}
                    >
                        {
                            equipment?.map((v, k) => {
                                return (
                                    <div
                                        key={k}
                                        className="col-6"
                                        style={{
                                            borderRight: equipment?.length === 1 ? 'none' :
                                                (k % 2 === 0 ? `1px solid ${theme === 'dark' ? Colors.Dark[2] : Colors.Dark[4]}` : 'none'),
                                            borderBottom: (equipment?.length - k > (k % 2 === 0 ? 2 : 1)) ? `1px solid ${theme === 'dark' ? Colors.Dark[2] : Colors.Dark[4]}` : 'none',
                                            fontSize: 14
                                        }}
                                    >
                                        <div className="d-flex mt-3 fw-bold">
                                            <div className="col-6">
                                                {t1.t(v.name) || t(v.name)}
                                            </div>
                                            <div className="col-6">
                                                {t("Qty")}
                                            </div>
                                        </div>
                                        {
                                            v.equipments.map((v1, k1) => {
                                                return (
                                                    <div key={k1} className="d-flex mt-1 mb-2 align-items-center">
                                                        <div className="col-6">
                                                            {v.name === "Upstream T&D" ? t2.t(v1.name) : (v1.sourceType === "Location Based" ? t3.t(v1.name) : t1.t(v1.name))}
                                                        </div>
                                                        <div className="col-6">
                                                            <MyInput
                                                                required={true}
                                                                id={v1.subId}
                                                                type={"number"}
                                                                value={v1.qty}
                                                                onChange={(e) => _handleQtyChange(e, (companyName == 'ITRI' && v.name == "Stationary Combustion")?'Mobile Combustion':v.name)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                    
                    
                </div>
            </div>
        )
    })
)

export default EquipmentQtySetup