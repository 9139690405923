import React,{useState,useEffect} from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";

import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import ReactDOMServer from "react-dom/server";

export const DyVerticalChart = (props) => {
  const theme = "light";
  const data = props.data.chartData.data

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          style={{
            background: "white",
            border: "1px solid #ccc",
            padding: 10,
          }}
        >
          <p className="label" style={{ textAlign: "center" }}>{`${label}`}</p>
          {payload.map((d) => (
            <div key={d}>
              <p style={{ color: d.color }}>{`${d.dataKey} : ${d.value}`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomizedTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fontSize={12} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );

  return (
    <div style={{ textAlign:'center',marginBottom: 20, border: "1px solid #E5DFDF", padding: 10 }}>
      <div
        className="d-flex"
        style={{
          fontSize: 22,
          justifyContent: "center",
          color: "gray",
          margin: 10,
        }}
      >
        {"Consumption & Emission for Year 2020"}
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={400}
        style={{ background: Colors.white, color: invertDark(theme) }}
      >
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis
            dataKey="name"
            type="category"
            scale="band"
            tick={<CustomizedTick />}
          />

          <Tooltip />
          <Legend />
          <Bar dataKey="Emission" barSize={30} fill="#ff7300" />
          <Bar dataKey="Consumption" barSize={30} fill="#1273DE" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};


// export const DyVerticalChart = (props) => {
//   const [chartHtmlState, setChartHtmlState] = useState('');

//   useEffect(() => {
//     const chartHtml = ReactDOMServer.renderToStaticMarkup(
//       <DynamicChart data={props.data} />
//     );
//     setChartHtmlState(chartHtml);
//   }, [props.data]);


//   return <div dangerouslySetInnerHTML={{ __html: chartHtmlState }} />;
// };