import { useState } from "react";
import { Colors } from "../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';

export const MyButton = (props) => {
  const {
    text,
    textAlign,
    onClick,
    type,
    style,
    small,
    normal,
    disabled,
    leftIcon,
    rightIcon,
    id,
    // noShadow,
    customColor,
    customHoverColor,
    dataToggle,
    dataTarget,
    dataDismiss,
    className,
    title,
    noTxtLight,
  } = props;
  const { t } = useTranslation("common");
  const [isHover, setIsHover] = useState(true);

  const defaultStyle1 = {
    background: customColor || Colors.lightBlue,
    // opacity: 0.8,
  };
// 
  const hoverStyle = {
    background: customHoverColor || customColor || Colors.lightBlue,
    opacity: 1,
    // boxShadow: noShadow?'none':`0px 2px 3px ${customColor||Color.SoftBlue}`
  };

  const defaultStyle = isHover || disabled ? defaultStyle1 : hoverStyle;

  const userStyle = style === undefined ? {} : style;
  return (
    <button
      data-dismiss={dataDismiss}
      data-bs-dismiss={dataDismiss}
      disabled={disabled}
      // data-target={dataTarget}
      data-bs-target={dataTarget}
      // data-toggle={dataToggle}
      data-bs-toggle={dataToggle}
      id={id}
      onClick={onClick}
      type={type === undefined ? "button" : type}
      className={`btn btn-block rounded ${noTxtLight ? "" : "text-light"} ${textAlign ? textAlign : "text-center"} ${
        small ? "p-1" : normal?'p-0': "p-2"
      } ${className}`}
      style={{
        ...defaultStyle,
        ...userStyle,
        outline: "none",
        boxShadow: "none",
        transition: ".5s",
        // maxHeight: 42,
      }}
      title={disabled ? title : ""}
      onMouseOver={() => setIsHover(false)}
      onMouseLeave={() => setIsHover(true)}
    >
      {leftIcon ? <span className="ps-1"> {leftIcon} </span> : null}
      {/* {t(text)} */}
      {text}
      {rightIcon ? <span className="ps-1"> {rightIcon} </span> : null}
    </button>
  );
};
