import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Capella from "../../Assets/images/capella.png";
import { OverviewTable } from "../../Components/AuditCompanyOverview/OverviewTable";
import "../../App.css";
import { useState, useEffect } from "react";
import { MyDatepicker, MyInput, Selector } from "../../Tools";
import { MyCheckbox } from "../../Tools";
import { VerificationTable } from "../../Components/AuditCompanyOverview/VerificationTable";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { baseURL, baseURLImage } from "../../API/URL";
import { useTranslation } from "react-i18next";

export const AuditCompanyVerification = inject("store")(
  observer((props) => {
    const { theme, addDefaultSrc } = props.store.common;
    const { 
      getCompanyVerification, verificationData, selectedFramework, getSurveyHeaderInfo, 
      selectedYear, setYearSelectorDatum,frameworkOptions, selectedScope, getAuditOverviewList,
      auditOverviewList
    } = props.store.audit;
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get("buildingId");
    const companyName = params.get("companyName");
    const buildingName = params.get("buildingName");
    const {t,i18n} = useTranslation("audit");
    const t1 = useTranslation("one_time");

    useEffect(() => {
      setYearSelectorDatum()
    }, [])

    useEffect(() => {
      const isGhgFw = frameworkOptions?.find((f) => f.value === selectedFramework)?.label === 'GHG'
      if(isGhgFw){
        if(selectedFramework && selectedYear && selectedScope){
          getCompanyVerification(buildingId);
        }
      }else{
        if(selectedFramework){
          getCompanyVerification(buildingId);
        }
      }
      
    }, [selectedFramework,selectedYear, selectedScope,i18n.resolvedLanguage]);

    useEffect(() => {
      getSurveyHeaderInfo(buildingId)
    }, [buildingId]);

    useEffect(()=> {
      if(companyName){
        getAuditOverviewList(companyName)
      }
    }, [companyName])
    
    return (
      <div className="m-4 mt-3">
        <div className="d-flex justify-content-end pb-2">
          <span
            style={{
              fontSize: 20,
              color: invertDark(theme),
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(`/audit-company-overview?companyName=${companyName}`)
            }
          >
            <i className="bi bi-arrow-left-circle-fill pe-3"></i>
            {t("Back")}
          </span>
        </div>
        <div className="d-flex justify-content-between mb-1">
          <div className="d-flex align-items-center">
            <img
              className="me-3"
              onError={addDefaultSrc}
              src={
                baseURLImage +
                auditOverviewList.find(dt => dt.buildingId === buildingId)?.buildingPhoto
              }
              width={"50px"}
              height={"50px"}
              style={{ borderRadius: "50%" }}
            />
            <div style={{ color: Colors[theme].textColor }}>
              <div className="h5 mb-1">
                {t("Verification for ") + buildingName}
                <i
                  className="bi bi-exclamation-circle ms-3"
                  style={{ fontSize: 13 }}
                ></i>
              </div>
            </div>
          </div>
          {/* <div
            className=""
            style={{
              whiteSpace: "nowrap",
              height: "38px",
              borderRadius: 5,
              border: `1px solid rgb(209 209 209 / ${
                theme === "dark" ? "50%" : "100%"
              })`,
            }}
          >
            <input
              className="form-control searchInput"
              type={"text"}
              // placeholder={t("Site Name")}
              style={{
                background: "transparent",
                border: "none",
                color: invertDark(theme),
                display: "inline-block",
                width: "88%",
              }}
              // onChange={(e)=>handleSearch(e)}
            />
            <i
              className="bi bi-search text-secondary"
              style={{
                display: "inline-block",
              }}
            />
          </div> */}
        </div>
        <FilterBar t={t}/>
        {
          !selectedFramework ?
          (<div className="text-center"><h5>{t("Please select framework first.")}</h5></div>)
          : (frameworkOptions?.find((f) => f.value === selectedFramework)?.label === 'GHG' && (!selectedScope || !selectedYear)?
          (<div className="text-center"><h5>{t("Please select year and scope.")}</h5></div>):
          (verificationData === null ? (
            <div className="text-center">
              <i className="spinner-border mt-5" />
            </div>
          ) : verificationData?.length === 0 ? (
            <div className="text-center text-muted"><h5><i className="bi bi-file-earmark-fill pe-1" />{t1.t("No Data")}</h5></div>
          ) : (
            <VerificationTable />
          )))
        }
      </div>
    );
  })
);

const FilterBar = inject("store")(
  observer((props) => {
    const { 
      selectedFramework, verificationData, frameworkOptions, 
      yearOptions, selectedYear, scopeOptions, selectedScope, _handleSelectSelector
    } = props.store.audit;
    const {theme} = props.store.common;
    // const frameworkOption = [];
    // verificationData?.[0]?.buildingInfo.surveyHeader.map(entry => {
    //   frameworkOption.push({label: entry.name, value: entry.framework_id});
    // });
    const {t} = props;
    const [month, setMonth] = useState(new Date());
    
    return (
      <div className="row d-flex align-items-end mb-1" style={{color: invertDark(theme)}}>
        <div className="d-flex align-items-center col-1" style={{}}>
          <i style={{ fontSize: 30 }} className="bi bi-filter-left pe-2"></i>
          <span className="fw-bold">{t("Filter")}</span>
        </div>
        {/* <div className="col-2">
          <MyDatepicker
            value={month}
            _handleChange={setMonth}
            inputFormat="MMM-yyyy"
            views={["year", "month"]}
            label="Month"
          />
        </div> */}
        <div className="col-2">
          <Selector
            value={frameworkOptions?.filter(
              (f) => f.value === selectedFramework
            )}
            _handleSelect={(e) => _handleSelectSelector('framework',e.value)}
            label={t("Framework")}
            labelStyle2
            menuListColor={Colors.Dark[0]}
            options={frameworkOptions}
            noBorder
            placeholder={t('Select Framework')}
          />
        </div>
        {
          frameworkOptions?.find(
            (f) => f.value === selectedFramework
          )?.label === 'GHG' ?
          <>
            <div className="col-2">
              <Selector
                value={yearOptions?.filter(
                  (f) => f.value === selectedYear
                )}
                _handleSelect={(e) => _handleSelectSelector('year',e.value)}
                label={t("Year")}
                labelStyle2
                menuListColor={Colors.Dark[0]}
                options={yearOptions}
                noBorder
                placeholder={t('Select Year')}
              />
            </div>
            <div className="col-2">
              <Selector
                value={scopeOptions?.filter(
                  (f) => f.value === selectedScope
                )}
                _handleSelect={(e) => _handleSelectSelector('scope',e.value)}
                label={t("Scope")}
                labelStyle2
                menuListColor={Colors.Dark[0]}
                options={scopeOptions}
                noBorder
                placeholder={t('Select Scope')}
              />
            </div>
          </>
          : null
        }
        
        {/* <div
                    className="col-5 d-flex justify-content-between align-items-center"
                    style={{ background: "#141C48", height: 44, borderRadius: 5 }}
                >
                    <span>Framework Status :</span>
                    <span className="pe-1">
                        <MyCheckbox checked />
                        <span className="ps-1">All</span>
                    </span>
                    <span className="pe-1">
                        <MyCheckbox checked />
                        <span className="ps-1">Verified</span>
                    </span>
                    <span className="pe-1">
                        <MyCheckbox checked />
                        <span className="ps-1">Rejected</span>
                    </span>
                    <span className="pe-1">
                        <MyCheckbox checked />
                        <span className="ps-1">Not Applicable</span>
                    </span>
                </div> */}
        <div
          style={{ fontSize: 14, color: Colors.greenMaterial }}
          className="col text-end"
        >
           {verificationData&& verificationData?.length+" "+t("Results Found")}
        </div>
      </div>
    );
  })
);
