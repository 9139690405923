import { Colors } from "../../Assets/Config/color.config";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MyButton } from "../../Tools";

const PreviewPDFDialog = inject("store")(
  observer((props) => {
    const { t } = useTranslation("common");
    const { theme } = props.store.common;
    const { pdfBlobLink, previewPDFName, _handleDownload } = props.store.survey;
    const defaultBtnColor =
      theme === "dark" ? Colors.primaryBule : "rgb(52 67 144 / 60%)";

    return (
      <Box
        className={`modal fade ${theme}`}
        id="pdfPreviewModal"
        tabIndex="-1"
        aria-labelledby="pdfPreviewModalLabel"
        aria-hidden="true"
      >
        <Box className="modal-dialog modal-xl">
          <Box
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
            }}
          >
            <Box className="modal-header mx-3 px-0">
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <h4>{previewPDFName}</h4>
                <MyButton
                  text="Download"
                  onClick={_handleDownload}
                  textAlign={"text-start"}
                  noTxtLight
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#f8f9fa",
                    marginLeft: 15,
                  }}
                  customColor={defaultBtnColor}
                  leftIcon={<i className="bi bi-download"></i>}
                  customHoverColor={Colors.water}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <i
                  style={{ cursor: "pointer" }}
                  className="bi bi-x-lg "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></i>
              </Grid>
            </Box>
            <Box className={`modal-body`} style={{ minHeight: 500 }}>
              <iframe
                id="myFrame"
                src={pdfBlobLink}
                frameBorder="0"
                border="0"
                cellSpacing="0"
                style={{ borderStyle: "none", width: "100%", height: "450px" }}
              ></iframe>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  })
);

export default PreviewPDFDialog;
