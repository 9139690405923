import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { AnalysisResutTable } from "../../Components/AnalysisReport/AnalysisReportTable";
import { Selector } from "../../Tools";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AnalysisChart } from "../../Components/AnalysisReport/AnalysisChart";

export const AnalysisResultContainer = inject("store")(
    observer((props) => {
        const navigate = useNavigate();
        const { t } = useTranslation("analysisReport");
        const { theme } = props.store.common;
        const {
            _handleGetYearOptions,
            yearOptions,
            selectedYear,
            _handleSelectedYear,
            getAnalysisReportData
        } = props.store.analysisResult;

        useEffect(() => {
            _handleGetYearOptions();
        }, []);

        useEffect(() => {
            getAnalysisReportData();
        }, [selectedYear])
        return (
            <div className="d-flex flex-wrap justify-content-center">
                <div className="d-flex col-10 justify-content-end mb-3">
                    <div
                        style={{
                            fontSize: 20,
                            color: invertDark(theme),
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                        }}
                        onClick={() => navigate("/dashboard")}
                    >
                        <i className="bi bi-arrow-left-circle-fill pe-2 pt-1"></i>
                        {t("Back")}
                    </div>
                </div>
                <div
                    className="col-10"
                    style={{
                        borderRadius: '10px',
                        background: theme === 'dark' ? 'rgba(255,255,255,0.06)' : 'rgba(217,217,217,0.6)'
                    }}
                >
                    <Title
                        yearOptions={yearOptions}
                        selectedYear={selectedYear}
                        _handleSelectedYear={_handleSelectedYear}
                    />
                    <div className="p-3">
                        <AnalysisResutTable />
                    </div>
                    <div className="pb-3 px-3">
                        <AnalysisChart />
                    </div>
                </div>
            </div>
        )
    })
)

const Title = (props) => {
    const { selectedYear, yearOptions, _handleSelectedYear } = props;
    const { t } = useTranslation("analysisReport");
    return (
        <div
            className="d-flex justify-content-between align-items-center px-3 py-2"
            style={{
                background: '#0056d2',
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px'
            }}
        >
            <div className="text-white fw-bold" style={{ fontSize: 18 }}>
                {`${t('Uncertain analysis Result of GHG emission data in')} ${selectedYear}(${t('Scope')} 1 & ${t('Scope')} 2)`}
            </div>
            <div className="d-flex align-items-center me-2">
                <div className="d-flex align-items-center">
                    <p style={{ fontSize: 15 }} className="me-2 text-white w-100 mb-0 text-nowrap">{t("Year")} : </p>
                </div>
                <div className="d-flex align-items-center">
                    <Selector
                        menuListColor={Colors.Dark[0]}
                        options={yearOptions}
                        value={
                            yearOptions.filter(
                                (v) => v.label === selectedYear
                            ) || ""
                        }
                        placeholder={"Select year"}
                        _handleSelect={(e) =>
                            _handleSelectedYear(e)
                        }
                    />
                </div>                
            </div>
        </div>
    )
}