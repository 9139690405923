import { inject, observer } from "mobx-react";
import BarChart from "../../Assets/Charts/BarChart";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import DetailChart from "../../Assets/Charts/DetailChart";
import { MyTable, MyTableKW } from "../../Tools";
import energyLogo from "../../Assets/images/Energy.svg";
import waterLogo from "../../Assets/images/water.svg";
import plasticLogo from "../../Assets/images/plastic.png";
import greenMaterialLogo from "../../Assets/images/greenMaterial.svg";
import { Cookies } from "react-cookie";
import kingWanData from "../../Assets/kingWan.json";
import { Fragment, useEffect, useState } from "react";
import { FocusArea } from "../Dashboard/FocusAreaOld";
import { baseURLImage, imageUrl, noImageUrl } from "../../API/URL";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../Page/Loading";
import { useNavigate } from "react-router-dom";
import { MyButton, Selector } from "../../Tools";
import { toJS } from "mobx";
import ManualEntryModal from "./ManualEntryModal";
import Pie_Chart from "../../Assets/Charts/PieChart";
import { BreakdownTable } from "./BreakdownTable";
import { ThousandSeparator } from "../../Assets/Config/thousandSeparator.config";

const TrackingDetail = inject("store")(
  observer((props) => {
    const { addDefaultSrc, theme, companyName, trackingChart, _handleSelectPeriod } = props.store.common;
    const {
      getManualEntryData,
      summaryData,
      consumptionChartData,
      isLoading,
      selectedYear,
      _handleSelectYear,
      yearOptions,
      getBreakDownReport,
      breakdownReportData,
      getManualEntryDataByCategory,
      yearlyChartData,
      quarterlyCosumptionChartData,
    } = props.store.trackingDetail;

    const {
      surveyFramework,
      getAllSurvey,
      getManualEntryDataByYear,
      manualEntryData,
    } = props.store.survey;

    const cookies = new Cookies();
    const userName = cookies.get("userName");
    const siteName = cookies.get("siteName");
    const focusArea = cookies.get("focusArea");
    const siteImage = cookies.get("siteImage");
    const { t } = useTranslation("tracking");
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get("buildingId");
    const category = params.get("category");
    const navigate = useNavigate();


    useEffect(() => {
      if (buildingId) {
        getAllSurvey(buildingId);
      }
    }, []);

    useEffect(() => {
      if (buildingId) {
        getBreakDownReport(buildingId, selectedYear);
      }
    }, [selectedYear]);

    useEffect(() => {
      if (buildingId && surveyFramework) {
        const ghgFrameworkId = "62da2e0c8dc52269efb8e7b1";
        // console.log(toJS(surveyFramework));
        if (companyName === 'Syscom') {
          focusArea !== 'Scope 3 Emission' && getManualEntryDataByYear(ghgFrameworkId);
          focusArea !== 'Scope 3 Emission' && getManualEntryData(buildingId, ghgFrameworkId, focusArea);
        }
        else {
          getManualEntryDataByYear(ghgFrameworkId);
          getManualEntryData(buildingId, ghgFrameworkId, focusArea);
        }

      }
    }, [buildingId, surveyFramework]);

    useEffect(() => {
      if (buildingId && surveyFramework) {
        const ghgFrameworkId = "62da2e0c8dc52269efb8e7b1";
        focusArea !== 'Scope 1 Emission' && getManualEntryData(buildingId, ghgFrameworkId, focusArea);
      }
    }, [manualEntryData]);

    useEffect(() => {
      if (category && companyName === 'Enterprise SG') {
        const ghgFrameworkId = "62da2e0c8dc52269efb8e7b1";
        if (focusArea === 'Scope 1 Emission' || focusArea === 'Scope 3 Emission') {
          getManualEntryDataByCategory(ghgFrameworkId, focusArea, category)
        }
      }
    }, [category])

    let unit = "";
    if (focusArea === "water") {
      unit = "m³/h";
    } else if (focusArea === "energy" || focusArea === "Energy") {
      unit = "kWh";
    } else if (focusArea === "Water") {
      unit = "CuM";
    } else if (focusArea === "Scope 2 Emission") {
      unit = ("kgCO2");
    }else {
      unit = "tonnes";
    }
    // console.log('focus area', focusArea)

    const column =
      (companyName === "Enterprise SG" || companyName === "Syscom") &&
        focusArea.includes("Emission")
        ? [ t("Year"), t("Carbon Emission (kg CO2)"), t("Delta (%) w.r.t ") + t("baseline year")]
        : (companyName === "Digital Edge" || companyName == "ClientA") && !focusArea.includes("Emission")
          ? [
            t("Year"),
            t(`${(focusArea === "Energy" || focusArea === "Water" || focusArea === "Waste") ? focusArea+" Consumption" : focusArea}`)+ " (kWh)",
            t("Delta (%) w.r.t ") + ` ${focusArea === "Water" ? t("baseline year") : ""} ${summaryData?.length > 0 ? summaryData[0].year : "2019"
            }`,
            "",
          ]
          : (companyName === "Digital Edge" || companyName == "ClientA") && focusArea.includes("Emission")
            ? [
              t("Year"),
              t(`Carbon Emission (kg CO2)`),
              t("Delta (%) w.r.t ") + ` ${focusArea === "Water" ? t("baseline year")
                : summaryData?.length > 0
                  ? summaryData[0].year
                  : "2019"
              } `,
              "",
            ]
            : companyName === "Far East Orchard" && focusArea.includes("Scope 2")
            ? [
              t("Year"),
              t(`${focusArea?.charAt(0).toUpperCase() + focusArea?.slice(1)
              } Consumption (kg CO2)`),
              t("Delta (%) w.r.t ") + ` ${focusArea === "Water" ? t("baseline year")
                : summaryData?.length > 0
                  ? summaryData[0].year
                  : "2019"
              } `,
              "",
            ]
            : 
            companyName === "ISCA" && focusArea.includes("Scope")
            ? [
              t("Year"),
             'Carbon Emission (kg CO2)',
              t("Delta (%) w.r.t baseline year"),
              "",
            ]
            : [
              t("Year"),
              `${t(focusArea?.charAt(0).toUpperCase() + focusArea?.slice(1))} 
              ${(focusArea === "Energy" || focusArea === "Water" || focusArea === "Waste") ? " " + t('Consumption') : ""}`
              + ` (${unit})`,
              t("Delta (%) w.r.t ") + ` ${focusArea === "Water" || focusArea==="Energy" ? t("baseline year")
                : summaryData?.length > 0
                  ? summaryData[0].year
                  : "2019"
              } `,
              "",
            ];

    const siteData =
      siteName === "Conrad"
        ? [
          {
            year: "2019",
            energyConsumption: 7760379,
            waterConsumption: 115628,
            delta: "-",
            success: false,
          },
          {
            year: "2020",
            energyConsumption: 5792353,
            waterConsumption: 81528,
            delta: focusArea === "energy" ? 23.36 : 29.49,
            success: true,
          },
          {
            year: "2021",
            energyConsumption: 6544543,
            waterConsumption: 63862,
            delta: focusArea === "energy" ? 15.67 : 44.77,
            success: true,
          },
          {
            year: "2022",
            energyConsumption: " ",
            waterConsumption: "",
            delta: "-",
            success: false,
          },
        ]
        : siteName === "Regent"
          ? [
            {
              year: "2019",
              energyConsumption: 11551046,
              waterConsumption: 144193,
              delta: "-",
              success: false,
            },
            {
              year: "2020",
              energyConsumption: 9748157,
              waterConsumption: 96158,
              delta: focusArea === "energy" ? 15.6 : 33.31,
              success: true,
            },
            {
              year: "2021",
              energyConsumption: 9405037,
              waterConsumption: 91689,
              delta: focusArea === "energy" ? 18.58 : 36.41,
              success: true,
            },
            {
              year: "2022",
              energyConsumption: " ",
              waterConsumption: "",
              delta: "-",
              success: false,
            },
          ]
          : siteName === "Capella"
            ? [
              {
                year: "2019",
                energyConsumption: 13774869.7,
                waterConsumption: 144435.6,
                delta: "-",
                success: false,
              },
              {
                year: "2020",
                energyConsumption: 11614733.01,
                waterConsumption: 113839.5,
                delta: 15.68,
                success: true,
              },
              {
                year: "2021",
                energyConsumption: 11965703.6,
                waterConsumption: 117979.9,
                delta: 13.13,
                success: true,
              },
              {
                year: "2022",
                energyConsumption: " ",
                waterConsumption: "",
                delta: "-",
                success: false,
              },
            ]
            : [
              { year: "2019", energyConsumption: "", delta: "-", success: false },
              { year: "2020", energyConsumption: "", delta: "-", success: false },
              { year: "2021", energyConsumption: "", delta: "-", success: false },
              {
                year: "2022",
                energyConsumption: " ",
                delta: "-",
                success: false,
              },
            ];

    // console.log(focusArea, '....')

    const breakdownColumn = [
      "Name",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];



    const breakDownData = toJS(breakdownReportData)?.monthData?.reduce((r, c) => {
      const R = [...r];
      const index = R.findIndex(v => v._id === c._id);
      if (index > -1) {
        R[index].monthData.push({ month: c.month, unit: c.unit, value: c.value });
      } else {
        R.push({ _id: c._id, name: c.name, monthData: [{ month: c.month, unit: c.unit, value: c.value }] });
      }
      return R;
    }, []);

    const COLORS = [
      "#663567",
      "#B04E6F",
      "#E77866",
      "#607d8b",
      "#ffeb3b",
      "#1273DE",
      "#388e3c",
      "#7b64ff",
      "#86442d",
      "#7bdcb5",
    ];
    // console.log("breakdownReportData > ", toJS(breakdownReportData), toJS(breakDownData) );
    
    return (
      <div className="px-3 py-1">

        <>
          <div className="d-flex justify-content-between">
            <span style={{ fontSize: 20, color: invertDark(theme) }}>

              <img
                onError={addDefaultSrc}
                className="me-2"
                src={(category && category != 'undefined') ? (category === 'Mobile Combustion' ? '/sites/car.png' : '/sites/plane1.png') : baseURLImage + siteImage}
                width={category ? 35 : 50}
              />

              {(category && category != 'undefined') ? category : siteName}

            </span>
            <span
              style={{
                fontSize: 20,
                color: invertDark(theme),
                alignItems: "center",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => navigate("/tracking")}
            >
              <i className="bi bi-arrow-left-circle-fill pe-3"></i>
              {t("Back to Target Tracking")}
            </span>
          </div>

          {/* {userName === "King Wansdf" ? (
            <IndividualOverview
              // title={focusArea?.charAt(0).toUpperCase() + focusArea?.slice(1)}
              focusArea={focusArea}
              userName={userName}
              theme={theme}
            />
          ) : ( */}
            <Fragment>
              {focusArea === "Energy" ||
                focusArea === "Water" ||
                focusArea === "Waste" ||
                focusArea === "Green Material" ? (
                <div className="d-flex justify-content-between my-2">
                  <div
                    className="py-3"
                    style={{
                      fontSize: 25,
                      // color:
                      //   focusArea === "energy"
                      //     ? Colors.energy
                      //     : focusArea === "water"
                      //     ? Colors.water
                      //     : focusArea === "plastic"
                      //     ? Colors.plastic
                      //     : Colors.greenMaterial,
                      color: theme === "dark" ? "#fff" : invertDark(theme),
                    }}
                  >
                    <img
                      className="pe-3"
                      width="45px"
                      src={
                        focusArea === "Energy"
                          ? energyLogo
                          : focusArea === "Water"
                            ? waterLogo
                            : focusArea === "Waste"
                              ? plasticLogo
                              : greenMaterialLogo
                      }
                    />
                    {(companyName === "Digital Edge" || companyName == "ClientA") && focusArea === "Energy"
                      ? t("Individual Electricity Overview")
                      : t("Individual Overview", { subject: t(focusArea) })}
                  </div>
                  {/* <div> */}
                  <div className="d-flex mt-2">
                    <div className="mx-1">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        options={[{ label: 'Yearly', value: 'Yearly' }, { label: 'Quarterly', value: 'Quarterly' }, { label: 'Monthly', value: 'Monthly' }]}
                        menuListColor={Colors.Dark[0]}
                        value={[{ label: 'Yearly', value: 'Yearly' }, { label: 'Quarterly', value: 'Quarterly' }, { label: 'Monthly', value: 'Monthly' }].filter(
                          (y) => y.value == trackingChart
                        )}
                        _handleSelect={_handleSelectPeriod}
                      />
                    </div>
                    {
                      trackingChart === "Yearly" ? null :
                        <div className="mx-1">
                          <Selector
                          getOptionLabel={(e) => (
                            <div style={{ display: "flex", textAlign: "left" }}>
                              
                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                            </div>
                          )}
                            options={yearOptions}
                            menuListColor={Colors.Dark[0]}
                            value={yearOptions.filter(
                              (y) => y.value == selectedYear
                            )}
                            _handleSelect={_handleSelectYear}
                          />
                        </div>
                    }

                  </div>

                  {/* </div> */}
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <div
                    className="py-3"
                    style={{
                      fontSize: 25,
                      // color:
                      //   focusArea === "energy"
                      //     ? Colors.energy
                      //     : focusArea === "water"
                      //     ? Colors.water
                      //     : focusArea === "plastic"
                      //     ? Colors.plastic
                      //     : Colors.greenMaterial,
                      color: theme === "dark" ? "#fff" : invertDark(theme),
                    }}
                  >
                    {t('Emission Overview')}
                  </div>
                  <div className="d-flex mt-2">
                    <div className="mx-1">
                      <Selector
                       getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        options={[{ label: 'Yearly', value: 'Yearly' }, { label: 'Quarterly', value: 'Quarterly' }, { label: 'Monthly', value: 'Monthly' }]}
                        menuListColor={Colors.Dark[0]}
                        value={[{ label: 'Yearly', value: 'Yearly' }, { label: 'Quarterly', value: 'Quarterly' }, { label: 'Monthly', value: 'Monthly' }].filter(
                          (y) => y.value == trackingChart
                        )}
                        _handleSelect={_handleSelectPeriod}
                      />
                    </div>
                    {
                      trackingChart === "Yearly" ? null :
                        <div className="mx-1">
                          <Selector
                           getOptionLabel={(e) => (
                            <div style={{ display: "flex", textAlign: "left" }}>
                              
                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                            </div>
                          )}
                            options={yearOptions}
                            menuListColor={Colors.Dark[0]}
                            value={yearOptions.filter(
                              (y) => y.value == selectedYear
                            )}
                            _handleSelect={_handleSelectYear}
                          />
                        </div>
                    }
                  </div>

                </div>
              )}
              <div className="row m-0">
                <div
                  className="col-lg-5 col-sm-12 cardStyle me-4 "
                  style={{
                    background: Colors[theme].active,
                    minWidth: 300,
                    color: Colors[theme].textColor,
                    fontSize: 14,
                  }}
                >
                  <div className="d-flex row justify-content-between">
                    <div
                      className="col"
                      style={{ fontSize: 23, color: invertDark(theme) }}
                    >
                      {/* {t("Summary", { subject: t(focusArea) })} */}
                      {t(String(focusArea))}
                      <br />
                    </div>
                    <div className="col d-flex justify-content-end">
                      {focusArea?.toLowerCase() !== "waste" &&
                        focusArea?.toLowerCase() !== "green material" &&
                        surveyFramework?.findIndex(
                          (v) => v.name === "GHG (Version 2)"
                        ) > -1 ? (
                        <MyButton
                          text={"Manual Input"}
                          id={"manualSetup"}
                          textAlign={"text-start"}
                          style={{
                            width: "134px",
                            fontSize: 14,
                            height: "50px",
                            fontWeight: "bold",
                          }}
                          customColor={"#066CFF"}
                          leftIcon={
                            <i className="bi bi-file-earmark-ruled-fill"></i>
                          }
                          customHoverColor={Colors.water}
                          dataToggle="modal"
                          dataTarget="#manualEntryModal"
                        />
                      ) : null}
                    </div>
                  </div>
                  {companyName !== "Digital Edge" || companyName == "ClientA" ? (
                    <MyTable
                      column={column}
                      data={(companyName === 'Enterprise SG' && focusArea === 'Scope 1 Emission') ? (summaryData) : (summaryData?.length > 0 ? summaryData : siteData)}
                      isDetail={true}
                      focusArea={focusArea}
                    />
                  ) : (
                    <MyTable
                      focusArea={focusArea}
                      column={column}
                      data={summaryData}
                      isDetail={true}
                    />
                  )}
                </div>
                <div
                  className="col-lg-6 col-sm-12 cardStyle d-flex flex-grow-1 flex-column "
                  style={{
                    background: Colors[theme].secondBackground,
                    color: Colors[theme].textColor,
                  }}
                >
                  <span
                    className="mb-3 d-flex justify-content-between"
                    style={{ fontSize: 23, color: invertDark(theme) }}
                  >
                    {t(focusArea === "Water"
                      ? "Monthly Water Consumption Profile"
                      : focusArea.includes("Emission")
                        ? `${trackingChart} Emission Profile`
                        : `${trackingChart} Consumption Profile`)}

                    {/* {focusArea==='Waste'||focusArea==='Green Material'||focusArea==='Energy' ||focusArea==='Water'?'Cumulative Consumption':'Monthly Emission Profile'}
                        <Selector options={yearOptions} menuListColor={Colors.Dark[0]} value={yearOptions.filter(y => y.value == selectedYear)} _handleSelect={_handleSelectYear} /> */}
                  </span>

                  <DetailChart
                    theme={theme}
                    companyName={companyName}
                    trackingChart={trackingChart}
                    consumptionChartData={trackingChart === "Monthly" ? consumptionChartData : trackingChart === "Quarterly"? quarterlyCosumptionChartData : yearlyChartData}
                    userName={userName}
                    title={focusArea}
                  />

                </div>
              </div>
              {(surveyFramework) && <ManualEntryModal focusArea={focusArea} />}
              {focusArea === "Energy" ? (
                <div
                  className="row cardStyle my-3 mx-1"
                  style={{
                    background: Colors[theme].active,
                    minWidth: 300,
                    color: Colors[theme].textColor,
                    fontSize: 14,
                  }}
                >
                  <div
                    className="py-2"
                    style={{ fontSize: 23, color: invertDark(theme) }}
                  >
                    {t("Breakdown of Energy Consumption (kWh)")}
                  </div>
                  {breakdownReportData?.monthData.length > 0 ?
                    <div className="row">
                      <div className="col-lg-3 col-12 col-sm-12 d-flex flex-wrap justify-content-center">

                        <div className="container d-flex justify-content-center">
                          <Pie_Chart
                            data={breakdownReportData?.totalData?.filter(v => v.name !== "Purchased Heat and Steam")}
                            showPercent={true}
                          />
                        </div>

                        <div className="row d-flex justify-content-lg-center justify-content-md-start justify-content-md-center">
                          {
                            breakdownReportData?.totalData?.filter(v => v.name !== "Purchased Heat and Steam")?.map((v, k) => {
                              return (
                                  <div className="col-12 d-flex flex-nowrap align-items-start mb-1" key={k}>
                                    <div className="me-2 my-1" style={{ height: 12, width: 12, minWidth: 12, background: COLORS[k % COLORS.length] }}>{" "}</div>
                                    {v.name} : {ThousandSeparator(v.value)}{" "}{v.unit}
                                  </div>
                              )
                            })
                          }
                        </div>

                      </div>
                      <div className="col-lg-9 col-12 col-lg-9 col-sm-12">
                        <BreakdownTable
                          column={breakdownColumn}
                          data={breakDownData.filter(v => v.name !== "Purchased Heat and Steam")}
                        />
                      </div>
                    </div>
                    : <div className="text-secondary h6 text-center my-2">{t("No breakdown of Energy Consumption data for")} {selectedYear}</div>
                  }
                </div>
              ) : null}
            </Fragment>
          {/* )} */}
        </>

      </div>
    );
  })
);
export default inject("store")(observer(TrackingDetail));

const IndividualOverview = (props) => {
  const { focusArea, userName, theme } = props;
  const { t } = useTranslation("tracking");
  let unit = "";
  if (focusArea === "water") {
    unit = "m³/h";
  } else {
    unit = "kWh";
  }
  const columnkw = [
    "Year",
    t(focusArea) + " Consumption (" + unit + ")",
    t("Delta (%) w.r.t ") + " 2021",
    "",
  ];

  const array = [];
  const [data, setData] = useState();

  const kingWan2021EnergyConsumption =
    kingWanData[2021].monthlyConsumption.reduce((cur, acc) => {
      return cur + acc.energyConsumption;
    }, 0);

  const kingWan2021WaterConsumption =
    kingWanData[2021].monthlyConsumption.reduce((cur, acc) => {
      return cur + acc.waterConsumption;
    }, 0);

  const year = [2021, 2022];

  useEffect(() => {
    if (focusArea === "energy") {
      for (var i = 0; i < 2; i++) {
        array.push(
          kingWanData[year[i]].monthlyConsumption.reduce(
            (cur, acc) => {
              return {
                year: year[i],
                energyConsumption: parseInt(
                  cur.energyConsumption + acc.energyConsumption
                ),
                delta: (
                  Math.abs(
                    cur.energyConsumption +
                    acc.energyConsumption -
                    kingWan2021EnergyConsumption
                  ) /
                  (cur.energyConsumption + acc.energyConsumption)
                ).toFixed(2),
              };
            },
            { energyConsumption: 0 }
          )
        );
      }
      setData(array);
    } else {
      for (var i = 0; i < 2; i++) {
        array.push(
          kingWanData[year[i]].monthlyConsumption.reduce(
            (cur, acc) => {
              return {
                year: year[i],
                waterConsumption: parseInt(
                  cur.waterConsumption + acc.waterConsumption
                ),
                delta: (
                  Math.abs(
                    cur.waterConsumption +
                    acc.waterConsumption -
                    kingWan2021WaterConsumption
                  ) /
                  (cur.waterConsumption + acc.waterConsumption)
                ).toFixed(2),
              };
            },
            { waterConsumption: 0 }
          )
        );
      }
      setData(array);
    }
  }, [focusArea]);

  return (
    <Fragment>
      <div
        className="py-3"
        style={{
          fontSize: 25,
          color: theme === "dark" ? "#fff" : invertDark(theme),
        }}
      >
        <img
          className="pe-3"
          width="45px"
          src={focusArea === "energy" ? energyLogo : waterLogo}
        />
        {t("Individual Overview", { subject: t(focusArea) })}
      </div>
      <div className="row m-0">
        <div className="col-lg-6 col-sm-12 me-4 cardStyle">
          <span
            className="mb-3"
            style={{ fontSize: 23, color: Colors.Dark[5] }}
          >
            {t("Cumulative Consumption", { year: 2022 })}
          </span>

          <DetailChart userName={userName} title={focusArea} />
        </div>
        <div className="col-lg-5 col-sm-12 d-flex flex-grow-1 flex-column cardStyle">
          <div className="" style={{ fontSize: 23, color: Colors.Dark[5] }}>
          {t(focusArea)}
            <br />
          </div>
          <MyTableKW column={columnkw} data={data} isDetail={true} />
        </div>
      </div>
    </Fragment>
  );
};
