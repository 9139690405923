import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { Colors } from "../Assets/Config/color.config";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import ListSubheader from "@mui/material/ListSubheader";
import { toJS } from "mobx";

export const MuiGroupMultiSelector = (props) => {
  const { t } = useTranslation("common");
  const {
    options,
    value,
    style,
    disabled,
    placeholder = t("Select_"),
    background,
    _handleChangeUnit,
  } = props;
  const [open, setOpen] = useState(false);

  const theme = localStorage.getItem("theme");

  const customStyle = {
    ...style,
    background: background ? background : Colors[theme].secondBackground,
    color: theme === "dark" ? '#ffffff !important' : '#303030 !important',
    minHeight: 43,
    fontSize: 14,
    cursor: disabled ? "not-allowed !important" : "default",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
    },
    ".MuiSvgIcon-root ": {
      fill: "rgb(4, 197, 0)",
    },
    ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      paddingTop: 0,
      paddingBottom: 0,    
    },
    ".MuiSelect-select": {
      cursor: disabled ? "not-allowed !important" : "default",
    },
  };
  const Icon = ({ innerRef, innerProps }) => (
    <span
      className="px-2"
      role="img"
      aria-label="Dropdown emoji"
      ref={innerRef}
      {...innerProps}
    >
      <i
        className="bi bi-caret-down-fill"
        style={{ color: Colors.greenMaterial }}
      />
    </span>
  );
  const components = {
    DropdownIndicator: Icon,
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        paddingTop: "10px",
      }}
    >
      <FormControl fullWidth>
        {/* <InputLabel
          id="demo-simple-select-label"
          style={{
            color: disabled ? "#808080" : Colors.Dark[4],
            lineHeight: "1em",
            overflow: "hidden",
            fontSize: "12px",
            width: "80%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {value.length > 0 ? "" : t("Select_")}
        </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          sx={customStyle}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={_handleChangeUnit}
          components={components}
          multiple
          renderValue={(selected) => {

            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value._id}
                    label={value.unitName}
                    style={{ color: Colors.Dark[4] }}
                  />
                ))}
              </Box>
            );
          }}
        >
          {options.map((option, index) => {
            return [
              <ListSubheader>{option.label}</ListSubheader>,
              (
                option.units.map((u, i) => {
                  u.buildingId = option.buildingId;
                  return (
                    <MenuItem value={u} key={i}>
                      <Checkbox
                        checked={value.map((v) => v._id).indexOf(u._id) > -1}
                      />
                      {u.unitName}
                    </MenuItem>
                  );
                })
              ),
            ];
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
