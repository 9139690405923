import React from "react";
import { inject, observer } from "mobx-react";
import { AssetTables } from "./AssetTables";
import { BuildingTable } from "./BuildingTable";

const AssetView = inject("store")(
    observer((props) => {
        return (
            props.data ?
                props.data.title === "Building" ?
                <BuildingTable selectedGroupId={props.selectedGroupId} selectedView={props.selectedView} data={props.data} theme={props.theme} _handleTextChange={props._handleTextChange} editTable={props.editTable} _handleEditTable={props._handleEditTable} _handleSelectChange={props._handleSelectChange}/>
                :
                <AssetTables selectedGroupId={props.selectedGroupId} selectedView={props.selectedView} data={props.data} theme={props.theme} _handleTextChange={props._handleTextChange} editTable={props.editTable} _handleEditTable={props._handleEditTable} _handleSelectChange={props._handleSelectChange}/>
            : null
        )
    }));

export default AssetView;