import { useEffect, useRef } from "react";
import { select, scaleBand, axisBottom, stack, max, scaleLinear } from "d3";
import { Colors, invertDark } from "../Config/color.config";

/**
 * Component that renders a StackedBarChart
 */

function StackedBarChart({
  data,
  keys,
  theme,
  currentCo2Emission,
  baseLineCo2Emission,
  offset,
}) {
  // const m = { top: 20, right: 20, bottom: 20, left: 20 };
  const svgRef = useRef();
  useEffect(() => {
    var yPosition = [];

    const svg = select(svgRef.current);
    const width = 225;
    const height = 350;
    svg.selectAll("*").remove();

    // stacks / layers
    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);
    const extent = [
      0,
      max(layers, (layer) => max(layer, (sequence) => sequence[1])),
    ];

    // scales
    const xScale = scaleBand()
      .domain(data.map((d) => d.data))
      .range([0, width])
      .padding(0.25);

    const xScale1 = scaleBand().range([0, 375]);

    const yScale = scaleLinear().domain(extent).range([height, 0]);

    const differencePercent = (a, b) => {
      return (b / a) * 100;
    };
    // example

    svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .selectAll("rect")
      .data((layer) => layer)
      .join("rect")
      .attr("fill", (sequence) =>
        xScale(sequence.data.data) > 100 ?  Colors.orange : Colors.water 
      )
      .attr("x", (sequence) =>
        xScale(sequence.data.data) > 100 ? xScale(sequence.data.data) + 176 : 0
      )
      .attr("width", xScale.bandwidth())
      .attr("stroke", theme === "dark" ? Colors.darkBlue : Colors.Dark[5])
      .attr("y", (sequence) => {
        yPosition.push(yScale(sequence[1]) + 40);
        return yScale(sequence[1]) + 40;
      })
      .attr("height", 0)

    const xAxis = axisBottom(xScale1);
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${height + 42})`)
      .style("color", theme === "dark" ? null : Colors.Dark[2])
      .style("stroke-width", "3px")
      .call(xAxis);

    localStorage.setItem(
      "yPosition",
      JSON.stringify(
        yPosition.filter(function (n) {
          return n;
        })
      )
    );

    const fuelLabel = JSON.parse(localStorage.getItem("yPosition"))
      .slice(0, 4)
      .sort(function (a, b) {
        return b - a;
      });

    svg
      .append("text")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("x", 37)
      .attr("y", yPosition[0]+10 )
      .attr("font-size", "10px")
      .text(data[0].Energy ? keys[0] : null)
      // .append("tspan")
      // .attr("y", yPosition[1] )
      // .attr("x", 37)
      // .text(keys[0])
      .append("tspan")
      .attr("y", yPosition[2]+10)
      .attr("x", 37)
      .text(data[0].Water ? keys[1] : null)
      .append("tspan")
      .attr("y", yPosition[4]+10)
      .attr("x", 37)
      .text(data[0].Plastic ? keys[2] : null)
      .append("tspan")
      .attr("y", yPosition[6]+10)
      .attr("x", 37)
      .text(data[0].GreenMaterial ? keys[3] : null)
      .append("tspan")
      .attr("y", yPosition[6] - 30)
      .attr("x", 37)
      .attr("font-weight", "bold")
      .attr("font-size", "14px")
      .style("fill", invertDark(theme))
      .text(
        Number(parseFloat(baseLineCo2Emission).toFixed(2)).toLocaleString(
          "en-US"
        )
      )
      .append("tspan")
      .style("fill", Colors.Dark[3])
      .attr("font-size", "12px")
      .attr("y", yPosition[6] - 10)
      .attr("x", 37)
      .text("tonnes");

    svg
      .append("text")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("x", 337)
      .attr("y", yPosition[1] + 10)
      .attr("font-size", "10px")
      .text(data[1].Energy ? keys[0] : null)
      .append("tspan")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("x", 337)
      .attr("y", yPosition[3] + 10)
      .attr("font-size", "10px")
      .text(() => {
        return data[1].Water &&
          differencePercent(data[1].Energy, data[1].Water) > 2
          ? keys[1]
          : null;
      })
      .append("tspan")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("x", 337)
      .attr("y", yPosition[5]+10 )
      .attr("font-size", "10px")
      .text(() => {
        return data[1].Plastic &&
          differencePercent(data[1].Water, data[1].Plastic) > 2
          ? keys[2]
          : null;
      })
      .append("tspan")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("x", 337)
      .attr("y", yPosition[7] +10)
      .attr("font-size", "10px")
      .text(() => {
        return data[1].GreenMaterial &&
          differencePercent(data[1].Plastic, data[1].GreenMaterial) > 2
          ? keys[3]
          : null;
      })
      .append("tspan")
      .attr("x", 355)
      .attr("font-size", "14px")
      .attr("font-weight", "bold")
      // .append("tspan")
      .attr("y", yPosition[7] - 30)
      .attr("x", 337)
      .style("fill", invertDark(theme))
      .text(
        Number(parseFloat(currentCo2Emission).toFixed(2)).toLocaleString(
          "en-US"
        )
      )
      .append("tspan")
      .attr("font-size", "12px")
      .style("fill", Colors.Dark[3])
      .attr("y", yPosition[7] - 10)
      .attr("x", 337)
      .text("tonnes");

    svg
      .selectAll("rect")
      .transition()
      .duration(500)
      .attr("y", (sequence) => {
        yPosition.push(yScale(sequence[1]) + 40);
        return yScale(sequence[1]) + 40;
      })
      .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]))
      .delay((d, i) => {
        return i * 30;
      });

     // Target line
svg
  .append("line")
  .attr("x1", 316)
  .attr("x2", 60)
  .attr("y1", "22%")
  .attr("y2", "22%")
  .attr("stroke-width", 2)
  .attr("stroke", theme === "dark" ? invertDark(theme) : Colors.darkBlue)
  .attr("margin");
 svg
    .append("path")
    .attr("d", function (d) {
      var x = 200,
        y = 40;
      return "M " + x + " " + y + " l 24 24 l -48 0 z";
    })
    .attr("stroke", "white")
    .attr("fill", invertDark(theme))
    .attr("stroke-width", "3px")
    .attr("transform", `translate(0, ${height + 1})`);
    
  }, [theme, data, baseLineCo2Emission, currentCo2Emission]);

  return (
    <div style={{ color: "white", width: 375 }}>
      <div
        className="d-flex justify-content-between fw-bold text-center pb-3"
        style={{
         
          color: theme !== "dark" ? Colors.Dark[2] : Colors.Dark[4],
          fontSize: 15,
        }}
      >
        <span className="col-2 ms-2">Baseline Emission Profile</span>
        <span className="col-2 me-2">Current Emission Profile</span>
      </div>
      <div style={{ paddingTop: 35 }}>
        <svg ref={svgRef} id="StackBar" width={500} height={500} />
      </div>
    </div>
  );
}

export default StackedBarChart;

