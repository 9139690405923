import { IconCard } from "../../Tools";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { baseURLImage } from "../../API/URL";
import { LoadingPage } from "../../Page/Loading";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';


export const Certification = inject("store")(
  observer((props) => {

    const { _handleTargetCheck, permittedBuilding, theme } = props.store.common;
    const { selectedIndex } = props.store.target;
    const [certificateArray, setCertificateArray] = useState([]);
    const { t } = useTranslation("target_setting");

    useEffect(() => {
      if (permittedBuilding) {
        const frameworks = permittedBuilding[selectedIndex]?.surveyHeader?.map(
          (v) => {
            return {
              ...v,
              isTracking: false,
            };
          }
        );
        setCertificateArray(frameworks);
      }
    }, [permittedBuilding, selectedIndex]);

    const _handleSelect = (name, status) => {};

    return (
      <div className="my-1">
        <span style={{ fontSize: 20, color: invertDark(theme) }}>{ t("Framework(s)") }</span>
        <div className="my-3 pb-2 d-flex justify-content-start flex-wrap" style={{ flexGrow: 1 }}>
          {certificateArray && certificateArray.length > 0 ? (
            <>
              {certificateArray.map((f, i) => (
                <div
                  key={i}
                  className="d-flex flex-column col-4 col-xxl-3 my-1"
                  onClick={() => _handleTargetCheck(f)}
                >
                  <IconCard
                    id={f.framework_id}
                    status={f.isTracking}
                    // icon={f.imageURL}
                    label={f.name}
                    width={50}
                    theme={theme}
                    // cardWidth={152}
                    type="checkbox"
                    imgURL={baseURLImage + f.imageURL}
                    shadow={'2px 4px 11px rgba(0,0,0,0.15)'}
                  />
                </div>))
              }
              <div className="d-flex flex-column col-4 col-xxl-3 my-1 px-2" style={{ color: Colors[theme].textColor, }}>
                <div
                  className="iconCardStyle d-flex h-100 flex-column align-items-center justify-content-center w-100 px-2"
                  data-bs-toggle="modal"
                  data-bs-target="#frameworkModal"
                  style={{
                    minHeight: 157,
                    background: "transparent",
                    border: theme === "dark"? "1px dashed white" : "1px dashed black",
                    borderStyle: "dashed",
                    cursor: "pointer",
                    color: Colors[theme].textColor,
                    whiteSpace: "normal"
                  }}
                >
                  <div className="row d-flex justify-content-center" style={{ fontSize: 34 }}>+</div>
                  <div className="row d-flex justify-content-center" style={{ fontSize: 13, opacity: 0.5, whiteSpace: "normal",}}>{ t("Add New Framework") }</div>
                </div>
              </div>
            </>
          ) : permittedBuilding?.length!=0 && (
            <div className="w-100 text-center">
              <i className="spinner-border mt-2" />{" "}
            </div>
          )}
        </div>
      </div>
    );
  })
);
