import { DonutChart } from "../../Assets/Charts/DonutChart";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import energyLogo from "../../Assets/images/Energy.svg";
import waterLogo from "../../Assets/images/water.svg";
import plasticLogo from "../../Assets/images/plastic.png";
import plasticBlackIcon from "../../Assets/images/plastic_black.png";
import greenMaterialLogo from "../../Assets/images/greenMaterial.svg";
import { MyTable } from "../../Tools";
import { ProgressBar, ProgressCircle } from "../../Tools";
import { useNavigate } from "react-router-dom";
import Store from "../../Store";
import { Tracking } from "../../Components/Tracking/Tracking";
import { Cookies } from "react-cookie";
import { useEffect } from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { DummyTracking } from "../../Components/Tracking/DummyTracking";

const cookies = new Cookies();
const userName = cookies.get("userName");
export const TrackingContainer = inject("store")(
  observer((props) => {
    const { focusArea, getBuildingList, permittedBuilding, theme, companyName } = props.store.common;
    const userName = cookies.get("userName");

    const { t } = useTranslation("tracking");

    const navigate = useNavigate();

    let trackings = null;

    let columns = null;

    const energyTrackings =
      [
        {
          title: "energy",
          target: t("TARGET") + " 1",
          description:
            companyName === 'Digital Edge' || companyName == "ClientA" ? t("Transition 50% of electricity consumption in operational sites to renewable or carbon-free energy by 2025, and 100% by 2030.") : t("To reduce energy consumption by 3.5% p.a. relative to") + ` ${userName === 'King Wan' ? '2021' : " baseline year"}`,
          progress: 100,
          percentage: 100,
          progressBarColor: Colors.energy,
        },
      ];

    const energyColumnName =
      userName === "King Wan"
        ? [
          t("Site"),t("Country"),
          t("Energy Consumption") + " 2021 (kWh)",
          t("Energy Consumption") + " 2022 (kWh)",
          t("Target Progress"),
        ]
        :
        companyName === 'Digital Edge' || companyName == "ClientA" ? [
         t("Site"),t("Country"),
          t("Electricity Consumption") +" kWh "+ "(" + t("Baseline Year") + ")",
          t("Electricity Consumption") +" kWh "/*+ "(" + t("Current Year") + ")"*/,
          // "2025" + t("Target Progress"),
        ]
          :
          companyName === 'Far East Orchard' ? [
            t("Site"),t("Country"),
            t("Energy Consumption") + " kWh (" + t("Baseline Year") + ")",
            t("Energy Consumption") + " kWh (" + t("Current Year") + ")",
            t("Target Progress"),
          ]
            : [
              t("Site"),t("Country"),
              t("Energy Consumption") + " kWh (" + t("Baseline Year") + ")",
              t("Energy Consumption") + " kWh (" + t("Current Year") + ")",
              t("Target Progress"),
            ];

    const scopeColumnName = companyName === 'Digital Edge' || companyName == "ClientA" ?
    [
      t("Site"),t("Country"),
      t(focusArea) +` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea) +` Kg CO2 `,
    ]
    :
     [
      t("Site"),t("Country"),
      t(focusArea) +` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea) +` Kg CO2 ` + "(" + t("Current Year") + ")",
      t("Total Progress")
    ];

    const scope1ColumnName = companyName === 'Digital Edge' || companyName == "ClientA" ?
    [
      companyName==="Enterprise SG"? t("Category"):t("Site"),t("Country"),
      t(focusArea) +` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea) +` Kg CO2 `
    ]
    :
    [
      companyName==="Enterprise SG"? t("Category"):t("Site"),t("Country"),
      t(focusArea) +` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea) +` Kg CO2 ` + "(" + t("Current Year") + ")",
      t("Total Progress")
    ]

    const scope3ColumnName = companyName === 'Digital Edge' || companyName == "ClientA" ?
    [
      t("Site"),t("Country"),
      t(focusArea)+ ` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea)+ ` Kg CO2 `,
    ]
    :
     [
      t("Site"),t("Country"),
      t(focusArea)+ ` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea)+ ` Kg CO2 ` + "(" + t("Current Year") + ")",
      t("Total Progress")
    ];

    const scope3ColumnNameByCategory = [
      t("Category"),
      t(focusArea)+` Kg CO2 ` + "(" + t("Baseline Year") + ")",
      t(focusArea)+` Kg CO2 ` + "(" + t("Current Year") + ")",
      t("Total Progress")
    ];

    const waterColumnName =
      userName === "King Wan"
        ? [
          t("Site"),t("Country"),
          t("Water Consumption") + " (m³/h) (2021)",
          t("Water Consumption") + " (m³/h) (2022)",
          t("Target Progress"),
        ]
        : companyName === 'Digital Edge' || companyName == "ClientA" ?
        [
          t("Site"),t("Country"),
          t("Water Consumption") + " CuM (" + t("Baseline Year") + ")",
          t("Water Consumption") ,
        ]:
        [
          t("Site"),t("Country"),
          t("Water Consumption") + " CuM (" + t("Baseline Year") + ")",
          t("Water Consumption") + " CuM (" + t("Current Year") + ")",
          t("Total Progress"),
        ];

        const greenColumnName = [
          t("Site"),
          t("Total No. of items to be used for Aggregation"),
          t("Total No. 'Green Material' items"),
          t("Target Progress"),
        ];
        
        const plasticColumnName = [
          t("Site"),
          t("Target Progress"),
          t("Est. Date of Completion"),
        ];
        
        
        const scope3Trackings =
          [
            {
              title: t("scope"),
              target: t("TARGET 1"),
              description:
                t('To reduce scope 3 business travel emission by 1% p.a. relative to baseline year'),
              progress: 100,
              percentage: 100,
              progressBarColor: Colors.energy,
            },
          ];
        
        
        
        
        
        const greenTrackings = [
          {
            title: "green",
            target: t("TARGET 1"),
            description:
              t("Increase amount of Green Materials used to 50%"),
            progress: 100,
            percentage: 75,
            progressBarColor: Colors.greenMaterial,
          },
        ];
        

    const scope1Trackings =
      [
        {
          title: "scope1",
          target: t("TARGET") + " 1",
          description: t("To reduce scope 1 emission by 5% p.a. relative to baseline year"),
          progress: 100,
          percentage: 100,
          progressBarColor: Colors.energy,
        },
      ];
    const scope2Trackings =
      [
        {
          title: "scope",
          target: t("TARGET") + " 1",
          description:
            companyName === 'Syscom' ? t("To reduce scope 2 emission by 5% p.a. relative to baseline year") : t("To reduce scope 2 emission by 20% p.a. relative to baseline year"),
          progress: 100,
          percentage: 100,
          progressBarColor: Colors.energy,
        },
      ];

    const waterTrackings = [
      {
        title: "water",
        target: t("TARGET") + " 1",
        description:
          companyName === 'Digital Edge' || companyName == "ClientA" ? t('To measure water consumption monthly'): t("To reduce water consumption by") + `${userName === 'King Wan' ? t('13% p.a. relative to 2021') : t('5% p.a. relative to baseline year')}`,
        progress: 100,
        percentage: 100,
        progressBarColor: Colors.water,
      },
    ];

    const plasticTrackings = companyName === 'Syscom' ? [
      {
        title: "waste",
        target: t("TARGET") + " 1",
        description: t("Eliminate plastic drinking bottles"),
        progress: 0,
        percentage: 0,
        progressBarColor: Colors.Dark[5],
      }
    ] : [
      {
        title: "waste",
        target: t("TARGET") + " 1",
        description: t("Eliminate plastic drinking bottles in all hotel rooms"),
        progress: 0,
        percentage: 0,
        progressBarColor: Colors.Dark[5],
      },
      {
        title: "waste",
        target: t("TARGET") + " 2",
        description: t("Eliminate plastic drinking bottles in PLG offices"),
        progress: 45,
        percentage: 100,
        progressBarColor: Colors.Dark[5],
      },
      {
        title: "waste",
        target: t("TARGET") + " 3",
        description: t("Eliminate plastic take away packaging in all F&B outlets"),
        progress: 78,
        percentage: 80,
        progressBarColor: Colors.Dark[5],
      },
      {
        title: "waste",
        target: t("TARGET") + " 4",
        description: t("Minimise in-room plastic presence"),
        progress: 65,
        percentage: 90,
        progressBarColor: Colors.Dark[5],
      },
      {
        title: "waste",
        target: t("TARGET") + " 5",
        description: t("Eliminate plastic drinking straws in all F&B outlets"),
        progress: 58,
        percentage: 70,
        progressBarColor: Colors.Dark[5],
      },
      {
        title: "waste",
        target: t("TARGET") + " 6",
        description: t("Elimination of small plastic liquid amenities bottles"),
        progress: 38,
        percentage: 65,
        progressBarColor: Colors.Dark[5],
      },
      {
        title: "waste",
        target: t("TARGET") + " 7",
        description: t("Eliminate single-use plastic presence in meeting rooms"),
        progress: 50,
        percentage: 65,
        progressBarColor: Colors.Dark[5],
      },

    ];

    if (focusArea === "Energy") {
      trackings = energyTrackings;
      columns = energyColumnName;
    } else if (focusArea === "Water") {
      trackings = waterTrackings;
      columns = waterColumnName;
    } else if (focusArea === "Waste") {
      trackings = plasticTrackings;
      columns = plasticColumnName;
    } else if (focusArea === "Green Material") {
      trackings = greenTrackings;
      columns = greenColumnName;
    }
    else if (focusArea === "Scope 1 Emission") {
      trackings = scope1Trackings;
      columns = scope1ColumnName;
    }
    else if (focusArea === "Scope 2 Emission") {
      trackings = scope2Trackings;
      columns = scopeColumnName;
    }
    else if (focusArea === "Scope 3 Emission") {
      trackings = scope3Trackings;
      if (companyName == "Enterprise SG") {
        columns = scope3ColumnNameByCategory;
      } else {
        columns = scope3ColumnName;
      }

    }

    useEffect(() => {
      getBuildingList()
    }, [])
    return (
      <div className="row m-3 mt-0">
        <div className="d-flex justify-content-between pb-3">
          <div
            style={{
              fontSize: 25,
              // color:
              //   focusArea === "energy"
              //     ? Colors.energy
              //     : focusArea === "water"
              //       ? Colors.water
              //       : focusArea === "plastic"
              //         ? Colors.plastic
              //         : Colors.greenMaterial,
              alignItems: "center",
              display: "flex",
              color: theme === 'dark' ? '#fff' : invertDark(theme)
            }}
          >
            {focusArea === "Energy" || focusArea === "Water" || focusArea === "Waste" || focusArea === "GreenMaterial" && <img
              width={"45px"}
              className="pe-3 my-2"
              src={
                focusArea === "Energy"
                  ? energyLogo
                  : focusArea === "Water"
                    ? waterLogo
                    : (focusArea === "Waste" && theme === 'dark')
                      ? plasticLogo
                      : (focusArea === "Waste" && theme === 'light')
                        ? plasticBlackIcon
                        : focusArea === "GreenMaterial" ? greenMaterialLogo : null
              }
            />}
            {(companyName === 'Digital Edge' || companyName == "ClientA") && focusArea === "Energy" ? 'Electricity Consumption and Target Tracking' : focusArea === "Energy" || focusArea === "Water" || focusArea === "Waste" || focusArea === "GreenMaterial" ? t("Consumption and Target Tracking", { subject: t(focusArea) })
              : t(focusArea + ' Tracking')}
          </div>
          <span
            style={{
              fontSize: 20,
              color: invertDark(theme),
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <i className="bi bi-arrow-left-circle-fill pe-3"></i>{t("Back to HQ view")}
          </span>
        </div>

        <div className="scroll" style={{ maxHeight: "85vh" }}>
          {
            (userName === 'Tester' && (focusArea === 'Scope 1 Emission' || focusArea === "Waste")) ?
              <DummyTracking
                trakingValue={trackings[0]}
                focusArea={focusArea}
                columNames={columns}
                index={0}
                key={0}
                permittedBuilding={[]}
              />
              : focusArea === 'Scope 3 Emission' ?
                <DummyTracking
                  companyName={companyName}
                  trakingValue={trackings[0]}
                  focusArea={focusArea}
                  columNames={columns}
                  permittedBuilding={permittedBuilding}
                />
                :
                (
                  trackings?.map((tracking, k) => {
                    return (
                      // ((companyName === 'Syscom' && focusArea === 'Water')) ? <DummyTracking
                      //   trakingValue={tracking}
                      //   focusArea={focusArea}
                      //   columNames={columns}
                      //   index={k}
                      //   key={k}
                      //   permittedBuilding={permittedBuilding}
                      // /> :
                      <Tracking
                        companyName={companyName}
                        trakingValue={tracking}
                        focusArea={focusArea}
                        columNames={columns}
                        index={k}
                        key={k}
                        permittedBuilding={permittedBuilding}
                      />
                    )
                  }

                  )
                )
          }
        </div>
      </div>
    );
  }));



