import NavButton from "../Tools/NavButton";
import { Colors } from "../Assets/Config/color.config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const UsFlag = <img src="/countryflags/us.svg" style={ {width: '32px', height: '32px'} } alt="US"/>;
const TaiwanFlag = <img src="/countryflags/taiwan.svg" style={ {width: '32px', height: '20px'} } alt="CN"/>;
const ChinaFlag = <img src="/countryflags/cn.svg" style={ {width: '32px', height: '32px'} } alt="CN"/>;
const ItalyFlag = <img src="/countryflags/it.svg" style={ {width: '32px', height: '32px'} } alt="IT"/>;
const GermanFlag = <img src="/countryflags/de.svg" style={ {width: '32px', height: '32px'} } alt="DE"/>;
const JapanFlag = <img src="/countryflags/jp.svg" style={ {width: '32px', height: '32px'} } alt="JP"/>;
const ThaiFlag = <img src="/countryflags/th.svg" style={ {width: '32px', height: '32px'} } alt="TH"/>;


const languages = [
    {lang: "en", flag: UsFlag,text:"English(US)"},
    {lang: "tw", flag: TaiwanFlag,text:"中文 (繁體)"},
    // {lang: "zh", flag: ChinaFlag,text:"中文 (简体)"},    
    {lang: "it", flag: ItalyFlag,text:"Italiano(IT)"},
    {lang: "de", flag: GermanFlag,text:"Deutsch(DE)"},
    {lang: "jp", flag: JapanFlag,text:"日本(JP)"},
    {lang: "th", flag: ThaiFlag,text:"ไทย(TH)"},
];

const LanguageMenuItem = (props) => {
    const navigate = useNavigate();
    const {
      userName,
      theme,
      authenticated,
      showDropDown,
      _handleLogout,
      ...others
    } = props;
    const { t, i18n } = useTranslation("authentication");

    
    let found = languages.find(obj => obj.lang === i18n.language);
    if(!found){
      i18n.changeLanguage("en");
    }

    const currentLang = languages.find(obj => obj.lang === i18n.resolvedLanguage);
    
  
    return <>
      <NavButton
        startIcon={currentLang.flag}
        {...others}
        style={{ color: Colors[theme].primaryColor, width:130, fontSize:12 }}
        theme={theme}
      >
        {currentLang.text}
      </NavButton>

      {showDropDown && (
            <div
              className="p-2 hover cardStyle"
              style={{
                position: "absolute",
                fontSize: 16,
                top: 65,
                // right: authenticated ? 116 : 20,
                background: Colors[theme].background,
                color: Colors[theme].primaryColor,
                zIndex: 1,
                borderRadius: 5,
                width: 170,
                cursor: "pointer",
                border: `1px solid ${Colors.lightBlue}`,
              }}
            >
                {languages.filter(obj => obj.lang !== i18n.resolvedLanguage)
                .map(obj =>
                    <div className="p-1"
                        key={obj.lang}
                        onClick={()=> i18n.changeLanguage(obj.lang)}
                    >
                        {obj.flag} {obj.text}
                    </div>
                )}
            </div>
          )}
    </>;
  }

  export default LanguageMenuItem;