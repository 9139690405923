import { inject, observer } from "mobx-react";
import { Colors } from "../Assets/Config/color.config";
import { MyButton } from "./MyButton";
import { toJS } from "mobx";
import { CheckManualEntryModal } from "../Components/Survey/CheckManualEntryModal";
import { useTranslation } from 'react-i18next';

export const Pagination = inject("store")(
  observer((props) => {
    const {
      subSectionArray,
      subSectionIndex,
      _handlePageClick,
      sectionIndex,
      indexAlphabet,
      surveyInfo,
      selectedYear,
      selectedField,
      isOpenAllQuestions,
      _handleCalculateCheckStatus,
      manualCheckStatus,
      _handleClickCarbonTab
    } = props.store.survey;

    const { t } = useTranslation("common");

    const { sectionName, sectionHeader } = toJS(
      surveyInfo?.surveySection[sectionIndex]
    );

    const { theme } = props.store.common;

    const { surveyTitle } = surveyInfo;


    const defaultStyle = {
      width: 38,
      height: 38,
      background: theme === 'dark' ? Colors[theme].active : '#fff',
      lineHeight: 2.3,
      cursor: "pointer",
      transition: "0.1s",
    };
    const selectedStyle = {
      ...defaultStyle,
      width: 45,
      height: 45,
      lineHeight: 2.8,
      fontWeight: "bold",
      boxShadow: Colors.shadow,
      //  borde r:"3px solid #fff"
    };

    const _handleCarbonTab = () => {
      if(sectionName === "Scope 2"){
        _handleClickCarbonTab("scope2Emission")
      }else if(sectionName === "Scope 3"){
        _handleClickCarbonTab("scope3Emission")

      }
    }

    const modifiedSubSectionArray =
      surveyTitle === "WWF" && !isOpenAllQuestions
        ? subSectionArray.slice(0, 1)
        : subSectionArray;
    return (
      <div
        className="d-flex flex-row flex-wrap text-center"
        style={{ height: 65, color: Colors[theme].textColor }}
      >
        {modifiedSubSectionArray.map((v, k) => (
          <span
            key={k}
            id={k}
            className="align-self-center m-2 rounded"
            style={k === subSectionIndex ? selectedStyle : defaultStyle}
            onClick={() => _handlePageClick(k)}
          >
            {indexAlphabet[sectionIndex]}
            {k + 1}
          </span>
        ))}
        {/* {sectionName === "Scope 2" && ( /////////////temporary closed by Thiri/////////
          <>
            <div
              id="calculateScope2"
              className="my-auto mx-1"
              // data-bs-toggle="modal"
              // data-bs-target="#calculateModal"
            >
              <MyButton
                text={t("Convert Emission")}
                type="button"
                id={"calculateManualEntry"}
                onClick={() => {
                  _handleCalculateCheckStatus(2);
                  _handleCarbonTab()
                }}
                // style={{ width: "135px" }}
                customHoverColor={"#35469b"}
              />
            </div>
          <CheckManualEntryModal />
          </>
        )} */}

        {sectionName === "Scope 3" && (
          <>
            <div
              id="calculateScope3"
              className="my-auto mx-1"
              // data-bs-toggle="modal"
              // data-bs-target="#calculateModal"
            >
              <MyButton
                text={"Calculate"}
                type="button"
                id={"calculateManualEntry"}
                onClick={() => _handleCalculateCheckStatus(3)}
                style={{ width: "140px" }}
                customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
                customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'}
              />
            </div>
          <CheckManualEntryModal />
          </>
        )}

        {/* <div
           title={"Set Target"}
          data-bs-toggle="modal"
          data-bs-target="#setTargetModal"
          style={{
            position: "absolute",
            left: 15,
            top: 15,
            cursor: "pointer",
            zIndex: 3,
          }}
         
        >
          <MyButton
            id='clickButton'
            customColor="#066CFF"
            customHoverColor={Colors.water}
            onClick = {(e) => _handleSettingData()}
            text={<i class="bi bi-gear-fill p-1" />}
          />
        </div> */}

        {/* {subSectionArray.map((v, k) => (
          <span
            key={k}
            id={k}
            className="align-self-center m-2 rounded"
            style={k === subSectionIndex ? selectedStyle : defaultStyle}
            onClick={() => _handlePageClick(k)}
          >
            {v.questionNumber}
          </span>
        ))} */}
      </div>
    );
  })
);
