import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { baseURLImage } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyInput, Selector, MyCreatableSelector, MyButton } from "../../Tools";
import PlusIcon from "../../Assets/images/PlusSquareFill.svg";
import { useTranslation } from "react-i18next";

const yearsOption = [
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];

export const SetTargetModal = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {
      selectedTargetData,
      _createTargetSetting,
      _handleTargetChange,
      _handleHeaderChange,
      _handleAddSetting,
      selectedTargetYear,
      selectedBaselineYear,
      settings,
      dataSource,
      unit,
      _handleCloseTarget,
      _handleAddDataSource
    } = props.store.target;

    const { t } = useTranslation("target_setting");

    return (
      <div
        className={`modal fade text-dark ${theme}`}
        id="setTargetModal"
        tabIndex="-1"
        aria-labelledby="setTargetModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="setTargetModalLabel">
                {t("Set Target")}
              </h5>
              <i
                className="bi bi-x"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => window.location.reload()}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="modal-body">
              <div className="my-3 mx-1 d-flex justify-content-start">
                {/* <div className="" style={{ color: invertDark(theme) }}> */}
                <label className="my-auto ms-1 me-3">{t("Data Source") + ": "}</label>
                <div className="mx-2" style={{ width: 250 }}>
                  <MyCreatableSelector
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", textAlign: "left" }}>

                        <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                      </div>
                    )}
                    theme={theme}
                    id={"dataSource"}
                    value={
                      dataSourceOption.find(
                        (option) => option.value === dataSource
                      )
                    }
                    options={dataSourceOption}
                    menuListColor={Colors.Dark[0]}
                    _handleSelect={(e) => {
                      _handleTargetChange(null, "dataSource", e)
                      _handleTargetChange(null, "unit", { value: null })
                    }}
                    placeholder={t("Select Data Source")}
                    textChange={(e) =>
                      _handleTargetChange(null, "dataSourceText", e)
                    }
                  />
                </div>
                <label className="my-auto ms-1 me-3">{t("Unit") + ": "}</label>
                <div className="mx-2" style={{ width: 150 }}>
                  <Selector
                    _handleSelect={(e) => _handleTargetChange(null, "unit", e)}
                    value={unitOptions.filter(
                      (option) => option.value === unit
                    )}
                    menuListColor={Colors.Dark[0]}
                    options={unitOptions.filter(v => v.dataSource === dataSource)}
                  />
                </div>
                <MyButton
                  id='createTarget'
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                  onClick={_handleAddDataSource}
                  text={"+ " + t("Add Target")}
                  // style={{height: 35}}
                  disabled={dataSource === null || !unit}
                  className="my-auto mx-1 pt-1"
                />
                {/* </div> */}
              </div>
              <table
                className="table"
                style={{
                  width: "100%",
                  background: Colors[theme].secondBackground,
                  color: invertDark(theme),
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                }}
              >
                <thead>
                  <tr
                    className="px-5"
                    style={{
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    <th className={`pb-3 ps-3`}>
                      {/* <img
                        onClick={() => _handleAddSetting()}
                        title={"Add New Target"}
                        className="col px-2"
                        src={PlusIcon}
                        alt="plus_icon"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ cursor: "pointer" }}
                      /> */}
                    </th>
                    <th className={`pb-3 ps-3`}>{t("Data Source")}</th>
                    <th className={`pb-3 ps-3`}><span><i className="bi bi-info-circle pe-1 text-secondary" title="A historic point of comparison that we can use to track changes and improvements at our property over time." style={{ cursor: "pointer" }}></i>{t("Baseline Year")}</span></th>
                    <th className={`pb-3 ps-3`}><span><i className="bi bi-info-circle pe-1 text-secondary" title="A reference state or the values against which we measure change." style={{ cursor: "pointer" }}></i>{t("Baseline Value")}</span></th>
                    <th className={`pb-3 ps-3`}><span><i className="bi bi-info-circle pe-1 text-secondary" title="A year commencing on January 1 and ending on the succeeding December 31." style={{ cursor: "pointer" }}></i>{t("Target Year")}</span></th>
                    <th className={`pb-3 ps-3`}><span><i className="bi bi-info-circle pe-1 text-secondary" title="A normalized metric that sets an organization's emissions target relative to an economic or operational variable." style={{ cursor: "pointer" }}></i>{t("Target Value")}</span></th>

                    <th className={`pb-3 ps-3`}>{t("Unit")}</th>
                    <th className={`pb-3 ps-3`}>{t("Target Reduction(%)")}</th>
                  </tr>
                </thead>
                <tbody>
                  {settings?.map((setting, index) => (
                    <TargetSetting setting={setting} index={index} t={t} />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              // onClick={() => window.location.reload()}
              >
                {t("Close")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => _createTargetSetting()}
              >
                {t("Save Changes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const dataSourceOption = [
  { label: "Energy Consumption", value: "Energy Consumption" },
  { label: "Water Consumption", value: "Water Consumption" },
  { label: "Waste Recycling", value: "Waste Recycling" },
  { label: "Scope1 Emission", value: "Scope1 Emission" },
  { label: "Scope2 Emission", value: "Scope2 Emission" },
  { label: "Scope3 Emission", value: "Scope3 Emission" },
];

export const TargetSetting = inject("store")(
  observer((props) => {
    const { setting, index, t } = props;
    const { theme } = props.store.common;
    const { _handleRemoveSetting, _handleTargetChange } = props.store.target;

    const dataSource = toJS(setting.dataSource) && {
      value: setting.dataSource,
      label: setting.dataSource,
    };

    return (
      <tr key={index} style={{ border: "0px solid rgba(255, 255, 255, 0.1)" }}>
        <td className="py-3 ps-2">
          <div
            className="col d-flex justify-content-center align-items-center rounded text-white mx-3"
            onClick={() => _handleRemoveSetting(index)}
            title="Delete Target"
            style={{
              background: "rgb(255 0 0 / 50%)",
              height: 26.3,
              width: 26.3,
              cursor: "pointer",
            }}
          >
            <i className="bi bi-trash3"></i>
          </div>
        </td>
        <td className="py-3 ps-2">
          <div className="d-flex align-items-center p-0 m-0">
            <MyInput
              id={"dataSource"}
              disabled
              value={t(setting.dataSource)}
              placeHolder="Data Source"
              type="text"
            />
            {/* <div style={{ width: 250 }}>
              <MyCreatableSelector
                theme={theme}
                id={"dataSource" + index}
                value={
                  dataSourceOption.find(
                    (option) => option.value === setting.dataSource
                  ) || dataSource
                }
                options={dataSourceOption}
                menuListColor={Colors.Dark[0]}
                _handleSelect={(e) =>
                  _handleTargetChange(index, "dataSource", e)
                }
                placeholder={"Select Data Source"}
                textChange={(e) =>
                  _handleTargetChange(index, "dataSourceText", e)
                }
              />
            </div> */}
          </div>
        </td>

        <td className="py-3 ">
          <div className="col-11">
            <Selector
              _handleSelect={(e) =>
                _handleTargetChange(index, "baselineYear", e)
              }
              value={yearsOption.filter(
                (option) => option.value === setting.baselineYear
              )}
              menuListColor={Colors.Dark[0]}
              options={yearsOption}
            />
          </div>
        </td>

        <td className="py-3 ps-2">
          <MyInput
            id={"baseline" + index}
            onChange={(e) => _handleTargetChange(index, "baseline", e)}
            value={setting.baselineValue}
            placeHolder="Base line value"
            type="number"
          />
        </td>

        <td className="py-3 ">
          <div className="col-11">
            <Selector
              _handleSelect={(e) => _handleTargetChange(index, "targetYear", e)}
              value={yearsOption.filter(
                (option) => option.value === setting.targetYear
              )}
              menuListColor={Colors.Dark[0]}
              options={yearsOption}
            />
          </div>
        </td>

        <td className="py-3 ps-2">
          <MyInput
            id={"target" + index}
            onChange={(e) => _handleTargetChange(index, "target", e)}
            value={setting.targetValue}
            placeHolder="Target value"
            type="number"
          />
        </td>
        <td className="py-3 ">
          <div className="col-11">

            <MyInput
              id={"unit"}
              disabled
              value={setting.unit}
              placeHolder="Unit"
              type="text"
            />
          </div>
        </td>
        <td className="py-3 ps-2">
          <MyInput
            id="targetPercent"
            onChange={(e) => _handleTargetChange(index, "targetPercent", e)}
            value={setting.targetPercent}
            placeHolder="Target Percent"
            type="number"
          />
        </td>

      </tr>
    );
  })
);

const unitOptions = [
  { label: "kg CO2", value: "kg CO2", dataSource: 'Scope1 Emission' },
  { label: "g CO2", value: "g CO2", dataSource: 'Scope1 Emission' },
  { label: "tonnes", value: "tonnes", dataSource: 'Scope1 Emission' },

  { label: "kg CO2", value: "kg CO2", dataSource: 'Scope2 Emission' },
  { label: "g CO2", value: "g CO2", dataSource: 'Scope2 Emission' },
  { label: "tonnes", value: "tonnes", dataSource: 'Scope2 Emission' },

  { label: "kg CO2", value: "kg CO2", dataSource: 'Scope3 Emission' },
  { label: "g CO2", value: "g CO2", dataSource: 'Scope3 Emission' },
  { label: "tonnes", value: "tonnes", dataSource: 'Scope3 Emission' },

  { label: "kWh", value: "kWh", dataSource: 'Energy Consumption' },
  { label: "Wh", value: "Wh", dataSource: 'Energy Consumption' },

  { label: "m3/h", value: "m3/h", dataSource: 'Water Consumption' },
  { label: "CuM", value: "CuM", dataSource: 'Water Consumption' },

  { label: "kg", value: "kg", dataSource: 'Waste Recycling' },
  { label: "pounds", value: "pounds", dataSource: 'Waste Recycling' },
  { label: "tonnes", value: "tonnes", dataSource: 'Waste Recycling' },
  // { label: "kg", value: "kg" },
  // { label: "g", value: "g" },
  // { label: "kg CO2", value: "kg CO2" },
  // { label: "tonnes", value: "tonnes" },
  // { label: "pounds", value: "pounds" },
  // { label: "gallon", value: "gallon" },
  // { label: "L", value: "L" },
  // { label: "m3", value: "m3" },
  // { label: "CuM", value: "CuM" },
  // { label: "kWH", value: "kWH" },
  // { label: "MWH", value: "MWH" },
  // { label: "MW", value: "MW" },
  // { label: "Horsepower", value: "Horsepower" },
];
