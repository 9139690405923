import { inject, observer } from "mobx-react";
import { invertDark } from "../Assets/Config/color.config";
import { useNavigate } from "react-router-dom";

const Navigator = inject("store")(
    observer((props) => {
        const { pageName, goTo, link, surveyNav, handleBack } = props;
        const { theme } = props.store.common;
        const navigate = useNavigate();
        return (
            <div className={`d-flex flex-row justify-content-between align-items-center ${surveyNav ? '' : 'mx-3'} `} style={{ color: invertDark(theme) }}>
                <div style={{ fontSize: 25 }}>{pageName}</div>
                <div
                    className="me-2"
                    style={{
                        fontSize: surveyNav ? 18 : 20,
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                    }}
                    onClick={link ? () => navigate(link): handleBack}
                >
                    <i className="bi bi-arrow-left-circle-fill pe-2 pt-1"></i>{(`${goTo}`)}
                </div>
            </div>
        )
    })
)

export default Navigator;