import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector, MyDatepicker } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const EquipmentModal = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {
      _handleCreateEquipmentSetup,
      createEquipmentList,
      _handleChangeInput,
      _handleSelectEquip,
      _handleChangeDate,
      _handleGetEmissionByCalculatedMethod,
      getCalApproaches,
      clearData,
      isEdit,
      _handleUpdateEquipmentSetup
    } = props.store.survey;

    const {
      activityTypeOptions,
      vehicleTypeOptions,
      deviceTypeOptions,
      unitOptions,
      sourceDataList,
      _getEnergySourceList
    } = props.store.quickOrganizationSetUp;

    useEffect(() => {
      _getEnergySourceList();
    },[]);

    useEffect(() => {
      if (createEquipmentList.category === "Refrigerants and Fugitives"){
        getCalApproaches("Refrigerants", createEquipmentList.category, "scope1");
      }
    },[createEquipmentList.category])


    useEffect(()=> {
      if(
        createEquipmentList.category &&
        (createEquipmentList.fuelType || createEquipmentList.vehicleType || createEquipmentList.calculateMethod) &&        
        createEquipmentList.unit
      ){
        _handleGetEmissionByCalculatedMethod("scope1");
      }
    }, [createEquipmentList.category, createEquipmentList.calculateMethod, createEquipmentList.unit, createEquipmentList.fuelType, createEquipmentList.vehicleType])

    // console.log("create equipment list > ", toJS(createEquipmentList), toJS(deviceTypeOption));
    const { t } = useTranslation("equipment");

    return (
      <div
        className={`modal fade ${theme}`}
        id="addEquipmentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addEquipmentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme)
            }}
          >
            <div className="modal-header">
              <h4>{t("Add Data for Scope 1")}</h4>
              <i
                onClick={() => clearData("scope1")}
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
              <div className="row py-1"><div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                <div className="row d-flex">
                  <div className="col">
                    <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                      label={t("Category")}
                      menuListColor={Colors.Dark[0]}
                      options={categoryOption}
                      value={
                        categoryOption.filter(
                          (v) => v.label === createEquipmentList.category
                        ) || ""
                      }
                      placeholder={t("Select category")}
                      _handleSelect={(e) => _handleSelectEquip("category", e, "equipment")}
                    />
                  </div>
                </div>
              </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        label={t("Record Period")}
                        menuListColor={Colors.Dark[0]}
                        options={recordPeriodOption}
                        value={
                          recordPeriodOption.filter(
                            (v) => v.label === createEquipmentList.recordPeriod
                          ) || ""
                        }
                        placeholder={t("Select record period")}
                        _handleSelect={(e) =>
                          _handleSelectEquip("recordPeriod", e, "equipment")
                        }
                      />
                    </div>
                  </div>
                </div>

              </div>
              <hr className="mt-4 mb-1" />
              {
                createEquipmentList.category === 'Mobile Combustion' ?
                  <>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Activity Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(activityTypeOptions.filter(at => at.category === createEquipmentList.category))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={activityTypeOptions.filter(v => v.label === createEquipmentList.activityType)}
                              placeholder={t("Select activity type")}
                              _handleSelect={(e) =>
                                _handleSelectEquip("activityType", e, "equipment")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Unit")}
                              menuListColor={Colors.Dark[0]}
                              options={(unitOptions.filter(v => v.activityType === createEquipmentList.activityType))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={unitOptions.filter(v => v.label === createEquipmentList.unit)}
                              placeholder={t("Select Unit")}
                              _handleSelect={(e) => _handleSelectEquip("unit", e, "equipment")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Fuel Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(sourceDataList.filter(o => o.title === createEquipmentList.category))[0]?.list.filter(o => o.sourceKey === "fuelType")}
                              value={(sourceDataList.filter(o => o.title === createEquipmentList.category))[0]?.list.filter(v => v.label === createEquipmentList.fuelType)}
                              placeholder={t("Select Fuel type")}
                              _handleSelect={(e) =>
                                _handleSelectEquip("fuelType", e, "equipment")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Vehicle Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(vehicleTypeOptions.filter(vt => vt.fuelType === createEquipmentList.fuelType))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={vehicleTypeOptions.filter(v => v.label === createEquipmentList.vehicleType)}
                              placeholder={t("Select vehicle type")}
                              _handleSelect={(e) => _handleSelectEquip("vehicleType", e, "equipment")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Model")}
                              required={true}
                              id={"brand"}
                              type={"text"}
                              value={createEquipmentList.brand}
                              onChange={(e) => _handleChangeInput(e, "equipment")}
                              placeHolder={t("Model")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Name")}
                              required={true}
                              id={"equipmentName"}
                              type={"text"}
                              value={createEquipmentList.equipmentName}
                              onChange={(e) => _handleChangeInput(e, "equipment")}
                              placeHolder={t("Name")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyDatepicker
                              label={t("Commissioned Date")}
                              _handleChange={(e) => _handleChangeDate(e, "equipment")}
                              value={createEquipmentList.commissionedDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : createEquipmentList.category === 'Refrigerants and Fugitives' ?
                  <>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Name")}
                              required={true}
                              id={"equipmentName"}
                              type={"text"}
                              value={createEquipmentList.equipmentName}
                              onChange={(e) => _handleChangeInput(e, "equipment")}
                              placeHolder={t("Equipment Name")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Equipment Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(deviceTypeOptions.filter(dt => dt.category === createEquipmentList.category))?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                              value={
                                deviceTypeOptions.filter(
                                  (v) => v.label === createEquipmentList.deviceType && v.category === createEquipmentList.category
                                ) || ""
                              }
                              placeholder={t("Select equipment type")}
                              _handleSelect={(e) =>
                                _handleSelectEquip("deviceType", e, "equipment")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                          <div className="row d-flex">
                            <div className="col">
                              <Selector
                                getOptionLabel={(e) => (
                                  <div style={{ display: "flex", textAlign: "left" }}>
                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                  </div>
                                )}
                                label={t("Refrigerants Used")}
                                menuListColor={Colors.Dark[0]}
                                options={(sourceDataList.filter(o => o.title === createEquipmentList.category))[0]?.list.filter(o => o.sourceKey === "fuelType")}
                                value={(sourceDataList.filter(o => o.title === createEquipmentList.category))[0]?.list.filter(v => v.label === createEquipmentList.fuelType)}
                                placeholder={t("Select Refrigerants Used")}
                                _handleSelect={(e) => _handleSelectEquip("calculateMethod", e, "equipment")}
                              />
                            </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Brand")}
                              required={true}
                              id={"brand"}
                              type={"text"}
                              value={createEquipmentList.brand}
                              onChange={(e) => _handleChangeInput(e, "equipment")}
                              placeHolder={t("Brand")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1 align-items-end">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Unit")}
                              menuListColor={Colors.Dark[0]}
                              options={(unitOptions.filter(v => (v.activityType === createEquipmentList.category && v.label !== 'Kg')))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={unitOptions.filter(v => v.label === createEquipmentList.unit)}
                              placeholder={t("Select Unit")}
                              _handleSelect={(e) => _handleSelectEquip("unit", e, "equipment")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1 ">
                        <div className="row d-flex">
                          <div className="col">
                            <MyDatepicker
                              label={t("Commissioned Date")}
                              _handleChange={(e) => _handleChangeDate(e, "equipment")}
                              value={createEquipmentList.commissionedDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  :<>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Name")}
                              required={true}
                              id={"equipmentName"}
                              type={"text"}
                              value={createEquipmentList.equipmentName}
                              onChange={(e) => _handleChangeInput(e, "equipment")}
                              placeHolder={t("Equipment Name")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Equipment Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(deviceTypeOptions.filter(dt => dt.category === createEquipmentList.category))?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                              value={
                                deviceTypeOptions.filter(
                                  (v) => v.label === createEquipmentList.deviceType && v.category === createEquipmentList.category
                                ) || ""
                              }
                              placeholder={t("Select equipment type")}
                              _handleSelect={(e) =>
                                _handleSelectEquip("deviceType", e, "equipment")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Fuel Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(sourceDataList.filter(o => o.title === createEquipmentList.category))[0]?.list.filter(o => o.sourceKey === "fuelType")}
                              value={(sourceDataList.filter(o => o.title === createEquipmentList.category))[0]?.list.filter(v => v.label === createEquipmentList.fuelType) || ""}
                              placeholder={t("Select Fuel type")}
                              _handleSelect={(e) => _handleSelectEquip("fuelType", e, "equipment")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Brand")}
                              required={true}
                              id={"brand"}
                              type={"text"}
                              value={createEquipmentList.brand}
                              onChange={(e) => _handleChangeInput(e, "equipment")}
                              placeHolder={t("Brand")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1 align-items-end">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Unit")}
                              menuListColor={Colors.Dark[0]}
                              options={(unitOptions.filter(v => v.activityType === createEquipmentList.category))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={unitOptions.filter(v => v.label === createEquipmentList.unit)}
                              placeholder={t("Select Unit")}
                              _handleSelect={(e) => _handleSelectEquip("unit", e, "equipment")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1 ">
                        <div className="row d-flex">
                          <div className="col">
                            <MyDatepicker
                              label={t("Commissioned Date")}
                              _handleChange={(e) => _handleChangeDate(e, "equipment")}
                              value={createEquipmentList.commissionedDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              }

              {
                createEquipmentList.category &&
                (createEquipmentList.fuelType || createEquipmentList.calculateMethod) &&
                (createEquipmentList.vehicleType || createEquipmentList.deviceType) && 
                createEquipmentList.unit 
                ?
                <>
                  <hr className="mt-4 mb-1" />
                  <div className="row py-1">
                    <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                      <div className="row d-flex">
                        <div className="col">
                            <MyInput
                              disabled={true}
                              label={"Emission Factor Value"}
                              required={true}
                              type={"number"}
                              id={"emissionFactorValue"}
                              value={createEquipmentList.emissionFactorValue}
                              placeHolder={"Emission Factor Value"}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                : null
              }

            </div>
            <div className="modal-footer mx-3 d-flex justify-content-end">
              <span data-bs-dismiss="modal" aria-label="Close">
                <MyButton
                  style={{ width: 200 }}
                  text={t("Cancel")}
                  customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                  customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                  onClick={() => clearData("scope1")}
                />
              </span>
              <span id={'create_setUp'}>
                <MyButton
                  onClick={() => isEdit ? _handleUpdateEquipmentSetup("equipment") : _handleCreateEquipmentSetup("equipment")}
                  style={{ width: 200 }}
                  text={isEdit ? t("Update") : t("Add")}
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const recordPeriodOption = [
  { value: 1, label: "Yearly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Quarterly" },
];

const categoryOption = [
  { value: 1, label: "Stationary Combustion" },
  { value: 2, label: "Mobile Combustion" },
  { value: 3, label: "Refrigerants and Fugitives" }];