import { debounce } from "@mui/material";
import { makeObservable, observable, action, runInAction } from "mobx";
import { toast } from "react-toastify";
import {findPageBykeyword} from "../API/api.pageSearch"



class PageSearch {

    // value={searchSelectText}
    //         onChange={_handleSelectPageSearch}
    //         inputValue={searchTypeText}
    searchTypeText = "";
    searchSelectText = null;
    searchOptions = [];

    constructor(){
        makeObservable(this,{
            searchTypeText: observable,
            searchSelectText: observable,
            searchOptions: observable,
            _handleTextChangePageSearch: action.bound,
            _handleSelectPageSearch: action.bound,
            _fetchSearchData: action.bound,
            _changeSearchOptions: action.bound
        });
    }

    _handleTextChangePageSearch(e, newValue){
       // console.log("TextChange", newValue);
       this.searchTypeText = newValue;
      // debounce((re))
       
    }
    _handleSelectPageSearch(e, newValue){
        console.log("HandleSelect", newValue);

        this.searchOptions = newValue ? [newValue, ...this.searchOptions] : this.searchOptions;
        this.searchSelectText = newValue;
        this.searchTypeText = newValue? newValue.pageName: "";

        
        return newValue;

    }

    _fetchSearchData(searchTypeText, callback){
        findPageBykeyword(searchTypeText, (err, data) => {
            if (data) {
                if (data.error) {
                    toast.error(data.error);
                }else{
                    let result = data.payload;
                    callback(result);
                }
            }
    
        });
    }

    _changeSearchOptions(value){
        console.log("Value", value);
        runInAction(()=>{
            this.searchOptions = [...value];
        })
    }



}

export default new PageSearch();