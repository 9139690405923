import { Fragment } from "react";
import { Colors, invertDark } from "../Assets/Config/color.config";
import "../App.css";

export const MyRadioButton = (props) => {
  const {
    style,
    disabled,
    required,
    id,
    onChange,
    value,
    checked,
    customColor,
    name,
    text
  } = props;
  const theme = localStorage.getItem("theme");

  return (
    <div className="form-check">
      <input
        className="form-check-input" 
        style={{ cursor: "pointer" }}
        id={id}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label className="form-check-label" htmlFor={id} style={{cursor:'pointer'}}>
        {text}
      </label>
    </div>
  );
};
