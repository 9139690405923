import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import Capella from '../../Assets/images/capella.png'
import { OverviewTable } from "../../Components/AuditCompanyOverview/OverviewTable";
import '../../App.css'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';
import { baseURLImage } from "../../API/URL";

export const AuditCompanyOverviewContainer = inject("store")(
    observer((props) => {
        const { theme,addDefaultSrc } = props.store.common;
        const [searchData, setSearchData] = useState([]);
        const [searchKey, setsearchKey] = useState(null);
        const {getAuditOverviewList,auditOverviewList,companyList,getCompanyList} = props.store.audit;
        const params = new URLSearchParams(window.location.search);
        const companyName = params.get("companyName");
        const { t } = useTranslation("audit");
        useEffect(() => {
            getAuditOverviewList(companyName);
            getCompanyList();
        }, []);

        const handleSearch = (e) => {
            
            const key = (e.target.value)?.toLowerCase();
            setsearchKey(key)
            const data = toJS(auditOverviewList).filter(v => (v.site)?.toLowerCase()?.includes(key));
           
            setSearchData(data)
        }
        const navigate = useNavigate();
        const companyImg = companyList?.find(v=> v.companyName === companyName)?.imageURL
        return (
            <div className="m-4 mt-3">
                <div className="d-flex justify-content-end pb-3">
                <span
                    style={{
                        fontSize: 20,
                        color: invertDark(theme),
                        alignItems: "center",
                        display: "flex",
                        cursor: "pointer",
                    }}
                    onClick={() => navigate("/auditor-overview")}
                >
                    <i className="bi bi-arrow-left-circle-fill pe-3"></i>
                    {t('Back')}
                </span>
                </div>
                <div className="d-flex justify-content-between mb-4">
                    <div className="d-flex align-items-center">
                        <img
                            className="me-3"
                            src={baseURLImage+companyImg}
                            onError={addDefaultSrc}
                            width={"50px"}
                            height={"50px"}
                            style={{ borderRadius: '50%' }}
                        />
                        <div style={{ color: Colors[theme].textColor }}>
                            <div className="h5 mb-1">
                                {t('Framework Verification')}
                                <i className="bi bi-exclamation-circle ms-3" style={{ fontSize: 13 }}></i>
                            </div>
                            <div className="text-secondary" style={{ fontSize: 15 }}>
                                {t('Please Select A Site/Building To Start The Verification/Audit')}
                            </div>
                        </div>
                    </div>
                    <div className="" style={{ whiteSpace: 'nowrap', height: '38px', borderRadius: 5, border: `1px solid rgb(209 209 209 / ${theme === 'dark' ? '50%' : '100%'})` }}>
                        <input
                            className="form-control searchInput"
                            type={'text'}
                            placeholder={t('Site Name')}
                            style={{
                                background: 'transparent',
                                border: 'none',
                                color: invertDark(theme),
                                display: 'inline-block',
                                width: '88%',
                            }}
                            onChange={(e) => handleSearch(e)}
                        />
                        <i
                            className="bi bi-search text-secondary"
                            style={{
                                display: 'inline-block'
                            }}
                        />
                    </div>
                </div>
                <OverviewTable data={searchKey? searchData:toJS(auditOverviewList)} />
            </div>
        )
    })
)

const dummyData = [
    {
        site: 'CONRAD',
        type: 'Hotel',
        framework: 4,
        verified: 14,
        notVerified: 108,
        summary: [
            { framework: 'GHG', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
            { framework: 'GSTC', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
            { framework: 'TCFD', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
            { framework: 'WWF', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
        ]
    },
    {
        site: 'CAPELLA',
        type: 'Hotel',
        framework: 4,
        verified: 2,
        notVerified: 2,
        summary: [
            { framework: 'GHG', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
            { framework: 'GSTC', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
            { framework: 'TCFD', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
            { framework: 'WWF', verified: 3, notVerified: 82, rejected: 10, notApplicable: 2 },
        ]
    },
]