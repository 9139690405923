import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

export const ForceDeleteConfirmDialog = inject("store")(observer((props) => {

    const { showDialog } = props;
    const { _handleCancelForceDelete, _handleForceDeleteUploadedFile, forceDeleteConfirmMsg, forceDeleteFile } = props.store.groupmanagement;

    const { t } = useTranslation("common");

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={_handleCancelForceDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="py-2 text-center">
                        Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {forceDeleteFile?.fileName}{" exists"}
                        {forceDeleteConfirmMsg}{"."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='justify-content-center my-2'>
                    <Button onClick={_handleCancelForceDelete} color="primary" size="small" className="shadow">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={_handleForceDeleteUploadedFile} color="primary" size="small" className="shadow">
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}))