import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { MyButton, MySortableTable, MySwitch, Selector } from "../../Tools";
import { EquipmentModal } from "./EquipmentModal";
import { EmptyData } from "../../Containers/SiteRelevant/SiteRelevantContainer";
import moment from "moment";
import { PurchasedElectricityModal } from "./purchaseElectricityModal";
import { TransportationModal } from "./transportationModal";
import { CustomEmissionFactorModal } from "./CustomEmissionFactorModal";
import * as XLSX from 'xlsx'
import { UploadFileModal } from "./UploadFileModal";
import { ConfirmDelteModal } from "./ConfirmDeleteModal";
import { Scope0Questionary } from "./Scope0Questionary.js";
import { SyscomEquipmentModal } from "./SyscomEquipmentModal";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";


export const EquipmentSetup = inject("store")(
  observer((props) => {
    const {
      getEquipmentList,
      equipmentList,
      isShowModel,
      _handleShowModel,
      setUp,
      _handleSelectSetup,
      _handleChangeActive,
      _handleDeleteSetupRow,
      setFileUploadData,
      excelRawData, setupDeleteId,
      _handleDeleteSetUpId,
      setScope0SetupVal,
      getScope0AnsData,
      setEdit
    } = props.store.survey;

    const { t } = useTranslation("common");

    const { theme, companyName } = props.store.common;
    
    const width = window.innerWidth;
    const setUpType = setupOptions.filter((v) => v.value === toJS(setUp))[0].label;
    
    useEffect(() => {
      if (setUp === 0) {
        getScope0AnsData()
      } else {
        getEquipmentList();
      }

    }, [setUp]);

    useEffect(()=> {
      if(companyName === 'Syscom'){
        setScope0SetupVal()
      }
    }, [])

    const readUploadFile = (e) => {
      e.preventDefault();
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);
          
          setFileUploadData(json)
        };
        reader.readAsArrayBuffer(e.target.files[0]);

      }
    }

    // const list = setUp === 3 ? equipmentList?.filter(d => d.typeCategory === 'transportation') : equipmentList;
    const list = equipmentList;
    const dataList = list?.map((v) => {
      if (setUp === 1) {
        return {
          equipmentName: v.equipmentName,
          category: v.category,
          fuelType: v.fuelType,
          unit: v.unit,
          brand: v.brand,
          commissionedDate: moment(v.commissionedDate).format("DD-MMM-YYYY"),
          recordPeriod: v.recordPeriod,
          active: <div className="d-flex">
            <MySwitch title={v.active ? "Change inactive" : "Change active"} active={v.active} onChange={() => _handleChangeActive(v._id, setUpType, !v.active)} />
            <span data-bs-toggle="modal" data-bs-target="#addEquipmentModal" title="Edit">
              <i onClick={() => setEdit(v,1)} style={{ cursor: 'pointer' }} className="bi bi-pencil-square me-2"></i>
            </span>
            <span data-bs-toggle="modal" data-bs-target="#setUpConfirmModal" title="Delete">
              <i onClick={() => _handleDeleteSetUpId(v._id, setUp)} style={{ cursor: 'pointer' }} className="bi bi-trash3 text-danger"></i>
            </span>
          </div>
        };
      } else if (setUp === 2) {
        return {
          year: v.year,
          category: t(v.category),
          supplier: v.supplier,
          recordPeriod: t(v.recordPeriod),
          calculateType: t(v.calculateType),
          calculatedMethod: v.calculateMethod,
          emissionFactor: t(v.emissionFactor?.replace("/", "/ ")),
          unit: t(v.unit),
          active: <div className="d-flex">
            <MySwitch title={v.active ? "Change inactive" : "Change active"} active={v.active} onChange={() => _handleChangeActive(v._id, setUpType, !v.active)} />
            <span data-bs-toggle="modal" data-bs-target="#addEquipmentModal" title="Edit">
              <i onClick={() => setEdit(v,2)} style={{ cursor: 'pointer' }} className="bi bi-pencil-square me-2"></i>
            </span>
            <span data-bs-toggle="modal" data-bs-target="#setUpConfirmModal" title="Delete">
              <i onClick={() => _handleDeleteSetUpId(v._id, setUp)} style={{ cursor: 'pointer' }} className="bi bi-trash3 text-danger"></i>
            </span>
          </div>,
        };
      } else if (setUp === 3) {
        return {
          year: v.year,
          category: v.category,
          transportMode: v.transportMode,
          vehicleType: v.vehicleType,
          activityType: v.activityType,
          unit: v.unit,
          active: <div className="d-flex">
            <MySwitch title={v.active ? "Change inactive" : "Change active"} active={v.active} onChange={() => _handleChangeActive(v._id, setUpType, !v.active)} />
            <span data-bs-toggle="modal" data-bs-target="#addEquipmentModal" title="Edit">
              <i onClick={() => setEdit(v,3)} style={{ cursor: 'pointer' }} className="bi bi-pencil-square me-2"></i>
            </span>
            <span data-bs-toggle="modal" data-bs-target="#setUpConfirmModal" title="Delete">
              <i onClick={() => _handleDeleteSetUpId(v._id, setUp)} style={{ cursor: 'pointer' }} className="bi bi-trash3 text-danger"></i>
            </span>
          </div>
        };
      }

    });

    const purchasedElectricityColumn = [
      { id: "year", numberic: false, label: t("Year"), sortable: true },
      { id: "category", numberic: false, label: t("Category"), sortable: true },
      { id: "equipmentName", numberic: false, label: t("Supplier"), sortable: true },
      { id: "recordPeriod", numberic: false, label: t("Record Period"), sortable: true },
      { id: "calculateType", numberic: false, label: t("Calculate Type"), sortable: true },
      { id: "calculatedMethod", numberic: false, label: t("Calculated Method"), sortable: true },
      { id: "emissionFactor", numberic: false, label: t("Emission Factor"), sortable: true },
      { id: "unit", numberic: false, label: t("Unit"), sortable: true },
      { id: "active", numberic: false, label: t("Active"), sortable: true },
    ]

    const equipmentColumn = [
      { id: "equipmentName", numberic: false, label: t("Name"), sortable: true },
      { id: "category", numberic: false, label: t("Category"), sortable: true },
      // { id: "type", numberic: false, label: "Type", sortable: true },
      { id: "fuelType", numberic: false, label: t("Fuel Type"), sortable: true },
      { id: "unit", numberic: false, label: t("Unit of Fuel"), sortable: true },
      { id: "brand", numberic: false, label: t("Brand"), sortable: true },
      {
        id: "commissionedDate",
        numberic: false,
        label: t("Commissioned Date"),
        sortable: true,
      },
      {
        id: "recordPeriod",
        numberic: false,
        label: t("Record Period"),
        sortable: true,
      },
      { id: "active", numberic: false, label: t("Active"), sortable: true },
    ];

    const transportationColumn = [
      { id: "year", numberic: false, label: t("Year"), sortable: true },
      { id: "category", numberic: false, label: t("Category"), sortable: true },
      { id: "transportMode", numberic: false, label: t("Mode of transport"), sortable: true },
      { id: "vehicleType", numberic: false, label: t("Vehicle Type"), sortable: true },
      { id: "activityType", numberic: false, label: t("Activity Type"), sortable: true },
      { id: "unit", numberic: false, label: t("Unit"), sortable: true },
      { id: "active", numberic: false, label: t("Active"), sortable: true },
    ]

    return (
      <div
        className={
          width > 700
            ? "col-9 col-sm-8 col-lg-8 col-md-8 col-xl-9 col-xs-9 pe-2"
            : " col-9 pe-2"
        }
        style={{
          // background: theme === "dark" ? Colors.darkBlue : Colors.blue,
          background:
            theme === "dark" ? Colors[theme].secondBackground : Colors.Dark[5],
          minHeight: "600px",
          borderRadius: 10,
          boxShadow: Colors.shadow,
        }}
      >
        <div className="d-flex flex-row m-2 justify-content-between">
          {/* {setUpPages.map(v=><div
            className="fw-bold p-2"
            style={{
              color: theme === "dark" ? Colors.dark[4] : Colors.white,
              borderBottom:`2px solid ${Colors.water}`,
              fontSize:"20px",
              cursor:"pointer"
            }}
          >
            {v.name}
          </div>)} */}
          <div className="mt-2" style={{ minWidth: 250 }}>
            <Selector
              // id={"editDependencies"}
              _handleSelect={(e) => _handleSelectSetup(e)}
              options={companyName === 'Syscom' ? setupOptions: (setupOptions.slice(1))}
              menuListColor={Colors.Dark[0]}
              value={setupOptions.filter((v) => v.value === setUp)}
            />{" "}
          </div>
          <div className="d-flex">
            {
              setUp === 2 ?
                <span
                  className="pt-1 mx-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addCustomEmission"
                >
                  <MyButton
                    id={"addEquipment"}
                    style={{ width: "100%" }}
                    text={t("Add Emission Factor")}
                    small
                    className={"px-2"}
                    customColor="#066CFF"
                    customHoverColor={Colors.water}
                  />
                </span>
                : null
            }
            {setUp === 3 && <label
              className="p-1 mt-1 mx-1  d-flex align-items-center justify-content-center rounded"
              htmlFor={'id'}
              style={{ background: "#066CFF", cursor: "pointer", color: Colors.Dark[5], height: 34 }}
            >
              <input
                style={{
                  position: "absolute",
                  width: "130px",
                  display: "none",

                  top: 10,
                  cursor: 'pointer'
                }}
                type="file"
                id={'id'}
                className='file'
                onChange={readUploadFile}
                accept={".xlsx"}
              />
              <span>
                <i style={{ fontSize: 20 }} className="bi bi-filetype-xlsx pe-2"></i>
                {t("Upload file")}
              </span>
            </label>}

            {
              setUp === 1 || setUp === 2 || setUp === 3 ?
                <span
                  className="mt-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addEquipmentModal"
                >
                  <MyButton
                    onClick={_handleShowModel}
                    id={"addEquipment"}
                    style={{ width: "100%" }}
                    text={"+"}
                    small
                    className={"px-2"}
                    customColor="#066CFF"
                    customHoverColor={Colors.water}
                  />
                </span>
                : null
            }

          </div>
        </div>
        <div className="ps-2">
          {
            setUp === 0 ?
            <Scope0Questionary />
            :
            (dataList && dataList.length > 0 ? (
              <MySortableTable
                columns={setUp === 1 ? equipmentColumn : setUp === 2 ? purchasedElectricityColumn : setUp === 3 ? transportationColumn : null}
                dataList={dataList || []}
              />
            ) : (
              <EmptyData text={t('No Data Found.')} />
            ))
          }
        </div>
        {isShowModel && setUp === 1 ? (
          // companyName==='Syscom'?<SyscomEquipmentModal/>:
          <EquipmentModal />
        ) : setUp === 2 ? (
          <PurchasedElectricityModal />
        ) : setUp === 3 ? (
          <TransportationModal />
        ) : null}


        <CustomEmissionFactorModal />
        {excelRawData.length > 0 && <UploadFileModal readUploadFile={readUploadFile} />}
        <ConfirmDelteModal deleteObj={setupDeleteId} handleDelete={_handleDeleteSetupRow} theme={theme} />
      </div>
    );
  })

  
);




const setUpColumn1 = [
  { id: "SupplierName", numberic: false, label: t("Supplier"), sortable: true },
  // { id: "type", numberic: false, label: "Type", sortable: true },
  { id: "unit", numberic: false, label: t("Unit"), sortable: true },
  {
    id: "calculationApproach",
    numberic: false,
    label: t("Calculation Approach"),
    sortable: true,
  },
  {
    id: "emissionFactor",
    numberic: false,
    label: t("Emission Factor"),
    sortable: true,
  },
  {
    id: "commissionedDate",
    numberic: false,
    label: t("Commissioned Date"),
    sortable: true,
  },
  {
    id: "recordPeriod",
    numberic: false,
    label: t("Record Period"),
    sortable: true,
  },
  { id: "active", numberic: false, label: "Active", sortable: true },
];

const setupOptions = [
  {
    value: 0,
    label: "Scope 0",
  },
  {
    value: 1,
    label: "Scope 1",
  },
  {
    value: 2,
    label: "Scope 2",
  },
  { value: 3, label: "Scope 3" },
];
