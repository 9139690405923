import Store from "../../Store";
import { ESGBreadCrumb } from "./ESGBreadCrumb";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
import { MyButton, MyInput, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import ESGeditModal from "./ESGeditModal";
import { useTranslation } from "react-i18next";
import { createNewPillar } from "../../API/api.esg";
import { ConfirmResetModal } from "./ConfirmResetModal";
import { useEffect } from "react";
import { toJS } from "mobx";

export const ESGPillarSetup = inject("store")(
  observer((props) => {
    const { theme } = Store.common;
    const {
      _handleConfirmReset,
      _fetchAllPillarSetup,
      pillars,
      _fetchAllPillars,
      pillar,
      subPillar,
      _fetchAllSubPillars,
    } = props.store.esg;
    const cardStyle = {
      background: Colors[theme].secondBackground,
      boxShadow: "0 4px 13px rgb(21,21,21,25%)",
      borderRadius: 10,
    };
    const { t } = useTranslation("esg_tracking");

    useEffect(() => {
      _fetchAllPillarSetup();
      _fetchAllSubPillars();
      _fetchAllPillars();
    }, []);
    return (
      <div className="mx-4 my-2">
        <div className="d-flex justify-content-between mb-2">
          <ESGBreadCrumb page={"esgPillarSetup"} />
          <img
            className="pe-2"
            width={160}
            src={digitalEdgeIcon}
            alt={t("Digital Edge Icon")}
          />
        </div>

        <div className="d-flex justify-content-between">
          <div className="col-4 p-3" style={{ ...cardStyle, maxHeight: 430 }}>
            <CreateESGPillar theme={theme} />
          </div>

          <div className="col ms-5 p-3" style={cardStyle}>
            <ESGPillarList theme={theme} pillars={pillars} />
          </div>
        </div>
        <ESGeditModal />
        <ConfirmResetModal
          _handleConfirmReset={_handleConfirmReset}
          theme={theme}
        />
      </div>
    );
  })
);

const CreateESGPillar = inject("store")(
  observer((props) => {
    const { theme } = props;
    const {
      subPillarOwnerTitle,
      _handleOwnerTitleChange,
      subPillarOwnerName,
      _handleOwnerNameChange,
      _handlePillarChange,
      _handleSubPillarChange,
      pillar,
      subPillar,
      pillarOption,
      subPillarOption,
      _createPillar,
      _fetchAllPillar,
    } = props.store.esg;
    const { t } = useTranslation("esg_tracking");

    return (
      <span className="" style={{ fontSize: 14, color: invertDark(theme) }}>
        <form className="px-2">
          <h5
            className="pb-5"
            style={{
              color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0],
            }}
          >
            {t("Create New ESG Pillar or Sub Pillar")}
          </h5>

          <div
            style={{
              color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0],
              fontWeight: "bold",
            }}
          >
            <div className="row mb-3">
              <span className="col-4 d-flex align-items-center">
                {t("ESG Pillar")} :
              </span>
              <span
                className="col-8"
                style={{ color: Colors.Dark[0], fontWeight: "normal" }}
              >
                <Selector
                  id="pillar"
                  _handleSelect={(e) => _handlePillarChange(e)}
                  options={pillarOption}
                  value={pillarOption.filter(
                    (option) => option.label === pillar
                  )}
                />
              </span>
            </div>
            <div className="row mb-3">
              <span className="col-4 d-flex align-items-center">
                {t("ESG Sub-Pillar")} :
              </span>
              <span
                className="col-8"
                style={{ color: Colors.Dark[0], fontWeight: "normal" }}
              >
                <Selector
                  id="subPillar"
                  _handleSelect={(e) => _handleSubPillarChange(e)}
                  options={subPillarOption}
                  value={subPillarOption.filter(
                    (option) => option.value === subPillar
                  )}
                />
              </span>
            </div>
            <div className="row mb-3">
              <span className="col-4 d-flex align-items-center">
                {t("Sub-Pillar Owner Title")} :
              </span>
              <span className="col-8">
                <MyInput
                  id="subPillarOwnerTitle"
                  onChange={(e) => _handleOwnerTitleChange(e)}
                  value={subPillarOwnerTitle}
                />
              </span>
            </div>
            <div
              className="row pb-4"
              style={{ borderBottom: "1px solid rgb(255,255,255,10%)" }}
            >
              <span className="col-4 d-flex align-items-center">
                {t("Sub-Pillar Owner Name")} :
              </span>
              <span className="col-8">
                <MyInput
                  id="subPillarOwnerName"
                  onChange={(e) => _handleOwnerNameChange(e)}
                  value={subPillarOwnerName}
                />
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <span
              className="col-6"
              data-bs-toggle="modal"
              data-bs-target="#confirmResetModal"
            >
              <MyButton
                style={{ width: "90%" }}
                text={t("Reset")}
                customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
              />
            </span>
            <MyButton
              style={{ width: "90%", textAlign: "end" }}
              text={t("Add Pillar")}
              customColor="#066CFF"
              onClick={() => _createPillar()}
              customHoverColor={Colors.water}
            />
          </div>
        </form>
      </span>
    );
  })
);

const ESGPillarList = (props) => {
  const { t } = useTranslation("esg_tracking");
  const { theme, pillars } = props;
  const { _handleEditPillarSetup } = Store.esg;
  return (
    <span style={{ fontSize: 14, color: invertDark(theme) }}>
      <div className="px-2">
        <div className="d-flex justify-content-between">
          <h5
            className="pb-5"
            style={{
              color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0],
            }}
          >
            {t("ESG Pillar List")}
          </h5>
          <MyButton
            customColor="#066CFF"
            style={{ height: '40px', width: '30%' }}
            customHoverColor={Colors.water}
            text={t("KPI Management")}
            onClick={() =>
              (window.location.pathname = "/esg-pillar-setup/kpi-management")
            }
          />
        </div>
        <div className="px-3">
          <div
            className="row fw-bold py-2"
            style={{
              color: theme === "dark" ? Colors.Dark[5] : Colors.Dark[0],
            }}
          >
            <span className="col-3">{t("ESG Pillar")}</span>
            <span className="col-3">{t("ESG Sub-Pillar")}</span>
            <span className="col-3">{t("Sub-Pillar Owner Title")}</span>
            <span className="col-2">{t("Sub-Pillar Owner Name")}</span>
          </div>
          {pillars.map((v, k) => (
            <div
              key={k}
              className="row py-2"
              style={{
                borderTop:
                  theme === "dark"
                    ? "1px solid rgb(255,255,255,10%)"
                    : "1px solid rgb(0,0,0,10%)",
              }}
            >
              {
                <>
                  <span className="col-3">{v.pillar.pillarName}</span>
                  <span className="col-3">{v.subPillar.subPillarName}</span>
                  <span className="col-3">{v.ownerTitle}</span>
                  <span className="col-2">{v.ownerName}</span>
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#esgEditModal"
                    className="col-1 text-end"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => _handleEditPillarSetup(pillars[k])}
                  >                    
                    <i className="bi bi-pencil-square" />
                  </span>
                </>
              }
            </div>
          ))}
        </div>
      </div>
    </span>
  );
};

const ESGListData = [
  {
    pillar: "Respect for Resources",
    subPillar: "Energy Management",
    ownerTitle: "VP People",
    ownerName: "Charmaine Cheng",
  },
  {
    pillar: "Respect for People & Communities",
    subPillar: "Health & Safety",
    ownerTitle: "Chief Development",
    ownerName: "Jay Park",
  },
  {
    pillar: "Respect for Transparency",
    subPillar: "GHG Emissions",
    ownerTitle: "Chief Legal & Compliance",
    ownerName: "John Freeman",
  },
  {
    pillar: "Respect for Transparency",
    subPillar: "Responsible Supply Chain",
    ownerTitle: "VP Services",
    ownerName: "John Yung",
  },
  {
    pillar: "Respect for Transparency",
    subPillar: "Customer Privacy & Data Security",
    ownerTitle: "Chief Product",
    ownerName: "Jonathan Chou",
  },
  {
    pillar: "Respect for Transparency",
    subPillar: "Diversity, Equity & Inclusion",
    ownerTitle: "Chief Finance",
    ownerName: "Jonathan Walbridge",
  },
  {
    pillar: "Respect for Transparency",
    subPillar: "Human Capital Management",
    ownerTitle: "Snr Dir, Site Selection",
    ownerName: "Michael Kim",
  },
  {
    pillar: "Respect for Transparency",
    subPillar: "Business Ethics",
    ownerTitle: "N/A",
    ownerName: "N/A",
  },
];
