import CircularSlider from '@fseehawer/react-circular-slider';
import { Colors } from '../Assets/Config/color.config';
import { ReactComponent as DragIcon } from '../Assets/images/planet-earth.svg';

export const MyCircularSlider = (props) => {
    const { knobSize, color, label, value, k, theme } = props
 
    let textColor = "plasticColor";
    if (color === Colors.energy) {
        textColor = "energyColor";
    } else if (color === Colors.water) {
        textColor = "waterColor";
    } else if (color === Colors.greenMaterial) {
        textColor = "greenColor";
    } else {
        textColor = "plasticColor";
    }
    return (
        <div className={"waterColor"}>
            <CircularSlider            
                width='200'
                label={label ? label : null}
                min={0}
                max={100}
                dataIndex={value}
                appendToValue={<div style={{ fontSize: 20, position: 'relative', top: 18 }}>%</div>}
                labelColor={Colors.Dark[5]}
                labelBottom={true}
                knobColor={"#2982ff"}
                hideKnob={k === 0 ?true : false}
                progressColorFrom={"#2982ff"}
                progressColorTo={"#2982ff"}
                knobSize={knobSize}
                progressSize={15}                
                trackColor= { theme === "dark" ? 'rgba(255, 255, 255, 0.1)' : Colors.Dark[4]}
                trackSize={15}
                progressLineCap={"flat"}
                knobDraggable={false}    
            >
                {/* <DragIcon x='13' y='12' width='18px' height='18px' /> */}
            </CircularSlider>
        </div>
    )

}