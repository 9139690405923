import { Colors, invertDark } from "../../Assets/Config/color.config"
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

const ConfirmBackModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleSetPageIndex } = props.store.quickOrganizationSetUp;
        const { t } = useTranslation("quickOrganizationSetUp");
        // 
        return (
            <div
                className={`modal fade ${theme}`}
                id="confirmBackModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].secondBackground,
                            borderRadius: 10,
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h4 className="modal-title" style={{ color: theme === 'dark' ? '#fff' : '#000' }} id="exampleModalLabel">
                                { t("Confirm Back") }
                            </h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label={ t("Close") }
                            // onClick={() => _handleClose()}
                            ></i>
                        </div>
                        <div className="modal-body" style={{ color: invertDark(theme) }}>
                            { t("Your data will be clear when you back to previous page.") }<br />
                            { t("Do you want to back?")}
                        </div>
                        <div className="modal-footer d-flex justify-content-center mx-3">
                            <button
                                type="button"
                                // onClick={() => _handleCreateNewSite()}
                                className="btn col-2 text-white"
                                style={{
                                    background: theme === "dark" ? Colors.darkBlue : '#919191',
                                    width: 85
                                }}
                                data-bs-dismiss="modal"
                            >
                                { t("Cancel") }
                            </button>
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                className="btn col-2 text-white"
                                style={{
                                    background: 'rgb(255 0 0 / 50%)'
                                }}
                                onClick={() => _handleSetPageIndex("Back")}
                            >
                                { t("Yes") }
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }))
export default ConfirmBackModal