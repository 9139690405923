import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {ThousandSeparator} from "../../Assets/Config/thousandSeparator.config";
import { toJS } from "mobx";

import { useEffect,useState } from "react";
import { t } from "i18next";

export const YearComparisonTable1 = inject("store")(
  observer((props) => {
    const { tabIndex, setTabIndex } = props.store.esg;
    const {companyName} = props.store.common;
    const {t} = useTranslation("target_setting");

     const handleChange = (event, newValue) => {
      
      setTabIndex(newValue);
    };

    const { data } = props;

    const {theme} = props.store.common
    
    const tabsList = (companyName === "Digital Edge") ? ["Energy Consumption","Water Consumption","Scope 1 Emissions","Scope 2 Emissions","Scope 3 Emissions",
    "Carbon offsets","PUE for Acquired, Legacy Sites","PUE for New Developments",
    "WUE for Acquired, Legacy Sites","WUE for New Developments", "Water withdrawn"] : ["Energy Consumption","Water Consumption","Scope 1 Emissions","Scope 2 Emissions","Scope 3 Emissions"];

    const useStyles = makeStyles({
      tabs: {
        "& .Mui-selected": {
          // display: "none",
          fontWeight:'bold',
          backgroundColor: theme==='dark'?"#0d1432":Colors.Dark[5],
          borderTopLeftRadius:10,
          borderTopRightRadius:10
        },
        "& .MuiTabs-indicator": {
          display: "none"
          //backgroundColor: "orange"
        }
      }
    })
    const classes = useStyles();

    return (
      <Box sx={{ background: theme === 'dark' ? '#0d1432' : Colors.Dark[5], boxShadow: '0 0 4px rgb(0,0,0,0.3)', borderTopRightRadius: 10, borderTopLeftRadius: 10  }}>
        <Tabs
          className={classes.tabs}
          textColor={theme === 'dark' ? 'inherit' : 'primary'}
          sx={{ bgcolor: Colors[theme].secondBackground, color: theme === 'dark' ? '#fff' : '#000', borderTopRightRadius: 10, borderTopLeftRadius: 10 }}
          value={tabIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabsList.map((title, k) => (
            <Tab style={{borderBottomLeftRadius:k===tabIndex+1&&10}} key={k} label={t(title)} ></Tab>
          ))}

        </Tabs>
        {data.length ?
          <div className="p-3" style={{ height: 300, overflowY: 'scroll' }}>
            <Table theme={theme} data={data} tabIndex={tabIndex} t={t} />
          </div> : ''}


      </Box>
    );
  }))

const Table = (props) => {
  const { theme, data, tabIndex,t } = props;
  const tDashboard = useTranslation("dashboard");
  const tSurvey = useTranslation("survey");
  
  let dataKeys = data.length && data[tabIndex].value[0] ? Object.keys(data[tabIndex].value[0]):[]
  
  if(tabIndex === 0){
    const x = dataKeys[1]
    dataKeys[1] = dataKeys[2]
    dataKeys[2] = x
  }
  return (
    data.length ?<table style={{ color: invertDark(theme) }} className="table px-3 mb-0">
      <thead>
        <tr>
          <th>{t("Description")}</th>
          {data[tabIndex].value.map((v, k) => (
            <th key={k}>{v.year}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataKeys?.length? dataKeys.map((v, k) => (
          v !== 'year' &&
          <tr style={{ borderBottom: theme === 'dark' ? '1px solid rgb(255,255,255,10%)' : '1px solid rgb(0,0,0,10%)' }} key={k}>
            <td>{tabIndex === 0 && k > 0? `${tDashboard.t('Percentage')} ${v}`: (
              k === 0 ?
              (`${tDashboard.t(v.split(" ")[0]+" "+v.split(" ")[1]+" "+v.split(" ")[2])} ${v.split(" ")[3]}`) :
              (tSurvey.t(v))
            )}</td>
            {data[tabIndex].value.map((v1, k1) => {
            return(
              <td key={k1}>
                {tabIndex === 0? 
                  v==='Total energy consumed [Gj]'? 
                    (typeof(v1["Total energy consumed [Gj]"]) === "number" ? ThousandSeparator((v1["Total energy consumed [Gj]"]*0.0036).toFixed(2)) : '-') 
                    : (v1[v+'per']? Number.isInteger(v1[v+'per']) ? v1[v+'per']+'%' :  (v1[v+'per'])?.toFixed(2) +'%':'-') 
                : (typeof(v1[v]) === "number" ? ThousandSeparator(v1[v].toFixed(2)) : "-")}
              </td>
            )
            })}
          </tr>
        )): <></>}
      </tbody>
    </table>: <></>
  )
}
