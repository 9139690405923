import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { fetchMaualEntryData, fetchBreakDownReport, fetchIndividualTracking, fetchScopeDataByCategory } from "../API/api.trackingDetail";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import common from "./common";
import survey from "./survey";
import target from './target'

class TrackingDetail {
  cookies = new Cookies();
  buildingName = this.cookies.get("siteName")
  userName = this.cookies.get("userName");
  summaryData = [];
  consumptionChartData = [];
  quarterlyCosumptionChartData = [];
  yearlyChartData = [];
  isLoading = false;
  entryData = [];
  key = "";
  baselineYear = null
  yearOptions = [
    { label: 2018, value: 2018 },
    { label: 2019, value: 2019 },
    { label: 2020, value: 2020 },
    { label: 2021, value: 2021 },
    { label: 2022, value: 2022 },
  ];
  focusArea = this.cookies.get("focusArea");
  companyName = common.companyName;
  breakdownReportData = null;

  selectedYear =
    this.focusArea === "Scope 2 Emission" || this.focusArea === "Water" || this.focusArea === 'Energy'
      ? this.yearOptions[0].value : this.focusArea === "Scope 3 Emission" ? 2022
        : this.baselineYear;

  constructor() {
    makeObservable(this, {
      summaryData: observable,
      consumptionChartData: observable,
      quarterlyCosumptionChartData: observable,
      isLoading: observable,
      selectedYear: observable,
      entryData: observable,
      key: observable,
      yearOptions: observable,
      breakdownReportData: observable,
      yearlyChartData: observable,

      getManualEntryData: action.bound,
      _handleSelectYear: action.bound,
      getBreakDownReport: action.bound,
      getManualEntryDataByCategory: action.bound,
    });
  }

  getManualEntryData = (buildingId, frameworkId, focusArea) => {
    // console.log('focusArea =', focusArea)
    fetchIndividualTracking((err, data) => {
      if (data) {
        const currentBuilding = data.payload.find(v => v.building === this.buildingName)
        this.baselineYear = this.focusArea === "Scope 3 Emission" ? 2022 : currentBuilding.baseline_year
      }
    })
    target._fetchTargetSetting(buildingId)
    
    // getAllSurvey(buildingId);
    const key =
      focusArea === "Scope 2 Emission"
        ? "scope2Emission"
        : focusArea === "Scope 3 Emission"
          ? "scope3Emission"
          : focusArea === "Water"
            ? "waterConsumption"
            : focusArea === "Energy"
              ? "energyConsumption"
              : focusArea === "Scope 1 Emission"
                ? "scope1Emission"
                : focusArea + "Consumption";
    this.key = key;
    runInAction(() => {
      this.isLoading = survey.surveyFramework === null && true;
      this.focusArea = focusArea;
    });

    fetchMaualEntryData(
      buildingId,
      frameworkId,
      2022,
      null,
      (err, data) => {
        if (err) {
          // toast.error(err)
        } else {
          if (!data.error) {
            const payloadData = data.payload ? data.payload : {};
            
            
            if (
              data.payload === null ||
              data.payload?.manual_entry_data?.length == 0
            ) {
              this.summaryData = [];
              this.consumptionChartData = [];
              this.quarterlyCosumptionChartData = [];
            }
            if (payloadData.manual_entry_data?.length > 0) {
              this.yearOptions = this.yearOptions?.filter((v) => false);
              const options = [...this.yearOptions]
              options.push(
                ...payloadData?.manual_entry_data.map((v) => {
                  return { label: Number(v.year), value: Number(v.year) };
                })
              );
              this.yearOptions = options.sort((a, b) => Number(a.value) - Number(b.value));
              // this.yearOptions = (options.filter((v, i, a) => a.findIndex(v2 => (Number(v2.value )=== Number(v.value))) === i && Number(v.value) >= Number(this.baselineYear)))?.sort((a, b) => Number(a.value) - Number(b.value))

            }
            this.selectedYear =  this.yearOptions[0]?.value;
    
            this.entryData = payloadData;
            const summaryRawData = payloadData?.manual_entry_data?.map((v) => {
              const Consumption = v.manualEntryData.reduce(
                (r, c) => r + (c[key] ? Number(c[key]) : 0),
                0
              );
              
              return {
                year: v.year,
                [key]: Consumption,
              };
            });
            const sortedData = summaryRawData?.sort(function (a, b) {
              return a.year - b.year;
            });

            let currentYear = new Date().getFullYear();
            let baseLineData = sortedData.find(y => y.year === this.baselineYear)
            //to calculate delta data(summary table)
            
            // for Digital Edge
            let manualDataByYear = [];
            data.payload?.manual_entry_data.forEach(v => {
              manualDataByYear[v.year] = v.manualEntryData;
            });    

            const summaryData_ = sortedData?.map((v1, k1) => {
              if (k1 === 0) {
                if (this.companyName === "Digital Edge" || this.companyName == "ClientA"){
                  
                  let checkFullDataMonth = false;
                  
                  manualDataByYear[v1.year].forEach(v=>{
                    if(checkFullDataMonth){
                      if(v.energyConsumption > 0){
                        checkFullDataMonth = false;
                        return ;
                      }
                    }
                    if(v.energyConsumption > 0){
                      checkFullDataMonth = true;
                    } 
                    
                    
                  })
                  // console.log("Company select", manualDataByYear[v1.year], checkFullDataMonth);

                  if(checkFullDataMonth){
                    return {
                      ...v1,
                      delta: (100).toFixed(2),
                      success: v1.year === currentYear ? false : true,
                    }
                  }else{
                    let dataFillCount = 0;
                    manualDataByYear[v1.year].forEach(v=>{
                      if(v.energyConsumption > 0){
                        dataFillCount ++;
                      }                    
                    })
                    
                    return {
                      ...v1,
                      delta: (dataFillCount/12*100).toFixed(2),
                      success: v1.year === currentYear ? false : true,
                    }
                  }
                                    
                }
                return {
                  ...v1,
                  delta: "-",
                  success: false,
                };
              } else {
                if (this.companyName === "Digital Edge" || this.companyName == "ClientA"){
                  // console.log("Company select", manualDataByYear[v1.year]);
                  let dataFillCount = 0;
 
                  manualDataByYear[v1.year].forEach(v=>{
                    if(this.focusArea === "Energy"){
                      if(v.energyConsumption > 0){
                        dataFillCount ++;
                      } 
                    } else if (this.focusArea === "Water"){
                      if(v.waterConsumption > 0){
                        dataFillCount ++;
                      } 
                    } else if (this.focusArea === "Scope 2 Emission"){
                      if(v.scope2Emission > 0){
                        dataFillCount ++;
                      } 
                    } else if (this.focusArea === "Scope 3 Emission"){
                      if(v.scope3Emission > 0){
                        dataFillCount ++;
                      } 
                    }
                                      
                  });
                  
                  return {
                    ...v1,
                    delta: (dataFillCount/12*100).toFixed(2),
                    success: v1.year === currentYear ? false : true,
                  }
                }                
                else if (this.userName === "Tester") {
                  return {
                    ...v1,
                    delta: v1[key]
                          ? (
                            ((v1[key] - sortedData[0]?.[key]) * 100) /
                            sortedData[0]?.[key]
                          ).toFixed(2)
                          : 0,
                    success: v1.year === currentYear ? false : true,
                  };
                } else {
                  return {
                    ...v1,
                    delta: v1[key]
                      ? (
                        ((v1[key] - baseLineData?.[key]) * 100) /
                        baseLineData?.[key]
                      ).toFixed(2)
                      : 0,
                    success: true,
                  };
                }
              }
            });
            const chartRawData = payloadData?.manual_entry_data?.find(
              (v) => v.year === this.selectedYear
            ).manualEntryData;

            const fa = focusArea.includes("Emission") ? focusArea : (focusArea+" Consumption")
            const targetVal = toJS(target.settings)?.find(v=> 
              (v.dataSource.replace(/\s/g, ''))?.toLowerCase() === (fa.replace(/\s/g, ''))?.toLowerCase()
              )?.targetValue;
            const R = [];            

            chartRawData?.map((v) =>
              R.push({
                name: v.month.substring(0, 3),
                waterConsumption: v.waterConsumption,
                EnergyConsumption: v.energyConsumption,
                scope1Emission: v.scope1Emission,
                scope2Emission: v.scope2Emission,
                scope3Emission: v.scope3Emission,
                Target:
                  // focusArea === "Energy"
                  //   ? 900000
                  //   : this.focusArea === "Water"
                  //     ? this.companyName === 'Syscom' ? '' : 90
                  //     : this.focusArea === "Scope 2 Emission"
                  //       ? this.companyName === 'Syscom' ? 80810.71 : 20000
                  //       : 900
                        (targetVal === null || targetVal === undefined) ? null : Number(targetVal),
                carbonEmission: v.carbonEmission,
              })
            );

        
            

            
            

            //find latestYear(consumption chart)
            // const latestYear = (payloadData?.manual_entry_data?.reduce((prev, cur)=> (prev.year > cur.year) ? prev : cur , null))?.manualEntryData;

            // // sum all comsumptionData of prev month
            // const chartData = latestYear ? latestYear.reduce((r,c,i)=> {
            //    const R = [...r];
            //    R.push({
            //     name: c.month?.slice(0,3),
            //     Target: 900,
            //     EnergyConsumption: c.energyConsumption > 0 ? (c.energyConsumption ? c.energyConsumption : 0) + (R[i-1]?.EnergyConsumption ? R[i-1]?.EnergyConsumption : 0) : 0,
            //     waterConsumption: c.waterConsumption > 0 ? (c.waterConsumption ? c.waterConsumption : 0) + (R[i-1]?.waterConsumption ? R[i-1]?.waterConsumption : 0) : 0,
            //     greenMaterialUsage: c.greenMaterialUsage > 0 ? (c.greenMaterialUsage ? c.greenMaterialUsage : 0) + (R[i-1]?.greenMaterialUsage ? R[i-1]?.greenMaterialUsage : 0) : 0,
            //     plasticUsage: c.plasticUsage > 0 ? (c.plasticUsage ? c.plasticUsage : 0) + (R[i-1]?.plasticUsage ? R[i-1]?.plasticUsage : 0) : 0
            //    })

            //    return R
            // }, []) : [];

            runInAction(() => {
              this.summaryData = summaryData_;
              this.consumptionChartData = R;
              this.quarterlyCosumptionChartData = this.calculateQuarterlyChartData(chartRawData, targetVal)
              if(payloadData.manual_entry_data){
                this.yearlyChartData = this.calculateYearlyChartData(payloadData.manual_entry_data, targetVal);   
              }              
              
            });
          }
        }

        runInAction(() => {
          this.isLoading = false;
        });
      }
    );
  };

  calculateQuarterlyChartData = (chartRawData, targetYear) => {    
    const quarterRawData = [];
    const chunkSize = 3;
    for (let i = 0; i < chartRawData.length; i += chunkSize) {
        const quarterChunk = chartRawData.slice(i, i + chunkSize);
        quarterRawData.push(quarterChunk)
    }

    let quarterlyChartData = quarterRawData.map((chunk)=>{
      let energyConsumption = 0;
      let waterConsumption = 0;
      let scope1Emission = 0;
      let scope2Emission = 0;
      let scope3Emission = 0;
      let carbonEmission = 0;
      let quarterMonth = "";

      chunk.map(value=>{
        energyConsumption += value.energyConsumption?value.energyConsumption:0;
        waterConsumption += value.waterConsumption?value.waterConsumption:0;
        scope1Emission += value.scope1Emission?value.scope1Emission:0;
        scope2Emission += value.scope2Emission?value.scope2Emission:0;
        scope3Emission += value.scope3Emission?value.scope3Emission:0;
        carbonEmission += value.carbonEmission?value.carbonEmission:0;
        quarterMonth = value.month;
      })

      let chartData = {
        name: quarterMonth,
        EnergyConsumption: energyConsumption,
        waterConsumption: waterConsumption,
        scope1Emission: scope1Emission,
        scope2Emission: scope2Emission,
        scope3Emission: scope3Emission,
        carbonEmission: carbonEmission,
        target: (targetYear === null || targetYear === undefined) ? null : Number(targetYear)
      }
      return chartData;
    })

    return quarterlyChartData;
  }

  calculateYearlyChartData = (manual_entry_data, targetVal) => {
    
    let yearlyChartData = manual_entry_data.map(v=>{
      // console.log("/////////////////////", v);
      let energyConsumption = 0;
      let waterConsumption = 0;
      let scope1Emission = 0;
      let scope2Emission = 0;
      let scope3Emission = 0;
      let carbonEmission = 0;
      //please get target value from api

      v.manualEntryData.forEach(value=>{
        energyConsumption += value.energyConsumption?value.energyConsumption:0;
        waterConsumption += value.waterConsumption?value.waterConsumption:0;
        scope1Emission += value.scope1Emission?value.scope1Emission:0;
        scope2Emission += value.scope2Emission?value.scope2Emission:0;
        scope3Emission += value.scope3Emission?value.scope3Emission:0;
        carbonEmission += value.carbonEmission?value.carbonEmission:0;
      })

      let prepareChart = {
        name: v.year.toString(),
        EnergyConsumption: energyConsumption,
        waterConsumption: waterConsumption,
        scope1Emission: scope1Emission,
        scope2Emission: scope2Emission,
        scope3Emission: scope3Emission,
        carbonEmission: carbonEmission,
        target: (targetVal === null || targetVal === undefined) ? null : Number(targetVal)
      }
      return prepareChart;
    })
    yearlyChartData.sort((a,b)=>a.name-b.name);
    return yearlyChartData;
  }

  getBreakDownReport = (buildingId, year) => {
    fetchBreakDownReport(buildingId, year, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        // console.log(data.payload)
        // if(!data.error){
        runInAction(() => {
          this.breakdownReportData = data?.payload;
        });
        // }
      }
    });
  };

  _handleSelectYear = (e) => {
    this.selectedYear = e.value;
    let summaryRawData = null;
    // console.log(this.companyName, this.focusArea)
    if (this.companyName === 'Enterprise SG' && (this.focusArea === 'Scope 1 Emission' || this.focusArea === 'Scope 3 Emission')) {
      
      summaryRawData = this.entryData.manual_entry_data?.map((v) => {
        const Consumption = v.manualEntryData?.reduce(
          (r, c) => r + (c.value ? Number(c.value) : 0),
          0
        );
        return {
          year: v.year,
          value: Consumption,
        };
      });
    } else {
      summaryRawData = this.entryData.manual_entry_data?.map((v) => {
        const Consumption = v.manualEntryData?.reduce(
          (r, c) => r + (c[this.key] ? Number(c[this.key]) : 0),
          0
        );
        return {
          year: v.year,
          [this.key]: Consumption,
        };
      });
    }

    const sortedData = summaryRawData?.sort(function (a, b) {
      return a.year - b.year;
    });
    if (
      this.entryData === null ||
      this.entryData?.manual_entry_data?.length == 0
    ) {
      this.summaryData = [];
      this.consumptionChartData = [];
      this.quarterlyCosumptionChartData = [];
    }

    let currentYear = new Date().getFullYear();
    //to calculate delta data(summary table)
    let baseLineData = sortedData.find(y => y.year === this.baselineYear)
    // console.log(sortedData)
    const summaryData_ = sortedData?.map((v1, k1) => {


      // for Digital Edge
      let manualDataByYear = [];
      this.entryData.manual_entry_data.forEach(v => {
        manualDataByYear[v.year] = v.manualEntryData;
      });   
      
      if (k1 === 0) {

        if (this.companyName === "Digital Edge" || this.companyName == "ClientA"){
                  
          let checkFullDataMonth = false;
          
          manualDataByYear[v1.year].forEach(v=>{
            if(checkFullDataMonth){
              if(v.energyConsumption > 0){
                checkFullDataMonth = false;
                return ;
              }
            }
            if(v.energyConsumption > 0){
              checkFullDataMonth = true;
            } 
            
            
          })
          // console.log("Company select", manualDataByYear[v1.year], checkFullDataMonth);

          if(checkFullDataMonth){
            return {
              ...v1,
              delta: (100).toFixed(2),
              success: v1.year === currentYear ? false : true,
            }
          }else{
            let dataFillCount = 0;
            manualDataByYear[v1.year].forEach(v=>{
              if(this.focusArea === "Energy"){
                if(v.energyConsumption > 0){
                  dataFillCount ++;
                } 
              } else if (this.focusArea === "Water"){
                if(v.waterConsumption > 0){
                  dataFillCount ++;
                } 
              } else if (this.focusArea === "Scope 2 Emission"){
                if(v.scope2Emission > 0){
                  dataFillCount ++;
                } 
              } else if (this.focusArea === "Scope 3 Emission"){
                if(v.scope3Emission > 0){
                  dataFillCount ++;
                } 
              }
                                
            });
            
            return {
              ...v1,
              delta: (dataFillCount/12*100).toFixed(2),
              success: v1.year === currentYear ? false : true,
            }
          }
                            
        }

        return {
          ...v1,
          delta: "-",
          success: false,
        };
      } else {
        if (this.companyName === "Digital Edge" || this.companyName == "ClientA"){
          // console.log("Company select", manualDataByYear[v1.year]);
          let dataFillCount = 0;
          
          manualDataByYear[v1.year].forEach(v=>{
            if(this.focusArea === "Energy"){
              if(v.energyConsumption > 0){
                dataFillCount ++;
              } 
            } else if (this.focusArea === "Water"){
              if(v.waterConsumption > 0){
                dataFillCount ++;
              } 
            } else if (this.focusArea === "Scope 2 Emission"){
              if(v.scope2Emission > 0){
                dataFillCount ++;
              } 
            } else if (this.focusArea === "Scope 3 Emission"){
              if(v.scope3Emission > 0){
                dataFillCount ++;
              } 
            }
                              
          });
          
          return {
            ...v1,
            delta: (dataFillCount/12*100).toFixed(2),
            success: v1.year === currentYear ? false : true,
          }
        }else if (this.companyName === "Enterprise SG" && (this.focusArea === 'Scope 1 Emission' || this.focusArea === 'Scope 3 Emission')) {
          return {
            ...v1,
            delta: v1.value
                  ? (
                    ((v1.value - sortedData[0]?.value) * 100) /
                    sortedData[0]?.value
                  ).toFixed(2)
                  : 0,
            success: v1.year === currentYear ? false : true,
          };
        } else {
          return {
            ...v1,
            delta: v1[this.key]
              ? (
                ((v1[this.key] - baseLineData?.[this.key]) * 100) /
                baseLineData?.[this.key]
              ).toFixed(2)
              : 0,
            success: true,
          };
        }

        // return {            
        //   ...v1,
        //   delta: v1[this.key]
        //     ? (
        //         ((v1[this.key] - sortedData[0]?.[this.key]) * 100) /
        //         sortedData[0]?.[this.key]
        //       ).toFixed(2)
        //     : 0,
        //   success: true,
        // };
      }
    });
    const chartRawData = this.entryData.manual_entry_data?.find(
      (v) => v.year == this.selectedYear
    )?.manualEntryData;

    const fa = this.focusArea.includes("Emission") ? this.focusArea : (this.focusArea+" Consumption")
    const targetVal = toJS(target.settings)?.find(v=> 
      (v.dataSource.replace(/\s/g, ''))?.toLowerCase() === (fa.replace(/\s/g, ''))?.toLowerCase()
      )?.targetValue;
    const R = [];
    chartRawData?.map((v) =>
      R.push({
        name: v.month.substring(0, 3),
        waterConsumption: v.waterConsumption,
        EnergyConsumption: v.energyConsumption,
        Target:
          // this.focusArea === "Energy"
          //   ? 900000
          //   : this.focusArea === "Water"
          //     ? this.companyName === 'Syscom' ? '' : 90
          //     : this.focusArea === "Scope 2 Emission"
          //       ? this.companyName === 'Syscom' ? 80810.71 : 20000
          //       : 900
          (targetVal === null || targetVal === undefined) ? null : Number(targetVal),
        carbonEmission: v.carbonEmission,
        scope1Emission: v.scope1Emission || v?.value,
        scope2Emission: v.scope2Emission,
        scope3Emission: v.scope3Emission || v?.value,
      })
    );
    runInAction(() => {
      this.summaryData = summaryData_;
      this.consumptionChartData = R;
      this.quarterlyCosumptionChartData = this.calculateQuarterlyChartData(chartRawData, targetVal)
    });
  };

  getManualEntryDataByCategory = (ghgFrameworkId, focusArea, category) => {
    fetchScopeDataByCategory(focusArea, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        // console.log('data payload ------------------ ',data.payload)
        this.baselineYear = data.payload?.find(v => v.category === category)?.baselineYear;

        fetchMaualEntryData(
          null,
          ghgFrameworkId,
          2022,
          category,
          (err, data) => {
            if (err) {
              // toast.error(err)
            } else {

              if (!data.error) {

                const payloadData = data.payload ? data.payload : {};

                if (
                  data.payload === null ||
                  data.payload?.manual_entry_data?.length == 0
                ) {
                  this.summaryData = [];
                  this.consumptionChartData = [];
                  this.quarterlyCosumptionChartData = [];
                }
                if (payloadData.manual_entry_data?.length > 0) {
                  this.yearOptions = this.yearOptions?.filter((v) => false);
                  const options = [...this.yearOptions]
                  options.push(
                    ...payloadData?.manual_entry_data.map((v) => {
                      return { label: Number(v.year), value: Number(v.year) };
                    })
                  );
                  this.yearOptions = (options.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i && v.value >= this.baselineYear))?.sort((a, b) => a.value - b.value)

                }
                this.selectedYear = this.baselineYear;
                this.entryData = payloadData;
                const summaryRawData = payloadData?.manual_entry_data?.map((v) => {
                  const Consumption = v.manualEntryData.reduce(
                    (r, c) => r + (c.value ? Number(c.value) : 0),
                    0
                  );
                  return {
                    year: v.year,
                    value: Consumption,
                  };
                });

                const sortedData = summaryRawData?.sort(function (a, b) {
                  return a.year - b.year;
                });

                let currentYear = new Date().getFullYear();
                let baseLineData = sortedData.find(y => y.year === this.baselineYear);

                //to calculate delta data(summary table)
                const summaryData_ = sortedData?.map((v1, k1) => {
                  if (k1 === 0) {
                    return {
                      ...v1,
                      delta: "-",
                      success: false,
                    };
                  } else {
                    if (this.userName === "Tester") {
                      return {
                        ...v1,
                        delta: v1.value
                              ? (
                                ((v1.value - sortedData[0]?.value) * 100) /
                                sortedData[0]?.value
                              ).toFixed(2)
                              : 0,
                        success: v1.year === currentYear ? false : true,
                      };
                    } else {
                      return {
                        ...v1,
                        delta: v1.value
                          ? (
                            ((v1.value - baseLineData?.value) * 100) /
                            baseLineData?.value
                          ).toFixed(2)
                          : 0,
                        success: true,
                      };
                    }
                  }
                });

                const chartRawData = payloadData?.manual_entry_data?.find(
                  (v) => v.year === this.selectedYear
                ).manualEntryData;

                const fa = focusArea.includes("Emission") ? focusArea : (focusArea+" Consumption")
            const targetVal = toJS(target.settings)?.find(v=> 
              (v.dataSource.replace(/\s/g, ''))?.toLowerCase() === (fa.replace(/\s/g, ''))?.toLowerCase()
              )?.targetValue;
                const R = [];
                chartRawData?.map((v) =>
                  R.push({
                    name: v.month.substring(0, 3),
                    waterConsumption: v.waterConsumption,
                    EnergyConsumption: v.energyConsumption,
                    scope1Emission: v?.scope1Emission || v?.value,
                    scope2Emission: v.scope2Emission,
                    scope3Emission: v?.scope3Emission || v?.value,
                    Target:
                      // focusArea === "Energy"
                      //   ? 900000
                      //   : this.focusArea === "Water"
                      //     ? this.companyName === 'Syscom' ? '' : 90
                      //     : this.focusArea === "Scope 2 Emission"
                      //       ? this.companyName === 'Syscom' ? 80810.71 : 20000
                      //       : 900
                      (targetVal === null || targetVal === undefined) ? null : Number(targetVal),
                    carbonEmission: v.carbonEmission,
                  })
                );
                // console.log('summaryData_================> ', R)
                runInAction(() => {
                  this.summaryData = summaryData_;
                  this.consumptionChartData = R;
                  this.quarterlyCosumptionChartData = this.calculateQuarterlyChartData(chartRawData, targetVal)
                });
              }
            }

            runInAction(() => {
              this.isLoading = false;
            });
          }
        );
      }
    });


  }
}

export default new TrackingDetail();
