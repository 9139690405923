import { inject, observer } from "mobx-react";

export const MyCheckbox = inject("store")(
  observer((props) => {
    const { id,value, setInputs, disabled,checked, onChange } = props;

    const myFunction = (e) => {
      // var checked = document.getElementById(value).checked;
      // setCheckedValue(id, checked);
      if(onChange){
        onChange(e);
      }else {
        setInputs("framework", e);
      }
      
    };

    return (
      <input
        checked={checked}
        style={{ cursor: "pointer" }}
        id={id}
        value={value}
        type="checkbox"
        name="framework"
        disabled={disabled}
        onChange={(e) => myFunction(e)}
      />
    );
  })
);
