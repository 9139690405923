import * as API from "./URL";

export const addOrganizationSetUp = async(params, data, callback) => {
  return fetch(API.createOrganizationSetUp(params), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err.toString(), null));    
}

export const fetchDataColumns = async (callback) => {
  return fetch(API.getDataColumns, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
}; 

export const fetchAllEnergySource = async (callback) => {
  return fetch(API.getEnergySourceList, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .then((data) => callback(null, data))
    .catch((err) => callback(err, null));
}