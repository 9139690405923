import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";

export const EditNewKPIModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleCloseKPIModal } = props.store.kpiManagement;

        return (
            <div
                className={`modal fade large ${theme}`}
                id="kpiEditModal"
                tabIndex="-1"
                aria-labelledby="kpiEditModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h5>Editing KPI</h5>
                            <i
                                onClick={() => _handleCloseKPIModal()}
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body px-4" style={{ fontSize: 14 }}>
                            <EditKPI />
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export const EditKPI = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleSelectChange, createKpiList, _handleCreateKpi, kpiListEdit,
            isExpandEdit, targetOption, expandKpiList, _handleInputChange, _handleRadioChange, actionMode,
            baseYearOption, ownerTitleOption, ownerNameOption, unitOption, frequencyOption, dependenciesOption, standardsOption, _handleConfirmReset,
            _updateKPIManagement,
        } = props.store.kpiManagement;

        const {_handleSankeyChartClick} = props.store.esg;

        const { pillarOption, subPillarOption, _fetchAllPillars, _fetchAllSubPillars } = props.store.esg;

        useEffect(()=>{            
            if(actionMode == null && expandKpiList?._id){                
                _handleSankeyChartClick(null, null, {id: expandKpiList._id})
            }

        },[actionMode]);

        return (
            <div style={{ fontSize: 14, color: invertDark(theme) }}>
                <form className="px-2">
                    <div className="d-flex align-items-center row my-2 ps-3 pb-3" style={{ borderBottom: isExpandEdit ? `1px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.1)'}` : "" }}>
                        <div className="col-lg-4 col-12 px-3 py-1">
                            <span className="" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                ESG Pillar :
                            </span>
                            <span className="px-2" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0], fontWeight: 'normal' }}>
                                {kpiListEdit?.pillar}
                            </span>
                        </div>
                        <div className="col-lg-8 col-12 px-3 py-1">
                            <span className="" style={{ fontWeight: 'bold' }}>
                                Materiality Topic/ESG Sub-Pillar :
                            </span>
                            <span className="px-2" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0], fontWeight: 'normal' }}>
                                {kpiListEdit?.subPillar}
                            </span>
                        </div>
                        {!isExpandEdit && <div className="col py-1 text-end">
                            <MyButton
                                style={{ height: 60, width: '30%' }}
                                text="Create"
                                customColor='#066CFF'
                                customHoverColor={Colors.water}
                                onClick={_handleCreateKpi}
                            />
                        </div>}
                    </div>

                    {toJS(isExpandEdit) === true &&
                        <div className="d-flex row my-3 ps-3" style={{ color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                            <div className="col-lg-4 col-12">
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>KPI :</span>
                                    <span className="col-7">
                                        <MyInput
                                            height={'100px'}
                                            id="editKpi"
                                            isTextArea={true}
                                            value={expandKpiList.kpi}
                                            placeHolder={"Please insert KPI"}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "kpi")}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Objectives : </span>
                                    <span className="col-7">
                                        <MyInput
                                            height={'100px'}
                                            id="editObjectives"
                                            isTextArea={true}
                                            placeHolder={"Please insert objectives"}
                                            value={expandKpiList.objectives}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "objectives")}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Data Source :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="editDataSource"
                                            placeholder=""
                                            value={expandKpiList.dataSource}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "dataSource")}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Progress :</span>
                                    <span className="col-7">
                                        <MyInput
                                            type="number"
                                            id="progress"
                                            placeholder="Progress"
                                            value={expandKpiList.progress}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "progress")}                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Result :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="result"
                                            placeholder="Result"
                                            value={expandKpiList.result}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "result")}
                                        />
                                    </span>
                                </div>
                                {/* <div className="row d-flex py-2">
                                    <span className="col-4" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Target Set :</span>
                                    <span className="col-7">
                                        <div className="row">
                                            {targetOption.map((v, k) => (
                                                <span className="col-6 text-light">
                                                    <MyRadioButton
                                                        id={"edit" + v.value + k}
                                                        onChange={(e) => _handleRadioChange(e)}
                                                        name={v.value}
                                                        value={v.label}
                                                        checked={expandKpiList.targetSet === v.label}
                                                    />
                                                </span>
                                            ))}
                                        </div>
                                    </span>
                                </div> */}
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Base Year :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editBaseYearSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "baseYear")}
                                            options={baseYearOption}
                                            value={baseYearOption.filter((option) => (option.value) === (expandKpiList.baseYear))[0]}
                                            placeholder={"Select base year"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        KPI Owner Title :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editOwnerTitleSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "ownerTitle")}
                                            options={ownerTitleOption}
                                            value={ownerTitleOption.filter((option) => option.value === expandKpiList.ownerTitle)}
                                            placeholder={"Select owner title"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        KPI Owner Name :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editOwnerNameSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "ownerName")}
                                            options={ownerNameOption}
                                            value={ownerNameOption.filter((option) => option.value === expandKpiList.ownerName)[0]}
                                            placeholder={"Select owner name"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Status:</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="editStatus"
                                            isTextArea={true}
                                            value={expandKpiList.status}
                                            placeHolder={"Please insert status"}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "status")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 d-flex align-items-center" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Unit of Measurement:
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editUnitSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "unit")}
                                            options={unitOption}
                                            value={unitOption.filter((option) => option.value === expandKpiList.unit)}
                                            placeholder={"Select unit"}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Frequency of Measurement:
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editFrequencySelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "frequency")}
                                            options={frequencyOption}
                                            value={frequencyOption.filter((option) => option.value === expandKpiList.frequency)}
                                            placeholder={"Select frequency"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Key Dependencies:
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editDependencies"}
                                            _handleSelect={(e) => _handleSelectChange(e, "dependencies")}
                                            options={dependenciesOption}
                                            value={dependenciesOption.filter((option) => option.value === expandKpiList.dependencies)[0]}
                                            placeholder={"Select dependencies"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5 py-2" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>
                                        Standards Alignment :
                                    </span>
                                    <span className="col-7" style={{ color: Colors.Dark[0], fontWeight: 'normal' }}>
                                        <Selector
                                            id={"editStandardsSelector"}
                                            _handleSelect={(e) => _handleSelectChange(e, "standards")}
                                            options={standardsOption}
                                            value={standardsOption.filter((option) => option.value === expandKpiList.standards)}
                                            placeholder={"Select standards"}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Notes :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="editNotes"
                                            placeHolder={"Please insert note"}
                                            isTextArea={true}
                                            value={expandKpiList.notes}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "notes")}
                                        />
                                    </span>
                                </div>
                                <div className="row d-flex py-2">
                                    <span className="col-5" style={{ fontWeight: 'bold', color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0] }}>Target :</span>
                                    <span className="col-7">
                                        <MyInput
                                            id="establishedTarget"
                                            placeHolder={"Please insert target"}
                                            isTextArea={true}
                                            value={expandKpiList.establishedTarget}
                                            onChange={(e) => _handleInputChange(e, "expandForm", "establishedTarget")}
                                            height={'100px'}
                                        />
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between py-2">
                                    <span
                                        className="col-6"
                                        data-bs-toggle="modal"
                                        data-bs-target="#confirmDeleteModel"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <MyButton
                                            style={{ width: "90%" }}
                                            text="Delete"
                                            customColor={theme === 'dark' ? Colors.primaryBule : '#d32f2f'}
                                            customHoverColor={theme === 'dark' ? Colors.lightBlue : '#D44E4E'} />
                                    </span>

                                    <span
                                        className="col-6"
                                        data-bs-dismiss="modal"
                                        aria-label="Close">
                                        <MyButton
                                            id={"edit"}
                                            style={{ width: "90%", textAlign: "end" }}
                                            text={"Update KPI"}
                                            onClick={(e) => _updateKPIManagement()}
                                            customColor='#066CFF'
                                            customHoverColor={Colors.water}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </form>
            </div>
        );
    })
) 