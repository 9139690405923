import { inject, observer } from "mobx-react";
import { MyTable } from "../../Tools";
import { Cookies } from "react-cookie";
import { MyButton } from "../../Tools";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

export const ReductionSettingTable = inject("store")(
  observer((props) => {
    const { settingTable, _handleSettingData, openModal, _handleCloseTarget } = props.store.target;
    const { theme } = props.store.common;
    
    useEffect(()=>{
      openModal&&document.getElementById('clickButton').click();
    },[])

    const { t } = useTranslation("target_setting");

    const cookies = new Cookies();
    const siteName = cookies.get("userName");
    const column = [" ",
      <span className="text-nowrap">{t("Data Source")}</span>,
      <span className="text-nowrap"><i className="bi bi-info-circle pe-1 text-white" title={t("A historic point of comparison that we can use to track changes and improvements at our property over time.")} style={{ cursor: "pointer" }}></i>{t("Baseline Year")}</span>,
      <span className="text-nowrap"><i className="bi bi-info-circle pe-1 text-white" title={t("A reference state or the values against which we measure change.")} style={{ cursor: "pointer" }}></i>{t("Baseline Value")}</span>,
      <span className="text-nowrap"><i className="bi bi-info-circle pe-1 text-white" title={t("A year commencing on January 1 and ending on the succeeding December 31.")} style={{ cursor: "pointer" }}></i>{t("Target Year")}</span>,
      <span className="text-nowrap"><i className="bi bi-info-circle pe-1 text-white" title={t("A normalized metric that sets an organization's emissions target relative to an economic or operational variable.")} style={{ cursor: "pointer" }}></i>{t("Target Value")}</span>,
      <span className="text-nowrap">{t("Target Reduction(%)")}</span>
    ];

    return (
      <div
        className="cardStyle position-relative"
        style={{ fontSize: "14px" }}
      >
        <div
           title={"Set Target"}
          data-bs-toggle="modal"
          data-bs-target="#setTargetModal"
          style={{
            position: "absolute",
            left: 15,
            top: 27,
            cursor: "pointer",
            zIndex: 3,
          }}
         
        >
          <MyButton
            id='clickButton'
            customColor="#066CFF"
            customHoverColor={Colors.water}
            onClick = {(e) => _handleSettingData()}
            text={<i class="bi bi-gear-fill p-1" />}
          />
        </div>
        {settingTable && (
          <MyTable
            column={column}
            targetSettingData={settingTable}
            theme={theme}
          />
        )}
      </div>
    );
  })
);


