import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";


export const UnitModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const {
            selectedBaseLineYear,
            yearOptions,
            selectedUnit,
            unitOptions,
            dataSourceOptions,
            selectedField,
            selectedCarbonTab,
            _handleSelectYear,
            _handleCancelUnitModal,
            _handleSaveUnit,
        } = props.store.survey;
        return (
            <div
                className={`modal fade text-dark ${theme}`}
                id="unitModal"
                tabIndex="-1"
                aria-labelledby="unitModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-sm">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme),
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="setTargetModalLabel">
                                Unit
                            </h5>
                            {/* <i
                                    className="bi bi-x"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ cursor: "pointer" }}
                                /> */}
                        </div>
                        <div className={`modal-body`}>
                            <div className="row d-flex">
                                <div className="col">
                                    <MyInput
                                        disabled={true}
                                        label={"Data Source"}
                                        value={
                                            dataSourceOptions.find(v => selectedField === 'carbonEmission' ? (v?.value === selectedCarbonTab) : (v?.value === selectedField))?.label
                                        }
                                        placeholder={"Select data source"}
                                    />
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col">
                                    <MyInput
                                        disabled={true}
                                        label={"Unit"}
                                        menuListColor={Colors.Dark[0]}
                                        value={
                                            unitOptions.find(v => v.value === selectedUnit)?.label
                                        }
                                        placeholder={"Select unit"}
                                    />
                                </div>
                            </div>
                            <div className="row d-flex">
                                <Selector
                                    label={"Baseline Year"}
                                    menuListColor={Colors.Dark[0]}
                                    options={yearOptions}
                                    value={
                                        selectedBaseLineYear ? yearOptions.find(v => v.value === selectedBaseLineYear) : null
                                    }
                                    placeholder={"Select baseline year"}
                                    _handleSelect={(e) =>
                                        _handleSelectYear(e.label, 'baselineYear')
                                    }
                                />
                            </div>
                        </div>
                        <div className={`modal-footer mx-3 px-0 d-flex justify-content-center`}>
                            <div className="d-flex justify-content-center">
                                <MyButton
                                    text={"Cancel"}
                                    rounded
                                    fontSize={15}
                                    dataDismiss={"modal"}
                                    customColor={
                                        theme === "dark" ? Colors.lightBlue : Colors.blue
                                    }
                                    onClick={_handleCancelUnitModal}
                                />
                                <div id={'save_unit'} className="mx-2" />
                                <MyButton
                                    text={"Save"}
                                    customColor={Colors.water}
                                    onClick={_handleSaveUnit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    })
)