import { inject, observer } from "mobx-react";
import React from 'react';
import { baseURL } from "../../API/URL";

export const FilePreviewModal = inject("store")(
  observer((props) => {
    // URL of the Excel file
    const fileURL = "http://localhost:8050/upload/data/e3a4fed5-8ab7-4829-b5be-ba6a860863ca.jpg";

    return (
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* Use an iframe to display the Excel file */}
              <iframe src="/get-excel-file" style={{ width: "100%", height: "500px" }} title="Excel Preview"></iframe>

            </div>
          </div>
        </div>
      </div>
    );
  })
);
