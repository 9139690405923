import { Colors } from "../Assets/Config/color.config";
import Store from "../Store";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

export const IconCard = inject("store")(
  observer((props) => {
    const {
      id,
      icon,
      width,
      cardSize,
      height,
      label,
      type,
      status,
      imgURL,
      style,
      cardWidth,
      shadow
    } = props;
    const { currentBuildingId } = props.store.target;
    const { theme } = props.store.common;
    const [isTracking, setIsTracking] = useState(status);

    const handleIsTracking = () => {
      setIsTracking(!isTracking);
    };

    return (
      <div
        className="pt-2 px-2 pb-1 mx-2"
        style={{
          width: cardWidth,
          backgroundColor: Colors[theme].active,
          color: Colors[theme].textColor,
          borderRadius: 5,
          boxShadow: shadow ? shadow : null
        }}
      >
        <div
          title={label}
          className="iconCardStyle mx-auto d-flex align-items-center justify-content-center"
          style={{ ...style, width: cardSize, height: cardSize }}
        >
          <img
            src={
              imgURL
                ? process.env.PUBLIC_URL + imgURL
                : process.env.PUBLIC_URL +
                  "/sites/" +
                  icon.toLowerCase() +
                  ".png"
            }
            width={width}
            height={height}
            // style={style}
          />
        </div>
        {type === "checkbox" ? (
          <div className="text-center fw-bold pt-2">{label}</div>
        ) : null}

        <p
          className="d-flex align-items-center justify-content-center"
          style={{ cursor: "pointer" }}
        >
          {type === "checkbox" ? (
            isTracking ? (
              <TrackingBox
                buildingId={currentBuildingId}
                frameworkId={id}
                handleIsTracking={handleIsTracking}
              />
            ) : (
              <SetTargetBox
                buildingId={currentBuildingId}
                frameworkId={id}
                handleIsTracking={handleIsTracking}
              />
            )
          ) : (
            <span className="my-2">{label}</span>
          )}
        </p>
      </div>
    );
  })
);

const TrackingBox = (props) => {
  const navigate = useNavigate();
  const { frameworkId, buildingId, handleIsTracking } = props;
  const { t } = useTranslation("target_setting");

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center my-2"
        onClick={handleIsTracking}
      >
        <span
          className="me-1 fw-bold d-flex align-items-center justify-content-center"
          style={{
            background: Colors.greenMaterial,
            width: 12,
            height: 12,
            borderRadius: 10,
          }}
        >
          <i className="bi bi-square-fill-lg"></i>
        </span>
        <span>Tracking</span>
      </div>
      {/* <div className="row d-flex align-items-center">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            navigate(
              `/target-setting/site-relevant/?frameworkId=${frameworkId}&buildingId=${buildingId}`
            );
          }}
        >
          { t("Site Relevant Data") }
        </button>
      </div> */}
    </div>
  );
};

const SetTargetBox = (props) => {
  const navigate = useNavigate();
  const { frameworkId, buildingId, handleIsTracking } = props;
  const { t } = useTranslation("target_setting");
  return (
    <div>
      {/* <div
        className="d-flex align-items-center justify-content-center my-2"
        onClick={handleIsTracking}
      >
        <span
          className="me-1 fw-bold d-flex align-items-center justify-content-center"
          style={{
            background: Colors.Dark[4],
            width: 12,
            height: 12,
            borderRadius: 10,
            color: Colors.primaryBule,
          }}
        >
          <i className="bi bi-square-fill-lg"></i>
        </span>
        <span >{ t("Set Target") }</span>
      </div> */}
      {/* <div className="row d-flex align-items-center">
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            navigate(`/target-setting/site-relevant/?frameworkId=${frameworkId}&buildingId=${buildingId}`)
          }}>
          { t("Site Relevant Data") }
        </button>
      </div> */}
    </div>
  );
};
