import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import {
  MyButton,
  MyInput,
  Selector,
  MyDatepicker,
} from "../../Tools";
import "./groupview.css";

export const AddConsumptionDataModal = inject("store")(
  observer((props) => {
    const {
      _getCompanyFileList,
      companyFileList,
      _handleChangeCompanyYear,
      _handleChangeDataEntryDate,
      _handleChangeDataEntrySelectedFile,
      _handleChangeDataEntryValue,
      dataEntry,
      _handleChangeCompanyFileType,
      addConsumptionData
    } = props;

    const { theme } = props.store.common;

    const {
      selectedView,
      selectedYear,
    } = props.store.groupmanagement;

    const fileList =
      selectedView === "Consumption View"
        ? companyFileList.filter((f) => f.fileType === "Consumption Data")
        : companyFileList;

    const { t } = useTranslation("one_time");

    useEffect(() => {
      _getCompanyFileList();
    }, [_getCompanyFileList]);

    useEffect(() => {
      if (selectedView === "Consumption View") {
        _handleChangeCompanyFileType("Consumption Data");
        _handleChangeCompanyYear(selectedYear);
      }
    }, []);

    const fileOptions = fileList.map((f, i) => ({
      label: f.fileName,
      value: f._id,
    }));

    return (
      <div
        className={`modal fade ${theme}`}
        id="dataEntryFileUploadModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="dataEntryFileUploadModal"
        aria-hidden="true"
        data-bs-focus="false"
      >
        <div className="modal-dialog">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              width: "600px",
            }}
          >
            <div className="modal-header">
              <h4 style={{ color: "rgb(198, 198, 198)" }}>
                {t("Add Consumption Data")}
              </h4>
              <i
                style={{ cursor: "pointer", color: "rgb(198, 198, 198)" }}
                className="bi bi-x-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
              <div
                className="d-flex row justify-content-between"
                style={{ color: invertDark(theme) }}
              >
                <div id="site_info" className="my-2 rounded">
                  <div
                    className="p-3"
                    style={{
                      background: Colors[theme].active,
                      borderRadius: 5,
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-3 row">
                          <p
                            style={{ color: invertDark(theme), fontSize: 15 }}
                            className="mb-1"
                          >
                            {t("Choose Uploaded File")}
                          </p>
                          <div className="col-6">
                            <Selector
                              options={fileOptions}
                              tran={t}
                              value={fileOptions.find((f,i) => f.value === dataEntry.selectedFile) || ""}
                              placeholder={t("Select File")}
                              menuListColor={Colors.Dark[0]}
                              _handleSelect={(e) => _handleChangeDataEntrySelectedFile(e.value) }
                            />
                          </div>
                          <div className="col-6">
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#fileOverviewModal"
                            >
                              <MyButton
                                text={t("Add New File")}
                                customColor={Colors.water}
                                className=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-6">
                            <p
                              style={{ color: invertDark(theme), fontSize: 15 }}
                              className="mb-1"
                            >
                              {t("Consumption Value")}
                            </p>
                            <MyInput
                              required={true}
                              id={"Consumption Value"}
                              type={"text"}
                              value={dataEntry.value || ""}
                              onChange={(e) => _handleChangeDataEntryValue(e.target.value)}
                              placeHolder={t("Consumption Value")}
                            />
                          </div>
                          <div className="col-6">
                            <p
                              style={{ color: invertDark(theme), fontSize: 15 }}
                              className="mb-1"
                            >
                              {t("Used Date")}
                            </p>
                            <MyDatepicker
                              value={dataEntry.date}
                              _handleChange={(e) => _handleChangeDataEntryDate(e)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-center">
                          <MyButton
                            text={t("Submit")}
                            customColor={Colors.water}
                            className="me-3 px-3"
                            onClick={() => addConsumptionData()}
                          />
                          <MyButton
                            text={t("Close")}
                            rounded
                            fontSize={15}
                            className="px-3"
                            id={"addConsumptionModalClose"}
                            dataDismiss={"modal"}
                            customColor={
                              theme === "dark" ? Colors.lightBlue : Colors.blue
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const fileTypeList = [
  { value: 1, label: "Asset Specifications" },
  { value: 2, label: "Consumption Data" },
  { value: 3, label: "Emission Data" },
  { value: 4, label: "Other" },
];
