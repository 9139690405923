import { Colors } from "../../Assets/Config/color.config"
import Store from "../../Store"
import { useTranslation } from 'react-i18next';
import { MyButton, MyInput, Selector } from "../../Tools";
import { useEffect } from "react";
import { toJS } from "mobx";

const ManualEntryModal = (props) => {
    const { focusArea } = props;
    const { theme } = Store.common;
    const { isNewVal, yearOptions, _handleSelectYear, selectedYear, manualEntryData, _handleManualInputChange, _handleResetManualEntry, _handleAddManualEntry, surveyFramework } = Store.survey;
    const { getManualEntryData } = Store.trackingDetail;
    const { t } = useTranslation("user_management");

    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get("buildingId");

    const selectedField = focusArea === "Energy" ? 'energyConsumption' : focusArea === "Water" ? 'waterConsumption' : focusArea;
    const title = focusArea === "Energy" ? "Energy Consumption (kWh)" : focusArea === "Water" ? "Water Consumption (cuM)" : (focusArea === 'Scope 1 Emission' || focusArea === 'Scope 3 Emission') ? `${focusArea} (kg CO2)` : `${focusArea} (kg)`
    // console.log('=======> ', toJS(manualEntryData))
    return (
        <div
            className={`modal fade ${theme}`}
            id="manualEntryModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content"
                    style={{
                        background: Colors[theme].background,
                        borderRadius: 10,
                        color: Colors[theme].textColor
                    }}
                >
                    <div className="modal-header mx-3 px-0">
                        <h4 className="modal-title" id="exampleModalLabel">
                            {"Manual"}
                            <i
                                className="bi bi-gear text-secondary ms-2"
                            />
                        </h4>
                        <i
                            style={{ cursor: "pointer" }}
                            className="bi bi-x-lg "
                            data-bs-dismiss="modal"
                            aria-label={t("Close")}
                        ></i>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>
                                {title}
                            </h5>
                            <Selector
                                id={"yearSelector"}
                                disabled={isNewVal}
                                options={yearOptions}
                                menuListColor={Colors.Dark[0]}
                                value={yearOptions.find((v) => v.label === selectedYear)}
                                _handleSelect={(e) => _handleSelectYear(e.label)}
                            />
                        </div>
                        <div className="row d-flex flex-wrap align-items-center py-2">

                            {
                                manualEntryData?.map((data, key) => {
                                    return (
                                        <div key={key} className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                            <MyInput
                                                label={data.month}
                                                id={data.month + '-' + selectedField}
                                                type={'number'}
                                                value={data[selectedField] ? Number(data[selectedField]) : null}
                                                onChange={(e) => _handleManualInputChange(e, selectedField)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center mx-3">
                        <MyButton
                            text={"Cancel"}
                            rounded
                            fontSize={15}
                            dataDismiss={"modal"}
                            onClick={() => _handleResetManualEntry()}
                            customColor={
                                theme === "dark" ? Colors.lightBlue : Colors.blue
                            }
                        />
                        <MyButton
                            disabled={!isNewVal}
                            text={" Save Progress"}
                            customColor={Colors.water}
                            onClick={() =>{
                                 _handleAddManualEntry(surveyFramework.find(v=> v.name === 'GHG (Version 2)')?.framework_id);
                                 getManualEntryData(buildingId,surveyFramework.find(v=> v.name === 'GHG (Version 2)')?.framework_id, focusArea)
                                }}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ManualEntryModal