import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyButton } from "../../Tools";
import { AddNewGroupModal } from "./AddNewGroupModal";
import { useTranslation } from "react-i18next";

const SideBar = inject("store")(
  observer((props) => {
    const { t } = useTranslation("assetSetupAndGroup"); 
    const { theme } = props.store.common;
    
    const {
      listGroup,
      selectedGroupId,
      _handleSelectGroupId,
      newGroupName,
      _handleNewGroupName,
      addNewGroup,
      selectedView
    } = props.store.groupmanagement;
    
    const navigate = useNavigate();

    return (
      <Fragment>
        <h4 className="d-flex justify-content-center fw-bold text-nowrap mb-3" style={{ color : Colors[theme].textColor}}>
          <i className="bi bi-info-circle me-2"></i>{t("Asset View")}
        </h4>
        <div style={{ width: "90%" }} className="float-end">
          <div className="d-flex justify-content-center mb-3">
            <span
              data-bs-toggle="modal"
              data-bs-target="#addNewGroupModal"
              className="w-75"
            >
              <MyButton
                text={"+"}
                customColor={Colors.blue}
                style={{ width: "100%", fontSize: 20, fontWeight: "bold"}}
              />
            </span>
          </div>

          <div
            style={{
              backgroundColor: theme == "dark" ? "#D9D9D910" : "#ffffff60",
              borderTopLeftRadius: 14,
              borderBottomLeftRadius: 14,
              height: "65vh",
              overflow: "auto"
            }}
            className="d-flex flex-column justify-content-between"
          >
            <div>
              {listGroup.map((g, id) =>
                g._id < 0 ? (
                    <div className="position-relative">
                      <MyButton
                        key={g._id}
                        text={t(g.groupName)}
                        customColor={
                          selectedGroupId == g._id ? theme === "dark" ? "#1E254C" : "#0d6efd" : "transparent"
                        }
                        style={{
                          width: "100%",
                          borderBottomColor:
                          selectedGroupId == g._id ? "" : theme == "dark" ? "#ffffff23" : "#00000023",
                          borderRadius: "none",
                        }}
                        className={`${
                          theme === "dark" ?
                          selectedGroupId == g._id ? "text-white" : "text-white-50" 
                          : selectedGroupId == g._id ? "text-white" : "text-black-50"
                        } fw-bold py-3`}
                        onClick={() => {
                          navigate(`/group-view?groupId=${g._id}&view=${selectedView}`, { replace: true });
                          _handleSelectGroupId(g._id)
                        }}
                      />
                    {selectedGroupId == g._id ? (
                      <div
                        className="position-absolute"
                        style={{
                          width: 8,
                          height: "100%",
                          backgroundColor: Colors.blue,
                          left: 0,
                          top: 0,
                          borderTopLeftRadius : 5,
                          // borderBottomLeftRadius : 5,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
              )}
              {listGroup.map((g, i) =>
                typeof g._id === 'string' ? (
                  <div className="position-relative" style={{marginLeft: 20}}>
                    <MyButton
                      key={g._id}
                      text={g.groupName}
                      customColor={
                        selectedGroupId == g._id ? theme === "dark" ? "#1E254C" : "#0d6efd" : "transparent"
                      }
                      style={{
                        width: "100%",
                        borderBottomColor:
                          selectedGroupId == g._id ? "" : theme === "dark" ? "#ffffff23" : "#00000023",
                        borderRadius: "none",
                      }}
                      className={`${
                        theme === "dark" ?
                        selectedGroupId == g._id ? "text-white" : "text-white-50" 
                        : selectedGroupId == g._id ? "text-white" : "text-black-50"
                      } fw-bold py-3`}
                      onClick={() => {
                        navigate(`/group-view?groupId=${g._id}&view=${selectedView}`, { replace: true });
                        _handleSelectGroupId(g._id)
                      }}
                    />
                    {selectedGroupId == g._id ? (
                      <div
                        className="position-absolute"
                        style={{
                          width: 8,
                          height: "100%",
                          backgroundColor: Colors.blue,
                          left: 0,
                          top: 0,
                          // borderTopLeftRadius : 5,
                          // borderBottomLeftRadius : 5,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
              )}
            </div>
            
          </div>
        </div>

        <AddNewGroupModal
          newGroupName={newGroupName}
          _handleNewGroupName={_handleNewGroupName}
          addNewGroup={addNewGroup}
        />
      </Fragment>
    );
  })
);

export default SideBar;
