import login from "./login";
import common from "./common";
import dashboard from "./dashboard";
import survey from "./survey";
import tracking from "./tracking";
import manageUsers from "./manageUsers";
import building from "./building";
import target from "./target";
import specialOneTime from "./specialOneTime";
import siteRelevant from './siteRelevant';
import siteManagement from "./siteManagement";
import esg from "./esg"
import kpiManagement from "./kpiManagement";
import trackingDetail from "./trackingDetail";
import audit from "./audit";
import quickOrganizationSetUp from "./quickOrganizationSetUp";
import groupmanagement from "./groupmanagement";
import analysisResult from './analysisReport'
import reportConfigure from './report_configure'
import pageSearch from "./pageSearch";
import historyData from "./historyData";

class Store {
  login = null;
  common = null;
  dashboard = null;
  survey = null;
  tracking = null;
  manageUsers = null;
  building = null;
  target = null;
  specialOneTime = null;
  siteRelevant = null;
  siteManagement = null;
  esg = null;
  kpiManagement = null;
  trackingDetail = null;
  audit = null;
  quickOrganizationSetUp = null;
  groupmanagement = null;
  analysisResult = null;
  reportConfigure = null;
  historyData = null;

  constructor(login, common, dashboard, survey, tracking, manageUsers,building,target,specialOneTime, siteRelevant, siteManagement, esg, kpiManagement, trackingDetail, audit, quickOrganizationSetUp, groupmanagement, analysisResult,reportConfigure, pageSearch, historyData) {
    this.login = login;
    this.common = common;
    this.dashboard = dashboard;
    this.survey = survey;
    this.tracking = tracking;
    this.manageUsers = manageUsers;
    this.building = building;
    this.target = target;
    this.specialOneTime = specialOneTime;
    this.siteRelevant = siteRelevant;
    this.siteManagement = siteManagement;
    this.esg = esg;
    this.kpiManagement = kpiManagement;
    this.trackingDetail = trackingDetail;
    this.audit = audit;
    this.quickOrganizationSetUp = quickOrganizationSetUp;
    this.groupmanagement = groupmanagement;
    this.analysisResult = analysisResult;
    this.reportConfigure = reportConfigure;
    this.pageSearch = pageSearch
    this.historyData = historyData
  }
}



export default new Store(login, common, dashboard, survey, tracking, manageUsers,building,target,specialOneTime, siteRelevant, siteManagement, esg, kpiManagement, trackingDetail, audit, quickOrganizationSetUp, groupmanagement, analysisResult,reportConfigure, pageSearch, historyData);

