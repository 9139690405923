import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import PlusMinusDark from '../../Assets/images/plus-minus-dark.png'
import PlusMinusWhite from '../../Assets/images/plus-minus-white.png'
import { useTranslation } from "react-i18next";

export const AnalysisResutTable = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { analysisReportData, reportTblLoading } = props.store.analysisResult;
        const { t } = useTranslation("analysisReport");
        const tEquip = useTranslation("equipment");
        const tableCellStyle = { borderLeft: 0, height: 40, backgroundColor: "#020845" }
        const tableCellClassName = theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'
        return (
            <TableContainer component={Paper} className="emission-table-container" style={{ maxHeight: '65vh', borderRadius: 4 }}>
                <Table stickyHeader aria-label="customized table" style={{ color: Colors[theme].textColor }}>
                    <TableHead>
                        <StyledTableRow>
                            <TableCell sx={{}} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0, textAlign: 'start', paddingLeft: 8 }}>{t("Emission Source")}</TableCell>
                            <TableCell sx={{}} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0 }}>{t("Scope")}</TableCell>
                            <TableCell sx={{}} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0 }}>{t("Emission(kgCO2e)")}</TableCell>
                            <TableCell sx={{}} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0 }}>{t("Proportion")}</TableCell>
                            <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} colSpan="2" style={{ borderLeft: 0, borderRight: 0, position: 'sticky' }}>{t("Emission Factor")}</TableCell>
                            <TableCell sx={{}} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderRight: 0 }}>{t("Integration Uncertainty(%)")}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderRight: 0, borderLeft: 0, top: 34 }}>
                                {t("Uncertainty (%)")}
                            </TableCell>
                            <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderRight: 0, width: 200, top: 34 }}>
                                {t("Source")}
                            </TableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reportTblLoading ?
                                <StyledTableRow>
                                    <TableCell colSpan={'7'} className={tableCellClassName} style={tableCellStyle}>
                                        <i className="spinner-border mt-2" />
                                    </TableCell>
                                </StyledTableRow>
                                :
                                analysisReportData.length > 0 ?
                                analysisReportData.map((v, k) => {
                                    return (
                                        <StyledTableRow key={'k'}>
                                            <TableCell className={tableCellClassName} style={{ ...tableCellStyle, textAlign: 'start', paddingLeft: 8 }}>
                                                {tEquip.t(v._id)}
                                            </TableCell>
                                            <TableCell className={tableCellClassName} style={tableCellStyle}>
                                                {t('Scope')+' ' + v.scope}
                                            </TableCell>
                                            <TableCell className={tableCellClassName} style={tableCellStyle}>
                                                {(v.total).toLocaleString()}
                                            </TableCell>
                                            <TableCell className={tableCellClassName} style={tableCellStyle}>
                                                {v.percentage?.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={tableCellClassName} style={tableCellStyle}>
                                                <img className="me-1" width={11} src={theme === 'dark' ? PlusMinusWhite : PlusMinusDark} />{v.uncertainty + '%'}
                                            </TableCell>
                                            <TableCell className={tableCellClassName} style={{ ...tableCellStyle, textAlign: 'start', paddingLeft: 8 }}>
                                                {v.source || 'IPCC AR5'}
                                            </TableCell>
                                            <TableCell className={tableCellClassName} style={tableCellStyle}>
                                                <img className="me-1" width={11} src={theme === 'dark' ? PlusMinusWhite : PlusMinusDark} />{v.integrateUncertainty?.toFixed(2) + '%'}
                                            </TableCell>

                                        </StyledTableRow>
                                    )
                                })
                                :
                                <StyledTableRow>
                                    <TableCell colSpan={'7'} className={tableCellClassName} style={tableCellStyle}>
                                        {t("No Data Found.")}
                                    </TableCell>
                                </StyledTableRow>
                        }
                    </TableBody>
                    <TableFooter style={{ position: 'sticky', bottom: '0px' }}>
                        {
                            analysisReportData.length > 0 ?
                                <StyledTableRow>
                                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderLeft: 0, textAlign: 'start', paddingLeft: 8 }}>{t("Uncertain Analysis Emission1")}</TableCell>
                                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderLeft: 0 }}>
                                        {
                                            ([...new Set(analysisReportData.map(v => (t('Scope')+' ' + v.scope)))]).sort()?.join(', ')
                                        }
                                    </TableCell>
                                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderLeft: 0 }}>
                                        {analysisReportData?.reduce((r, c) => { return r + c.total }, 0)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderLeft: 0 }}>
                                        {analysisReportData?.reduce((r, c) => { return r + c.percentage }, 0)?.toFixed(2)}
                                    </TableCell>
                                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} colSpan="2" style={{ borderLeft: 0, position: 'sticky' }}>{t("Average Emission total uncertainty")}</TableCell>
                                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} style={{ borderLeft: 0 }}>
                                        <img className="me-1" width={11} src={theme === 'dark' ? PlusMinusWhite : PlusMinusDark} />{((analysisReportData?.reduce((r, c) => { return r + c.integrateUncertainty }, 0))/analysisReportData.length)?.toFixed(2) + '%'}
                                    </TableCell>
                                </StyledTableRow>
                                : null
                        }
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    })
)

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    'td, th': {
        border: '1px solid #8C8C8C41',
        textAlign: 'center',
        padding: '5px',
    },
    '&:first-child th': {
        borderTop: 0
    },
    'td': {
        borderBottom: 0,
        height: '53px'
    },
    'th': {
        height: '30px',
    }
}));
