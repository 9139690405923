import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyDatepicker, MyInput, Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const MultiMCSetupModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { scope0AnsRow, _handleChangeS0Input, _handleSelectS0, _handleChangeS0Date, _handleSubmitScope0 } = props.store.survey;
        const { t } = useTranslation("equipment");

        return (
            <div
                className={`modal fade ${theme}`}
                id="scope0Modal"
                tabIndex="-1"
                aria-labelledby="scope0ModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>{t("Add Data for Scope 0")}</h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <TableComponent
                                theme={theme}
                                data={scope0AnsRow}
                                _handleChangeS0Input={_handleChangeS0Input}
                                _handleSelectS0={_handleSelectS0}
                                _handleChangeS0Date={_handleChangeS0Date}
                            />
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span id='save_scope0'>
                                <MyButton
                                    onClick={_handleSubmitScope0}
                                    style={{ width: 200 }}
                                    text={t("Save")}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

const fields = [
    'Activity Type', 'Unit', 'Fuel Type', 'Vehicle Type', 'Model', 'Name', 'Commissioned Date'
]

const TableComponent = (props) => {
    const { t } = useTranslation("equipment");
    const { theme, data, _handleChangeS0Input, _handleSelectS0, _handleChangeS0Date, _handleDeleteScope0AnsRow } = props;
    const deleteMode = data.find(v => v.deleteMode === true)?.deleteMode;
    return (
        <div
            className="table-responsive"
            style={{
                borderRadius: 10,
                // border: " 1px solid",
                paddingBottom: 100
            }}
        >
            <table
                className="table mb-0"
                style={{ color: Colors[theme].textColor, borderColor: "transparent" }}
            >
                <thead>
                    <tr>
                        {
                            fields.map((v, k) => {
                                return (
                                    <th
                                        key={k}
                                        className="text-center"
                                        style={{
                                            width: "200px",
                                            borderLeft: k > 0 ? " 1px solid" : "none",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        {t(v)}
                                    </th>
                                )
                            })
                        }
                        {/* {
                            deleteMode ? 
                            <th 
                            style={{
                                width: "60px",
                                borderLeft: " 1px solid",
                                borderLeftColor:
                                    theme === "dark"
                                        ? "rgb(255, 255, 255,0.1)"
                                        : Colors.Dark[4],
                            }}
                            />
                            : null
                        } */}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((v, k) => {

                            return (
                                <tr
                                    key={k}
                                    style={{
                                        borderTop: " 1px solid",
                                        borderTopColor:
                                            theme === "dark"
                                                ? "rgb(255, 255, 255,0.1)"
                                                : Colors.Dark[4],
                                    }}
                                >
                                    <td
                                        style={{
                                            minWidth: 100,
                                        }}
                                    >
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={activityTypeOption}
                                            value={
                                                activityTypeOption.find(
                                                    (dt) => dt.label === v.activityType
                                                ) || ""
                                            }
                                            placeholder={t("Select activity type")}
                                            _handleSelect={(e) => _handleSelectS0(e, k, "activityType")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={unitOption.filter(dt => dt.activityType === v.activityType)}
                                            value={
                                                unitOption.find(
                                                    (dt) => dt.label === v.unit
                                                ) || ""
                                            }
                                            placeholder={t("Select Unit")}
                                            _handleSelect={(e) => _handleSelectS0(e, k, "unit")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={fuelTypeOption}
                                            value={
                                                fuelTypeOption.find(
                                                    (dt) => dt.label === v.fuelType
                                                ) || ""
                                            }
                                            placeholder={t("Select Fuel type")}
                                            _handleSelect={(e) => _handleSelectS0(e, k, "fuelType")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={vehicleTypeOption.filter(dt => dt.fuelType === v.fuelType)}
                                            value={
                                                vehicleTypeOption.filter(
                                                    (dt) => dt.label === v.vehicleType
                                                ) || ""
                                            }
                                            placeholder={t("Select vehicle type")}
                                            _handleSelect={(e) => _handleSelectS0(e, k, "vehicleType")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <MyInput
                                            required={true}
                                            id={"brand"+k}
                                            type={"text"}
                                            value={v.brand}
                                            placeHolder={t("Model")}
                                            onChange={(e) =>  _handleChangeS0Input(e, k, 'brand')}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <MyInput
                                            required={true}
                                            id={"equipmentName"+k}
                                            type={"text"}
                                            value={v.equipmentName}
                                            placeHolder={t("Name")}
                                            onChange={(e) => _handleChangeS0Input(e, k, 'equipmentName')}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <MyDatepicker
                                            _handleChange={(e) => _handleChangeS0Date(e, k)}
                                            value={v.commissionedDate}
                                        />
                                    </td>
                                    {/* {
                                        v.deleteMode ?
                                        <td
                                        className="text-center"
                                        style={{
                                            minWidth: 60,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                            verticalAlign: 'middle'
                                        }}
                                    >
                                        <i title="Delete" className="bi bi-trash text-danger me-2" onClick={()=>_handleDeleteScope0AnsRow()}></i>
                                        <i title="Undo" className="bi bi-arrow-counterclockwise"></i>
                                    </td>
                                    : null
                                    } */}
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

const fuelTypeOption = [
    { value: 1, label: "Motor Gasoline", category: "Mobile Combustion" },
    { value: 2, label: "Diesel Fuel", category: "Mobile Combustion" },
    { value: 3, label: "Biodiesel (100%)", category: "Mobile Combustion" },
    { value: 4, label: "Compressed Natural Gas", category: "Mobile Combustion" },
    { value: 5, label: "Ethanol (100%)", category: "Mobile Combustion" },
    { value: 6, label: "Jet Fuel", category: "Mobile Combustion" },
    { value: 7, label: "Aviation Gasoline", category: "Mobile Combustion" }
]

const activityTypeOption = [
    { value: 1, label: 'Fuel Use' },
    { value: 2, label: 'Distance Activity' },
    { value: 3, label: 'Custom emission factor' }
];

const unitOption = [
    { value: 3, label: "gal (US)", activityType: 'Fuel Use' },
    { value: 4, label: "L", activityType: 'Fuel Use' },
    { value: 5, label: "bbl", activityType: 'Fuel Use' },
    { value: 6, label: "scf", activityType: 'Fuel Use' },
    { value: 7, label: "ccf", activityType: 'Fuel Use' },
    { value: 8, label: "m³", activityType: 'Fuel Use' },

    { value: 9, label: "nautical mile", activityType: 'Distance Activity' },
    { value: 10, label: "mile", activityType: 'Distance Activity' },
    { value: 11, label: "km", activityType: 'Distance Activity' },

    { value: 12, label: "gal (US)", activityType: 'Custom emission factor' },
    { value: 13, label: "L", activityType: 'Custom emission factor' },
    { value: 14, label: "bbl", activityType: 'Custom emission factor' },
    { value: 15, label: "scf", activityType: 'Custom emission factor' },
    { value: 16, label: "ccf", activityType: 'Custom emission factor' },
    { value: 17, label: "m³", activityType: 'Custom emission factor' },
]

const vehicleTypeOption = [
    { value: 1, label: "Gasoline Passenger Cars", fuelType: "Motor Gasoline" },
    { value: 2, label: "Gasoline Light-duty Trucks (Vans, Pickup Trucks, SUVs)", fuelType: "Motor Gasoline" },
    { value: 3, label: "Gasoline Heavy-duty Vehicles", fuelType: "Motor Gasoline" },
    { value: 4, label: "Hybrid (Gasoline) Passenger Cars", fuelType: "Motor Gasoline" },
    { value: 5, label: "Gasoline Agricultural Equipment", fuelType: "Motor Gasoline" },
    { value: 6, label: "Gasoline Ships and Boats", fuelType: "Motor Gasoline" },
    { value: 7, label: "Gasoline Motorcycles", fuelType: "Motor Gasoline" },
    { value: 8, label: "Other Gasoline Non-Road Vehicles", fuelType: "Motor Gasoline" },

    { value: 9, label: "Diesel Passenger Cars", fuelType: "Diesel Fuel" },
    { value: 10, label: "Diesel Light-duty Trucks", fuelType: "Diesel Fuel" },
    { value: 11, label: "Diesel Medium- and Heavy-duty Vehicles", fuelType: "Diesel Fuel" },
    { value: 12, label: "Diesel Agricultural Equipment", fuelType: "Diesel Fuel" },
    { value: 13, label: "Diesel Ships and Boats", fuelType: "Diesel Fuel" },
    { value: 15, label: "Other Diesel Non-Road Vehicles", fuelType: "Diesel Fuel" },

    { value: 16, label: "Biodiesel Passenger Cars", fuelType: "Biodiesel (100%)" },
    { value: 17, label: "Biodiesel Light-duty Vehicles", fuelType: "Biodiesel (100%)" },
    { value: 18, label: "Biodiesel Medium- and Heavy-duty Vehicles", fuelType: "Biodiesel (100%)" },

    { value: 19, label: "CNG Light-duty Vehicles", fuelType: "Compressed Natural Gas" },
    { value: 20, label: "CNG Medium- and Heavy-duty Vehicles", fuelType: "Compressed Natural Gas" },

    { value: 21, label: "Ethanol Light-duty Vehicles", fuelType: "Ethanol (100%)" },
    { value: 22, label: "Ethanol Medium- and Heavy-duty Vehicles", fuelType: "Ethanol (100%)" },

    { value: 23, label: "Jet Fuel Aircraft", fuelType: "Jet Fuel" },

    { value: 24, label: "Aviation Gasoline Aircraft", fuelType: "Aviation Gasoline" },

    { value: 25, label: "General", fuelType: "Diesel oil" },
    { value: 26, label: "General", fuelType: "Petrol" },
]
