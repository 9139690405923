import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyDatepicker, MyInput, Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const Scope0PurchasedModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { calApproachOptions, scope0AnsRow, _handleChangeS0Input, _handleSelectS0, _handleChangeS0Date, _handleSubmitScope0 } = props.store.survey;
        const { t } = useTranslation("common");

        // console.log("scope0AnsRow >> ", toJS(scope0AnsRow));

        return (
            <div
                className={`modal fade ${theme}`}
                id="scope0Modal"
                tabIndex="-1"
                aria-labelledby="scope0ModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>{t("Add Data for Scope 0")}</h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <TableComponent
                                theme={theme}
                                data={scope0AnsRow}
                                _handleChangeS0Input={_handleChangeS0Input}
                                _handleSelectS0={_handleSelectS0}
                                _handleChangeS0Date={_handleChangeS0Date}
                                calApproachOptions={calApproachOptions}
                            />
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span id='save_scope0'>
                                <MyButton
                                    onClick={_handleSubmitScope0}
                                    style={{ width: 200 }}
                                    text={t("Save")}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

const fields = [
    'Supplier Name', 'Emission Factor', 'Calculation Approach', 'Calculate Method', 'Record Period', 'Unit'
]

const TableComponent = (props) => {
    const { t } = useTranslation("equipment");
    const { calApproachOptions, theme, data, _handleChangeS0Input, _handleSelectS0, _handleChangeS0Date, _handleDeleteScope0AnsRow } = props;
    const deleteMode = data.find(v=> v.deleteMode === true)?.deleteMode;

    return (
        <div
            className="table-responsive"
            style={{
                borderRadius: 10,
                // border: " 1px solid",
                paddingBottom: 100
            }}
        >
            <table
                className="table mb-0"
                style={{ color: Colors[theme].textColor, borderColor: "transparent" }}
            >
                <thead>
                    <tr>
                        {
                            fields.map((v, k) => {
                                return (
                                    <th
                                        key={k}
                                        className="text-center"
                                        style={{
                                            width: "200px",
                                            borderLeft: k > 0 ? " 1px solid" : "none",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        {t(v)}
                                    </th>
                                )
                            })
                        }
                        {/* {
                            deleteMode ? 
                            <th 
                            style={{
                                width: "60px",
                                borderLeft: " 1px solid",
                                borderLeftColor:
                                    theme === "dark"
                                        ? "rgb(255, 255, 255,0.1)"
                                        : Colors.Dark[4],
                            }}
                            />
                            : null
                        } */}
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((v, k) => {
                            
                            return (
                                <tr
                                    key={k}
                                    style={{
                                        borderTop: " 1px solid",
                                        borderTopColor:
                                            theme === "dark"
                                                ? "rgb(255, 255, 255,0.1)"
                                                : Colors.Dark[4],
                                    }}
                                >
                                    <td
                                        style={{
                                            minWidth: 100,
                                        }}
                                    >
                                        <MyInput
                                            required={true}
                                            id={"supplierName"+k}
                                            type={"text"}
                                            value={v.supplierName}
                                            placeHolder={t("Supplier Name")}
                                            onChange={(e) => _handleChangeS0Input(e, k, 'supplierName')}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={emissionFactorOption}
                                            value={
                                                emissionFactorOption.find(
                                                    (dt) => dt.label === v.emissionFactor
                                                ) || ""
                                            }
                                            placeholder={t("Select Emission Factor")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"emissionFactor")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={calculationApproachOption}
                                            value={
                                                calculationApproachOption.find(
                                                    (dt) => dt.label === v.calculationApproach
                                                ) || ""
                                            }
                                            placeholder={t("Select Calculation Approach")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"calculationApproach")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={calApproachOptions}
                                            value={
                                                calApproachOptions.find(
                                                    (dt) => dt.label === v.calculateMethod
                                                ) || ""
                                            }
                                            placeholder={t("Select Calculate Method")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"calculateMethod")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={recordPeriodOption}
                                            value={
                                                recordPeriodOption.find(
                                                    (dt) => dt.label === v.recordPeriod
                                                ) || ""
                                            }
                                            placeholder={t("Select Record Period")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"recordPeriod")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={unitOption}
                                            value={
                                                unitOption.find(
                                                    (dt) => dt.label === v.unit
                                                ) || ""
                                            }
                                            placeholder={t("Select Unit")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"unit")}
                                        />
                                    </td>
                                    
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

const recordPeriodOption = [
    { value: 1, label: "Yearly" },
    { value: 2, label: "Monthly" },
  ];

const emissionFactorOption = [
    { value: 1, label: "Grid Average/Location Based" },
    { value: 2, label: "Custom emission factor" },
];

const calculationApproachOption = [
    { value: "Location Based", label: "Purchased Electricity - Location Based" },
    { value: "Market Based", label: "Purchased Electricity - Market Based" },
    { value: "Steam and Heat", label: "Heat/Steam" }
  ];

const unitOption = [
    { value: 1, label: "kWh" },
  ];