const specialCharacterRegx = /[`!#$%^&*()+\-=[\]{};:"\\|<>/?~]/;
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const LoginFormValidation = ({ eMail, password }) => {
  const err = {};
  if (eMail === "" || !eMail) {
    err.userErr = "Email can't be empty!";
  } else if (!emailRegex.test(eMail)) {
    err.userErr = "Invalid email address!";
  } else if (password === "") {
    err.passwordErr = "Password can't be empty!";
  }
  return err;
};

export const CreateNewSiteValidation = (data) => {
  const err = {};
  const { siteName, email, userName, password, buildingType, frameworks } =
    data;

  if (siteName === "") {
    err.siteNameErr = "Fill Site Name!";
  } else if (specialCharacterRegx.test(siteName)) {
    err.siteNameErr = "Not Allow Special Characters!";
  }

  if (email === "") {
    err.emailErr = "Please fill Email!";
  } else if (!emailRegex.test(email)) {
    err.emailErr = `Invalid email address! ${email}`;
  }

  if (userName === "") {
    err.userNameErr = "Please fill User Name!";
  } else if (specialCharacterRegx.test(userName)) {
    err.userNameErr = "Not Allow Special Characters";
  }

  if (password === null) {
    return err;
  } else if (password === "") {
    err.passwordErr = "Please fill Password!";
  } else if (password.length < 8) {
    err.passwordErr = "Password must be minium 8 Characters";
  }

  if (buildingType === "") {
    err.buildingTypeErr = "Please select one of building type!.";
  }

  if (frameworks.length === 0) {
    err.frameworksErr = "Please select one of the rapid assessment framework!";
  }

  return err;
};

export const CreateEquipmentValidation = (data, setUp) => {
  const err = {};
  const { equipmentName, supplier , name, category, fuelType, unit, recordPeriod, brand, energySource, calculationApproach, activityType, vehicleType, model } = data;
  if(setUp === 1){
  
    if (category === "") {
      err.categoryErr = "Please select one of category!";
    }
    if (recordPeriod === "") {
      err.recordPeriodErr = "Please select one of record period!";
    }
    if(category === 'Mobile Combustion'){
      if(activityType === ""){
        err.activityTypeErr = "Please select activity type!"
      }
      if (unit === "") {
        err.unitErr = "Please fill unit!";
      }
      if (fuelType === "") {
        err.fuelTypeErr = "Please fill fuel type!";
      }
      if(vehicleType === ""){
        err.vehicleTypeErr = "Please select vehicle type!"
      }
      
      if(name === ""){
        err.nameErr = "Please fill equipmentName!"
      }
      if (brand === "") {
        err.brandErr = "Please fill model!";
      }
    }else{
      if (equipmentName === "") {
        err.equipmentNameErr = "Please fill equipment name!";
      }
      if (fuelType === "") {
        err.fuelTypeErr = "Please fill fuel type!";
      }
      if (brand === "") {
        err.brandErr = "Please fill brand!";
      }
      if (unit === "") {
        err.unitErr = "Please fill unit!";
      }
    }
    
  }else if(setUp ===2){
  
    if (category === "") {
      err.categoryErr = "Please select one of category!";
    }
    if (supplier === "") {
      err.supplierErr = "Please fill supplier name!";
    }
    if(energySource === ""){
      err.energySourceErr = "Please select one of energy source!";
    }
    if(calculationApproach === ""){
      err.calculationApproachErr = "Please select one of calculation approach!";
    }
    if (unit === "") {
      err.unitErr = "Please fill unit!";
    }
  }else if (setUp === 3){
    if (category === "") {
      err.categoryErr = "Please select one of category!";
    }
    if (activityType === "" || !activityType) {
      err.activityTypeErr = "Please select one of activity type!";
    }
    if (name === ""){
      err.nameErr = "Please select transportation name";
    }
  }
  

  // if (photo === null) {
  //   err.imageErr = "Please upload site image!";
  // }

  return err;
}

export const CreateGroupViewEquipmentValidation = (data, setUp) => {
  const err = {};
  const { carbonEmission, distance, supplierName , name,assetName,transportMode, category, fuelType, unit, recordPeriod, brand, emissionFactor, calculationApproach, activityType, vehicleType, model,equipmentType,calculateMethod,year, transportationName } = data;

  if (category === "") {
    err.categoryErr = "Please select one of category!";
  }
  if (recordPeriod === "") {
    err.recordPeriodErr = "Please select one of record period!";
  }
  if(category === 'Mobile Combustion'){
    if(activityType === "" || !activityType){
      err.activityTypeErr = "Please select activity type!"
    }else if (unit === "" || !unit) {
      err.unitErr = "Please fill unit!";
    }else if (fuelType === "" || !fuelType) {
      err.fuelTypeErr = "Please fill fuel type!";
    }else if(vehicleType === "" || !vehicleType){
      err.vehicleTypeErr = "Please select vehicle type!"
    }
    else if(assetName === "" || !assetName){
      err.nameErr = "Please fill equipmentName!"
    }else if (model === "" || !model) {
      err.brandErr = "Please fill model!";
    }
  }else if(category === "Stationary Combustion" || category === "Refrigerants and Fugitives"){
    if (assetName === "" || !assetName) {
      err.equipmentNameErr = "Please fill equipment name!";
    }else if (equipmentType === "" || !equipmentType) {
      err.equipmentTypeErr = "Please fill Euipment Type!";
    }else if (fuelType === "" || !fuelType) {
      err.fuelTypeErr = "Please fill fuel type!";
    }else if (brand === "" || !brand) {
      err.brandErr = "Please fill brand!";
    }else if (unit === "" || !unit) {
      err.unitErr = "Please fill unit!";
    }
  }else if(category === "Purchased Electricity"){
    if (supplierName === "" || !supplierName) {
      err.supplierErr = "Please fill supplier name!";
    }else if(emissionFactor === "" || !emissionFactor){
      err.emissionFactorErr = "Please select Emission Factor!";
    }else if(calculationApproach === "" || !calculationApproach){
      err.calculationApproachErr = "Please select one of calculation approach!";
    }else if(calculateMethod === "" || !calculateMethod){
      err.calculateMethodErr = "Please fill Calculate Method!";
    }else if (unit === "" || !unit) {
      err.unitErr = "Please fill unit!";
    }else if(year === "" || !year){
      err.yearErr = "Please select Year!";
    }
  }else if(category === "Upstream T&D"){
    if (transportMode === "" || !transportMode) {
      err.transportModeErr = "Please select Transport Mode!";
    }else if(vehicleType === "" || !vehicleType){
      err.vehicleTypeErr = "Please select one of Vehicle Type!";
    }else if(transportationName === "" || !transportationName){
      err.transportationNameErr = "Please fill Transportation Name!";
    }else if(activityType === "" || !activityType){
      err.activityTypeErr = "Please select Activity Type!";
    }else if (unit === "" || !unit) {
      err.unitErr = "Please fill unit!";
    }else if(year === "" || !year){
      err.yearErr = "Please select Year!";
    }
  }

  if(setUp === 1){
  
    
    
  }else if(setUp ===2){
  
    if (category === "" || !category) {
      err.categoryErr = "Please select one of category!";
    }
    if (supplierName === "" || !supplierName) {
      err.supplierErr = "Please fill supplier name!";
    }
    if(calculationApproach === "" || !calculationApproach){
      err.calculationApproachErr = "Please select one of calculation approach!";
    }
    if (unit === "" || !unit) {
      err.unitErr = "Please fill unit!";
    }
    if(category === "Purchased Electricity"){
      if(calculateMethod === "" || !calculateMethod){
        err.calculateMethodErr = "Please fill Calculate Method!";
      }
    }
  }else if (setUp === 3){
    if (category === "") {
      err.categoryErr = "Please select one of category!";
    }
    if (activityType === "" || !activityType) {
      err.activityTypeErr = "Please select one of activity type!";
    }
    if (name === ""){
      err.nameErr = "Please select transportation name";
    }
  }
  

  // if (photo === null) {
  //   err.imageErr = "Please upload site image!";
  // }

  return err;
}

export const CreateOneTimeSiteValidation = (data) => {
  const err = {};
  const { siteName, email, userName, password, buildingType, frameworks,photo } =
    data;

  if (siteName === "") {
    err.siteNameErr = "Fill Site Name!";
  } else if (specialCharacterRegx.test(siteName)) {
    err.siteNameErr = "Not Allow Special Characters!";
  }

  if (buildingType === "") {
    err.buildingTypeErr = "Please select one of building type!.";
  }

  if (frameworks.length === 0) {
    err.frameworksErr = "Please select one of the rapid assessment framework!";
  }
  // if (photo === null) {
  //   err.imageErr = "Please upload site image!";
  // }

  return err;
};

export const PillarSetupFormValidation = (data) => {
  const err = {};
  const { pillar, sub_pillar, sub_owner_title, sub_owner_name } = data;

  if(pillar ===  ""){
    err.pillarErr = "Please select one of ESG Pillar."
  }else if(sub_pillar === ""){
    err.subPillarErr = "Please select one of ESG Sub-Pillar."
  }else if(sub_owner_title === ""){
    err.subOwnerTitleErr = "Please enter Sub-Pillar Owner Title."
  }else if(sub_owner_name === ""){
    err.subOwnerNameErr = "Please enter Sub-Pillar Owner Name."
  }

  return err;
}

export const kpiManagementFormValidation = (data) => {
  const err = {};
  const {dataSource, target, kpi, objectives, baseYear, kpiOwnerTitle, kpiOwnerName, kpi_owner_title, kpi_owner_name, status,
    unitOfMeasurement, freqMeasurement, standardAlignment, keyDependency} = data;

    if(kpi === ""){
      err.kpiErr = "Please enter KPI.";
    }else if(objectives === ""){
      err.objectivesErr = "Please enter Objectives.";
    }else if(dataSource === ""){
      err.dataSourceErr ="Please enter Data Source.";
    }else if(target === ""){
     // err.targetSetErr = "Please select Target Set.";
    }else if(baseYear === ""){
      err.baseYearErr = "Please select Base Year.";
    }else if(kpiOwnerTitle === ""){
      err.ownerTitleErr = "Please select KPI Owner Title.";
    }else if(kpiOwnerName === ""){
      err.ownerNameErr = "Please select KPI Owner Name.";
    }else if(unitOfMeasurement === ""){
      err.unitErr = "Please select Unit of Measurement.";
    }else if(freqMeasurement === ""){
      err.frequencyErr = "Please select Frequency of Measurement.";
    }else if(standardAlignment === ""){
      err.standardsErr = "Please select Standards Alignment.";
    }else if(keyDependency === ""){
      err.dependenciesErr = "Please select Key Dependencies";
    }

    return err;
}

export const RegisterFormValidation = (data,formMode) => {
  const err = {};
  const { password, userName, eMail, companyName, role, email } = data;


  if (userName === "") {
    err.userNameErr = "Fill User Name!";
  } else if (specialCharacterRegx.test(userName)) {
    err.userNameErr = "Not Allow Special Characters";
  }else if (companyName === "") {
    err.companyName = "Fill Company Name!";
  }else if (eMail === "" || email === "") {
    err.emailErr = "Fill Email!";
  } else if (!emailRegex.test(eMail || email)) {
    err.emailErr = `Invalid email address! ${eMail || email}`;
  } else if (password === null) {
    return err;
  } else if (formMode !== "Editing"&&password === "") {
    err.passwordErr = "Fill Password!";
  } else if (formMode !== "Editing"&&password.length < 8) {
    err.passwordErr = "Minium 8 Characters";
  }

  if (role === null) {
    err.roleErr = "Select Role";
    // return err
  }

  return err;
};

export const CreateCustomCalApproachValidation = (data) => {
  const err = {};
  const { type, customEFName, sourceOfEmission, co2Factor } = data;

  if (type === "") {
    err.typeErr = "Please select Calculation Approach!";
  } else if (customEFName === "") {
    err.customEFNameErr = "Fill Custom Emission Factor Name!";
  } else if (sourceOfEmission === "") {
    err.sourceOfEmissionErr = "Fill Source of Emission!";
  } else if (co2Factor === "" || co2Factor === null) {
    err.co2FactorErr = "Fill Emission Value!";
  } 
  return err;
};