import LoginForm from "../../Components/LoginForm";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";

export const LoginContainer = inject("store")(
  observer((props) => {
  const {theme} = props.store.common;

  return theme && (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center">
        <LoginForm />
      </div>
    </div>
  );
  })
);

