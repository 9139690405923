import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { Colors } from "../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import { MyInput , MuiSelector } from ".";
import SurveyFileUploadingModal from "../Components/Survey/SurveyFileUploadingModal";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Upload from '@mui/icons-material/Upload';
import {FilePreviewModal} from "../Components/Survey/FilePreviewModal"

export const MonthlyDynamicTable = inject("store")(
  observer((props) => {
    const { questions,category, id, equipmentData, childSection ,muiSelector, options } = props;
    const { 
      answerArray, 
      _handleNumberChange,
      surveyFramework, 
      frameWorkIndex, 
      buildingId,
      _handleScope2DescriptionChange, 
      editedScope2Month, 
      _handleSurveyFileChange,
      _handleAnswerSubmit,
      _handleFileDownload
    } = props.store.survey;
    const { theme } = props.store.common;

    const { t } = useTranslation("common");

    const {    
      siteList,
    } = props.store.siteRelevant;    
    
    const frameworkId = surveyFramework?.[frameWorkIndex]?.framework_id;

    const uploadedFiles = [];
    if (toJS(siteList).length > 0) {
      toJS(siteList).forEach((element, index) => {        
        uploadedFiles.push({
          label: element.fileName,     
          value: element._id,
          path: element.filePath
        }) 
      });
    }  

  
    return (
      <div
        className="tableStyle table-responsive my-2 monthlyCustomTbl"
        style={{
          borderRadius: 10,
        }}
      >
        <table
          className="table mb-0 "
          style={{ color: Colors[theme].textColor, borderColor: "transparent" }}
        >
          <tr>
            <th rowSpan="2" style={{ padding: 8 }}>
              {category[0] === "Vehicle" ? t("Name") : t("Energy Source") }
            </th>
            <th
            className="px-3"
              rowSpan="2"
              style={{
                padding: 8,
                borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
              }}
            >
              {t("Unit")}
            </th>
            <th
              colSpan="12"
              className="text-center"
              style={{
                padding: 8,
                borderLeft: " 1px solid",
                borderLeftColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                borderBottom: " 1px solid",
                borderBottomColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
              }}
            >
              {category[0] === "Vehicle" ? t("Fuel Consumption") : t("Energy Usage") } - Monthly
            </th>
          </tr>

          <tr>
            {months.map((month, index) => {
              return (
                <th
                  className="text-center"
                  key={index}
                  style={{
                    width: "200px",
                    padding: 8,
                    borderLeft: " 1px solid",
                    borderLeftColor:
                      theme === "dark"
                        ? "rgb(255, 255, 255,0.1)"
                        : Colors.Dark[4],
                  }}
                >
                  {month.label}
                </th>
              );
            })}
          </tr>

          {equipmentData.map((v, k) => (
            <tr
              key={k}
              style={{
                borderTop: " 1px solid",
                borderTopColor:
                  theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
              }}
            >
              <td className="p-3">
                {v[category[0]?.toLowerCase()] ||
                  v.equipmentName ||
                  v.category ||
                  v.supplier}
              </td>
              <td
                className="px-3"
                style={{
                  padding: 8,
                  borderLeft: " 1px solid",
                  borderLeftColor:
                    theme === "dark"
                      ? "rgb(255, 255, 255,0.1)"
                      : Colors.Dark[4],
                }}
              >
                {v.unit}
              </td>
              {questions.map(
                (q, index) =>
                  index === 0 &&
                  months.map((m, mi) => (
                    <td
                      key={q._id + k + mi}
                      className="text-center px-2 pt-2"
                      style={{
                        minWidth: 100,
                        borderLeft: " 1px solid",
                        borderLeftColor:
                          theme === "dark"
                            ? "rgb(255, 255, 255,0.1)"
                            : Colors.Dark[4],
                      }}
                    >
                      {muiSelector?<p style={{ fontSize: "12px", textAlign: "left", margin: 0, padding: 0 }}>{t("Usage")}</p>:""}
                      <MyInput
                        id={q._id + k + mi}
                        type={"number"}
                        style={{
                          marginBottom: q.answer.find(qa=>qa.dynamicDataId === (v._id+'_'+mi)) ? '5px': '',
                        }}
                        onChange={(e) =>
                          _handleNumberChange(
                            e,
                            q._id,
                            childSection,
                            null,
                            index,
                            v._id + "_" + mi,
                            mi,
                            answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id + "_" + mi
                            ).length > 0
                              ? answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id + "_" + mi
                                )[0]?.linkedFileId
                              : q.answer.find(
                                  (qa) => qa.dynamicDataId === v._id + "_" + mi
                                )?.linkedFileId,
                            answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id + "_" + mi
                            ).length > 0
                              ? answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id + "_" + mi
                                )[0]?.description
                              : q.answer.find(
                                  (qa) => qa.dynamicDataId === v._id + "_" + mi
                                )?.description
                          )
                        }
                        pattern="[0-9]*"
                        //Take value from same Question ID and Dynamic ID
                        value={
                          answerArray?.filter(
                            (a) =>
                              a.questionId === q._id &&
                              a.dynamicDataId === v._id + "_" + mi
                          ).length > 0
                            ? answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                              )[0]?.answer
                            : q.answer.find(
                                (qa) => qa.dynamicDataId === v._id + "_" + mi
                              )?.answer[0]
                        }
                      />
                      {muiSelector ? 
                        (<div id="uploaded_file_muiSelector" style={{padding:0}}>
                          <p style={{ fontSize: "12px", textAlign: "left", margin: 0, padding: 0 }}>
                            {t("Data Validation File")}
                          </p>
                          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>                           
                            <MuiSelector               
                              style={{ width : '200px' }} 
                              value={options.find(o => o.value === (answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id + "_" + mi
                                  )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === (v._id+'_'+mi))?.linkedFileId))?.value === undefined?"":options.find(o => o.value === (answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id + "_" + mi
                                  ).length > 0
                                    ? answerArray?.filter(
                                        (a) =>
                                          a.questionId === q._id &&
                                          a.dynamicDataId === v._id + "_" + mi
                                      )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === (v._id+'_'+mi))?.linkedFileId))?.value}
                              options={options || []} 
                              type={"monthly_dynamic_tbl"}
                              _handleSelect = {(e) => _handleSurveyFileChange(
                                e.target.value, 
                                q._id, 
                                childSection, 
                                index, 
                                v._id + "_" + mi, 
                                mi, 
                                answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id + "_" + mi
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id + "_" + mi
                                    )[0]?.description
                                  : q.answer.find(
                                      (qa) => qa.dynamicDataId === v._id + "_" + mi
                                    )?.description,
                                answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                                  ).length > 0
                                    ? answerArray?.filter(
                                        (a) =>
                                          a.questionId === q._id &&
                                          a.dynamicDataId === v._id + "_" + mi
                                      )[0]?.answer
                                    : q.answer.find(
                                        (qa) => qa.dynamicDataId === v._id + "_" + mi
                                      )?.answer[0])
                                }                            
                            />
                            <span data-bs-toggle="modal" data-bs-target="#Scope2SurveyFileModal" style={{ cursor: "pointer" }}>
                            <button title={t("Browse your files")} onClick={(e) =>
                            _handleSurveyFileChange(
                              "uploadBtn", 
                              q._id, 
                              childSection, 
                              index, 
                              v._id + "_" + mi, 
                              mi, 
                              answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id + "_" + mi
                                  )[0]?.description
                                : q.answer.find(
                                    (qa) => qa.dynamicDataId === v._id + "_" + mi
                                  )?.description,
                              answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id + "_" + mi
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id + "_" + mi
                                    )[0]?.answer
                                  : q.answer.find(
                                      (qa) => qa.dynamicDataId === v._id + "_" + mi
                                    )?.answer[0])
                              }      style={{ marginBottom: "-5px", border: theme === "dark"? "1px solid #5e5e5e" : "1px solid #c6c6c6", minWidth: "36px !important", maxWidth: "36px !important", padding: "9px", borderRadius: "5px", background: theme === "dark"?"transparent" : "#fff"}}><Upload style={{ color: "rgb(4, 197, 0)" }}/></button>
                          </span>
                          </Stack>
                        </div>) : ''
                      }                      
                      <SurveyFileUploadingModal modalId="Scope2SurveyFileModal" btnId="scope2_save_file_and_description" frameworkId={frameworkId} buildingId={buildingId} />
                      {muiSelector ?
                        (<div id="uploaded_file_byCat" style={{padding:0, marginTop: 3}}>
                          <p style={{ fontSize: "12px", textAlign: "left", margin: 0, padding: 0 }}>
                            {t("Description of the uploaded file")}
                          </p>
                        <MyInput
                          id={q._id + k + mi + "_"}
                          required={true}
                          isTextArea={true}
                          value={answerArray?.filter(
                            (a) =>
                              a.questionId === q._id &&
                              a.dynamicDataId === v._id + "_" + mi
                          ).length > 0
                            ? answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                              )[0]?.description
                            : q.answer.find(
                                (qa) => qa.dynamicDataId === v._id + "_" + mi
                              )?.description}
                          onChange={(e) =>
                            _handleScope2DescriptionChange(
                              e, 
                              q._id, 
                              childSection, 
                              null, 
                              index, 
                              v._id + "_" + mi, 
                              mi, 
                              answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id + "_" + mi
                                  )[0]?.linkedFileId
                                : q.answer.find(
                                    (qa) => qa.dynamicDataId === v._id + "_" + mi
                                  )?.linkedFileId,
                              answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id + "_" + mi
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id + "_" + mi
                                    )[0]?.answer
                                  : q.answer.find(
                                      (qa) => qa.dynamicDataId === v._id + "_" + mi
                                    )?.answer[0])
                              }
                          />   
                          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}
                            style={{
                              padding: "5px",
                              fontSize: "12px",
                              height: "20px"
                            }}
                          >
                          <Link
                            href="#" underline="none" 
                            style={{                        
                              color: "#fff",
                              background: "#2982ff",
                              padding: "0px 4px",
                              borderRadius: "2px",
                              display: (options.find(o => o.value === (answerArray?.filter(
                                (a) =>
                                  a.questionId === q._id &&
                                  a.dynamicDataId === v._id + "_" + mi
                              ).length > 0
                                ? answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id + "_" + mi
                                  )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === (v._id+'_'+mi))?.linkedFileId))?.value === undefined?"":options.find(o => o.value === (answerArray?.filter(
                                    (a) =>
                                      a.questionId === q._id &&
                                      a.dynamicDataId === v._id + "_" + mi
                                  ).length > 0
                                    ? answerArray?.filter(
                                        (a) =>
                                          a.questionId === q._id &&
                                          a.dynamicDataId === v._id + "_" + mi
                                      )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === (v._id+'_'+mi))?.linkedFileId))?.value).length > 0 ? "block" : "none",
                            }}
                            onClick={(e) => _handleFileDownload( options.find(o => o.value === (answerArray?.filter(
                              (a) =>
                                a.questionId === q._id &&
                                a.dynamicDataId === v._id + "_" + mi
                            ).length > 0
                              ? answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id + "_" + mi
                                )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === (v._id+'_'+mi))?.linkedFileId))?.value === undefined?"":options.find(o => o.value === (answerArray?.filter(
                                  (a) =>
                                    a.questionId === q._id &&
                                    a.dynamicDataId === v._id + "_" + mi
                                ).length > 0
                                  ? answerArray?.filter(
                                      (a) =>
                                        a.questionId === q._id &&
                                        a.dynamicDataId === v._id + "_" + mi
                                    )[0]?.linkedFileId:q.answer.find(k=>k.dynamicDataId === (v._id+'_'+mi))?.linkedFileId))?.value, uploadedFiles, e)}
                            // data-bs-toggle="modal" data-bs-target="#exampleModal"
                          >
                            {t("Download")}
                          </Link>   
                               
                          <Link
                            href="#" underline="none" 
                            style={{                        
                              color: "#fff",
                              background: "#2982ff",
                              padding: "0px 4px",
                              borderRadius: "2px",
                              display: editedScope2Month.includes(v._id + "_" + mi)? "block" : "none",
                            }}
                            onClick={() => _handleAnswerSubmit(frameworkId, buildingId)}
                          >
                            {t("Save")}
                          </Link>
                        </Stack>                      
                      </div>) : ''
                      }
                    </td>
                  ))
              )}
            </tr>
          ))}
        </table>
        {/* <FilePreviewModal/>     */}
      </div>
    );
  })
);

const months = [
  { monthIndex: 0, label: "Jan" },
  { monthIndex: 1, label: "Feb" },
  { monthIndex: 3, label: "Mar" },
  { monthIndex: 4, label: "Apr" },
  { monthIndex: 5, label: "May" },
  { monthIndex: 6, label: "Jun" },
  { monthIndex: 7, label: "Jul" },
  { monthIndex: 8, label: "Aug" },
  { monthIndex: 9, label: "Sept" },
  { monthIndex: 10, label: "Oct" },
  { monthIndex: 11, label: "Nov" },
  { monthIndex: 12, label: "Dec" },
];
