import * as React from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Colors, invertDark } from '../../Assets/Config/color.config';
import { TableBody,IconButton,Collapse,Box } from "@mui/material";
import "./groupview.css";
import { NumberInput,MyButton } from "../../Tools";
import { toJS } from "mobx";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AddConsumptionDataModal } from "./AddConsumptionDataModal";
import { FileOverviewModal } from "./FileOverviewModal";
import { ForceDeleteConfirmDialog } from "./ForceDeleteConfirmDialog";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { baseURLImage } from '../../API/URL';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  'td, th': {
    border: '1px solid #8C8C8C41',
    textAlign: 'center',
    padding: '5px',

  },
  '&:first-child th': {
    borderTop: 0
  },
  'td':{
    borderBottom: 0,
    height: '53px'
  },
  'th': {
    height: '30px',
  }
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right-start" arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
      color: '#f5f5f9',
  }
}));

export const ConsumptionTables = inject("store")(
  observer((props) => { 
    const { t } = useTranslation("assetSetupAndGroup");
    const t1 = useTranslation("common");
    const tMonth = useTranslation("month");
      
    const {yearOptions} = props.store.common;
    const { selectedView, data, theme, index, selectedGroupId } = props;    
    const tableCellStyle = { border: 0, color: invertDark(theme), padding: '10px 13px' };
    const { 
            editTypes, 
            _handleComsumptionChange, 
            months, 
            selectedGroupData,
            _getCompanyFileList,
            companyFileList,
            _handleChangeCompanyFile,
            companyFile,
            _handleChangeCompanyFileName,
            _handleCompanyFileUpload,
            _handleChangeCompanyFileType, 
            _handleChangeRemark,
            _handleChangeCompanyYear,
            _handleSelectedRowIndex,
            showForceDeleteConfirmDialog,
            dataEntry,
            _handleChangeDataEntryDate,
            _handleChangeDataEntrySelectedFile,
            _handleChangeDataEntryValue,
            _handleChangeDataEntryAssetId,
            addConsumptionData
          } = props.store.groupmanagement;
    
    const HoverText = (props) => {
      const {value, hoverIndex} = props;
      const indexValue =  toJS(selectedGroupData[index]?.data[hoverIndex]);

      return (
          <HtmlTooltip
              title={
              <React.Fragment>
                  <>
                      <div className="row d-flex">
                          <div className="col-7">{indexValue?.equipmentType ? t("Equipment Type") + ":": t("Vehicle Type") + ":"}</div>
                          <div className="col-5">{indexValue?.equipmentType || indexValue?.vehicleType || "-"}</div>
                      </div>
                      <div className="row">
                          <div className="col-7">{t("Emission Factor")}:</div>
                          <div className="col-5">{indexValue?.emissionFactorValue || "-"}</div>
                      </div>
                      <div className="row">
                          <div className="col-7">{indexValue?.brand ? t("Brand") + ":" : t("Model") + ":"}</div>
                          <div className="col-5">{indexValue?.brand || indexValue?.model || "-"}</div>
                      </div>
                  </>
              </React.Fragment>
              }
          >
              <span key={hoverIndex} style={{cursor:"pointer"}}>
                  {value}
              </span>
          </HtmlTooltip>
      );
    };
  
  const CompanyCard = (props) => {
    const {value, index} = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <div className="w-100" style={{ background: Colors[theme].secondBackground }} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <div className="row">
            <div className="col-2 d-flex align-items-center" style={{padding: '10px 13px',minWidth: 100, border: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: open ? 0 : 4 }}>
              <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
              >
                  {
                      open ?
                          <KeyboardArrowUpIcon style={{ color: Colors.water, fontWeight: 'bold' }} /> :
                          <KeyboardArrowDownIcon style={{ color: Colors.water, fontWeight: 'bold' }} />
                  }
              </IconButton>
            </div>
            <div style={{ ...tableCellStyle, minWidth: 150 }} className="col-3 d-flex align-items-center" component="th" scope="row">
              {value.assetName || value.supplierName || value.transportationName || value.name} 
            </div>
            <div style={tableCellStyle} align="center" className="col-3 d-flex align-items-center">{t(value.unit)}</div>
            <div style={{ ...tableCellStyle }} component="th" scope="row" className="col-4 d-flex align-items-center justify-content-end pe-4">
              <div
                data-bs-toggle="modal"
                data-bs-target="#dataEntryFileUploadModal"
              >
                <button className="btn btn-sm text-white" style={{backgroundColor : Colors.water}} onClick={() => _handleChangeDataEntryAssetId(value.assetId)}>{t("ADD DATA")}</button>
              </div> 
            </div>
          </div>
          
        </div>
        <TableRow style={{ background: Colors[theme].secondBackground, padding: 0, margin: 0, borderSpacing: 0 }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, color: invertDark(theme), borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ height : 200 }}>
                
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow style={{ background: 'transparent', height: 6 }} />
      </React.Fragment>
    )
  }

  return (
    <>
      { data ? 
        (
          <div className="emission-table-main-div">
            {/* {data?.data.map((value, dIndex) => {
              return (
                <CompanyCard value={value} index={dIndex} />
              )
            })} */}
            
            <TableContainer component={Paper} className="emission-table-container" style={{overflow:"auto", maxHeight:280}}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0 }}>{t("Asset Name")}</TableCell>
                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderLeft: 0 }}>{t("Unit")}</TableCell>
                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} colSpan="12" style={{ borderRight: 0 }}>{t("Consumption")}</TableCell>
                    <TableCell className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'} rowSpan="2" style={{ borderRight: 0 }}>{t("Specification File")}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow >  
                    {months.map((month, mIndex) =>                       
                      (
                        <TableCell key={mIndex} className={theme === 'dark' ? 'table-header-cell-dark' : 'table-header-cell-white'}
                          style={{ borderLeft: mIndex === 0 ? 0 : '', borderRight: mIndex === 11 ? 0 : '', top:34 }}>
                          {tMonth.t(month.monthName)}
                        </TableCell>
                      )                     
                    )} 
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                {editTypes.includes(selectedGroupId + selectedView + data.title)?
                  data?.data.map((value, dIndex) => {
                    return (
                      <StyledTableRow  key={dIndex}>
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ borderLeft: 0, width: 150, backgroundColor: "#020845" }}> 
                          <HoverText 
                          hoverIndex = {dIndex}
                           value={value.assetName || value.supplierName || value.transportationName || value.name} 
                          />
                        </TableCell>
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ borderLeft: 0, width: 50, backgroundColor: "#020845" }}>{value.unit}</TableCell>
                        {value.consumptionData.map((consumpData, dataIndex) => 
                          (
                            <TableCell key={data.title + dIndex + "-" + dataIndex} className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'}
                              style={{ borderRight: dataIndex === 11 ? 0 : '',  backgroundColor: "#020845" }}>
                              <NumberInput style={{margin: 0}} value={consumpData.value} id={data.title + dIndex + "-" + dataIndex}
                              onChange={e => _handleComsumptionChange(e, data.title, value.assetName, consumpData.month, value.assetId, dIndex, dataIndex)} />
                            </TableCell>
                          )
                        )}
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ width: 150, backgroundColor: "#020845" }}> 
                          <div
                            data-bs-toggle="modal"
                            data-bs-target="#fileOverviewModal"
                            onClick={() => _handleSelectedRowIndex(dIndex, data.title)}
                            className={theme === 'dark' ? "refFileName-link-dark" : "refFileName-link-white"}>
                              {companyFileList?.find(file => file._id == value.assetFile)?.fileName || t("No File Uploaded")}
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    )
                  })
                  :
                  data?.data.map((row, dIndex) => {
                    return (
                      <StyledTableRow  key={dIndex}>
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ borderLeft: 0, width: 150, backgroundColor: "#020845" }}>
                          <HoverText 
                            hoverIndex={dIndex} 
                            value={row.assetName || row.supplierName || row.transportationName || row.name} 
                          />
                        </TableCell>
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ borderLeft: 0, width: 50, backgroundColor: "#020845" }}>{t1.t(row.unit)}</TableCell>
                        {row.consumptionData.map((consumpData, dataIndex) => 
                          (<TableCell style={{ borderRight: dataIndex === 11 ? 0 : '', backgroundColor: "#020845" }} key={dIndex + "-" + dataIndex} className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'}>
                            {consumpData.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </TableCell>)
                        )}
                        <TableCell className={theme === 'dark' ? 'table-body-cell-dark' : 'table-body-cell-white'} style={{ width: 150, backgroundColor: "#020845" }}>
                          {companyFileList?.find(file => file._id == row.assetFile)?.fileName || t("No File Uploaded")}
                        </TableCell>
                      </StyledTableRow>
                    )
                  })
                }                  
                </TableBody>
              </Table>
            </TableContainer>  
            {/* <AddConsumptionDataModal 
              companyFileList={companyFileList} 
              _getCompanyFileList={_getCompanyFileList} 
              _handleChangeDataEntryDate={_handleChangeDataEntryDate}
              _handleChangeDataEntrySelectedFile={_handleChangeDataEntrySelectedFile}
              _handleChangeDataEntryValue={_handleChangeDataEntryValue}
              _handleChangeCompanyYear={_handleChangeCompanyYear}
              _handleChangeCompanyFileType={_handleChangeCompanyFileType}
              dataEntry={dataEntry}
              yearOptions={yearOptions}
              addConsumptionData={addConsumptionData}
            />    */}
            <FileOverviewModal 
              companyFileList={companyFileList} 
              _getCompanyFileList={_getCompanyFileList} 
              _handleChangeCompanyFile={_handleChangeCompanyFile} 
              companyFile={companyFile} 
              _handleChangeCompanyFileName={_handleChangeCompanyFileName}
              _handleChangeRemark={_handleChangeRemark}
              _handleChangeCompanyYear={_handleChangeCompanyYear}
              yearOptions={yearOptions}
              _handleCompanyFileUpload={_handleCompanyFileUpload}
              _handleChangeCompanyFileType={_handleChangeCompanyFileType}
            />  
            <ForceDeleteConfirmDialog
              showDialog={showForceDeleteConfirmDialog}
            />     
          </div>
        )
        :
        ("")
      }      
    </>
  )
}))