import { inject, observer } from "mobx-react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles, styled } from "@mui/styles";
import { format } from "date-fns";

export const HistoryDataView = inject("store")(
    observer((props) => {
        const { histroyTableData, _getHistoryData } = props.store.historyData;
        const { theme } = props.store.common;
        const { rightArrow } = props;
        const { t } = useTranslation("dashboard");

        console.log("histroyTableData", histroyTableData)
        //const originTheme = createTheme();

        useEffect(() => {
            _getHistoryData();
        }, []);

        const useStyles = () => makeStyles({
            table: {
                //minWidth: 800,
                width: "100%",
                borderSpacing: "0px 3px",
                // minHeight: 328
            },
        });

        const classes = useStyles();

        const StyledTableHeaderCell = styled(TableCell)(({ origin_theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme === "dark" ? Colors.lightBlue : Colors.water,
                color: Colors.Dark[5],
                fontWeight: "bold",
                //padding: "8px 13px 8px 13px",
                textAlign: "start",
            }
        }));

        const StyledTableCell = styled(TableCell)((origin_theme) => ({
            [`&.${tableCellClasses.body}`]: {
                color: invertDark(theme),
                fontSize: "12px",
                //padding: "10px 13px 10px 13px",
                textAlign: "center",
            }
        }))


        return (
            <div className="cardStyle col" style={{ color: Colors[theme].textColor, marginBottom: 15 }}>
                <span className="d-flex justify-content-between">
                    <span className="cardTitle">{t("History Data")}</span>
                    <span style={{ color: Colors.water }}>
                        {t("See More")}
                        <img src={rightArrow} className="ps-1" />
                    </span>
                </span>
                <hr className="my-2" />
                {/* <div style={{ height: 170, overflowY: 'scroll' }}> */}
                <div style={{height: 350, overflow: 'hidden'}}>
                <Paper sx={{ width: '100%', overflow: 'hidden', background: "transparent", boxShadow: "none" }}>
                <TableContainer  id="tblContainer" sx={{ maxHeight: 350 }}
                    style={{ }}>
                    <Table 
                        stickyHeader
                        aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeaderCell>{t("Entry Date")}</StyledTableHeaderCell>
                                <StyledTableHeaderCell>{t("Activity Log")}</StyledTableHeaderCell>
                                <StyledTableHeaderCell>{t("Value")}</StyledTableHeaderCell>
                                <StyledTableHeaderCell>{t("Status")}</StyledTableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                histroyTableData.map((row, key) => {
                                    return (<TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell scope="row">
                                            { format(new Date(row.updatedDate), "dd-MMM-yyyy HH:mm")}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.message}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.updatedCategory == "Asset Consumption" || row.updatedCategory == "Asset Emission" ? row.updatedValue : ""}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.type}
                                        </StyledTableCell>
                                    </TableRow>)
                                }
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Paper>
                </div>
                {/* </div> */}
            </div>
        )
    }))