import { MyCircularSlider } from "../../Tools";
import { Colors } from "../../Assets/Config/color.config";
import SubstainableProduct from "../../Assets/images/planet-earth.svg";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';


export const SubstainableUsage = inject("store")(
  observer((props) => {
    const { selectedTargetData } = props.store.target;
    const { theme } = props.store.common;
    const { t } = useTranslation("target_setting");
    return (
      <div className={`cardStyle col-12 col-xl-6 mt-4 ${(window.screen.width > 1200 && window.screen.width < 1600) && 'col-xl-12'}`}>
        <div
          style={{ fontSize: 20, color: Colors[theme].textColor }}
        >
          <img
            width={45}
            className="pe-3"
            src={SubstainableProduct}
          />
          { t("Sustainable usage for construction") }
        </div>
        <div className="my-3 d-flex justify-content-around flex-wrap">
          {selectedTargetData && selectedTargetData.construction.map((value, key) => (
            <div className="m-2" key = {key}>
              <MyCircularSlider k={key} value={value.progress} label={value.year}
                color={value.isCurrent ? Colors.greenMaterial : Colors.energy} knobSize={value.isCurrent? 42 : 0} theme={theme} />
            </div>
          ))}
        </div>
      </div>
    );
  })
)
