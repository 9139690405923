import { toJS } from "mobx";
import { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyDatepicker, MyInput, Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const Scope0Modal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { scope0AnsRow, _handleChangeS0Input, _handleSelectS0, _handleChangeS0Date, _handleSubmitScope0, deletedDevice, _handleDeleteScope0Device} = props.store.survey;
        const { t } = useTranslation("common");

        return (
            <div
                className={`modal fade ${theme}`}
                id="scope0Modal"
                tabIndex="-1"
                aria-labelledby="scope0ModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme)
                        }}
                    >
                        <div className="modal-header">
                            <h4>{t("Add Data for Scope 0")}</h4>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></i>
                        </div>
                        <div className="modal-body">
                            <TableComponent
                                theme={theme}
                                data={scope0AnsRow}
                                _handleChangeS0Input={_handleChangeS0Input}
                                _handleSelectS0={_handleSelectS0}
                                _handleChangeS0Date={_handleChangeS0Date}
                                deletedDevice={deletedDevice}
                                _handleDeleteScope0Device = {_handleDeleteScope0Device}
                            />
                        </div>
                        <div className="modal-footer mx-3 d-flex justify-content-end">
                            <span id='save_scope0'>
                                <MyButton
                                    onClick={_handleSubmitScope0}
                                    style={{ width: 200 }}
                                    text={t("Save")}
                                    customColor="#066CFF"
                                    customHoverColor={Colors.water}
                                />
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
)

const fields = [
    'Name', 'Equipment Type', 'Fuel Type', 'Brand', 'Unit', 'Commissioned Date'
]

const TableComponent = (props) => {
    const { t } = useTranslation("equipment");
    const { theme, data, _handleChangeS0Input, _handleSelectS0, _handleChangeS0Date, _handleDeleteScope0AnsRow,  deletedDevice, _handleDeleteScope0Device } = props;
    const deleteMode = data.find(v=> v.deleteMode === true)?.deleteMode;


    const _handleClick = (id, k) => {
      const trr = document.getElementById(id);
      trr.querySelector("td:nth-child(1)").style.opacity = 0.3;
      trr.querySelector("td:nth-child(2)").style.opacity = 0.3;
      trr.querySelector("td:nth-child(3)").style.opacity = 0.3;
      trr.querySelector("td:nth-child(4)").style.opacity = 0.3;
      trr.querySelector("td:nth-child(5)").style.opacity = 0.3;
      trr.querySelector("td:nth-child(6)").style.opacity = 0.3;
      trr.querySelector("td:nth-child(7)").style.opacity = 0.3; 
    }
    
    return (
        <div
            className="table-responsive"
            style={{
                borderRadius: 10,
                // border: " 1px solid",
                paddingBottom: 100
            }}
        >
            <table
                className="table mb-0"
                style={{ color: Colors[theme].textColor, borderColor: "transparent" }}
            >
                <thead>
                    <tr>
                        {
                            fields.map((v, k) => {
                                return (
                                    <th
                                        key={k}
                                        className="text-center"
                                        style={{
                                            width: "200px",
                                            borderLeft: k > 0 ? " 1px solid" : "none",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        {t(v)}
                                    </th>
                                )
                            })
                        }
                            <th 
                            style={{
                                width: "60px",
                                borderLeft: " 1px solid",
                                borderLeftColor:
                                    theme === "dark"
                                        ? "rgb(255, 255, 255,0.1)"
                                        : Colors.Dark[4],
                            }}
                            />
                            
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((v, k) => {
                            
                            return (
                                <tr id={v._id}
                                    key={k}
                                    style={{
                                        borderTop: " 1px solid",
                                        borderTopColor:
                                            theme === "dark"
                                                ? "rgb(255, 255, 255,0.1)"
                                                : Colors.Dark[4],
                                    }}
                                >
                                    <td
                                        style={{
                                            minWidth: 100,
                                        }}
                                    >
                                        <MyInput
                                            required={true}
                                            id={"equipmentName"+k}
                                            type={"text"}
                                            value={v.equipmentName}
                                            placeHolder={t("Equipment Name")}
                                            onChange={(e) => _handleChangeS0Input(e, k, 'equipmentName')}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <MyInput
                                            required={true}
                                            id={"deviceType"+k}
                                            type={"text"}
                                            value={t(v.deviceType)}
                                            onChange={()=> console.log('')}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={fuelTypeOption}
                                            value={
                                                fuelTypeOption.find(
                                                    (dt) => dt.label === v.fuelType
                                                ) || ""
                                            }
                                            placeholder={t("Select Fuel type")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"fuelType")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <MyInput
                                            required={true}
                                            id={"brand"+k}
                                            type={"text"}
                                            value={v.brand}
                                            placeHolder={t("Brand")}
                                            onChange={(e) =>  _handleChangeS0Input(e, k, 'brand')}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <Selector
                                        getOptionLabel={(e) => (
                                            <div style={{ display: "flex", textAlign: "left" }}>
                                              <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                            </div>
                                          )}
                                            menuListColor={Colors.Dark[0]}
                                            options={unitOption}
                                            value={
                                                unitOption.find(
                                                    (dt) => dt.label === v.unit
                                                ) || ""
                                            }
                                            placeholder={t("Select Unit")}
                                        _handleSelect={(e) => _handleSelectS0(e,k,"unit")}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            minWidth: 100,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                        }}
                                    >
                                        <MyDatepicker
                                            _handleChange={(e) => _handleChangeS0Date(e, k)}
                                            value={v.commissionedDate}
                                        />
                                    </td>
                                    {/* {
                                        !v.deleteMode ? */}
                                        <td onClick={()=>_handleClick(v._id, k)}
                                        className="text-center"
                                        style={{
                                            minWidth: 60,
                                            borderLeft: " 1px solid",
                                            borderLeftColor:
                                                theme === "dark"
                                                    ? "rgb(255, 255, 255,0.1)"
                                                    : Colors.Dark[4],
                                            verticalAlign: 'middle'
                                        }}
                                    >
                                      {/* {deletedDevice.find(deviceId => deviceId === v._id)?
                                      <i title="Undo" className="bi bi-arrow-counterclockwise"></i>: */}
                                      {v._id?<i title="Delete" className="bi bi-trash text-danger me-2" style={{cursor: "pointer"}} onClick={()=>_handleDeleteScope0Device(v._id)}></i>:""}
                                      
                        {/* } */}
                                    </td>
                                    {/* : null
                                    } */}
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

const fuelTypeOption = [
    { value: 8, label: "Anthracite Coal", category: "Stationary Combustion" },
    { value: 9, label: "Bituminous Coal", category: "Stationary Combustion" },
    { value: 10, label: "Sub-bituminous Coal", category: "Stationary Combustion" },
    { value: 11, label: "Lignite Coal", category: "Stationary Combustion" },
    { value: 12, label: "Mixed (Commercial Sector)", category: "Stationary Combustion" },
    { value: 13, label: "Mixed (Electric Power Sector)", category: "Stationary Combustion" },
    { value: 14, label: "Mixed (Industrial Coking)", category: "Stationary Combustion" },
    { value: 15, label: "Mixed (Industrial Sector)", category: "Stationary Combustion" },
    { value: 16, label: "Coal Coke", category: "Stationary Combustion" },
    { value: 17, label: "Municipal Solid Waste", category: "Stationary Combustion" },
    { value: 18, label: "Petroleum Coke (Solid)", category: "Stationary Combustion" },
    { value: 19, label: "Plastics", category: "Stationary Combustion" },
    { value: 20, label: "Tires", category: "Stationary Combustion" },
    { value: 21, label: "Agricultural Byproducts", category: "Stationary Combustion" },
    { value: 22, label: "Peat", category: "Stationary Combustion" },
    { value: 23, label: "Solid Byproducts", category: "Stationary Combustion" },
    { value: 24, label: "Wood and Wood Residuals", category: "Stationary Combustion" },
    { value: 25, label: "Natural Gas", category: "Stationary Combustion" },
    { value: 26, label: "Blast Furnace Gas", category: "Stationary Combustion" },
    { value: 28, label: "Coke Oven Gas", category: "Stationary Combustion" },
    { value: 29, label: "Fuel Gas", category: "Stationary Combustion" },
    { value: 30, label: "Propane Gas", category: "Stationary Combustion" },
    { value: 31, label: "Landfill Gas", category: "Stationary Combustion" },
    { value: 32, label: "Other Biomass Gases", category: "Stationary Combustion" },
    { value: 33, label: "Asphalt and Road Oil", category: "Stationary Combustion" },
    { value: 34, label: "Aviation Gasoline", category: "Stationary Combustion" },
    { value: 35, label: "Butane", category: "Stationary Combustion" },
    { value: 36, label: "Butylene", category: "Stationary Combustion" },
    { value: 37, label: "Crude Oil", category: "Stationary Combustion" },
    { value: 38, label: "Distillate Fuel Oil No. 1", category: "Stationary Combustion" },
    { value: 39, label: "Distillate Fuel Oil No. 2", category: "Stationary Combustion" },
    { value: 40, label: "Distillate Fuel Oil No. 4", category: "Stationary Combustion" },
    { value: 41, label: "Ethane", category: "Stationary Combustion" },
    { value: 42, label: "Ethylene", category: "Stationary Combustion" },
    { value: 43, label: "Heavy Gas Oils", category: "Stationary Combustion" },
    { value: 44, label: "Isobutane", category: "Stationary Combustion" },
    { value: 45, label: "Isobutylene", category: "Stationary Combustion" },
    { value: 46, label: "Kerosene", category: "Stationary Combustion" },
    { value: 47, label: "Kerosene-Type Jet Fuel", category: "Stationary Combustion" },
    { value: 48, label: "Liquefied Petroleum Gases (LPG)", category: "Stationary Combustion" },
    { value: 49, label: "Lubricants", category: "Stationary Combustion" },
    { value: 50, label: "Motor Gasoline", category: "Stationary Combustion" },
    { value: 51, label: "Naphtha (<401 deg F)", category: "Stationary Combustion" },
    { value: 52, label: "Natural Gasoline", category: "Stationary Combustion" },
    { value: 53, label: "Other Oil (>401 deg F)", category: "Stationary Combustion" },
    { value: 54, label: "Pentanes Plus", category: "Stationary Combustion" },
    { value: 55, label: "Petrochemical Feedstocks", category: "Stationary Combustion" },
    { value: 56, label: "Petroleum Coke", category: "Stationary Combustion" },
    { value: 57, label: "Propane", category: "Stationary Combustion" },
    { value: 58, label: "Propylene", category: "Stationary Combustion" },
    { value: 59, label: "Residual Fuel Oil No. 5", category: "Stationary Combustion" },
    { value: 60, label: "Residual Fuel Oil No. 6", category: "Stationary Combustion" },
    { value: 61, label: "Special Naphtha", category: "Stationary Combustion" },
    { value: 62, label: "Unfinished Oils", category: "Stationary Combustion" },
    { value: 63, label: "Used Oil", category: "Stationary Combustion" },
    { value: 64, label: "Biodiesel (100%)", category: "Stationary Combustion" },
    { value: 65, label: "Ethanol (100%)", category: "Stationary Combustion" },
    { value: 66, label: "Rendered Animal Fat", category: "Stationary Combustion" },
    { value: 67, label: "Vegetable Oil", category: "Stationary Combustion" },
]

const unitOption = [
    { value: 1, label: "L", },
    { value: 2, label: "gal (US)", },
]