import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector, MyDatepicker } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";

export const Scope1SetUpModal = inject("store")(
  observer((props) => {
    const { theme, siteModuleData, tableColumns } = props.store.common;
    const { getCalApproaches, calApproachOptions } = props.store.survey;
    const { activeDialog, selectedGroupId, _handleGetEmissionByCalculatedMethod, assetEmissionFactorValue, _handleChangeAssetData, listGroup, _handleCreateCompanySetup, companyAssetData, clearData } = props.store.groupmanagement;
    const {
      activityTypeOptions,
      vehicleTypeOptions,
      deviceTypeOptions,
      unitOptions,
      sourceDataList,
      _getEnergySourceList
    } = props.store.quickOrganizationSetUp;

    const scope = tableColumns[0]?.dataColumns.filter(c => c.cat === activeDialog)[0]?.scope;

    useEffect(() => {      
      _getEnergySourceList();
    },[])

    useEffect(() => {
      if (activeDialog === "Refrigerants and Fugitives"){
        getCalApproaches("Refrigerants", activeDialog, "scope1");
      }
    },[activeDialog])

    useEffect(()=> {
      if(
        activeDialog && scope === 1 &&
        companyAssetData[0].companyAsset[0].assets[0].fuelType &&
        companyAssetData[0].companyAsset[0].assets[0].vehicleType &&
        companyAssetData[0].companyAsset[0].assets[0].unit 
        
      ){
        _handleGetEmissionByCalculatedMethod("scope1", activeDialog );
      }
    }, [activeDialog, companyAssetData[0].companyAsset[0].assets[0].fuelType, companyAssetData[0].companyAsset[0].assets[0].vehicleType, companyAssetData[0].companyAsset[0].assets[0].unit])

    const { t } = useTranslation("equipment");


    return (
      <div
        className={`modal fade ${theme}`}
        id="scope1SetUpModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="addEquipmentModalLabel"
        aria-hidden="true" 
      >
        <div className="modal-dialog modal-lg">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme)
            }}
          >
            <div className="modal-header">
              <h4>{t("Add Data for Scope 1")}</h4>
              <i
                onClick={() => clearData()}
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"                
              ></i>
            </div>
            <div className="modal-body">
              <div className="row py-1"><div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                <div className="row d-flex">
                  <div className="col">
                    <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                      disabled
                      label={t("Category")}
                      menuListColor={Colors.Dark[0]}
                      options={categoryOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                      value={[{value: activeDialog, label: activeDialog}].filter((v) => v.label === activeDialog)}
                      placeholder={t("Select category")}
                    />
                  </div>
                </div>
              </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        disabled
                        label={t("Record Period")}
                        menuListColor={Colors.Dark[0]}
                        options={recordPeriodOption}
                        value={recordPeriodOption.filter((v) => v.label === "Monthly")}
                        placeholder={t("Select record period")}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <hr className="mt-4 mb-1" />
              {
                activeDialog === 'Refrigerants and Fugitives' ?
                  <>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Name")}
                              required={true}
                              id={"assetName"}
                              type={"text"}
                              value={companyAssetData[0].companyAsset[0].assets[0].assetName}
                              onChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.target.value, "assetName")}
                              placeHolder={t("Equipment Name")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Equipment Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(deviceTypeOptions.filter(dt => dt.category === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={deviceTypeOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].equipmentType)}
                              placeholder={t("Select equipment type")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "equipmentType")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                          <div className="row d-flex">
                            <div className="col">
                              <Selector
                                getOptionLabel={(e) => (
                                  <div style={{ display: "flex", textAlign: "left" }}>
                                    <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                  </div>
                                )}
                                label={t("Refrigerants Used")}
                                menuListColor={Colors.Dark[0]}
                                options={(sourceDataList.filter(o => o.title === activeDialog))[0].list.filter(o => o.sourceKey === "fuelType")}
                                value={(sourceDataList.filter(o => o.title === activeDialog))[0].list.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].fuelType)}
                                placeholder={t("Select Refrigerants Used")}
                                _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "fuelType")}
                              />
                            </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Brand")}
                              required={true}
                              id={"brand"}
                              type={"text"}                              
                              value={companyAssetData[0].companyAsset[0].assets[0].brand}
                              onChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.target.value, "brand")}
                              placeHolder={t("Brand")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1 align-items-end">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}                              
                              label={t("Unit")}
                              menuListColor={Colors.Dark[0]}
                              options={(unitOptions.filter(v => v.activityType === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={unitOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].unit)}
                              placeholder={t("Select Unit")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "unit")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              disabled
                              label={t("View")}
                              id={"group"}
                              type={"text"}
                              value={listGroup.filter(g => g._id === selectedGroupId)[0]?.groupName}
                            />
                          </div>
                        </div>
                      </div>                      
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1 ">
                        <div className="row d-flex">
                          <div className="col"><br />
                            <MyDatepicker
                              label={t("Commissioned Date")}
                              _handleChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e, "commissionedDate")}
                              value={companyAssetData[0].companyAsset[0].assets[0].commissionedDate}
                            />
                          </div>
                        </div>
                      </div>
                      {
                        activeDialog &&
                        companyAssetData[0].companyAsset[0].assets[0].fuelType &&
                        companyAssetData[0].companyAsset[0].assets[0].unit ?
                        <>   
                          <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                            <div className="row d-flex">
                              <div className="col">
                                  <MyInput
                                    disabled={true}
                                    label={"Emission Factor Value"}
                                    required={true}
                                    type={"number"}
                                    id={"emissionFactorValue"}                             
                                    value={assetEmissionFactorValue}
                                    placeHolder={"Emission Factor Value"}
                                  />
                              </div>
                            </div>
                          </div>        
                        </>
                        : null
                      }
                    </div>
                  </>
                : activeDialog === 'Mobile Combustion' ?
                  <>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Activity Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(activityTypeOptions.filter(at => at.category === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={activityTypeOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].activityType)}
                              placeholder={t("Select activity type")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "activityType")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Unit")}
                              menuListColor={Colors.Dark[0]}
                              options={(unitOptions.filter(v => v.activityType === companyAssetData[0].companyAsset[0].assets[0].activityType))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={unitOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].unit)}
                              placeholder={t("Select Unit")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "unit")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Fuel Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(sourceDataList.filter(o => o.title === activeDialog))[0].list.filter(o => o.sourceKey === "fuelType")}
                              value={(sourceDataList.filter(o => o.title === activeDialog))[0].list.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].fuelType)}
                              placeholder={t("Select Fuel type")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "fuelType")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Vehicle Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(vehicleTypeOptions.filter(vt => vt.fuelType === companyAssetData[0].companyAsset[0].assets[0].fuelType))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={vehicleTypeOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].vehicleType)}
                              placeholder={t("Select vehicle type")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "vehicleType")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Model")}
                              required={true}
                              id={"model"}
                              type={"text"}                              
                              value={companyAssetData[0].companyAsset[0].assets[0].model}
                              onChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.target.value, "model")}
                              placeHolder={t("Model")}
                            />                           
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              label={t("Name")}
                              required={true}
                              id={"assetName"}
                              type={"text"}                             
                              value={companyAssetData[0].companyAsset[0].assets[0].assetName}
                              onChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.target.value, "assetName")}
                              placeHolder={t("Name")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              disabled
                              label={t("View")}
                              id={"group"}
                              type={"text"}
                              value={listGroup.filter(g => g._id === selectedGroupId)[0]?.groupName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col"><br />
                            <MyDatepicker
                              label={t("Commissioned Date")}
                              _handleChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e, "commissionedDate")}
                              value={companyAssetData[0].companyAsset[0].assets[0].commissionedDate}
                            />
                          </div>
                        </div>
                      </div>                      
                    </div>
                    {
                        activeDialog &&
                        companyAssetData[0].companyAsset[0].assets[0].fuelType &&
                        companyAssetData[0].companyAsset[0].assets[0].vehicleType &&
                        companyAssetData[0].companyAsset[0].assets[0].unit ?
                        <>  
                          <div className="row py-1">
                            <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                              <div className="row d-flex">
                                <div className="col">
                                    <MyInput
                                      disabled={true}
                                      label={"Emission Factor Value"}
                                      required={true}
                                      type={"number"}
                                      id={"emissionFactorValue"}                             
                                      value={assetEmissionFactorValue}
                                      placeHolder={"Emission Factor Value"}
                                    />
                                </div>
                              </div>
                            </div>    
                          </div>    
                        </>
                        : null
                      }
                  </>
                :
                  <>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                          <MyInput
                              label={t("Name")}
                              required={true}
                              id={"assetName"}
                              type={"text"}                             
                              value={companyAssetData[0].companyAsset[0].assets[0].assetName}
                              onChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.target.value, "assetName")}
                              placeHolder={t("Equipment Name")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Equipment Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(deviceTypeOptions.filter(dt => dt.category === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={deviceTypeOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].equipmentType)}
                              placeholder={t("Select equipment type")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "equipmentType")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Fuel Type")}
                              menuListColor={Colors.Dark[0]}
                              options={(sourceDataList.filter(o => o.title === activeDialog))[0]?.list.filter(o => o.sourceKey === "fuelType")}
                              value={(sourceDataList.filter(o => o.title === activeDialog))[0]?.list.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].fuelType)}
                              placeholder={t("Select Fuel type")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "fuelType")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                          <div className="col">                             
                            <MyInput
                              label={t("Brand")}
                              required={true}
                              id={"brand"}
                              type={"text"}
                              value={companyAssetData[0].companyAsset[0].assets[0].brand}
                              onChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.target.value, "brand")}
                              placeHolder={t("Brand")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1 align-items-end">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <Selector
                              getOptionLabel={(e) => (
                                <div style={{ display: "flex", textAlign: "left" }}>
                                  <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                                </div>
                              )}
                              label={t("Unit")}
                              menuListColor={Colors.Dark[0]}
                              options={(unitOptions.filter(v => v.activityType === activeDialog))?.sort((a, b) => b.label > a.label ? -1 : 0)}
                              value={unitOptions.filter(v => v.label === companyAssetData[0].companyAsset[0].assets[0].unit)}
                              placeholder={t("Select Unit")}
                              _handleSelect={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e.label, "unit")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1 ">
                        <div className="row d-flex">
                          <div className="col">
                            <MyDatepicker
                              label={t("Commissioned Date")}
                              _handleChange={(e) => _handleChangeAssetData(1, selectedGroupId, activeDialog, e, "commissionedDate")}
                              value={companyAssetData[0].companyAsset[0].assets[0].commissionedDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                        <div className="row d-flex">
                          <div className="col">
                            <MyInput
                              disabled
                              label={t("View")}
                              id={"group"}
                              type={"text"}
                              value={listGroup.filter(g => g._id === selectedGroupId)[0]?.groupName}
                            />
                          </div>
                        </div>
                      </div>
                      {
                        activeDialog &&
                        companyAssetData[0].companyAsset[0].assets[0].fuelType &&
                        companyAssetData[0].companyAsset[0].assets[0].unit ?
                        <>
                          <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                            <div className="row d-flex">
                              <div className="col">
                                  <MyInput
                                    disabled={true}
                                    label={"Emission Factor Value"}
                                    required={true}
                                    type={"number"}
                                    id={"emissionFactorValue"}                             
                                    value={assetEmissionFactorValue}
                                    placeHolder={"Emission Factor Value"}
                                  />
                              </div>
                            </div>
                          </div>
                        </>
                        : null
                      }
                    </div>
                  </>
              }              
            </div>
            <div className="modal-footer mx-3 d-flex justify-content-end">
              <span data-bs-dismiss="modal" aria-label="Close">
                <MyButton
                  style={{ width: 200 }}
                  text={t("Cancel")}
                  customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                  customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                  onClick={() => clearData()}
                />
              </span>
              <span id={'create_asset_scope1'}>
                <MyButton
                  onClick={() => _handleCreateCompanySetup(siteModuleData._id, "create_asset_scope1")}
                  style={{ width: 200 }}
                  text={t("Add")}
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const recordPeriodOption = [
  { value: 1, label: "Yearly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Quarterly" },
];

const categoryOption = [
  { value: 1, label: "Stationary Combustion" },
  { value: 2, label: "Mobile Combustion" },
  { value: 3, label: "Refrigerants and Fugitives" }];