import { Fragment, useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Display from "../../Assets/images/Display.png";
import Form from "../../Assets/images/form.png";
import CreateNewSiteModal from "./CreateNewSiteModal";
import PlusIcon from "../../Assets/images/PlusSquareFill.svg";
import "../../App.css";
import UserIcon from "../../Assets/images/users.svg";
import { toJS } from "mobx";
import ConfirmDeleteGroupModal from "./ConfirmDeleteGroupModal";
import { baseURLImage } from "../../API/URL";
import { Cookies } from "react-cookie";
import BuildingWizard from "../../Assets/images/buildingWizard.svg";
import { LoadingPage } from "../../Page/Loading";
import { useTranslation } from "react-i18next";
import getNsTrans from "../../util/makeNsTrans.js";
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
import { AddNewGroupModal } from "../GroupView/AddNewGroupModal";
const Trans = getNsTrans("landing");

const LandingGroupComponent = inject("store")(
  observer((props) => {
    const navigate = useNavigate();
    const { t } = useTranslation("landing");
    const {
      getBuildingList,
      permittedBuilding,
      permittedBuildingLoading,
      role,
      theme,
      companyName
    } = props.store.common;
    const {
      listGroup
  } = props.store.groupmanagement;

  const listGroupData = toJS(listGroup)?.filter(v=> v._id !== -1 && v._id !== -2)

    
    useEffect(() => {
      getBuildingList();
    }, []);
    //
    return permittedBuildingLoading ? (
      <LoadingPage />
    ) : (
      <Fragment>
        {companyName === 'Digital Edge' && <div className="text-end">
          <img
            className="pe-2 pt-2"
            width={"160px"}
            src={digitalEdgeIcon}
            alt={t("Digital Edge Icon")}
          />
        </div>}
        <div className="row m-0 justify-content-center align-items-start">
          <div
            className="w-100 mt-5 px-5 py-5"
            style={{ color: Colors[theme].textColor }}
          >
            <div style={{ fontSize: 30, paddingBottom: 20 }}>
              {t("Welcome")}
            </div>
            <span>
              <Trans i18nKey="welcome_text_">
                This platform is specifically designed in accordance to the ESG
                framework,
                <br />
                which assesses the impact of the sustainability and ethical
                practices of the company.
              </Trans>
            </span>
            {/* <h6 className="text-left pt-4">{ t("Please Select a tool to start") }</h6> */}
          </div>
          <div className="d-flex px-1 justify-content-end">
            <div className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2 d-flex justify-content-center">
              <div className="mx-3" style={{ maxWidth: "600px" }}>
                <div className="d-flex flex-row justify-content-end">
                  {role === "admin" ? (
                    <>
                      {/* <div className="col-4 ps-2">
                        <div
                          className={`tableStyle d-flex align-items-center flex-column h-100 text-center px-2 ${theme === 'dark' ? 'user_management_btn' : 'user_management_light_btn'} align-items-center`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            minWidth: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => { window.location.pathname = "/site-management" }}
                        >
                          <i className="bi bi-building" style={{ fontSize: 35, marginTop:-5 }} />
                          <div className="p-1" style={{marginTop:-10}}>
                            Site Management
                          </div>
                        </div>
                      </div> */}
                      <div className="col-6 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                            }`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            width: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/quick-organization-setup";
                          }}
                        >
                          <div className="">
                            <i className="bi bi-building" style={{ fontSize: 30 }} />
                          </div>
                          <div>{t("Company SetUp")}</div>
                        </div>
                      </div>
                      {/* <div className="col-4 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                            }`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            width: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/building-and-user-registration";
                          }}
                        >
                          <div className="pt-1">
                            <img
                              src={BuildingWizard}
                              alt="building_wizard"
                              height="38px"
                              width="43px"
                            />
                          </div>
                          <div>{t("Site Wizard")}</div>
                        </div>
                      </div> */}
                      <div className="col-6 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                            } align-items-center`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            minWidth: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/user-management&creation";
                          }}
                        >
                          <div style={{ marginTop: -4 }}>
                            <img
                              src={UserIcon}
                              alt="user_icon"
                              height="45px"
                              width="45px"
                            />
                          </div>
                          <div className="p-0">{t("User Management")}</div>
                        </div>
                      </div>
                      {/* <div className="col-4 ps-2">
                        <div
                          className={`tableStyle d-flex flex-column h-100 text-center px-2 ${
                            theme === "dark"
                              ? "user_management_btn"
                              : "user_management_light_btn"
                          } align-items-center`}
                          style={{
                            borderRadius: 10,
                            cursor: "pointer",
                            minWidth: 160,
                            color: Colors[theme].textColor,
                          }}
                          onClick={() => {
                            window.location.pathname =
                              "/site-location";
                          }}
                        >
                          <div style={{ marginTop: -4 }}>
                            <img
                              src={BuildingWizard}
                              alt="user_icon"
                              height="45px"
                              width="45px"
                            />
                          </div>
                          <div className="p-0">{t("Site Location")}</div>
                        </div>
                      </div> */}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <TargetTrackingCard
            permittedBuilding={permittedBuilding}
            listGroup={listGroup}
            theme={theme}
          />
          {role !== "admin" ? (
            <RapidAssessmentCard
              permittedBuilding={permittedBuilding?.[0]}
              role={role}
              theme={theme}
            />
          ) : (
            <GroupCard theme={theme} />
          )}
        </div>
        <CreateNewSiteModal />
        <ConfirmDeleteGroupModal />
      </Fragment>
    );
  })
);

const TargetTrackingCard = (props) => {
  const { permittedBuilding, role, theme, listGroup } = props;

  const navigate = useNavigate();
  const { t } = useTranslation("landing");
  const linkURL = "/dashboard";


  return (
    <div
      id={"tracking_card_id"}
      className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2"
      style={{ color: Colors[theme].textColor }}
    >
      <div
        className="tableStyle container p-2 text-center"
        style={{
          maxWidth: 600,
          minHeight: 400,
          overflowY: "scroll",
          backgroundColor: Colors[theme].active,
          color: Colors[theme].textColor,
        }}
      >
        <div className="py-2">
          <img src={Display} alt="TrackingIcon" />
        </div>
        <h5>{t("Target Tracking")}</h5>
        <hr className="m-0" />
        <div className="px-5 py-3 text-left">
          {t("Target Tracking Description_")}
          <br />
          {/* <MyLink text={ t("Learn more") } url={"#"} /> */}
        </div>
        <div className="col mb-3 pb-1">
          <MyButton
            disabled={listGroup?.length === 0}
            text={t("Dashboard")}
            rounded
            fontSize={15}
            customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
            onClick={() =>
              role === "user"
                ? navigate(
                  linkURL +
                  "/?site=" +
                  permittedBuilding[0]._id +
                  "&survey=" +
                  permittedBuilding[0].surveyHeader[0].id +
                  "&name=" +
                  permittedBuilding[0].buildingName
                )
                : navigate(linkURL)
            }
          />
        </div>
      </div>
    </div>
  );
};

const RapidAssessmentCard = (props) => {
  const { permittedBuilding, role, theme } = props;
  const navigate = useNavigate();
  const { linkURL } = rapidAssessmentData;
  const { t } = useTranslation("landing");

  return (
    <div className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2">
      <div
        className="tableStyle container p-2 text-center"
        style={{
          maxWidth: 600,
          borderRadius: 10,
          backgroundColor: Colors[theme].active,
          color: Colors[theme].textColor,
        }}
      >
        <div className="py-2">
          <img src={Form} alt={t("Assessment")} />
        </div>
        <h5>{t("Rapid Assessment")}</h5>
        <hr className="m-0" />
        <div className="px-5 py-3 text-left">
          {t("Rapid Assessment Description_")}
          <br />
          {/* <MyLink text={ t("Learn more") } url={"#"} /> */}
        </div>
        <div className="col mb-4">
          <MyButton
            text={
              role === "viewer" ? t("Data Input Unavailable") : t("Data Input")
            }
            leftIcon={
              role === "viewer" ? (
                <i className="bi bi-exclamation-triangle-fill"></i>
              ) : null
            }
            disabled={role === "viewer" ? true : false}
            rounded
            fontSize={15}
            onClick={() => {
              navigate(
                linkURL +
                "/?site=" +
                permittedBuilding._id +
                "&survey=" +
                permittedBuilding.surveyHeader[0].id +
                "&name=" +
                permittedBuilding.buildingName
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

const GroupCard = inject("store")(
  observer((props) => {
    const navigate = useNavigate();
    const { linkURL } = rapidAssessmentData;
    const { t } = useTranslation("landing");
    const {
      theme,
      companyName,
      assetList,
      getAssetList,
    } = props.store.common;
    
    
    const {
        listGroup,
        isClickedDeleteGroup,
        setClickedDeleteGroup,
        _cancelDeleteGroup,
        deleteCheckedArray,
        setDeleteGroupChecked,
        _handleSelectGroupId,
        getAllGroups,
        selectedView,
        newGroupName,
        _handleNewGroupName,
        addNewGroup
    } = props.store.groupmanagement;

    useEffect(()=> {
      getAllGroups();
      getAssetList();
    }, [])
    const listGroupData = toJS(listGroup)?.filter(v=> v._id !== -1 && v._id !== -2)
 
    const cookies = new Cookies(); //
    const userId = cookies.get("userId");

    return (     
        <div
          id={"group-card"}
          className="col col-12 col-sm-12 col-lg-6 col-md-6 col-xl-6 py-2"
        >
          <div
            className="tableStyle container p-2 text-center position-relative"
            style={{
              maxWidth: 600,
              height: 400,
              borderRadius: 10,
              backgroundColor: Colors[theme].active,
              color: Colors[theme].textColor,
              boxShadow: '2px 4px 11px rgba(0, 0, 0, 0.1)'
            }}
          >
            <div className="d-flex flex-row justify-content-between px-2 py-1">
              <div 
                className="ps-2 d-flex align-items-center"
                style={{
                  cursor: 'pointer'
                }}
                onClick={()=> navigate(`/group-view?groupId=null&view=${selectedView}`)}
              >
                <i className="bi bi-diagram-3 pe-2" style={{ fontSize: 40 }} />
                <span style={{ fontSize: 20, fontWeight: 500 }}>
                  {t("Views")}
                </span>
              </div>
              <div className="row  d-flex align-items-center">
                {listGroupData?.length > 0 && (
                  <div
                    className="col d-flex justify-content-center align-items-center rounded text-white"
                    onClick={() => setClickedDeleteGroup(!isClickedDeleteGroup)}
                    title={t("Delete Views")}
                    style={{
                      height: 26.3,
                      width: 26.3,
                      cursor: "pointer",
                    }}
                  >
                    <i style={{ color: Colors[theme].textColor }} className="bi bi-trash3"></i>
                  </div>
                )}
                <img
                  data-bs-toggle="modal"
                  data-bs-target="#addNewGroupModal"
                  className="col"
                  src={PlusIcon}
                  alt="plus_icon"
                  style={{ cursor: "pointer" }}
                  title={t("Create New View")}
                />
              </div>
            </div>

            <hr className="m-0 mb-1" />
            <div
              className="scroll"
              style={{
                overflowY: "scroll",
                maxHeight: isClickedDeleteGroup ? 260 : 300,
              }}
            >

            <div 
              style={{
              background: theme === 'dark' ? "#2d3c86" : '#e5e5e5',
              color: invertDark(theme),
              cursor: 'pointer',
              // border: "1px solid #2d3c86",
              borderRadius: "5px",
            }}
              className={`flex-row mb-1 px-2 py-1 d-flex align-items-center ${theme}`}
              onClick={() => { navigate(`/group-view?groupId=-2&view=${selectedView}`); _handleSelectGroupId(-2) }}>
                <i className="bi bi-building pe-2" style={{ fontSize: 30 }} />
                <span style={{ fontWeight: 500 }}>
                {companyName} - {assetList.length === 0 ? t("No Assets") : t("All Assets")}
                </span>
                
            </div>

              {listGroupData ? (
                listGroupData.length === 0 ? (
                  <h5 className="pt-5 text-center">{t("No View Found!")}</h5>
                ) 
                : 
                listGroupData.length === 0 ? null : (
                  listGroupData.map((v, k) => (
                    <div
                      key={k}
                      className={`flex-row d-flex align-items-center ${theme}`}
                      style={{ overflow: "hidden", marginLeft: isClickedDeleteGroup ? 0 : 30 }}
                    >
                      {isClickedDeleteGroup && (
                        <input
                          style={{ cursor: "pointer" }}
                          type="checkbox"
                          className="col-1 m-0"
                          value={listGroupData[k]._id}
                          onClick={(e) =>
                              setDeleteGroupChecked(e.target.value, e.target.checked)
                          }
                        />
                      )}

                      <div
                        className={`d-flex flex-row justify-content-between px-2 py-1 my-1 rounded ${isClickedDeleteGroup ? "col-11" : "col-12"
                          } m-0  `}
                        style={{
                          background: Colors[theme].secondBackground,
                          color: invertDark(theme),
                          cursor: 'pointer'
                        }}
                        key={k}
                        onClick={() => {
                              !isClickedDeleteGroup &&
                              navigate(`/group-view?groupId=${v._id}&view=${selectedView}`);
                                _handleSelectGroupId(v._id)
                                
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <i className="bi bi-diagram-2-fill me-2" style={{ fontSize: 30, color: theme === 'dark'? '#90CAF9' : '#1565C0' }}></i>
                          {v.groupName}
                        </div>
                      </div>
                    </div>
                  ))
                )
              ) : (
                <div className="pt-5">
                  <i className="spinner-border mt-2" />
                </div>
              )}
            </div>
            {isClickedDeleteGroup && (
              <div className="d-flex justify-content-end m-0 p-2">
                <div>
                  <MyButton
                    text={t("Cancel")}
                    rounded
                    fontSize={15}
                    onClick={() => _cancelDeleteGroup()}
                    customColor={theme === 'dark' ? Colors.lightBlue : '#888'}
                  customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                  />
                  <span
                    data-bs-toggle={
                      deleteCheckedArray.length !== 0 ? "modal" : null
                    }
                    data-bs-target={
                      deleteCheckedArray.length !== 0
                        ? "#confirmDeleteGroupModal"
                        : null
                    }
                    style={{
                      cursor: "pointer",
                      paddingLeft: 20,
                    }}
                  >
                    <MyButton
                      disabled={deleteCheckedArray.length === 0}
                      text={t("Delete")}
                      rounded
                      fontSize={15}
                      customColor={"rgb(255 0 0 / 50%)"}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
          <AddNewGroupModal
            newGroupName={newGroupName}
            _handleNewGroupName={_handleNewGroupName}
            addNewGroup={addNewGroup}
          />
        </div>
    );
  })
);

export default LandingGroupComponent;

const rapidAssessmentData = {
  linkURL: "/rapid-assessment",
};

// process.env.PUBLIC_URL +
// `/sites/${v.buildingName
//   ?.replace(/\s/g, "")
//   .toLowerCase()}.png`
