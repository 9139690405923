import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { toJS } from "mobx";
import React, { Fragment, useEffect, useState } from "react";
import { baseURL } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import BasicEnv from "../../Assets/images/basicEnvironmentDisclosure.png";
import "../../App.css";
import { useTranslation } from 'react-i18next';

const UserTable = (props) => {
  const [modifiedUserData, setModifiedUserData] = useState([]);
  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(13);
  
  const { t } = useTranslation("user_management");

  const headCell = [
    { id: "userName", numeric: false, label: t("User") },
    { id: "email", numeric: false, label: t("Email") },
    { id: "role", numeric: false, label: t("Role") },
    { id: "buildingName", numeric: false, label: t("Site") },
    { id: "unit/floor", numeric: false, label: t("Unit or Floor") },
    { id: "frameworks", numeric: false, label: t("Frameworks") },
  ];


  const { allUsers, selectedId, _handleEditUser, theme, loading } = props;

  useEffect(() => {
    const modifyData = allUsers?.reduce((r, c) => {
      const R = [...r];
      const index = R.findIndex((d) => d.buildingId === c.buildingId);
      // 
      if (index < 0) {
        R.push({
          buildingId: c.buildingId,
          buildingName: c.buildingName,
          users: [
            {
              _id: c._id,
              userName: c.userName,
              companyName: c.companyName,
              email: c.email,
              role: c.role,
              "unit/floor": c["unit/floor"],
              frameworks: c.frameworks,
            },
          ],
        });
      } else {
        R[index].users.push({
          _id: c._id,
          userName: c.userName,
          companyName: c.companyName,
          email: c.email,
          role: c.role,
          "unit/floor": c["unit/floor"],
          frameworks: c.frameworks,
          companyName: c.companyName,
        });
      }
      return R;
    }, []);

    if (allUsers) {
      const rows = allUsers?.reduce((r, c) => {
        const R = [...r];
        const index = r.findIndex((v) => v._id === c._id);
        const unit = c["unit/floor"] ? c["unit/floor"] : "-";
        const frameworks = c.frameworks ? c.frameworks : "";
        const buildingId = c.buildingId ? c.buildingId : "";
        const units = c.units ? c.units : "";
        const buildingName = c.buildingName ? c.buildingName : "";
        if (index < 0) {
          R.push({
            _id: c._id,
            userName: c.userName,
            email: c.email,
            role: c.role,
            companyName: c.companyName,
            "unit/floor": [unit],
            frameworks: [frameworks],
            buildingId: [buildingId],
            buildingName: [buildingName],
            units: units
            // siteInfo : [
            //   {
            //     framework: frameworks,
            //     buildingId: buildingId,
            //     buildingName: buildingName,
            //     unit: unit,
            //   }
            // ]
          });
        } else {
          R[index]["unit/floor"].push(unit);
          R[index].frameworks.push(frameworks);
          R[index].buildingId.push(buildingId);
          R[index].buildingName.push(buildingName);
          // R[index].siteInfo.push({
          //   framework: frameworks,
          //   buildingId: buildingId,
          //   buildingName: buildingName,
          //   unit: unit,
          // })
        }
        return R;
      }, []);
      setRows(rows);
      setOriginalRows(rows);
    }

    setModifiedUserData(modifyData);
  }, [allUsers]);
//   useEffect(() => {
//     const targetElement1 = document.getElementById('col1')
//     targetElement1?.addEventListener('scroll', (event) => {
//       const source1 = document.getElementById('col2')
//       const source2 =document.getElementById('col3')
//       source1.scrollTo({ top: event.target.scrollTop, left: 0, })
//       source2.scrollTo({ top: event.target.scrollTop, left: 0, })
//   });

//   const targetElement2 = document.getElementById('col2')
//     targetElement2?.addEventListener('scroll', (event) => {
//       const source1 = document.getElementById('col1')
//       const source2 =document.getElementById('col3')
//       source1.scrollTo({ top: event.target.scrollTop, left: 0, })
//       source2.scrollTo({ top: event.target.scrollTop, left: 0, })
//   });

//   const targetElement3 = document.getElementById('col3')
//     targetElement3?.addEventListener('scroll', (event) => {
//       const source1 = document.getElementById('col1')
//       const source2 =document.getElementById('col2')
//       source1.scrollTo({ top: event.target.scrollTop, left: 0, })
//       source2.scrollTo({ top: event.target.scrollTop, left: 0, })
//   });

// })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = originalRows.filter((row) => {
      return (
        row.userName?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.role?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.companyName?.toLowerCase().includes(searchedVal.toLowerCase())
        // Math.round(row.fileSize * 100) / 100 == Number(searchedVal)
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setRows(originalRows);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const useStyles = makeStyles({
    table: {
      //minWidth: 800,
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: "0px 3px",
      // minHeight: 328
    },
    customTextField: {
      "& input::placeholder": {
        color: props.theme === 'dark' ? '#fff' : "black",
      },
    }
  });

  const classes = useStyles();

  const StyledTableRow = withStyles((theme) => ({
    root: {
      // borderBottom: '0.5px solid #e0e0e0',
      // height: '50px',
      maxHeight: "50px",
      // background: Colors.darkBlue,
      borderRadius: "10px",
      margin: "20px",
      "&:hover": {
        backgroundColor: `${props.theme === 'dark' ? Colors[props.theme].active : '#e6e6e6'} !important`,
      },
    },
  }))(TableRow);
 
  const handleScroll = (k) => {
    const targetElement1 = document.getElementById(`col1_${k}`)
    targetElement1?.addEventListener('scroll', (event) => {
      const source1 = document.getElementById(`col2_${k}`)
      const source2 =document.getElementById(`col3_${k}`)
      source1.scrollTo({ top: event.target.scrollTop, left: 0, })
      source2.scrollTo({ top: event.target.scrollTop, left: 0, })
  });

  const targetElement2 = document.getElementById(`col2_${k}`)
    targetElement2?.addEventListener('scroll', (event) => {
      const source1 = document.getElementById(`col1_${k}`)
      const source2 =document.getElementById(`col3_${k}`)
      source1.scrollTo({ top: event.target.scrollTop, left: 0, })
      source2.scrollTo({ top: event.target.scrollTop, left: 0, })
  });

  const targetElement3 = document.getElementById(`col3_${k}`)
    targetElement3?.addEventListener('scroll', (event) => {
      const source1 = document.getElementById(`col1_${k}`)
      const source2 =document.getElementById(`col2_${k}`)
      source1.scrollTo({ top: event.target.scrollTop, left: 0, })
      source2.scrollTo({ top: event.target.scrollTop, left: 0, })
  });
  }

  return (
    <>
      {/* <div className=" w-100" style={{ overflow: "scroll", minHeight: "443px", maxHeight: '70vh' }}>
        <div
          className="row gx-0 py-3 fw-bold"
          style={{
            background: "#131F4F",
            borderRadius: 5,
            position: "sticky",
            top: 0,
            minWidth: 680,
          }}
        >
          <div className="col-2  text-center px-1">{table_headers[0].label}</div>
          <div className="col-10 ">
            <div className="row gx-0">
              {table_headers.map(
                (v, k) =>
                  v.value !== "site" && (
                    <div className={`${v.col} ps-2`} key={k}>
                      {v.label}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>

        {modifiedUserData ? (modifiedUserData.length > 0 ? modifiedUserData.map((v, k) => (
          <div
            key={k}
            className="row gx-0 mt-1"
            style={{
              background: "#131F4F",
              borderRadius: 5,
              cursor: "pointer",
              minWidth: 680,
            }}
          >
            <div
              className="col-2 d-flex flex-column align-items-center "
              style={{
                borderRight: `1px solid rgb(255, 255, 255,0.05)`,
                // borderBottom:
                //   modifiedUserData.at(-1) !== v ? "2px solid rgb(255, 255, 255,0.1)" : "",
              }}
            >
              <div className="h-100 d-flex align-items-center">
                {v.buildingName}
              </div>
            </div>

            <div className="col-10 d-flex flex-column">
              {v.users.map((v1, k1) => {
                return (
                  <div
                    key={k1}
                    className="row gx-0 py-1"
                    style={{
                      background: formData._id === v1._id ? Colors.lightBlue : "",
                      borderBottom:
                        v.users.at(-1) === v1 && modifiedUserData.at(-1) !== v
                          ? null
                          : v.users.at(-1) !== v1
                            ? "1px solid rgb(255, 255, 255,0.05)"
                            : "",
                      color: Colors.Dark[4],
                    }}
                    onClick={() => _handleEditUser(v1, v.buildingId,)}
                  >
                    <div className="col-2 d-flex align-items-center  ps-2 ">
                      {v1.userName}
                    </div>
                    <div className="col-4 d-flex align-items-center  ps-2 ">
                      {v1.email}
                    </div>
                    <div className="col-2 d-flex align-items-center  ps-2 ">
                      {v1.role?.charAt(0).toUpperCase() + v1.role?.slice(1)}
                    </div>
                    <div className="col-2 d-flex align-items-center  ps-2 ">
                      {v1["unit/floor"] || '-'}
                    </div>
                    <div className="col-2 d-flex align-items-center  ps-2 ">

                      {v1.frameworks !== "" &&
                        v1?.frameworks?.map((value, key) => (
                          <img
                            className="mx-1"
                            src={baseURL + value.imageURL}
                            width={20}
                            key={key}
                          />
                          //<img className="mx-1" src={BasicEnv} key = {key}/>
                        ))}
                    </div>
                  </div>
                )
              }
              )}
            </div>
          </div>
        )) : <h5 className="text-center pt-5">No user found!</h5>) : <div className="w-100 text-center pt-4"><i className="spinner-border mt-2" /></div>}
      </div> */}
      <>
        <div className={`d-flex justify-content-between mb-3 ${theme}`}>
          <div className="pt-1">
            {rows && (
              <span className="fw-bold">{ t("Total Account") } : {rows.length}</span>
            )}
          </div>

          <SearchBar
            className={classes.customTextField}
            placeholder={ t("Search_") }
            style={{
              width: "30%",
              height: 35,
              background: "transparent",
              boxShadow: "none",
              borderBottom: `1px solid ${invertDark(theme)}`,
              borderRadius: 0,
            }}
            // value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
        </div>
        <TableContainer
          component={Paper}
          id="tblContainer"
          style={{ background: "transparent", boxShadow: "none", overflow: 'scroll', maxHeight: 700 }}
        >
          <Table
            id="custom_tbl"
            className={classes.table}
            stickyHeader
            aria-label="customized table"
          >
            <SortableTHead
              id={"id"}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCell}
              theme={theme}
            />
            <TableBody>
              {!loading && allUsers?.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // rows
                  .map((v, k) => {
                    // const users = {
                    //   _id: v._id,
                    //   userName: v.userName,
                    //   email: v.email,
                    //   role: v.role,
                    //   companyName: v.companyName,
                    //   buildingName: v.buildingName,
                    //   buildingId: v.buildingId,
                    //   frameworks: v.frameworks,
                    //   unit: v['unit/floor']
                    // }
                    const style = {
                      color: invertDark(theme),
                      textAlign: "left",
                      fontSize: 14,
                      padding: "5px 0px",
                      borderBottom: `1px solid ${Colors.Dark[2]}`,
                      paddingLeft: 5,
                    };
                    const subRowStyle = {
                      color: invertDark(theme),
                      textAlign: "left",
                      fontSize: 14,
                      padding: 0,
                      borderBottom: `1px solid ${Colors.Dark[2]}`,
                    }
                    return (
                      <StyledTableRow
                        key={k}
                        hover
                        onClick={() =>
                          _handleEditUser(v, "62d16bf7a17c8c59481c35d6")
                        }
                        style={{
                          cursor: "pointer",
                          background:
                          selectedId === v._id ? ( theme === 'dark' ? Colors[theme].active : '#e6e6e6') : "",
                        }}
                      >
                        <TableCell style={style}>
                          <div className="ms-2">{"test"}</div>
                        </TableCell>
                        <TableCell style={style}>{v.email}</TableCell>
                        <TableCell style={style}>{v.role}</TableCell>
                        <TableCell
                          style={{...subRowStyle, borderLeft: "1px solid #bfbfbf"}}
                        >
                          <div onScroll={()=> handleScroll(k)} id={`col1_${k}`} className='col1' style={{maxHeight: 200, overflow: 'scroll'}}>
                          {v.buildingName.map((building, b_key) => {
                            return (
                              <>
                                <div className="ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title={building}
                                style={{fontSize:14, width:"130px", textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>
                                    {building}
                                </div>
                                {v.buildingName.length - 1 !== b_key && (
                                  <hr className="p-0 mx-0 my-2" />
                                )}
                              </>
                            );
                          })}
                          </div>
                        </TableCell>
                        <TableCell style={subRowStyle}>
                        <div onScroll={()=> handleScroll(k)} id={`col2_${k}`} className='col2' style={{maxHeight: 200, overflow: 'scroll'}}>
                          {v?.["unit/floor"].map((unit, u_key) => {
                            return (
                              <>
                                {unit === "-" || unit.length == 0
                                  ? "-"
                                  : unit.map((v) =><span className="p-1 bg-secondary mx-1 text-light rounded">{v.unitName}</span>)}
                                {v["unit/floor"].length - 1 !== u_key && (
                                  <hr className="p-0 mx-0 my-2" />
                                )}
                              </>
                            );
                          })}
                          </div>
                        </TableCell>
                        <TableCell style={subRowStyle}>
                        <div onScroll={()=> handleScroll(k)} className='col3' id={`col3_${k}`} style={{maxHeight: 200, overflow: 'scroll'}}>
                          {v?.frameworks.map((fw, fw_key) => {
                            return (
                              <Fragment key={fw_key}>
                                {fw &&
                                  fw?.map((fw1, fw1_key) => {
                                    return (
                                      <span title={fw1.name}>
                                        <img
                                          className="mx-1"
                                          src={baseURL + fw1.imageURL}
                                          height={18}
                                          key={fw1_key}
                                        />
                                      </span>
                                    );
                                  })}
                                {v.frameworks.length - 1 !== fw_key && (
                                  <hr className="p-0 mx-0 my-2" />
                                )}
                              </Fragment>
                            );
                          })}
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center", color: "#c6c6c6" }}
                    colSpan={6}
                  >
                    {loading ? (
                      <i className="spinner-border mt-2 " />
                    ) : (
                      <div className="text-secondary h6 mb-0">
                        { t("No User Found") }
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={`${theme}`}
          style={{ color: invertDark(theme) }}
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    </>
  );
};

export default React.memo(UserTable);

const table_headers = [
  { label: "Site", value: "site", col: "col-2" },
  { label: "User", value: "user", col: "col-2" },
  { label: "Email", value: "email", col: "col-4" },
  { label: "Role", value: "role", col: "col-2" },
  { label: "Unit/Floor", value: "unit/floor", col: "col-2" },
  { label: "Frameworks", value: "frameworks", col: "col-2" },
];

const SortableTHead = (props) => {
  const { order, orderBy, onRequestSort, headCells, theme } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              backgroundColor:
                theme === "dark" ? Colors.primaryBule : Colors.blue,
              color: invertDark(theme),
              fontWeight: "bold",
              padding: "8px 13px 8px 13px",
              textAlign: headCell.id === "size" ? "end" : "center",
              position: "static",
              textAlign: "left",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                color: Colors.white,
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
