import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { ProgressBar } from "../../Tools/ProgressBar";
import { PdfDownload } from "./PdfDownload";
import { toJS } from "mobx";
import { MyButton } from "../../Tools";
import { useRef } from 'react';
import PreviewPDFDialog from "./PreviewPDFDialog";
import { useTranslation } from 'react-i18next';

export const SurveySideBar = inject("store")(
  observer((props) => {

    const { t } = useTranslation("target_setting");
    
    const {
      surveyInfo,
      _handleSectionClick,
      sectionIndex,
      ansTotalPercent,
      answerPercent,
      _handleSetup,
      _handlePreviewPDF,
      _handleManualInput,
      isSetup,
      isManualSetup,
      surveyFramework,
      frameWorkIndex,      
    } = props.store.survey;
    const { surveySection } = surveyInfo;
    const width = window.innerWidth;
    const pdfRef = useRef();
    const { theme, accessmentList } = props.store.common;
    // console.log('survey info => ', toJS(accessmentList), toJS(surveyInfo), frameWorkIndex, toJS(surveyFramework))
    const currentFrameworkId = surveyFramework[frameWorkIndex]?.framework_id;
    const frameworkAction = accessmentList?.find(
      (v) => v._id === currentFrameworkId
    )?.frameworkAction;

    const selectedStyle = {
      background: theme === "dark" ? Colors.lightBlue : Colors.white,
      borderRadius: "5px",
      color: theme === "dark" ? "#FFF" : "#000",
    };
    const defaultStyle = {
      color: theme === "dark" ? Colors.dark[4] : Colors.white,
      cursor: "pointer",
      transition: "0.1s",
    };


    const selectedBtnColor = theme === "dark" ? "#0056D2" : "#F6F6F6";   
    const defaultBtnColor = theme === "dark" ? Colors.primaryBule : "rgb(52 67 144 / 60%)";
    // const modifiedSectionList =
    //   surveyTitle === "WWF" && !isOpenAllQuestions
    //     ? surveySection.slice(0, 1)
    //     : surveySection;

    const frameworkActionData = [
      {text: 'Setup', id: 'equipmentSetup', onClick: _handleSetup, current: isSetup, leftIcon: <i className="bi bi-gear-wide-connected"></i> },
      {text: 'Manual Entry', id: 'manualSetup', onClick: _handleManualInput, current: isManualSetup, leftIcon: <i className="bi bi-file-earmark-ruled-fill"></i> },
      {text: 'Download PDF', id: 'downloadpdf', onClick: _handlePreviewPDF, current: null, leftIcon: <i className="bi bi-download"></i> }
    ];
    
    return (
      <div
        className="me-2 p-2 py-3 position-relative"
        style={{
          background: theme === "dark" ? Colors.darkBlue : Colors.blue,
          // minWidth:300,
          minHeight: "600px",
          borderRadius: 10,
          boxShadow: Colors.shadow,
        }}
      >
        <div className="d-flex flex-row flex-wrap">
          {/* <img src={GSTC} alt="GSTC_Logo" width={50} style={{ borderRadius: 5 }} /> */}
          <h5
            className="d-flex align-items-center fw-bold ps-2"
            style={{ color: theme === "dark" ? Colors.dark[4] : Colors.white }}
          >
            {surveyInfo.surveyHeader}           
          </h5>
        </div>

        {/* {frameworkAction ? (
          surveyInfo?.surveyTitle === "GHG" ? (
            <div className="row">
              <div className="col-lg-6 col-sm-12 my-1">
                <MyButton
                  text={"Setup"}
                  id={"equipmentSetup"}
                  onClick={_handleSetup}
                  textAlign={"text-start"}
                  className={isSetup ? "shadow" : ""}
                  noTxtLight
                  style={{
                    width: "100%",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: theme === "light" && isSetup ? "#303030" : "#f8f9fa",
                  }}
                  customColor={isSetup ? selectedBtnColor : defaultBtnColor}
                  leftIcon={<i className="bi bi-gear-wide-connected"></i>}
                />
              </div>
              <div className="col-lg-6 col-sm-12 my-1">
                <MyButton
                  text={"Manual Input"}
                  id={"manualSetup"}
                  onClick={_handleManualInput}
                  textAlign={"text-start"}
                  className={isManualSetup ? "shadow" : ""}
                  noTxtLight
                  style={{
                    width: "100%",
                    fontSize: 14,
                    fontWeight: "bold",
                    color:
                      theme === "light" && isManualSetup
                        ? "#303030"
                        : "#f8f9fa",
                  }}
                  customColor={
                    isManualSetup ? selectedBtnColor : defaultBtnColor
                  }
                  leftIcon={<i className="bi bi-file-earmark-ruled-fill"></i>}
                />
              </div>
            </div>
          ) : (
            surveyInfo?.surveyTitle === "TCFD" && (
              <div className="row">
                <div className="col-12 my-1">
                  <MyButton
                    text={frameworkAction}
                    id={frameworkAction}
                    onClick={() => _handleExportPDF(pdfRef)}
                    textAlign={"text-center"}
                    className={"shadow"}
                    style={{
                      width: "100%",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                    customColor={defaultBtnColor}
                    leftIcon={<i className="bi bi-download"></i>}
                    customHoverColor={Colors.water}
                  />
                </div>
                <div ref={pdfRef} style={{opacity:0}}>Test PDF</div>
              </div>
            )
          )
        ) : null} */}
        {frameworkAction?.length > 0 && (
          <div className="row">
            {
              frameworkAction.map((ac,k)=> {
                const action = frameworkActionData.find(dd => dd.text === ac);
                
              return(
                <div key={k} className={/*action.id === "downloadpdf" ? "col-lg-8 col-sm-12 my-1" :*/ "col-lg-6 col-sm-12 my-1"}>
                  <span
                    data-bs-toggle={action.id === "downloadpdf"?"modal":null}
                    data-bs-target={action.id === "downloadpdf"?"#pdfPreviewModal":null}
                    style={{
                      cursor: "pointer"
                    }}
                  >
                    <MyButton
                      text={t(action?.text)}
                      id={action?.id}
                      onClick={action?.onClick}
                      textAlign={"text-start"}
                      className={action?.current ? "shadow" : ""}
                      noTxtLight
                      style={{ width: "100%", fontSize: 14, fontWeight: "bold", color: (theme === "light" && action?.current) ? "#303030" : "#f8f9fa"}}
                      customColor={action?.current ? selectedBtnColor : defaultBtnColor}
                      leftIcon={action?.leftIcon}
                      //customHoverColor={Colors.water}
                    />
                  </span>
            </div>
              )})
            }</div>
        )}

        <PreviewPDFDialog />

        {/* {frameworkAction && (
          <div className="row">
            <div className="col-lg-6 col-sm-12 my-1">
              <MyButton
                text={"Setup"}
                id={"equipmentSetup"}
                onClick={_handleSetup}
                textAlign={"text-start"}
                className={isSetup ? "shadow" : ""}
                noTxtLight
                style={{ width: "100%", fontSize: 14, fontWeight: "bold", color: (theme === "light" && isSetup) ? "#303030" : "#f8f9fa" }}
                customColor={isSetup ? selectedBtnColor : defaultBtnColor}
                leftIcon={<i className="bi bi-gear-wide-connected"></i>}
              //customHoverColor={Colors.water}
              />
            </div>
            <div className="col-lg-6 col-sm-12 my-1">
              <MyButton
                text={"Manual Input"}
                id={"manualSetup"}
                onClick={_handleManualInput}
                textAlign={"text-start"}
                className={isManualSetup ? "shadow" : ""}
                noTxtLight
                style={{ width: "100%", fontSize: 14, fontWeight: "bold", color: (theme === "light" && isManualSetup) ? "#303030" : "#f8f9fa" }}
                customColor={isManualSetup ? selectedBtnColor : defaultBtnColor}
                leftIcon={<i className="bi bi-file-earmark-ruled-fill"></i>}
              // customHoverColor={Colors.water}
              />
            </div>
          </div>
        )} */}

        {surveySection.map((v, k) => (
          <div
            key={k}
            className="my-3 p-3"
            style={
              isSetup || isManualSetup
                ? defaultStyle
                : k === sectionIndex
                  ? selectedStyle
                  : defaultStyle
            }
            onClick={() => _handleSectionClick(k)}
          >
            <div className="row me-0 justify-content-between">
              <div className="col-9 fw-bold">{t(v.sectionName)}</div>
              <div className="col-3">
                <ProgressBar
                  progress={
                    ansTotalPercent?.section == k
                      ? ansTotalPercent?.percentage
                      : answerPercent[k].percent &&
                      parseInt(answerPercent[k].percent)
                  }
                  noLabel
                  color={Colors.greenMaterial}
                  noTarget
                />
              </div>
            </div>
            <hr className="my-0" />
            <div style={{ fontSize: 14,opacity:0.5 }}>{t(v.sectionHeader)}</div>
          </div>
        ))}
        {/* <div style={{ bottom: "20px" }}>
          <span> Readiness Assessment for :<br /></span>
          <hr className="mt-0 mb-1" />
          <img src={`/sites/${props.buildingName.toLowerCase()}.png`} alt="Company_Logo" width={50} style={{ borderRadius: 5 }} />{" "}
          {props.buildingName}
        </div> */}
      </div>
    );
  })
);
