import { useState } from "react";
import "../../App.css";
import { Colors } from "../../Assets/Config/color.config";
import Store from "../../Store";
import { useTranslation } from 'react-i18next';

export const ESGBreadCrumb = (props) => {
    const { page } = props;
    const [hover, setHover] = useState(null);
    const { theme } = Store.common;
    const { t } = useTranslation("esg_tracking");
    return (
        <nav aria-label="breadcrumb" style={{}}>
            <ol className="breadcrumb">
                <li className={`breadcrumb-item`}>
                    <a
                        href="/dashboard"
                        className={``}
                        style={{ textDecoration: "none" }}
                    >
                        { t("Dashboard") }
                    </a>
                </li>

                {page ? page === "esgPillarSetup" ? (
                    <>
                        <li
                            className="breadcrumb-item"
                            style={{ color: Colors[theme].textColor }}
                        >
                            <a
                                href="/esg-tracking"
                                className={``}
                                style={{ textDecoration: "none" }}
                            >
                                { t("ESG Objectives and Targets") }
                            </a>
                        </li>
                        <li
                            className="breadcrumb-item fw-bold"
                            style={{ color: Colors[theme].textColor }}
                        >
                            { t("ESG Pillar Setup") }
                        </li>
                    </>
                ) : (
                    <>
                        <li
                            className="breadcrumb-item"
                            style={{ color: Colors[theme].textColor }}
                        >
                            <a
                                href="/esg-tracking"
                                className={``}
                                style={{ textDecoration: "none" }}
                            >
                                { t("ESG Objectives and Targets") }
                            </a>
                        </li>
                        <li
                            className="breadcrumb-item"
                            style={{ color: Colors[theme].textColor }}
                        >
                            <a
                                href="/esg-pillar-setup"
                                className={``}
                                style={{ textDecoration: "none" }}
                            >
                                { t("ESG Pillar Setup") }
                            </a>

                        </li>
                        <li
                            className="breadcrumb-item fw-bold"
                            style={{ color: Colors[theme].textColor }}
                        >
                            { t("KPI Management") }
                        </li>
                    </>
                )
                    : (<li
                        className="breadcrumb-item fw-bold"
                        style={{ color: Colors[theme].textColor }}
                    >
                        { t("ESG Objectives and Targets") }
                    </li>)
                }
            </ol>
        </nav>
    );
};
