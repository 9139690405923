import * as API from "./URL";

export const createKPIManagement = async (kpiManagementData, pillar, subPillar, callback) => {
    return fetch(API.kpiManagement + "?pillar=" + pillar + "&subPillar=" + subPillar, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(kpiManagementData)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const getKPIByPillar = async (pillar, callback) => {
    return fetch(API.kpiManagement + "?pillar=" + pillar, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },        
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const getKPIById = async (kpiId, callback) => {
    return fetch(API.kpiManagement+kpiId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },        
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const updateKPIManagement = async (kpiManagementData, kpi_id, callback) => {
    return fetch(API.kpiManagement + kpi_id, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(kpiManagementData)
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchAllKPIManagements = async (callback) => {
    return fetch(API.kpiManagement + "all", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const deleteKPIManamgement = async (kpiId, callback) => {
    return fetch(API.kpiManagement + kpiId, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchBaseYears = async (callback) => {
    return fetch(API.baseYears, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },

    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchKPIOwnerTitles = async (callback) => {
    return fetch(API.kpiOwnerTitle, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchKPIOwnerNames = async (callback) => {
    return fetch(API.kpiOwnerName, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchUnitOfMeasurement = async (callback) => {
    return fetch(API.unitOfMeasurement, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchFreqOfMeasurement = async (callback) => {
    return fetch(API.freqOfMeasurement, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchKeyDependency = async (callback) => {
    return fetch(API.keyDependency, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}

export const fetchStandardAlignment = async (callback) => {
    return fetch(API.standardAlignment, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null, data))
        .catch((err) => callback(err.toString(), null));
}



