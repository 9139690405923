import { Colors, invertDark } from "../../Assets/Config/color.config";
import { toJS } from "mobx";
import Store from "../../Store";
import {ThousandSeparator} from "../../Assets/Config/thousandSeparator.config";
import { useTranslation } from "react-i18next";

export const BreakdownTable = ({ column, data }) => {
  const { theme } = Store.common;
  const tMonth = useTranslation("month");
  const monthCol = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  return (
    <div
      style={{
        width: "100%",
        fontSize: 14,
        marginTop: 12,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}
    >
      <table className="table">
        <thead>
          <tr>
            {column.map((v, k) => (
              <th
                key={k}
                className="text-left text-white"
                style={{
                  // color: invertDark(theme),
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor:
                    theme === "dark" ? Colors.primaryBule : Colors.blue,
                  minWidth: 30,
                }}
              >
                {tMonth.t(v)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            color: invertDark(theme),
          }}
        >
          {data
            ? data.length > 0 && data.map((v,k) => 
            (
              <tr
                style={{
                  border: `0px solid ${theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(83, 83, 83, 0.1)'}`,
                  // borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              >
                <td className="col-2" style={{ paddingTop: 25 }}>
                  {v.name}
                </td>
                {monthCol.map((col, key) => (
                  <td className="col" style={{ paddingTop: 25 }}>
                    {/* {data[k] ? data[k].value : 0} */}
                    {ThousandSeparator(v.monthData.find(m => m.month === col)?.value) || "-"}
                  </td>
                ))}
              </tr>
            )
            )
            
            : null}
        </tbody>
      </table>
    </div>
  );
};
