import {
  action,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { toast } from "react-toastify";
import {
  fetchAnswer,
  fetchManualByCategoryAnswer,
} from "../API/api.questionnaire";
import {
  fetchQuestion,
  fetchFrameworkAnsCount,
  fetchSurveyHeader,
  fetchDownloadFramework,
  deleteFramework,
  fetchCalApproaches,
  checkManualEntry,
  calculateEmissionBaseOnUsage,
  getEmissionByCalculatedMethod,
  fetchAddCustomCalApproach,
  fetchUpdateUnit,
  fetchExcelUpload,
  fetchDeleteSetup,
  updateUploadDescription,
  fetchScope0Data,
  fetchUpdateScope0Data,
  fetchEqDataByDeviceType,
  getS1EmissionVal,
  fetchFile
} from "../API/api.survey";
import {
  CreateCustomCalApproachValidation,
  CreateEquipmentValidation,
} from "../util/formValidation";
import {
  fetchEquipmentList,
  addEquipmentSetup,
  fetchEquipmentActive,
  addEquipmentData,
  updateEquipmentSetup
} from "../API/api.equipment";
import { fetchMaualEntryData } from "../API/api.trackingDetail";
import { addManualentryData } from "../API/api.siteRelevant";
import i18n from "../i18n";
import common from "./common";
import target from "./target";
import { Cookies } from "react-cookie";
const cookies = new Cookies();
class Survey {
  pdfBlobLink = null;
  previewPDFName = null;
  surveyInfo = {};
  sectionIndex = 0;
  subSectionIndex = 0;
  subSectionArray = [];
  answerObj = {};
  answerArray = [];
  categoryArray = [];
  showModel = true;
  indexAlphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  frameWorkIndex = 0;
  surveyFramework = null;
  answerPercent = null; //////from api
  ansTotalPercent = null; /////must be calculated value
  isDisabled = false;
  showAllSurvey = false;
  isOpenAllQuestions = false;
  ghgFuelType = null;
  status = undefined;
  buildingId = null;
  isSetup = false;
  isManualSetup = false;
  err = {};
  siteRelevantId = null;
  equipmentList = [];
  resetData = {
    category: "",
    recordPeriod: "",
    equipmentName: "",
    deviceType: "",
    fuelType: "",
    brand: "",
    unit: "",
    year: "",
    supplier: "",
    calculationApproach: "",
    emissionFactor: "",
    calculateMethod: "",
    name: "",
    activityType: "",
    carbonEmission: "",
    distance: "",
    commissionedDate: new Date(),
    active: true,
  };
  createEquipmentList = {
    category: "",
    recordPeriod: "",
    equipmentName: "",
    deviceType: "",
    fuelType: "",
    brand: "",
    unit: "",
    commissionedDate: new Date(),
    active: true,
  };
  createPurchasedList = {
    year: "",
    supplier: "",
    calculationApproach: "",
    emissionFactor: "",
    unit: "",
    active: true,
    recordPeriod: "",
    calculateType: "",
    calculateMethod: "",
    category: "",
    emissionFactorValue: "",
    name: "",
  };
  createTransportationList = {
    category: "",
    year: "",
    transportMode: "",
    emissionFactor: "",
    vehicleType: "",
    activityType: "",
    active: true,
    unit: "",
    name: "",
    date: new Date(),
    carbonEmission: "",
    distance: "",
  };
  isEdit = false;
  yearOptions = [
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
  ];
  selectedYear = "2022";
  selectedField = "energyConsumption";
  entryFields = [
    { label: "Month", value: "Month", key: "month", unit: "", type: "fixed" },
    {
      label: "Energy Consumption",
      value: "Energy Consumption",
      key: "energyConsumption",
      unit: "kWh",
      type: "variable",
    },
    {
      label: "Water Consumption",
      value: "Water Consumption",
      key: "waterConsumption",
      unit: "m³",
      type: "variable",
    },
    {
      label: "Carbon Emission",
      value: "Carbon Emission",
      key: "carbonEmission",
      unit: "kg",
      type: "variable",
    },
  ];
  manualEntryData = [];
  emptyManualEntryData = [
    {
      month: "January",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "February",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "March",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "April",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "May",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "June",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "July",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "August",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "September",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "October",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "November",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
    {
      month: "December",
      energyConsumption: null,
      waterConsumption: null,
      carbonEmission: null,
      scope1Emission: null,
      scope2Emission: null,
      scope3Emission: null,
    },
  ];
  allManualEntryData = [];
  isShowModel = true;
  isNewVal = false;
  setUp = 1;
  surveyYear = null;
  expectedSectionIndex = null;
  expectedFrameworkIndex = null;
  expectedSurveyTitle = null;
  showDialog = false;
  manualCheckStatus = false;
  calApproachOptions = [];
  customEmissionFactor = {
    type: "",
    customEFName: "",
    sourceOfEmission: "",
    co2Factor: null,
    scope: "scope2",
  };
  tabOption = [
    { value: "scope1Emission", label: "Scope 1" },
    { value: "scope2Emission", label: "Scope 2" },
    { value: "scope3Emission", label: "Scope 3" },
  ];
  selectedCarbonTab = this.tabOption[0]?.value;
  excelRawData = [];
  checkedArray = [];
  requiredField = [
    "date",
    "category",
    "emissionFactor",
    "transportMode",
    "activityType",
    "vehicleType",
    "unit",
    "distance",
    "carbonEmission",
    "name",
  ];
  pageIndex = 0;
  filteredExcelArray = [];
  selectedExcelHeader = {
    date: "date",
    category: "category",
    emissionFactor: "emissionFactor",
    transportMode: "transportMode",
    activityType: "activityType",
    vehicleType: "vehicleType",
    unit: "unit",
    distance: "distance",
    carbonEmission: "carbonEmission",
    name: "name",
  };
  excelDefaultValue = {
    date: new Date(),
    category: "-",
    emissionFactor: "-",
    transportMode: "-",
    activityType: "-",
    vehicleType: "-",
    unit: "-",
    distance: 0,
    carbonEmission: 0,
    name: "-",
  };
  lastExcelData = [];
  unitOptions = [
    { label: "kg CO2", value: "kg CO2", dataSource: "scope1Emission" },
    { label: "g CO2", value: "g CO2", dataSource: "scope1Emission" },
    { label: "tonnes", value: "tonnes", dataSource: "scope1Emission" },

    { label: "kg CO2", value: "kg CO2", dataSource: "scope2Emission" },
    { label: "g CO2", value: "g CO2", dataSource: "scope2Emission" },
    { label: "tonnes", value: "tonnes", dataSource: "scope2Emission" },

    { label: "kg CO2", value: "kg CO2", dataSource: "scope3Emission" },
    { label: "g CO2", value: "g CO2", dataSource: "scope3Emission" },
    { label: "tonnes", value: "tonnes", dataSource: "scope3Emission" },

    { label: "m3/h", value: "m3/h", dataSource: "waterConsumption" },
    { label: "CuM", value: "CuM", dataSource: "waterConsumption" },

    { label: "kWh", value: "kWh", dataSource: "energyConsumption" },
    { label: "Wh", value: "Wh", dataSource: "energyConsumption" },
  ];
  selectedUnit = null;
  dataSourceOptions = [
    { value: "energyConsumption", label: "Energy Consumption" },
    { value: "waterConsumption", label: "Water Consumption" },
    { value: "scope1Emission", label: "Scope 1" },
    { value: "scope2Emission", label: "Scope 2" },
    { value: "scope3Emission", label: "Scope 3" },
  ];
  selectedBaseLineYear = null;
  isManualEditMode = false;
  setupDeleteId = null;
  clickManualAction = false;
  toClickSetupMenu = null;
  manualDataByCategory = [];
  allManualDataByCategory = [];
  manualEditMode = false;
  selectedFile = null;
  uploadDescription = null;
  selectedMonth = null;
  ghgDataType = "DetailData";
  scope0ans = [];
  scope0Index = 0;
  scope0ExpectedIndex = null;
  showScope0Dialog = false;
  scope0Data = [];
  scope0AnsRow = [];
  selectedAnswerId = null;
  deletedDevice = [];
  desFileId = "";
  editedMonth = [];
  editedScope2Month = [];

  surveyFileUploadData = {
      questionId: null,
      childSection: null,
      questionIndex: null,
      dynamicDataId: null,
      monthIndex: null,
      desValue: null,
      answerValue: null
  }

  constructor(props) {
    makeObservable(this, {
      sectionIndex: observable,
      subSectionIndex: observable,
      surveyInfo: observable,
      subSectionArray: observable,
      showModel: observable,
      answerObj: observable,
      answerArray: observable,
      indexAlphabet: observable,
      frameWorkIndex: observable,
      surveyFramework: observable,
      ansTotalPercent: observable,
      answerPercent: observable,
      isDisabled: observable,
      showAllSurvey: observable,
      isOpenAllQuestions: observable,
      ghgFuelType: observable,
      status: observable,
      buildingId: observable,
      pdfBlobLink: observable,
      previewPDFName: observable,
      isSetup: observable,
      isManualSetup: observable,
      err: observable,
      equipmentList: observable,
      createEquipmentList: observable,
      createPurchasedList: observable,
      createTransportationList: observable,
      isEdit: observable,
      yearOptions: observable,
      selectedYear: observable,
      entryFields: observable,
      manualEntryData: observable,
      siteRelevantId: observable,
      selectedField: observable,
      isNewVal: observable,
      setUp: observable,
      surveyYear: observable,
      showDialog: observable,
      expectedSectionIndex: observable,
      expectedFrameworkIndex: observable,
      expectedSurveyTitle: observable,
      manualCheckStatus: observable,
      calApproachOptions: observable,
      customEmissionFactor: observable,
      tabOption: observable,
      selectedCarbonTab: observable,
      excelRawData: observable,
      checkedArray: observable,
      requiredField: observable,
      pageIndex: observable,
      selectedExcelHeader: observable,
      excelDefaultValue: observable,
      lastExcelData: observable,
      unitOptions: observable,
      selectedUnit: observable,
      dataSourceOptions: observable,
      selectedBaseLineYear: observable,
      allManualEntryData: observable,
      isManualEditMode: observable,
      setupDeleteId: observable,
      clickManualAction: observable,
      toClickSetupMenu: observable,
      manualDataByCategory: observable,
      allManualDataByCategory: observable,
      manualEditMode: observable,
      selectedFile: observable,
      uploadDescription: observable,
      selectedMonth: observable,
      ghgDataType: observable,
      scope0ans: observable,
      scope0Index: observable,
      scope0ExpectedIndex: observable,
      showScope0Dialog: observable,
      scope0Data: observable,
      scope0AnsRow: observable,
      selectedAnswerId: observable,
      deletedDevice: observable,
      desFileId: observable,
      editedMonth: observable,
      editedScope2Month: observable,
      categoryArray: observable,

      getAllSection: action.bound,
      getAllQuestion: action.bound,
      _handleSectionClick: action.bound,
      _handlePageClick: action.bound,
      _handleNextClick: action.bound,
      _handlePreviousClick: action.bound,
      _handleAnswerSubmit: action.bound,
      _handleTextChange: action.bound,
      _handleNumberChange: action.bound,
      _handleSelectAnswer: action.bound,
      _handleRadioChange: action.bound,
      _handleSurveyClick: action.bound,
      getAllSurvey: action.bound,
      getAnsPercentByEachSection: action.bound,
      _handleOpenAllQuestions: action.bound,
      _handleCloseAllQuestions: action.bound,
      reset: action.bound,
      _handleSetup: action.bound,
      getEquipmentList: action.bound,
      _handleCreateEquipmentSetup: action.bound,
      _handleManualInput: action.bound,
      _handleChangeInput: action.bound,
      _handleSelectEquip: action.bound,
      _handleChangeDate: action.bound,
      _handleSelectYear: action.bound,
      _handleManualInputChange: action.bound,
      _handleShowModel: action.bound,
      _handleAddManualEntry: action.bound,
      getManualEntryDataByYear: action.bound,
      _handleSelectSetup: action.bound,
      _handleResetManualEntry: action.bound,
      _handleChangeActive: action.bound,
      _handleDownload: action.bound,
      _handlePreviewPDF: action.bound,
      _handleSelectSurveyYear: action.bound,
      _deleteCurrentFramework: action.bound,
      saveMoveToNext: action.bound,
      discardMoveToNext: action.bound,
      onDiscardClick: action.bound,
      _handleCalculateEmissionBaseOnUsage: action.bound,
      _handleGetEmissionByCalculatedMethod: action.bound,
      _handleSelectCustomEmission: action.bound,
      _handleAddCustomEmission: action.bound,
      _handleCancelCustomEmission: action.bound,
      _handleClickCarbonTab: action.bound,
      setFileUploadData: action.bound,
      setPageIndex: action.bound,
      _handleExcelUploadNext: action.bound,
      _handleSelectExlHeader: action.bound,
      _handleDefaultExcelInputChange: action.bound,
      _handleSelectUnit: action.bound,
      _handleCancelUnitModal: action.bound,
      _handleSaveUnit: action.bound,
      setSelectedUnit: action.bound,
      _handleDeleteSetupRow: action.bound,
      _handleClose: action.bound,
      _handleDeleteSetUpId: action.bound,
      _handleAlertForOtherAction: action.bound,
      getManualEntryDataByCategory: action.bound,
      _handleManualNumberChange: action.bound,
      _handleManualEditMode: action.bound,
      _handleSelectedFile: action.bound,
      _handleSaveUploadedFile: action.bound,
      _handleDescriptionChange: action.bound,
      setScope0SetupVal: action.bound,
      _handleCheckChange: action.bound,
      _handleQtyChange: action.bound,
      _handleQuesClick: action.bound,
      _handleNextQuesClick: action.bound,
      _handlePreviousQuesClick: action.bound,
      saveMoveToNext_scpoe0: action.bound,
      discardMoveToNext_scpoe0: action.bound,
      onDiscardClick_scpoe0: action.bound,
      getScope0AnsData: action.bound,
      _handleSetScope0AnsRow: action.bound,
      _handleChangeS0Input: action.bound,
      _handleSelectS0: action.bound,
      _handleChangeS0Date: action.bound,
      _handleSubmitScope0: action.bound,
      _handleDeleteScope0Device: action.bound,
      _handleGetS1EmissionVal: action.bound,
      _handleSetGhgDataType: action.bound,
      _handleScope2DescriptionChange: action.bound,
      _handleSurveyFileChange: action.bound,
      _handleCateDescriptionChange: action.bound,
      _handleCategorySubmit: action.bound,
      setSelectedMonth: action.bound,
      getCalApproaches: action.bound,
      _handleGetBaseLineYear: action.bound,
      clearData: action.bound,
      setEdit: action.bound,
      _handleUpdateEquipmentSetup: action.bound,
      _handleFileDownload: action.bound

    });
  }

  clearData = (scope) => {
    if(scope === "scope1"){
      this.createEquipmentList = this.resetData;
    }else if(scope === "scope2"){
      this.createPurchasedList = this.resetData;
    }else{
      this.createTransportationList = this.resetData;
    }
  }

  reset() {
    this.sectionIndex = 0;
    this.subSectionIndex = 0;
    this.frameWorkIndex = 0;
    this.surveyFramework = null;
    this.subSectionArray = [];
    this.surveyInfo = {};
    this.answerArray = [];
  }

  _handleManualEditMode = () => {
    runInAction(() => {
      this.manualEditMode = !this.manualEditMode;
    });
  };

  _deleteCurrentFramework = (buildingId, frameworkId) => {
    deleteFramework(buildingId, frameworkId, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (!data.error) {
          toast.success(data.payload);
          this.getAllSurvey(buildingId);
        } else toast.error(data.error);
      }
    });
  };

  //buildingId change from surveyHeaderId
  getAllSection(frameworkId) {
    runInAction(() => {
      this.isDisabled = true;
      this.surveyInfo = { surveyTitle: null };
    });

    fetchFrameworkAnsCount(
      this.buildingId,
      this.surveyFramework[this.frameWorkIndex]?.framework_id,
      this.surveyYear,
      (err, data) => {
        if (data.payload) {
          const { buildingName, surveyHeader } = data.payload;
          runInAction(() => {
            this.surveyInfo = {
              buildingName,
              surveySection: surveyHeader.section,
              surveyHeader: surveyHeader.headerName,
              surveyTitle: surveyHeader.title,
              surveyPeriod: surveyHeader.surveyPeriod,
            };
            this.answerPercent = surveyHeader.section;
          });
          this.isDisabled = false;
        } else {
          toast.error(err);
        }
      }
    );
  }

  getEquipmentList() {
    const buildingId = toJS(this.buildingId);
    const type = this.setUp;
    const frameworkId = toJS(
      this.surveyFramework[this.frameWorkIndex].framework_id
    );
    if (buildingId && frameworkId) {
      fetchEquipmentList(buildingId, frameworkId, type, (err, data) => {
        if (data) {
          runInAction(() => {
            this.equipmentList = data.payload;
          });
        } else {
          toast.error(err);
        }
      });
    }
  }

  _handleChangeActive(dynamicDataId, setup, active) {
    const type =
      setup === "Scope 1"
        ? "scope1"
        : setup === "Scope 2"
        ? "scope2"
        : "scope3";

    fetchEquipmentActive(
      dynamicDataId,
      type,
      active,
      this.buildingId,
      (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (!data.error) {
            toast.success(data.payload);
            this.getEquipmentList();
          } else toast.error(data.error);
        }
      }
    );
  }

  _handleDeleteSetupRow() {
    const { deleteId, setupType } = toJS(this.setupDeleteId);
    const frameworkId = toJS(
      this.surveyFramework[this.frameWorkIndex].framework_id
    );
    fetchDeleteSetup(
      this.buildingId,
      frameworkId,
      deleteId,
      setupType,
      (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (!data.error) {
            toast.success(data.payload);
            this.getEquipmentList();
          } else toast.error(data.error);
        }
      }
    );
  }

  _handleDeleteSetUpId(id, setup) {
    runInAction(() => {
      this.setupDeleteId = { deleteId: id, setupType: setup };
    });
  }

  _handleClickCarbonTab(value) {
    runInAction(() => {
      this.selectedCarbonTab = value;
    });
  }

  _handleCreateEquipmentSetup(type) {
    let dataList;
    const buildingId = this.buildingId;
    const frameworkId = this.surveyFramework[this.frameWorkIndex].framework_id;

    if (type === "equipment") {
      dataList = toJS(this.createEquipmentList);
    } else if (type === "purchasedElectricity") {
      dataList = toJS(this.createPurchasedList);
    } else if (type === "transportation") {
      dataList = toJS(this.createTransportationList);
    }

    if (dataList) {
      const ValidatedErr = CreateEquipmentValidation(dataList, this.setUp);
      this.err = ValidatedErr;
      if (ValidatedErr.equipmentNameErr) {
        toast.error(this.err.equipmentNameErr);
        document.getElementById("equipmentName").focus();
      } else if(ValidatedErr.supplierErr) {
        toast.error(this.err.supplierErr);
        document.getElementById("supplier").focus();
      } else if (ValidatedErr.categoryErr) {
        toast.error(this.err.categoryErr);
      } else if (ValidatedErr.unitErr) {
        toast.error(this.err.unitErr);
      } else if (ValidatedErr.fuelTypeErr) {
        toast.error(this.err.fuelTypeErr);
      } else if (ValidatedErr.recordPeriodErr) {
        toast.error(this.err.recordPeriodErr);
      } else if (ValidatedErr.brandErr) {
        toast.error(this.err.brandErr);
      } else if (ValidatedErr.energySourceErr) {
        toast.error(this.err.energySourceErr);
      } else if (ValidatedErr.calculationApproachErr) {
        toast.error(this.err.calculationApproachErr);
      } else if (ValidatedErr.activityTypeErr) {
        toast.error(this.err.activityTypeErr);
      } else if (ValidatedErr.vehicleTypeErr) {
        toast.error(this.err.vehicleTypeErr);
      } else if (ValidatedErr.modelErr) {
        toast.error(this.err.modelErr);
      } else if (ValidatedErr.nameErr) {
        toast.error(this.err.nameErr);
        document.getElementById("name").focus();
      }

      if (Object.keys(ValidatedErr).length === 0) {
        if (buildingId && frameworkId) {
          if (dataList.category === "Mobile Combustion") {
            const bodyData = {
              active: dataList.active,
              activityType: dataList.activityType,
              category: dataList.category,
              commissionedDate: dataList.commissionedDate,
              fuelType: dataList.fuelType,
              refrigerantsUsed: dataList.fuelType,
              brand: dataList.brand,
              equipmentName: dataList.equipmentName,
              recordPeriod: dataList.recordPeriod,
              unit: dataList.unit,
              vehicleType: dataList.vehicleType,
            };
            addEquipmentData(
              buildingId,
              frameworkId,
              this.setUp,
              bodyData,
              (err, data) => {
                if (err) {
                  toast.error(err);
                } else {
                  if (!data.error) {
                    toast.success(data.payload);
                    this.getEquipmentList();
                    document
                      .getElementById("create_setUp")
                      .setAttribute("data-bs-dismiss", "modal");
                    let clickEvent = new MouseEvent("click", {
                      view: window,
                      bubbles: true,
                      cancelable: false,
                    });
                    document
                      .getElementById("create_setUp")
                      .dispatchEvent(clickEvent);

                    document
                      .getElementById("create_setUp")
                      .removeAttribute("data-bs-dismiss");

                    if (this.isSetup) {
                      this.getEquipmentList();
                    } else {
                      const frameworkId =
                        this.surveyFramework[this.frameWorkIndex]?.framework_id;
                      this.getAllQuestion(frameworkId);
                    }
                  } else toast.error(data.error);
                }
              }
            );
          } else {
            addEquipmentSetup(
              buildingId,
              frameworkId,
              this.setUp,
              dataList,
              (err, data) => {
                if (err) {
                  toast.error(err);
                } else {
                  if (!data.error) {
                    toast.success(data.payload);
                    this.getEquipmentList();
                    document
                      .getElementById("create_setUp")
                      .setAttribute("data-bs-dismiss", "modal");
                    let clickEvent = new MouseEvent("click", {
                      view: window,
                      bubbles: true,
                      cancelable: false,
                    });
                    document
                      .getElementById("create_setUp")
                      .dispatchEvent(clickEvent);

                    document
                      .getElementById("create_setUp")
                      .removeAttribute("data-bs-dismiss");

                    if (this.isSetup) {
                      this.getEquipmentList();
                    } else {
                      const frameworkId =
                        this.surveyFramework[this.frameWorkIndex]?.framework_id;
                      this.getAllQuestion(frameworkId);
                    }
                  } else toast.error(data.error);
                }
              }
            );
          }
        }
      }
    }
  }

  _handleUpdateEquipmentSetup(type) {
    let dataList;
    const buildingId = this.buildingId;
    const frameworkId = this.surveyFramework[this.frameWorkIndex].framework_id;

    if (type === "equipment") {
      dataList = toJS(this.createEquipmentList);
    } else if (type === "purchasedElectricity") {
      dataList = toJS(this.createPurchasedList);
    } else if (type === "transportation") {
      dataList = toJS(this.createTransportationList);
    }

    if (dataList) {
      const ValidatedErr = CreateEquipmentValidation(dataList, this.setUp);
      this.err = ValidatedErr;
      if (ValidatedErr.equipmentNameErr) {
        toast.error(this.err.equipmentNameErr);
        document.getElementById("equipmentName").focus();
      } else if(ValidatedErr.supplierErr) {
        toast.error(this.err.supplierErr);
        document.getElementById("supplier").focus();
      } else if (ValidatedErr.categoryErr) {
        toast.error(this.err.categoryErr);
      } else if (ValidatedErr.unitErr) {
        toast.error(this.err.unitErr);
      } else if (ValidatedErr.fuelTypeErr) {
        toast.error(this.err.fuelTypeErr);
      } else if (ValidatedErr.recordPeriodErr) {
        toast.error(this.err.recordPeriodErr);
      } else if (ValidatedErr.brandErr) {
        toast.error(this.err.brandErr);
      } else if (ValidatedErr.energySourceErr) {
        toast.error(this.err.energySourceErr);
      } else if (ValidatedErr.calculationApproachErr) {
        toast.error(this.err.calculationApproachErr);
      } else if (ValidatedErr.activityTypeErr) {
        toast.error(this.err.activityTypeErr);
      } else if (ValidatedErr.vehicleTypeErr) {
        toast.error(this.err.vehicleTypeErr);
      } else if (ValidatedErr.modelErr) {
        toast.error(this.err.modelErr);
      } else if (ValidatedErr.nameErr) {
        toast.error(this.err.nameErr);
        document.getElementById("name").focus();
      }

      if (Object.keys(ValidatedErr).length === 0) {
        if (buildingId && frameworkId) {
          if (dataList.category === "Mobile Combustion") {
            const bodyData = {
              _id: dataList._id,
              active: dataList.active,
              activityType: dataList.activityType,
              category: dataList.category,
              commissionedDate: dataList.commissionedDate,
              fuelType: dataList.fuelType,
              refrigerantsUsed: dataList.fuelType,
              brand: dataList.brand,
              equipmentName: dataList.equipmentName,
              recordPeriod: dataList.recordPeriod,
              unit: dataList.unit,
              vehicleType: dataList.vehicleType,
            };
            updateEquipmentSetup(
              buildingId,
              frameworkId,
              this.setUp,
              bodyData,
              (err, data) => {
                if (err) {
                  toast.error(err);
                } else {
                  if (!data.error) {
                    toast.success(data.payload);
                    this.getEquipmentList();
                    document
                      .getElementById("create_setUp")
                      .setAttribute("data-bs-dismiss", "modal");
                    let clickEvent = new MouseEvent("click", {
                      view: window,
                      bubbles: true,
                      cancelable: false,
                    });
                    document
                      .getElementById("create_setUp")
                      .dispatchEvent(clickEvent);

                    document
                      .getElementById("create_setUp")
                      .removeAttribute("data-bs-dismiss");

                    if (this.isSetup) {
                      this.getEquipmentList();
                    } else {
                      const frameworkId =
                        this.surveyFramework[this.frameWorkIndex]?.framework_id;
                      this.getAllQuestion(frameworkId);
                    }
                  } else toast.error(data.error);
                }
              }
            );
          } else {
            updateEquipmentSetup(
              buildingId,
              frameworkId,
              this.setUp,
              dataList,
              (err, data) => {
                if (err) {
                  toast.error(err);
                } else {
                  if (!data.error) {
                    toast.success(data.payload);
                    this.getEquipmentList();
                    document
                      .getElementById("create_setUp")
                      .setAttribute("data-bs-dismiss", "modal");
                    let clickEvent = new MouseEvent("click", {
                      view: window,
                      bubbles: true,
                      cancelable: false,
                    });
                    document
                      .getElementById("create_setUp")
                      .dispatchEvent(clickEvent);

                    document
                      .getElementById("create_setUp")
                      .removeAttribute("data-bs-dismiss");

                    if (this.isSetup) {
                      this.getEquipmentList();
                    } else {
                      const frameworkId =
                        this.surveyFramework[this.frameWorkIndex]?.framework_id;
                      this.getAllQuestion(frameworkId);
                    }
                  } else toast.error(data.error);
                }
              }
            );
          }
        }
      }
    }
  }

  _handleAlertForOtherAction() {
    runInAction(() => {
      this.showDialog = true;    
      this.clickManualAction = true;
    });
  }

  _handleSectionClick(index) {
    this.setUp = index + 1;
    if (
      Object.keys(this.answerObj)?.length === 0 &&
      this.answerArray?.length === 0 &&
      !this.isNewVal
    ) {
      runInAction(() => {
        this.status = undefined;
        this.sectionIndex = index;
        this.subSectionIndex = 0;
        this.answerArray = [];
        this.isSetup = false;
        this.isManualSetup = false;
        this.scope = index + 1;
      });
      this.getAllSection(this.surveyFramework[this.frameWorkIndex].id);
    } else {
      runInAction(() => {
        this.expectedSectionIndex = index;
        this.showDialog = true;
        this.clickManualAction = false;
      });
    }
  }

  _handleChangeInput(e, type) {
    const key = e.target.id;
    const val = e.target.value;
    const newObj =
      type === "equipment"
        ? { ...this.createEquipmentList }
        : type === "purchasedElectricity"
        ? { ...this.createPurchasedList }
        : type === "customEmissionFactor"
        ? { ...this.customEmissionFactor }
        : { ...this.createTransportationList };
    newObj[key] = val;
    runInAction(() => {
      if (type === "equipment") {
        this.createEquipmentList = newObj;
      } else if (type === "purchasedElectricity") {
        this.createPurchasedList = newObj;
      } else if (type === "transportation") {
        this.createTransportationList = newObj;
      } else if (type === "customEmissionFactor") {
        this.customEmissionFactor = newObj;
      }
    });
  }

  _handleChangeDate(e, type) {
    const newObj =
      type === "equipment"
        ? { ...this.createEquipmentList }
        : type === "purchasedElectricity"
        ? { ...this.createPurchasedList }
        : { ...this.createTransportationList };
    newObj["commissionedDate"] = e;
    runInAction(() => {
      if (type === "equipment") {
        this.createEquipmentList = newObj;
      } else if (type === "purchasedElectricity") {
        this.createPurchasedList = newObj;
      } else if (type === "transportation") {
        this.createTransportationList = newObj;
      }
    });
  }

  _handleSelectEquip = (key, e, type) => {
    const newObj =
      type === "equipment"
        ? { ...this.createEquipmentList }
        : type === "purchasedElectricity"
        ? { ...this.createPurchasedList }
        : { ...this.createTransportationList };
    newObj[key] = e.label;

    runInAction(() => {
      if (type === "equipment") {
        if (newObj.category === "Mobile Combustion") {
          if (key === "activityType") {
            newObj.unit = "";
          } else if (key === "fuelType") {
            newObj.vehicleType = "";
          } else if (key === "category"){
            ////// after choosing category, all selections will be clear //////
            newObj.deviceType = "";
            newObj.recordPeriod = "";
            newObj.equipmentName = "";
            // newObj.deviceType = "";
            newObj.fuelType = "";
            newObj.brand = "";
            newObj.unit = "";
            newObj.commissionedDate = new Date();
            newObj.active= true;
          }
          this.createEquipmentList = newObj;
        } else {
          if(key === "calculateMethod"){
            newObj.fuelType = e.label;
          }else if(key === "deviceType"){
            newObj.fuelType = "";
            newObj.calculateMethod = "";
          }else if (key === "category"){
            ////// after choosing category, all selections will be clear //////
            newObj.deviceType = "";
            newObj.recordPeriod = "";
            newObj.equipmentName = "";
            newObj.fuelType = "";
            newObj.brand = "";
            newObj.unit = "";
            newObj.commissionedDate = new Date();
            newObj.active= true;
          }
          this.createEquipmentList = newObj;
        }
      } else if (type === "purchasedElectricity") {
        if (key === "calculationApproach") {
          newObj.calculateType = e.value;
          this.getCalApproaches(e.value, newObj.emissionFactor, "scope2");
        }
        this.createPurchasedList = newObj;
      } else if (type === "transportation") {
        this.createTransportationList = newObj;
      }
    });
  };

  _handleManualInput() {
    if (
      Object.keys(this.answerObj)?.length > 0 ||
      this.answerArray?.length > 0 ||
      this.isNewVal
    ) {
      this.showDialog = true;
      this.clickManualAction = true;
      this.toClickSetupMenu = "manualEntry";
    } else {
      this.isSetup = false;
      this.isManualSetup = true;
      this.yearOptions = [
        { value: "2019", label: "2019" },
        { value: "2020", label: "2020" },
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" },
      ];
    }
  }

  _handleSetup() {
    if (
      Object.keys(this.answerObj)?.length > 0 ||
      this.answerArray?.length > 0 ||
      this.isNewVal
    ) {
      this.showDialog = true;
      this.clickManualAction = false;
      this.toClickSetupMenu = "setup";
    } else {
      this.isSetup = true;
      this.isManualSetup = false;
    }
  }

  _handlePageClick(index) {
    this.status = undefined;
    runInAction(() => {
      this.subSectionIndex = index;
    });
  }

  _handleNextClick() {
    this.status = undefined;
    if (this.subSectionArray.length !== this.subSectionIndex + 1) {
      ++this.subSectionIndex;
    }
    const subSection = toJS(this.subSectionArray),
      answerAry = toJS(this.answerArray);
    let findAnswer;
    const findSubSection = subSection.filter((sub) => {
      return answerAry.some((ans) => {
        return sub._id === ans.subSectionId;
      });
    });
    /////////compare allsurvey array and answer array/////////
    for (let v = 0; v < findSubSection.length; v++) {
      for (let vv = 0; vv < findSubSection[v].questions.length; vv++) {
        let questions = findSubSection[v].questions;
        for (let vvv = 0; vvv < answerAry.length; vvv++) {
          if (questions[vv]._id == answerAry[vvv].questionId) {
            findAnswer = [...findSubSection];
            findAnswer[v].questions[vv].answer = answerAry[vvv].answer;
          }
        }
      }
    }
    for (let i = 0; i < subSection.length; i++) {
      for (let j = 0; j < findAnswer.length; j++) {
        if (subSection[i]._id === findAnswer[j]._id) {
          subSection[i] = findAnswer[j];
        }
      }
    }
    this.subSectionArray = subSection;
    this.ansTotalPercent = this.getAnsPercentByEachSection(subSection);
    //
  }

  _handlePreviousClick() {
    this.status = undefined;
    this.subSectionIndex = this.subSectionIndex - 1;
  }

  //frameworkId changed from surveyHeaderId
  getAllQuestion(frameworkId) {
    runInAction(() => {
      this.subSectionArray = [];
    });
    if (this.surveyInfo.surveySection) {
      fetchQuestion(
        {
          //surveyHeaderId: surveyHeaderId,
          buildingId: this.buildingId,
          frameworkId,
          section: this.surveyInfo.surveySection[this.sectionIndex]._id,
          title: this.surveyInfo.surveyTitle,
          year: this.surveyYear,
          scope: this.scope,
        },
        (err, data) => {
          if (data) {
            if (data.error) {
              toast.error(data.error);
            } else {
              runInAction(() => {
                this.subSectionArray = data.payload;
                this.ansTotalPercent = this.getAnsPercentByEachSection(
                  this.subSectionArray
                );
              });

              //get year selector from target Setting's baseline year
              let baselineYear = target.settingTable.find(
                (v) => v.dataSource == `Scope${this.scope} Emission`
              )?.baselineYear;
              if (!baselineYear) {
                //if basline year not exist
                //get start year data from Equipment/PurchaseElectricity
                const years = data.payload
                  ?.reduce((r, c) => {
                    const R = [...r];
                    for (let i = 0; i < c?.equipmentData?.length; i++) {
                      const year =
                        new Date(
                          c?.equipmentData[i]?.commissionedDate
                        ).getFullYear() || c?.equipmentData[i]?.year;
                      R.push(year);
                    }

                    return R;
                  }, [])
                  ?.sort();

                baselineYear = years[0];
              }

              //create year options list
              if (baselineYear) {
                let yearOptions = [];
                for (
                  let i = Number(baselineYear);
                  i <= new Date().getFullYear();
                  i++
                ) {
                  yearOptions.push({
                    value: String(i),
                    label: String(i),
                  });
                }
                this.yearOptions = yearOptions;
              } else {
                //if baselineYear not found in above two way, started year as 2019
                let yearOptions = [];
                for (let i = 2020; i <= new Date().getFullYear(); i++) {
                  yearOptions.push({
                    value: String(i),
                    label: String(i),
                  });
                }
                this.yearOptions = yearOptions;
              }
            }
          }
        }
      );
    }
  }

  getAnsPercentByEachSection(data) {
    let ansList = 0,
      quesList = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].childSections.length > 0) {
        let childSec = data[i].childSections;
        for (let ii = 0; ii < childSec.length; ii++) {
          for (let iii = 0; iii < childSec[ii].questions.length; iii++) {
            quesList++;
            ansList = this.findAnswer(
              childSec[ii].questions[iii].answer,
              ansList
            );
          }
        }
      }
      for (let j = 0; j < data[i].questions.length; j++) {
        let ques = data[i].questions[j];
        quesList++;
        ansList = this.findAnswer(ques.answer, ansList);
      }
    }
    //
    const ansPercentage = (ansList * 100) / quesList;
    const result = {
      section: this.sectionIndex,
      percentage: parseFloat(ansPercentage.toFixed(2)),
    };
    //
    return result;
  }

  findAnswer = (answer, ansList) => {
    if (
      answer !== "" &&
      answer !== null &&
      answer !== undefined &&
      !Array.isArray(answer)
    ) {
      ansList++;
    }
    if (Array.isArray(answer) && answer.length > 0) {
      ansList++;
    }
    return ansList;
  };

  _handleTextChange = (
    e,
    questionId,
    childSection,
    questionType,
    questionIndex,
    dynamicDataId,
    linkedFileIdValue,
    desValue
  ) => {
    this.status = undefined;
    const subSectionId = this.subSectionArray[this.subSectionIndex]._id;
    // if (other) {
    this.answerObj =
      this.subSectionArray[this.subSectionIndex].childSections.length > 0 &&
      childSection
        ? {
            questionId: questionId,
            subSectionId: subSectionId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
            childSection: {
              questionId,
              answer: e.target.value,
              childSectionHeader: childSection?.childSectionHeader,
              subSectionIndex: this.subSectionIndex,
              sectionIndex: this.sectionIndex,
              questionIndex: questionIndex,
              dynamicDataId: dynamicDataId,
            },
          }
        : {
            answer: e.target.value,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            linkedFileId: linkedFileIdValue,
            description: desValue,
            dynamicDataId: dynamicDataId,
          };
    // } else {
    //   this.answerObj =
    //     this.subSectionArray[this.subSectionIndex].childSections.length > 0
    //       ? {
    //           questionId: questionId,
    //           subSectionId: subSectionId,
    //           childSection: {
    //             questionId,
    //             answer: e.target.value,
    //             childSectionHeader: childSection.childSectionHeader,
    //           },
    //         }
    //       : {
    //           answer: e.target.value,
    //           questionId: questionId,
    //           subSectionId: subSectionId,
    //         };
    // }

    //Checking answerArray for answered or not condition
    const isAnswer = this.answerArray.findIndex((ans) =>
      this.subSectionArray[this.subSectionIndex].childSections.length > 0 &&
      childSection
        ? ans.childSection.questionId === questionId &&
          ans.subSectionId === subSectionId
        : ans.questionId === questionId && ans.subSectionId === subSectionId 
    );

    //If new input to ans array, create new
    if (isAnswer === -1) {
      // this.answerArray.push(this.answerObj);
      runInAction(() => {
        this.isNewVal = true;
        this.answerArray = [...this.answerArray, this.answerObj];
      });
    } else {
      this.isNewVal = true;
      this.answerArray[isAnswer] = this.answerObj;
    }
  };

  _handleSelectAnswer(e, questionId, questionIndex, linkedFileIdValue, desValue) {
    this.status = "";
    const subSectionId = this.subSectionArray[this.subSectionIndex]._id;
    this.ghgFuelType = e.label;

    if (e.label === i18n.t("Others", { ns: "questions" })) {
      this.answerObj = {
        answer: "",
        questionId: questionId,
        subSectionId: subSectionId,
        subSectionIndex: this.subSectionIndex,
        sectionIndex: this.sectionIndex,
        questionIndex: questionIndex,
        linkedFileId: linkedFileIdValue,
        description: desValue,
      };
    } else {
      this.answerObj = {
        answer: e.value,
        questionId: questionId,
        subSectionId: subSectionId,
        subSectionIndex: this.subSectionIndex,
        sectionIndex: this.sectionIndex,
        questionIndex: questionIndex,
        linkedFileId: linkedFileIdValue,
        description: desValue,
      };
    }

    //Checking answerArray for answered or not condition
    const isAnswer = this.answerArray.findIndex(
      (ans) =>
        ans.questionId === questionId && ans.subSectionId === subSectionId
    );

    //If new input to ans array, create new
    if (isAnswer === -1) {
      // this.answerArray.push(this.answerObj);
      runInAction(() => {
        this.answerArray = [...this.answerArray, this.answerObj];
      });
      if (e.label !== i18n.t("Others", { ns: "questions" })) {
        this._handleAnswerSubmit(
          this.surveyFramework[this.frameWorkIndex].framework_id,
          this.buildingId
        );
      }
    } else {
      //
      this.answerArray[isAnswer] = this.answerObj;
      if (e.label !== i18n.t("Others", { ns: "questions" })) {
        this._handleAnswerSubmit(
          this.surveyFramework[this.frameWorkIndex].framework_id,
          this.buildingId
        );
      }
    }
    //
  }

  _handleRadioChange = (
    ansOpt,
    questionId,
    childSection,
    questionType,
    questionIndex,
    dynamicDataId,
    linkedFileIdValue,
    desValue,
  ) => {

    if (questionType == "no_inputByuser" && ansOpt.text !== "Yes") {
      this.status = undefined;
    } else {
      this.status = "";
    }

    const subSectionId = this.subSectionArray[this.subSectionIndex]._id;
    //
    this.answerObj =
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? {
            questionId: questionId,
            subSectionId: subSectionId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
            childSection: {
              questionId,
              answer: ansOpt._id,
              childSectionHeader: childSection.childSectionHeader,
              subSectionIndex: this.subSectionIndex,
              sectionIndex: this.sectionIndex,
              questionIndex: questionIndex,
              dynamicDataId: dynamicDataId,
            },
          }
        : {
            answer:
              questionType == "no_inputByuser" &&
              ansOpt.text == i18n.t("No", { ns: "questions" })
                ? ""
                : ansOpt._id,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
          };

    //Checking answerArray for answered or not condition
    const isAnswer = this.answerArray.findIndex((ans) =>
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? ans.childSection && ans.childSection.length > 0
          ? ans.childSection?.questionId === questionId &&
            ans.subSectionId === subSectionId
          : ans.questionId === questionId
        : ans.questionId === questionId && ans.subSectionId === subSectionId
    );

    //If new input to ans array, create new
    if (isAnswer === -1) {
      runInAction(() => {
        this.answerArray = [...this.answerArray, this.answerObj];
        if (questionType != "no_inputByuser") {
          this._handleAnswerSubmit(
            this.surveyFramework[this.frameWorkIndex].framework_id,
            this.buildingId
          );
        } else if (questionType === "no_inputByuser" && ansOpt.text === "Yes") {
          this._handleAnswerSubmit(
            this.surveyFramework[this.frameWorkIndex].framework_id,
            this.buildingId
          );
        }
      });
    } else {
      const newArr = [...this.answerArray];
      newArr[isAnswer] = this.answerObj;
      runInAction(() => {
        this.answerArray = newArr;
        if (questionType != "no_inputByuser") {
          this._handleAnswerSubmit(
            this.surveyFramework[this.frameWorkIndex].framework_id,
            this.buildingId
          );
        }
      });
    }
  };

  _handleAnswerSubmit = (frameworkId, buildingId, submitAndExit) => {
    this.status = "";
   
    const current_section =
      this.surveyInfo.surveySection[this.sectionIndex]?.sectionName;
    
    if (this.ghgDataType === "Category") {
      const params = {
        frameworkId,
        buildingId,
      };
      const subSectionHeader =
        this.subSectionArray?.[this.subSectionIndex]?.subSectionHeader;
      this._handleCategorySubmit(); ///////category file submit only///////////s
      if(this.answerArray.length > 0){
        fetchManualByCategoryAnswer(params, this.answerArray, (err, data) => {
          if (!data.error) {
            // toast.success(data.payload);
  
            this.status = "Updated Successfully!";
            this.answerObj = {};
            setTimeout(function () {
              runInAction(() => {
                this.status = undefined;
              });
            }, 4000);
  
            this.answerArray = [];
            if (data.success) {
              setTimeout(function () {
                this.getManualEntryDataByCategory(frameworkId, subSectionHeader);
              }, 400);
            }
  
            if (submitAndExit === "yes") {
              let count0 = 0;
              var myInterval0 = setInterval(function () {
                count0++;
  
                if (count0 === 2) {
                  clearInterval(myInterval0);
                } else {
                  window.location.pathname = "/home";
                }
              }, 6000);
            }
          }
        });
      }
    } else {
      const params = {
        frameworkId,
        buildingId,
        year: this.surveyYear,
        scope: this.scope,
      };

      // const filterAry = this.answerArray;
      // filterAry.map(v => {
      //   const value = v;
      //   delete value.other;
      //   return value;
      // });
      fetchAnswer(params, this.answerArray, (err, data) => {
        if (!data.error) {
          // toast.success(data.payload);
          this.status = "Updated Successfully!";
          this.answerObj = {};
          setTimeout(function () {
            runInAction(() => {
              this.status = undefined;
            });
          }, 4000);
          runInAction(() => {
            this.answerArray = [];
            this.editedScope2Month = [];
            // this.answerObj={}
            this.getAllQuestion(frameworkId);
          });
          if (submitAndExit === "yes") {
            let count0 = 0;
            var myInterval0 = setInterval(function () {
              count0++;

              if (count0 === 2) {
                clearInterval(myInterval0);
              } else {
                window.location.pathname = "/home";
              }
            }, 6000);
          }
        }
      });
    }

    this.isNewVal = false;
  };

  _handleCalculateCheckStatus = (scope) => {
    const frameworkId = this.surveyFramework[this.frameWorkIndex]?.framework_id;
    
    checkManualEntry(
      this.buildingId,
      frameworkId,
      this.surveyYear,
      `scope${scope}Emission`,
      (err, data) => {
        if (data.payload) {
          if (data.payload?.isManualEntryData === true) {
            this.manualCheckStatus = true;

            document
              .getElementById(`calculateScope${scope}`)
              .setAttribute("data-bs-toggle", "modal");
            document
              .getElementById(`calculateScope${scope}`)
              .setAttribute("data-bs-target", "#calculateModal");
            let clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            document
              .getElementById(`calculateScope${scope}`)
              .dispatchEvent(clickEvent);
            this._handleSelectField(`carbonEmission`);
          } else {
            this._handleCalculateEmissionBaseOnUsage(scope);
          }
        }
      }
    );
  };

  _handleCalculateEmissionBaseOnUsage = (scope) => {
    const frameworkId = this.surveyFramework[this.frameWorkIndex]?.framework_id;
    calculateEmissionBaseOnUsage(
      this.buildingId,
      frameworkId,
      this.surveyYear,

      (err, data) => {
        if (!data.error) {
          toast.success(
            "Successfully calculated carbon emission base on usage."
          );
        }
      }
    );
  };

  _handleGetEmissionByCalculatedMethod = (scope) => {  
    let type = null;
    let calculatedMethod = null;
    let unit = null
    let emissionFactor = null;
    let vehicleType = null;
    let category = null;    
    if(scope === 'scope1'){
        if(this.createEquipmentList.category === 'Refrigerants and Fugitives'){
            type = "Refrigerants";
            calculatedMethod = this.createEquipmentList?.calculateMethod;
        }else if(this.createEquipmentList.category === "Mobile Combustion"){
            type = "Mobile Combustion";
            vehicleType = this.createEquipmentList?.vehicleType;
            calculatedMethod = this.createEquipmentList?.fuelType;
        }else{
            type = "Fuel Type";
            calculatedMethod = this.createEquipmentList?.fuelType;
        }
        
        unit = this.createEquipmentList.unit;
        emissionFactor = null;
        category = this.createEquipmentList.category;
    }else if(scope === 'scope2'){
        if(this.createPurchasedList.calculationApproach === "Purchased Electricity - Location Based"){
            type = "Location Based";
        }else if(this.createPurchasedList.calculationApproach === "Purchased Electricity - Market Based"){
            type = "Market Based";
        }else{
            type = "Steam and Heat";
        }

        calculatedMethod = this.createPurchasedList.calculateMethod;
        unit = this.createPurchasedList.unit;
        emissionFactor = this.createPurchasedList.emissionFactor;
        category = this.createPurchasedList.category;
    }else{
        type = "Lookup name";
        calculatedMethod = this.createTransportationList.vehicleType;
        emissionFactor = this.createTransportationList.emissionFactor;
        category = this.createTransportationList.category;
    }

    getEmissionByCalculatedMethod(
      type,
      calculatedMethod,
      unit,
      emissionFactor,
      scope,
      category,
      vehicleType,
      (err, data) => {
        if (data) {
          if (data.error) {
            toast.error(data.error);
          } else {
            runInAction(() => {
              if(scope === 'scope1'){
                this.createEquipmentList.emissionFactorValue = data.payload;
              }else if(scope === 'scope2'){
                this.createPurchasedList.emissionFactorValue = data.payload;                
              }else{
                this.createTransportationList.emissionFactorValue = data.payload;
              }              
            });
          }
        }
      }
    );
  };

  _handleGetS1EmissionVal = () => {
    const params = {
      category: this.createEquipmentList.category,
      deviceType: this.createEquipmentList.deviceType,
      fuelType: this.createEquipmentList.fuelType,
      unit: this.createEquipmentList.unit,
      scope: 1
    };
    getS1EmissionVal(params, (err, data) => {
      if (data) {
        if (data.error) {
          toast.error(data.error);
        } else {
          runInAction(() => {
            this.createEquipmentList.emissionFactorValue = data.payload;
          });
        }
      }
    });
  };

  _handleSurveyClick = ({ index, surveyTitle, accessmentList }) => {
    runInAction(() => {
      this.status = undefined;
      this.frameWorkIndex = index;
      this.sectionIndex = 0;
      this.subSectionIndex = 0;
      this.surveyInfo.surveyTitle = surveyTitle;

      // if (toJS(this.surveyFramework[index])?.name?.includes("GHG")) {

      this.surveyInfo.surveyPeriod = null;

      if (
        toJS(
          accessmentList?.find(
            (v) => v._id === this.surveyFramework[index]?.framework_id
          )?.frameworkAction
        )?.includes("Manual Entry")
      ) {
        this.isManualSetup = true;
      } else {
        this.isManualSetup = false;
      }

      this.isSetup = false;
    });
    this.getAllSection(this.surveyFramework[index].id);

    this.getAllQuestion(this.surveyFramework[index].id);
  };

  getAllSurvey(buildingId) {
    fetchSurveyHeader(buildingId, (err, data) => {
      if (data) {
        const { surveyHeader } = data.payload;
        runInAction(() => {
          this.buildingId = buildingId;
          this.frameWorkIndex = 0;
          this.surveyFramework = surveyHeader;
          this.getAllSection(buildingId);

          if (toJS(this.surveyFramework[this.frameWorkIndex])?.name === "GHG") {
            this.isManualSetup = true;
          } else {
            this.isManualSetup = false;
          }

          this.isSetup = false;
        });
      } else {
        // toast.error(err);
      }
    });

    const getAccessmentFramework = common.getAccessmentFramework;
    getAccessmentFramework().then((accessmentList) => {
      fetchSurveyHeader(buildingId, (err, data) => {
        if (data) {
          const { surveyHeader } = data.payload;
          runInAction(() => {
            this.buildingId = buildingId;
            this.frameWorkIndex = 0;
            this.surveyFramework = surveyHeader;
            this.getAllSection(buildingId);
            if (
              toJS(
                accessmentList?.find(
                  (v) =>
                    v._id ===
                    this.surveyFramework[this.frameWorkIndex]?.framework_id
                )?.frameworkAction
              )?.includes("Manual Entry")
            ) {
              this.isManualSetup = true;
            } else {
              this.isManualSetup = false;
            }

            this.isSetup = false;
          });
        } else {
          // toast.error(err);
        }
      });
    });
  }

  _handleOpenAllQuestions() {
    runInAction(() => {
      this.isOpenAllQuestions = true;
    });
  }
  _handleCloseAllQuestions() {
    runInAction(() => {
      this.isOpenAllQuestions = false;
    });
  }

  _handleNumberChange = (
    e,
    questionId,
    childSection,
    questionType,
    questionIndex,
    dynamicDataId,
    monthIndex,
    linkedFileIdValue,
    desValue
  ) => {
    this.status = undefined;
    const subSectionId = this.subSectionArray[this.subSectionIndex]._id;

    this.answerObj =
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? {
            questionId: questionId,
            subSectionId: subSectionId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
            childSection: {
              questionId,
              answer: e.target.value,
              childSectionHeader: childSection.childSectionHeader,
              subSectionIndex: this.subSectionIndex,
              sectionIndex: this.sectionIndex,
              questionIndex: questionIndex,
              
            },
          }
        : monthIndex !== null
        ? {
            answer: e.target.value,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            monthIndex: monthIndex,
            linkedFileId: linkedFileIdValue,
            description: desValue,
          }
        : {
            answer: e.target.value,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
          };
   
    //Checking answerArray for answered or not condition
    const isAnswer = this.answerArray.findIndex((ans) =>
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? ans.childSection.questionId === questionId &&
          ans.subSectionId === subSectionId
        : ans.questionId === questionId &&
          ans.subSectionId === subSectionId &&
          ans.dynamicDataId === dynamicDataId
    );

    //If new input to ans array, create new
    if (isAnswer === -1) {
      // this.answerArray.push(this.answerObj);
      runInAction(() => {
        this.isNewVal = true;
        this.answerArray = [...this.answerArray, this.answerObj];
      });
    } else {
      this.isNewVal = true;
      this.answerArray[isAnswer] = this.answerObj;
    }
  };

  _handleSelectYear = (label, key) => {
    if (key === "baselineYear") {
      this.selectedBaseLineYear = label;
    } else {
      runInAction(() => {
        this.isManualEditMode = true;
        this.selectedYear = label;
        const selectedManualEntryData = this.allManualEntryData?.find(
          (v) => String(v.year) === String(label)
        );
        const targetData =
          selectedManualEntryData?.manualEntryData || this.emptyManualEntryData;
        // const filteredKeyMData = targetData.map(v=> {
        //   const data = {
        //     month: v.month,
        //     [this.selectedField]: v[this.selectedField]
        //   }
        //   return data
        // })
        this.manualEntryData =
          targetData?.length > 0
            ? this.emptyManualEntryData.reduce((r, c) => {
                const R = [...r];
                const index = targetData.findIndex((d) => d.month === c.month);
                if (index > -1) {
                  R.push({
                    ...targetData[index],
                    energyConsumption:
                      targetData[index]?.energyConsumption?.toFixed(2),
                    waterConsumption:
                      targetData[index]?.waterConsumption?.toFixed(2),
                    scope1Emission:
                      targetData[index]?.scope1Emission?.toFixed(2),
                    scope2Emission:
                      targetData[index]?.scope2Emission?.toFixed(2),
                    scope3Emission:
                      targetData[index]?.scope3Emission?.toFixed(2),
                  });
                } else {
                  R.push({
                    ...c,
                  });
                }
                return R;
              }, [])
            : this.emptyManualEntryData;
      });
    }
  };

  _handleGetBaseLineYear = (baseLineList) => {
    let source;
    if(this.selectedField === "carbonEmission"){
      source = this.selectedCarbonTab === "scope1Emission"?
       "Scope1 Emission" :
        this.selectedCarbonTab === "scope2Emission" 
        ? "Scope2 Emission" : "Scope3 Emission";
    }else {
      source = this.entryFields.find(v => v.key === this.selectedField)?.label;
    }
    const baseLineYear = baseLineList.find(v => v.dataSource === source)?.baselineYear;

    if (baseLineYear) {
      let yearOptions = [];
      for ( let i = Number(baseLineYear); i <= new Date().getFullYear(); i++) 
        {
        yearOptions.push({
          value: String(i),
          label: String(i),
        });
      }
      this.yearOptions = yearOptions;
    } 
    else {
      let yearOptions = [];
      for (let i = 2020; i <= new Date().getFullYear(); i++) {
        yearOptions.push({
          value: String(i),
          label: String(i),
        });
      }
      this.yearOptions = yearOptions;
    }
  }

  _handleSelectField = (key) => {
    runInAction(() => {
      this.selectedField = key;
      const year =
        this.manualCheckStatus === true ? this.surveyYear : this.selectedYear;
      const selectedManualEntryData = this.allManualEntryData?.find(
        (v) => String(v.year) === String(year)
      );
      const targetData =
        selectedManualEntryData?.manualEntryData || this.emptyManualEntryData;
      // const filteredKeyMData = targetData.map(v=> {
      //   const data = {
      //     month: v.month,
      //     [key]: v[key]
      //   }
      //   return data
      // });
      this.manualEntryData =
        targetData?.length > 0
          ? this.emptyManualEntryData.reduce((r, c) => {
              const R = [...r];
              const index = targetData.findIndex((d) => d.month === c.month);
              if (index > -1) {
                R.push({
                  ...targetData[index],
                  energyConsumption:
                    targetData[index]?.energyConsumption?.toFixed(2),
                  waterConsumption:
                    targetData[index]?.waterConsumption?.toFixed(2),
                  scope1Emission: targetData[index]?.scope1Emission?.toFixed(2),
                  scope2Emission: targetData[index]?.scope2Emission?.toFixed(2),
                  scope3Emission: targetData[index]?.scope3Emission?.toFixed(2),
                });
              } else {
                R.push({
                  ...c,
                });
              }
              return R;
            }, [])
          : this.emptyManualEntryData;
    });
  };

  _handleSurveyFileChange = (
    linkedFileIdValue,
    questionId,
    childSection,
    questionIndex,
    dynamicDataId,
    monthIndex,
    desValue,
    answerValue
  ) => {

    if(linkedFileIdValue === "uploadBtn"){
      this.surveyFileUploadData.questionId = questionId;
      this.surveyFileUploadData.childSection = childSection;
      this.surveyFileUploadData.questionIndex = questionIndex;
      this.surveyFileUploadData.dynamicDataId = dynamicDataId;
      this.surveyFileUploadData.monthIndex = monthIndex;
      this.surveyFileUploadData.desValue = desValue;
      this.surveyFileUploadData.answerValue = answerValue;
    }

    if(!this.editedScope2Month.includes(dynamicDataId)){
      this.editedScope2Month.push(dynamicDataId);
    }

    this.status = undefined;
    const subSectionId = this.subSectionArray[this.subSectionIndex]._id;

    this.answerObj =
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? {
            questionId: questionId,
            subSectionId: subSectionId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
            childSection: {
              questionId,
              answer: answerValue,
              childSectionHeader: childSection.childSectionHeader,
              subSectionIndex: this.subSectionIndex,
              sectionIndex: this.sectionIndex,
              questionIndex: questionIndex,
            },
          }
        : monthIndex !== null ? {
            answer: answerValue,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            monthIndex: monthIndex,
            linkedFileId: linkedFileIdValue,
            description: desValue,
          }
        : {
            answer: answerValue,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            linkedFileId: linkedFileIdValue,
            description: desValue,
          };

    // Checking answerArray for answered or not condition
    const isAnswer = this.answerArray.findIndex((ans) =>
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? ans.childSection.questionId === questionId &&
          ans.subSectionId === subSectionId
        : ans.questionId === questionId &&
          ans.subSectionId === subSectionId &&
          ans.dynamicDataId === dynamicDataId
    );

    //If new input to ans array, create new
    if (isAnswer === -1) {
      // this.answerArray.push(this.answerObj);
      runInAction(() => {
        this.isNewVal = true;
        this.answerArray = [...this.answerArray, this.answerObj];
      });
    } else {
      this.isNewVal = true;
      this.answerArray[isAnswer] = this.answerObj;
    }
  };

  handleDataChange = (e, key, editMonth, desValue, desKey, btnId) => {
    if(btnId === "scope3_save_file_and_description"){
      const newArr = [...toJS(this.manualDataByCategory)];
      const index = newArr[0].manualEntryData?.findIndex(v=> v.month === editMonth);
      newArr[0].manualEntryData[index].description = desValue;
      newArr[0].manualEntryData[index].linkedFileId = e;

      const newObj = {
        catMonth: editMonth,
        category: newArr[0].category,
        description: desValue,
        linkedFileId: e,
        monthYear: editMonth+"_"+newArr[0].year,
        scope: newArr[0].scope,
        _id: newArr[0]._id
      };
      this.categoryArray = [newObj]
      this.manualDataByCategory = newArr;
      this.isNewVal = true;
    }else{
      if (!this.editedMonth.includes(editMonth)) {
        this.editedMonth.push(editMonth);
      }
      const newArr = [...this.manualEntryData];
      const index = newArr.findIndex((v) => v.month === editMonth);
      newArr[index][key] = e;
      newArr[index][desKey] = desValue;
      runInAction(() => {
        this.selectedMonth = editMonth;
        this.manualEntryData = newArr;
        this.isNewVal = true;
      });
    }
    
  };

  _handleManualInputChange = (e, key, editMonth) => {
    if (!this.editedMonth.includes(editMonth)) {
      this.editedMonth.push(editMonth);
    }

    const Month = e.target.id.split("-")[0];
    const newArr = [...this.manualEntryData];
    const index = newArr.findIndex((v) => v.month === Month);
    newArr[index][key] = (e.target.value).replaceAll(",","");
    runInAction(() => {
      this.manualEntryData = newArr;
      this.isNewVal = true;
    });
  };

  getManualEntryDataByYear = (frameworkId) => {
    fetchMaualEntryData(
      this.buildingId,
      frameworkId || this.surveyFramework[this.frameWorkIndex]?.framework_id,
      this.manualCheckStatus === false ? this.selectedYear : this.surveyYear,
      null,
      (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          if (!data.error && data.payload) {
            const selectedManualEntryData =
              data.payload.manual_entry_data?.find(
                (v) => String(v.year) === String(this.selectedYear)
              );
            const targetData =
              selectedManualEntryData?.manualEntryData ||
              this.emptyManualEntryData;
            // const filteredKeyMData = targetData.map(v=> {
            //   const data = {
            //     month: v.month,
            //     [this.selectedField]: v[this.selectedField]
            //   }
            //   return data
            // })

            this.manualEntryData =
              targetData?.length > 0
                ? this.emptyManualEntryData.reduce((r, c) => {
                    const R = [...r];
                    const index = targetData.findIndex(
                      (d) => d.month === c.month
                    );
                    if (index > -1) {
                      R.push({
                        ...targetData[index],
                        energyConsumption:
                          targetData[index]?.energyConsumption?.toFixed(2),
                        waterConsumption:
                          targetData[index]?.waterConsumption?.toFixed(2),
                        scope1Emission:
                          targetData[index]?.scope1Emission?.toFixed(2),
                        scope2Emission:
                          targetData[index]?.scope2Emission?.toFixed(2),
                        scope3Emission:
                          targetData[index]?.scope3Emission?.toFixed(2),
                      });
                    } else {
                      R.push({
                        ...c,
                      });
                    }
                    return R;
                  }, [])
                : this.emptyManualEntryData;
            this.siteRelevantId = data.payload._id;
            this.allManualEntryData = data.payload?.manual_entry_data;
          } else {
            // const filteredKeyMData = this.emptyManualEntryData.map(v=> {
            //   const data = {
            //     month: v.month,
            //     [this.selectedField]: v[this.selectedField]
            //   }
            //   return data
            // })
            this.manualEntryData = this.emptyManualEntryData;
          }
        }
      }
    );
  };

  _handleShowModel = () => {
    runInAction(() => {
      this.showModel = false;
    });
  };

  _handleAddManualEntry = (frameworkId) => {
    addManualentryData(
      this.siteRelevantId,
      this.selectedYear,
      this.buildingId,
      frameworkId || this.surveyFramework[this.frameWorkIndex].framework_id,
      this.manualEntryData,
      (err, data) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success(data.payload || "Updated Successfully!");
          this.getManualEntryDataByYear();
          this.isNewVal = false;
          this.editedMonth = [];
        }
      }
    );
  };

  _handleSelectSetup = (e) => {
    runInAction(() => {
      this.setUp = e.value;
    });
  };

  _handleResetManualEntry = () => {
    runInAction(() => {
      const selectedManualEntryData = this.allManualEntryData?.find(
        (v) => String(v.year) === String(this.selectedYear)
      );
      const targetData =
        selectedManualEntryData?.manualEntryData || this.emptyManualEntryData;
      this.manualEntryData =
        targetData?.length > 0
          ? this.emptyManualEntryData.reduce((r, c) => {
              const R = [...r];
              const index = targetData.findIndex((d) => d.month === c.month);
              if (index > -1) {
                R.push({
                  ...targetData[index],
                  energyConsumption:
                    targetData[index]?.energyConsumption?.toFixed(2),
                  waterConsumption:
                    targetData[index]?.waterConsumption?.toFixed(2),
                  scope1Emission: targetData[index]?.scope1Emission?.toFixed(2),
                  scope2Emission: targetData[index]?.scope2Emission?.toFixed(2),
                  scope3Emission: targetData[index]?.scope3Emission?.toFixed(2),
                });
              } else {
                R.push({
                  ...c,
                });
              }
              return R;
            }, [])
          : this.emptyManualEntryData;
      this.isNewVal = false;
      this.isManualEditMode = false;
    });
  };

  _handleSelectSurveyYear = (e) => {
    this.status = undefined;
    this.surveyYear = e.label;
    this.getAllQuestion(
      this.surveyFramework?.[this.frameWorkIndex].framework_id
    );
    this.getAllSection(this.surveyFramework[this.frameWorkIndex].id);
  };

  download = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/pdf;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  _handlePreviewPDF = () => {
    fetchDownloadFramework(
      this.buildingId,
      this.surveyFramework[this.frameWorkIndex].framework_id
    ).then((res) => {
      res.arrayBuffer().then((v) => {
        const blob = new Blob([v], { type: "application/pdf" });
        const params = new URLSearchParams(window.location.search);
        const buildingName = params.get("name");
        this.previewPDFName =
          buildingName +
          " - " +
          this.surveyFramework[this.frameWorkIndex].name +
          ".pdf";
        this.pdfBlobLink = URL.createObjectURL(blob);
      });
    });
  };

  _handleDownload = () => {
    fetchDownloadFramework(
      this.buildingId,
      this.surveyFramework[this.frameWorkIndex].framework_id
    ).then((res) => {
      res.arrayBuffer().then((v) => {
        const blob = new Blob([v], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const params = new URLSearchParams(window.location.search);
        const buildingName = params.get("name");
        link.download =
          buildingName +
          " - " +
          this.surveyFramework[this.frameWorkIndex].name +
          ".pdf";
        link.click();
      });
    });
  };

  saveMoveToNext = (frameworkId, buildingId) => {
    this.showDialog = false;
    this.clickManualAction = false;

    if (
      Object.keys(this.answerObj)?.length > 0 ||
      this.answerArray?.length > 0
    ) {
      this._handleAnswerSubmit(frameworkId, buildingId);
    } else if (this.isNewVal) {
      this._handleAddManualEntry(frameworkId);
    }
    if (this.expectedSectionIndex !== null) {
      runInAction(() => {
        this.status = undefined;
        this.sectionIndex = this.expectedSectionIndex;
        this.subSectionIndex = 0;
        this.answerArray = [];
        this.isSetup = false;
        this.isManualSetup = false;
        this.expectedSectionIndex = null;
      });
      this.getAllSection(this.surveyFramework[this.frameWorkIndex].id);
    } else if (this.expectedFrameworkIndex !== null) {
      const accessmentList = common.accessmentList;
      runInAction(() => {
        this.status = undefined;
        this.frameWorkIndex = this.expectedFrameworkIndex;
        this.sectionIndex = 0;
        this.subSectionIndex = 0;
        this.surveyInfo.surveyTitle = this.expectedSurveyTitle;

        // if (toJS(this.surveyFramework[index])?.name?.includes("GHG")) {

        this.surveyInfo.surveyPeriod = null;

        if (
          toJS(
            accessmentList?.find(
              (v) =>
                v._id ===
                this.surveyFramework[this.expectedFrameworkIndex]?.framework_id
            )?.frameworkAction
          )?.includes("Manual Entry")
        ) {
          this.isManualSetup = true;
        } else {
          this.isManualSetup = false;
        }

        this.isSetup = false;
      });
    }
    if (this.toClickSetupMenu === "setup") {
      this.isSetup = true;
      this.isManualSetup = false;
    } else if (this.toClickSetupMenu === "manualEntry") {
      this.isSetup = false;
      this.isManualSetup = true;
      this.yearOptions = [
        { value: "2019", label: "2019" },
        { value: "2020", label: "2020" },
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" },
      ];
    }
    runInAction(() => {
      this._handleResetManualEntry();
      this.answerArray = [];
      this.answerObj = {};
      this.getAllSection(this.surveyFramework[this.frameWorkIndex]?.id);
    });
  };

  discardMoveToNext = () => {
    this.showDialog = false;
    if (this.expectedSectionIndex !== null) {
      runInAction(() => {
        this.status = undefined;
        this.sectionIndex = this.expectedSectionIndex;
        this.subSectionIndex = 0;
        this.answerArray = [];
        this.answerObj = {};
        this.isSetup = false;
        this.isManualSetup = false;
        this.expectedSectionIndex = null;
        this.isNewVal = false;
      });
      this._handleResetManualEntry();
      this.getAllSection(this.surveyFramework[this.frameWorkIndex].id);
    } else {
      // if (!this.clickManualAction) {
      //   const accessmentList = common.accessmentList;
      //   runInAction(() => {
      //     this.status = undefined;
      //     this.frameWorkIndex = this.expectedFrameworkIndex;
      //     this.sectionIndex = 0;
      //     this.subSectionIndex = 0;
      //     this.surveyInfo.surveyTitle = this.expectedSurveyTitle;
      //     this.expectedFrameworkIndex = null;
      //     this.expectedSurveyTitle = null;
      //     this.answerArray = [];
      //     this.answerObj = {};
      //     this.isNewVal = false;

      //     // if (toJS(this.surveyFramework[index])?.name?.includes("GHG")) {

      //     this.surveyInfo.surveyPeriod = null;

      //     if (
      //       toJS(
      //         accessmentList?.find(
      //           (v) =>
      //             v._id ===
      //             this.surveyFramework[this.frameWorkIndex]?.framework_id
      //         )?.frameworkAction
      //       )?.includes("Manual Entry")
      //     ) {
      //       this.isManualSetup = true;
      //     } else {
      //       this.isManualSetup = false;
      //     }
      //     this.isSetup = false;
      //   });
      // }

      if(this.clickManualAction === true) {
        this.isNewVal = false;
      }
      else if (this.toClickSetupMenu === "setup") {
        this.isSetup = true;
        this.isManualSetup = false;
      } else if (this.toClickSetupMenu === "manualEntry") {
        this.isSetup = false;
        this.isManualSetup = true;
        this.yearOptions = [
          { value: "2019", label: "2019" },
          { value: "2020", label: "2020" },
          { value: "2021", label: "2021" },
          { value: "2022", label: "2022" },
        ];
      }
      runInAction(() => {
        this._handleResetManualEntry();
        this.answerArray = [];
        this.answerObj = {};
        this.isManualSetup === false && this.getAllSection(this.surveyFramework[this.frameWorkIndex]?.id);
      });
    }


  };

  onDiscardClick = () => {
    this.showDialog = false;
    this.expectedSectionIndex = null;
    this.expectedFrameworkIndex = null;
    this.expectedSurveyTitle = null;
  };

  getCalApproaches = async(type, emissionFactor, scope) => {
    await fetchCalApproaches(type, emissionFactor, scope, (err, data) => {
      if (data) {
        const options = data.payload?.methods?.map((v) => {
          return {
            label: type === "" ? (v.sourceOfEmission+" - "+v.type) : v.sourceOfEmission,
            value: type === "" ? (v.sourceOfEmission+" - "+v.type) :v.sourceOfEmission,
          };
        });
        runInAction(() => {
          this.calApproachOptions = options;
        });
      } else {
        toast.error(err);
      }
    });

    return this.calApproachOptions;
  };

  _handleSelectCustomEmission = (key, e) => {
    const obj = { ...this.customEmissionFactor };
    obj[key] = e.value;
    runInAction(() => {
      this.customEmissionFactor = obj;
    });
  };

  // to solve conflict
  // _handleAddCustomEmission = () => {
  //   const ValidatedErr = CreateCustomCalApproachValidation(
  //     this.customEmissionFactor
  //   );
  //   if (ValidatedErr.typeErr) {
  //     toast.error(ValidatedErr.typeErr);
  //   } else if (ValidatedErr.customEFNameErr) {
  //     toast.error(ValidatedErr.customEFNameErr);
  //   } else if (ValidatedErr.sourceOfEmissionErr) {
  //     toast.error(ValidatedErr.sourceOfEmissionErr);
  //   } else if (ValidatedErr.co2FactorErr) {
  //     toast.error(ValidatedErr.co2FactorErr);
  //   } else {
  //     fetchAddCustomCalApproach(this.customEmissionFactor, (err, data) => {
  //       if (data) {
  // //         if (data.success) {
  // //           toast.success("Created Custom Emission Factor Successfully!");
  // //           this.customEmissionFactor = {
  // //             type: "",
  // //             customEFName: "",
  // //             sourceOfEmission: "",
  // //             co2Factor: null,
  // //             scope: "scope2",
  // //           };
  // //         }
  // //       } else {
  // //         toast.error(err);
  // //       }
  // //     });
  // //   }
  // // };
  //         const options = data.payload?.methods?.map(v => {
  //           return ({
  //             label: v.sourceOfEmission,
  //             value: v.sourceOfEmission,
  //           })
  //         });
  //         runInAction(() => {
  //           this.calApproachOptions = options
  //         });
  //       } else {
  //         toast.error(err);
  //       }
  //     }
  //   );
  // }

  _handleSelectCustomEmission = (key, e) => {
    const obj = { ...this.customEmissionFactor };
    obj[key] = e.value;
    runInAction(() => {
      this.customEmissionFactor = obj;
    });
  };

  _handleAddCustomEmission = () => {
    const ValidatedErr = CreateCustomCalApproachValidation(
      this.customEmissionFactor
    );
    if (ValidatedErr.typeErr) {
      toast.error(ValidatedErr.typeErr);
    } else if (ValidatedErr.customEFNameErr) {
      toast.error(ValidatedErr.customEFNameErr);
    } else if (ValidatedErr.sourceOfEmissionErr) {
      toast.error(ValidatedErr.sourceOfEmissionErr);
    } else if (ValidatedErr.co2FactorErr) {
      toast.error(ValidatedErr.co2FactorErr);
    } else {
      fetchAddCustomCalApproach(this.customEmissionFactor, (err, data) => {
        if (data) {
          if (data.success) {
            toast.success(data.payload);
            this.customEmissionFactor = {
              type: "",
              customEFName: "",
              sourceOfEmission: "",
              co2Factor: null,
              scope: "scope2",
            };
          } else {
            toast.error(data.payload);
          }
        } else {
          toast.error(err);
        }
      });
    }
  };

  _handleCancelCustomEmission = () => {
    this.customEmissionFactor = {
      type: "",
      customEFName: "",
      sourceOfEmission: "",
      co2Factor: null,
      scope: "scope2",
    };
  };

  setFileUploadData = (data) => {
    this.excelRawData = data;
  };

  setPageIndex = (index) => {
    this.pageIndex = index;
  };

  _handleExcelUploadNext = (back) => {
    if (back) {
      this.pageIndex !== 0 && this.setPageIndex(this.pageIndex - 1);
    } else if (!back) {
      this.pageIndex !== 3 && this.setPageIndex(this.pageIndex + 1);
    }
    if (this.pageIndex === 1) {
      this.excelRawData.map((v) => {
        let newObj = Object.keys(v)
          .filter((key) => {
            let found = false;
            this.checkedArray.forEach((v) => {
              if (v == key) {
                found = true;
                return;
              }
            });
            return found;
          })
          .reduce((acc, key) => {
            acc[key] = v[key];
            return acc;
          }, {});
        this.filteredExcelArray.push(newObj);
      });
    } else if (this.pageIndex === 2) {
      let renameObjectKey = (object) => {
        let keyPair = Object.entries(this.selectedExcelHeader);
        for (let [index, element] of keyPair) {
          if (index == "date") {
            if (index !== element) {
              object[index] = object[element]
                ? this.ExcelDateToJSDate(object[element])
                : this.excelDefaultValue[index];
              delete object[element];
            } else
              object[index] = object[element]
                ? this.ExcelDateToJSDate(object[element])
                : this.excelDefaultValue[index];
          } else {
            if (index !== element) {
              object[index] = object[element]
                ? object[element]
                : this.excelDefaultValue[index];
              delete object[element];
            } else {
              object[index] = object[element]
                ? object[element]
                : this.excelDefaultValue[index];
            }
          }
        }
      };
      this?.filteredExcelArray.map((v) => {
        renameObjectKey(v);
        // this.filteredExcelArray.push(v)
      });
    }
    this.lastExcelData = this.filteredExcelArray.map((c) => ({
      activityType: c.activityType,
      carbonEmission: c.carbonEmission,
      category: c.category,
      date: new Date(c.date).toLocaleDateString("en-US"),
      distance: c.distance,
      emissionFactor: c.emissionFactor,
      name: c.name,
      transportMode: c.transportMode,
      unit: c.unit,
      vehicleType: c.vehicleType,
    }));
    if (this.pageIndex === 3) {
      fetchExcelUpload(
        this.buildingId,
        "62da2e0c8dc52269efb8e7b1",
        this.lastExcelData,
        (err, data) => {
          if (data) {
            runInAction(() => {
              this.excelRawData = [];
            });
            if (!data.success) {
              toast.error(data.err);
            } else {
              toast.success(data.payload);
              this._handleClose();
              this.pageIndex = 0;
            }
          } else {
            toast.error(err);
          }
        }
      );
    }
  };

  _handleDefaultExcelInputChange = (e, value, type) => {
    switch (type) {
      case undefined:
        this.excelDefaultValue[value] = e.target.value;
        break;
      case "transportation":
        this.excelDefaultValue[value] = e.label;
        break;
    }
    // this.excelDefaultValue[value] = e.target.value
  };

  _handleSelectExlHeader = (e, v) => {
    this.selectedExcelHeader[v] = e.value;
  };

  ExcelDateToJSDate = (serial) => {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
  };

  _handleResetExcel = () => {
    this.lastExcelData = [];
    this.filteredExcelArray = [];
    this.selectedExcelHeader = {
      date: "date",
      category: "category",
      emissionFactor: "emissionFactor",
      transportMode: "transportMode",
      activityType: "activityType",
      vehicleType: "vehicleType",
      unit: "unit",
      distance: "distance",
      carbonEmission: "carbonEmission",
      name: "name",
    };
    this.excelDefaultValue = {
      date: new Date(),
      category: "-",
      emissionFactor: "-",
      transportMode: "-",
      activityType: "-",
      vehicleType: "-",
      unit: "-",
      distance: 0,
      carbonEmission: 0,
      name: "-",
    };
    this.checkedArray = [];
  };

  _handleSelectUnit = (value) => {
    if (this.selectedUnit != "" && !this.selectedUnit) {
      document
        .getElementById("unit_selector")
        .setAttribute("data-bs-toggle", "modal");
      document
        .getElementById("unit_selector")
        .setAttribute("data-bs-target", "#unitModal");
      let clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      document.getElementById("unit_selector").dispatchEvent(clickEvent);

      document
        .getElementById("unit_selector")
        .removeAttribute("data-bs-toggle");
      document
        .getElementById("unit_selector")
        .removeAttribute("data-bs-target");

      runInAction(() => {
        this.selectedUnit = value;
      });
    } else {
      runInAction(() => {
        this.selectedUnit = value;
      });
      this._handleSaveUnit("updateUnit");
    }
  };

  _handleCancelUnitModal = () => {
    this.selectedBaseLineYear = null;
    this.selectedUnit = null;
  };

  _handleSaveUnit = (type) => {
    const data_source = this.dataSourceOptions.find((v) =>
      this.selectedField === "carbonEmission"
        ? v?.value === this.selectedCarbonTab
        : v?.value === this.selectedField
    )?.label;
    const data = {
      buildingId: this.buildingId,
      dataSource: data_source?.includes("Scope")
        ? data_source?.replace(/\s/g, "") + " Emission"
        : data_source,
      unit: this.selectedUnit,
      baselineYear: this.selectedBaseLineYear,
    };

    if (type === "updateUnit") {
      delete data.baselineYear;
    }

    fetchUpdateUnit(data, (err, data) => {
      if (data) {
        if (data.success) {
          toast.success("Success!");
          document
            .getElementById("save_unit")
            .setAttribute("data-bs-dismiss", "modal");
          let clickEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          document.getElementById("save_unit").dispatchEvent(clickEvent);

          document
            .getElementById("save_unit")
            .removeAttribute("data-bs-dismiss");

          target._fetchTargetSetting(this.buildingId);
        }
      } else {
        toast.error(err);
      }
    });
  };

  _handleClose = () => {
    this.setFileUploadData([]);
    const file = document.querySelector(".file");
    file.value = "";
    this.setPageIndex(0);
    this._handleResetExcel();
  };

  setSelectedUnit = (settings) => {
    const dataSource = this.dataSourceOptions.find(
      (v) =>
        v.value ===
        (this.selectedField === "carbonEmission"
          ? this.selectedCarbonTab
          : this.selectedField)
    )?.label;
    const unit = dataSource?.includes("Scope")
      ? dataSource?.replace(/\s/g, "") + " Emission"
      : dataSource;
    //default unit will be ''
    
    const getUnit = settings.find((v) => v.dataSource === unit)?.unit;

    this.selectedUnit = getUnit;
  };

  getManualEntryDataByCategory = (frameworkId, category) => {
    this.manualDataByCategory = [];
    this.allManualDataByCategory = [];
    const subSectionHeader =
      this.subSectionArray?.[this.subSectionIndex]?.subSectionHeader;

    fetchMaualEntryData(
      this.buildingId,
      frameworkId,
      2022,
      category,
      (err, data) => {
        if (err) {
        } else {
          if (!data.error) {
            if(data.payload?.manual_entry_data.length > 0){
              let temp = data.payload?.manual_entry_data?.filter(
                (v) => Number(v.year) === Number(this.surveyYear)
              );
              temp.forEach((a) => (a._id = data.payload?._id));
              this.manualDataByCategory = temp;
              this.allManualDataByCategory = data.payload?.manual_entry_data;
            }else {
              const temp = {
                year: this.surveyYear,
                category: subSectionHeader,
                scope: this.sectionIndex + 1,
                manualEntryData: [
                  { month: "January", value: null },
                  { month: "February", value: null },
                  { month: "March", value: null },
                  { month: "April", value: null },
                  { month: "May", value: null },
                  { month: "June", value: null },
                  { month: "July", value: null },
                  { month: "August", value: null },
                  { month: "September", value: null },
                  { month: "October", value: null },
                  { month: "November", value: null },
                  { month: "December", value: null },
                ],
              };
              this.manualDataByCategory = [temp];
            }            
          }
        }
      }
    );
  };

  _handleManualNumberChange = (e, month, data) => {
    const temp = { ...data };
    const i = temp?.manualEntryData.findIndex((v) => v.month === month);
    temp.manualEntryData[i].value = Number(e.target.value);
    this.answerObj = { answer: temp };

    const i2 = this.answerArray.findIndex(
      (v) => v.category === data.category && v.year === data.year
    );
    if (i2 < 0) {
      this.answerArray.push(temp);
    } else {
      const newArr = this.answerArray;
      newArr.splice(i2, 1, temp);
    }
    this.isNewVal = true;
  };

  _handleDescriptionChange = (e, cat, editMonth) => {
    if (!this.editedMonth.includes(editMonth)) {
      this.editedMonth.push(editMonth);
    }

    const Month = e.target.id.split("__")[0];
    const newArr = [...this.manualEntryData];
    const index = newArr.findIndex((v) => v.month === Month);
    switch (cat) {
      case "waterConsumption":
        newArr[index]["waterDescription"] = e.target.value;
        break;
      case "energyConsumption":
        newArr[index]["energyDescription"] = e.target.value;
        break;
      case "scope1Emission":
        newArr[index]["scope1Description"] = e.target.value;
        break;
      case "scope2Emission":
        newArr[index]["scope2Description"] = e.target.value;
        break;
      case "scope3Emission":
        newArr[index]["scope3Description"] = e.target.value;
        break;
    }

    runInAction(() => {
      this.manualEntryData = newArr;
      this.isNewVal = true;
    });
  };

  _handleSelectedFile = (value, monthYear, category, id, scope) => {
    if (value !== "uploadBtn") {
      let dataList = {};
      if (category) {
        const month = monthYear.split("_")[0];
        const index = this.categoryArray.findIndex((f) => f.catMonth === month);
        if (index > -1) {
          dataList = { ...this.categoryArray[index], linkedFileId: value };
          const catAry = [...this.categoryArray];
          catAry.splice(index, 1, dataList);
          this.categoryArray = catAry;
        } else {
          dataList = {
            _id: id,
            linkedFileId: value,
            description: toJS(this.manualDataByCategory[0]?.manualEntryData.find(v => v.month === month)?.description),
            scope: scope,
            catMonth: monthYear.split("_")[0],
            category:
              this.subSectionArray[this.subSectionIndex]?.subSectionHeader,
            monthYear: monthYear,
          };
          this.categoryArray.push(dataList);
        }

        
      } else {
        // const dataBody = {
        //   linkedFileId: value,
        //   // description: this.uploadDescription,
        //   scopeType: this.selectedField === "energyConsumption" ? "energy" : this.selectedField === "waterConsumption"? "water": this.selectedCarbonTab.split("Emission")[0]
        // }
        // const params = this.selectedAnswerId ?
        // {
        //   buildingId: this.buildingId,
        //   frameworkId: toJS(
        //     this.surveyFramework[this.frameWorkIndex].framework_id
        //   ),
        //   answer_id: this.selectedAnswerId,
        //   year: this.selectedYear
        // }
        // : {
        //   buildingId: this.buildingId,
        //   frameworkId: toJS(
        //     this.surveyFramework[this.frameWorkIndex].framework_id
        //   ),
        //   year: this.selectedYear,
        //   month: monthYear.split("_")[0]
        // };
        // updateUploadDescription(params, dataBody, (err, data) => {
        //   if (data && data.success) {
        //     toast.success("Success!");
        //       runInAction(() => {
        //         this.desFileId = value;
        //         this.selectedFile = value;
        //       });
        //   } else {
        //     toast.error(err);
        //   }
        // });
      }
    } else {
      if (monthYear) {
        const month = monthYear.split("_")[0];
        const year = monthYear.split("_")[1];
        this.selectedMonth = month;
        this.selectedYear = year;
      }
    }
    this.isNewVal = true;
  };

  _handleScope2SelectedFile = (
    value,
    answer_id,
    year,
    containerId,
    modalId
  ) => {
    if (value !== "uploadBtn") {
      const dataBody = {
        linkedFileId: value,        
      };

      const params = {
        buildingId: this.buildingId,
        frameworkId: toJS(
          this.surveyFramework[this.frameWorkIndex].framework_id
        ),
        answer_id: answer_id,
        year: year,
      };

      updateUploadDescription(params, dataBody, (err, data) => {
        if (data && data.success) {
          toast.success("Success!");

          runInAction(() => {
            this.desFileId = value;
            this.selectedFile = value;
          });
          this.getAllQuestion(
            this.surveyFramework[this.frameWorkIndex].framework_id
          );
          this.uploadDescription = null;
        } else {
          toast.error(err);
        }
      });
    } else {
      this.selectedAnswerId = answer_id;
      this.selectedYear = year;
    }
  };

  _handleSaveUploadedFile = (btnId, isCateUpload) => {
    const dataBody = isCateUpload
      ? {
          linkedFileId: this.selectedFile,
          description: this.uploadDescription,
          scope: this.sectionIndex + 1,
        }
      : {
          linkedFileId: this.selectedFile,
          description: this.uploadDescription,
        };

    const params = isCateUpload
      ? {
          buildingId: this.buildingId,
          frameworkId: toJS(
            this.surveyFramework[this.frameWorkIndex].framework_id
          ),
          year: this.selectedYear,
          catMonth: this.selectedMonth,
          category:
            this.subSectionArray[this.subSectionIndex]?.subSectionHeader,
        }
      : this.selectedAnswerId
      ? {
          buildingId: this.buildingId,
          frameworkId: toJS(
            this.surveyFramework[this.frameWorkIndex].framework_id
          ),
          answer_id: this.selectedAnswerId,
          year: this.selectedYear,
        }
      : {
          buildingId: this.buildingId,
          frameworkId: toJS(
            this.surveyFramework[this.frameWorkIndex].framework_id
          ),
          year: this.selectedYear,
          catMonth: this.selectedMonth,
        };

    updateUploadDescription(params, dataBody, (err, data) => {
      if (data && data.success) {
        toast.success("Uploaded Successfully!");
        document.getElementById(btnId).setAttribute("data-bs-dismiss", "modal");
        let clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        document.getElementById(btnId).dispatchEvent(clickEvent);

        document.getElementById(btnId).removeAttribute("data-bs-dismiss");

        runInAction(() => {
          this.desFileId = this.selectedFile;
        });
        this.getManualEntryDataByYear(
          this.surveyFramework[this.frameWorkIndex].framework_id
        );
        this.getAllQuestion(
          this.surveyFramework[this.frameWorkIndex].framework_id
        );
        this.uploadDescription = null;
      } else {
        toast.error(err);
      }
    });
  };

  setScope0SetupVal = () => {
    this.setUp = 0;
  };

  _handleCheckChange = (e, questionId, deviceType) => {
    const tempArr = [...this.scope0ans];
    const status = e.target.checked;
    cookies.set(deviceType, e.target.value);
    const i = tempArr.findIndex(
      (v) => v.questionId === questionId && v.deviceType === deviceType
    );
    if (i < 0) {
      tempArr.push({
        questionId,
        deviceType,
        status,
        qty: null,
      });
    } else {
      tempArr[i].status = status;
      tempArr[i].qty = null;
    }

    this.scope0ans = tempArr;
  };

  _handleQtyChange = (e, questionId, deviceType) => {
    const tempArr = [...this.scope0ans];
    const qty = e.target.value;
    const i = tempArr.findIndex(
      (v) => v.questionId === questionId && v.deviceType === deviceType
    );

    if (i < 0) {
      tempArr.push({
        questionId,
        deviceType,
        status: true,
        qty: qty,
      });
    } else {
      tempArr[i].status = true;
      tempArr[i].qty = qty;
    }

    this.scope0ans = tempArr;
  };

  _handleQuesClick = (i) => {
    //auto skip for this index
    if (
      this.scope0Index == 1 ||
      this.scope0Index == 2 ||
      this.scope0Index == 3 ||
      this.scope0Index == 4
    ) {
      this.scope0Index = i;
      return;
    }
    if (this.scope0ans.length === 0) {
      this.scope0Index = i;
    } else {
      this.showScope0Dialog = true;
      this.scope0ExpectedIndex = i;
    }
  };

  _handleNextQuesClick = () => {
    //auto skip for this index
    if (
      this.scope0Index == 1 ||
      this.scope0Index == 2 ||
      this.scope0Index == 3 ||
      this.scope0Index == 4
    ) {
      this.scope0Index = this.scope0Index + 1;
      return;
    }
    if (this.scope0ans.length === 0) {
      this.scope0Index = this.scope0Index + 1;
    } else {
      this.showScope0Dialog = true;
      this.scope0ExpectedIndex = this.scope0Index + 1;
    }
  };

  _handlePreviousQuesClick = () => {
    //auto skip for this index
    if (
      this.scope0Index == 1 ||
      this.scope0Index == 2 ||
      this.scope0Index == 3 ||
      this.scope0Index == 4
    ) {
      this.scope0Index = this.scope0Index - 1;
      return;
    }
    if (this.scope0ans.length === 0) {
      this.scope0Index = this.scope0Index - 1;
    } else {
      this.showScope0Dialog = true;
      this.scope0ExpectedIndex = this.scope0Index - 1;
    }
  };

  saveMoveToNext_scpoe0 = () => {
    this.showScope0Dialog = false;
    // to show another modal box
    this._handleSetScope0AnsRow();
  };

  discardMoveToNext_scpoe0 = () => {
    this.scope0ans = [];
    this.scope0Index = this.scope0ExpectedIndex;
    this.showScope0Dialog = false;
    this.scope0ExpectedIndex = null;
  };

  onDiscardClick_scpoe0 = () => {
    this.showScope0Dialog = false;
    this.scope0ExpectedIndex = null;
  };

  getScope0AnsData = () => {
    const frameworkId = toJS(
      this.surveyFramework[this.frameWorkIndex].framework_id
    );
    const params = { buildingId: this.buildingId, frameworkId: frameworkId };
    fetchScope0Data(params, (err, data) => {
      if (err) {
      } else {
        if (!data.error) {
          const payload = data.payload?.map((v) => ({
            deviceType: v.name,
            qty: v.count,
          }));
          this.scope0Data = payload;
        }
      }
    });
  };

  _handleSetScope0AnsRow = () => {
    const frameworkId = toJS(
      this.surveyFramework[this.frameWorkIndex].framework_id
    );
    const params = { buildingId: this.buildingId, frameworkId: frameworkId };
    const data = this.scope0ans.map((v) => v.deviceType);
    fetchEqDataByDeviceType(params, data, (err, data) => {
      if (err) {
      } else {
        if (!data.error) {
          let arr = [];
          for (let dt of this.scope0ans) {
            const a1 = data.payload.filter(
              (v) => v.equipment_data?.deviceType === dt.deviceType
            );
            for (let i1 = 0; i1 < a1.length; i1++) {
              const ele =
                this.scope0Index === 0
                  ? {
                      _id: a1[i1].equipment_data._id,
                      equipmentName: a1[i1].equipment_data.equipmentName,
                      deviceType: a1[i1].equipment_data.deviceType,
                      fuelType: a1[i1].equipment_data.fuelType,
                      brand: a1[i1].equipment_data.brand,
                      unit: a1[i1].equipment_data.unit,
                      commissionedDate: a1[i1].equipment_data.commissionedDate,
                      active: true,
                      category: "Stationary Combustion",
                      recordPeriod: "Monthly",
                      deleteMode: dt.qty < a1.length ? true : false,
                    }
                  : this.scope0Index === 1
                  ? {
                      activityType: a1[i1].equipment_data.activityType,
                      unit: a1[i1].equipment_data.unit,
                      fuelType: a1[i1].equipment_data.fuelType,
                      vehicleType: a1[i1].equipment_data.vehicleType,
                      brand: a1[i1].equipment_data.brand,
                      equipmentName: a1[i1].equipment_data.equipmentName,
                      commissionedDate: a1[i1].equipment_data.commissionedDate,
                      active: true,
                      category: "Mobile Combustion",
                      recordPeriod: "Monthly",
                      deleteMode: dt.qty < a1.length ? true : false,
                    }
                  : {
                      supplierName: "",
                      emissionFactor: "",
                      calculationApproach: "",
                      calculateMethod: "",
                      recordPeriod: "Monthly",
                      unit: a1[i1].equipment_data.unit,
                    };
              arr.push(ele);
            }
            for (let i = 0; i < dt.qty - a1?.length; i++) {
              const ele =
                this.scope0Index === 0
                  ? {
                      equipmentName: "",
                      deviceType: dt.deviceType,
                      fuelType: "",
                      brand: "",
                      unit: "",
                      commissionedDate: new Date(),
                      active: true,
                      category: "Stationary Combustion",
                      recordPeriod: "Monthly",
                      deleteMode: false,
                    }
                  : this.scope0Index === 1
                  ? {
                      activityType: "",
                      unit: "",
                      fuelType: "",
                      vehicleType: "",
                      brand: "",
                      equipmentName: "",
                      commissionedDate: new Date(),
                      active: true,
                      category: "Mobile Combustion",
                      recordPeriod: "Monthly",
                      deleteMode: false,
                    }
                  : {
                      supplierName: "",
                      emissionFactor: "",
                      calculationApproach: "",
                      calculateMethod: "",
                      recordPeriod: "Monthly",
                      unit: "",
                    };
              arr.push(ele);
            }
          }

          this.scope0AnsRow = arr;
        }
      }
    });
  };

  _handleChangeS0Input = (e, index, key) => {
    const tempArr = [...this.scope0AnsRow];
    tempArr[index][key] = e.target.value;
    this.scope0AnsRow = tempArr;
  };

  _handleSelectS0 = (e, index, key) => {
    const tempArr = [...this.scope0AnsRow];
    tempArr[index][key] = e.label;
    if (key === "activityType") {
      tempArr[index].unit = "";
    }
    if (key === "fuelType") {
      tempArr[index].vehicleType = "";
    }
    this.scope0AnsRow = tempArr;
  };

  _handleChangeS0Date = (e, index) => {
    const tempArr = [...this.scope0AnsRow];
    tempArr[index].commissionedDate = e;
    this.scope0AnsRow = tempArr;
  };

  _handleSubmitScope0 = () => {
    const data = this.scope0AnsRow;
    const frameworkId = toJS(
      this.surveyFramework[this.frameWorkIndex].framework_id
    );

    const scope0AnsRow = [];

    data.forEach((val) => {
      if (this.deletedDevice.includes(val._id)) {
        val.deleteFlag = true;
      } else {
        val.deleteFlag = false;
      }
      scope0AnsRow.push(val);
    });

    const params = { buildingId: this.buildingId, frameworkId: frameworkId };
    fetchUpdateScope0Data(params, scope0AnsRow, (err, data) => {
      if (data) {
        if (data.success) {
          toast.success("Success!");
          document
            .getElementById("save_scope0")
            .setAttribute("data-bs-dismiss", "modal");
          let clickEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          document.getElementById("save_scope0").dispatchEvent(clickEvent);

          document
            .getElementById("save_scope0")
            .removeAttribute("data-bs-dismiss");

          this.getScope0AnsData();
          this.scope0ans = [];
          this.scope0AnsRow = [];

          if (this.scope0ExpectedIndex !== null) {
            this.scope0Index = this.scope0ExpectedIndex;
            this.scope0ExpectedIndex = null;
          }
        }
      } else {
        toast.error(err);
      }
    });
  };

  _handleDeleteScope0Device = (id) => {
    if (id) {
      const deviceId = this.deletedDevice.find((deviceId) => deviceId === id);
      if (!deviceId) {
        runInAction(() => {
          this.deletedDevice.push(id);
        });
      }
    }
  };

  _handleSetGhgDataType = () => {
    const siteModuleData = common.siteModuleData;
    this.ghgDataType =
      toJS(siteModuleData)?.["scope" + (this.sectionIndex + 1)]?.dataType ||
      "DetailData";
  };

  _handleScope2DescriptionChange = (
    e,
    questionId,
    childSection,
    questionType,
    questionIndex,
    dynamicDataId,
    monthIndex,
    linkedFileIdValue,
    answerValue
  ) => {

    if (!this.editedScope2Month.includes(dynamicDataId)) {
      this.editedScope2Month.push(dynamicDataId);
    }

    this.status = undefined;
    const subSectionId = this.subSectionArray[this.subSectionIndex]._id;

    this.answerObj =
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? {
            questionId: questionId,
            subSectionId: subSectionId,
            linkedFileId: linkedFileIdValue,
            description: e.target.value,
            childSection: {
              questionId,
              answer: answerValue,
              childSectionHeader: childSection.childSectionHeader,
              subSectionIndex: this.subSectionIndex,
              sectionIndex: this.sectionIndex,
              questionIndex: questionIndex,
              dynamicDataId: dynamicDataId,
            },
          }
        : monthIndex !== null ? {
            answer: answerValue,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            monthIndex: monthIndex,
            linkedFileId: linkedFileIdValue,
            description: e.target.value,
          }
        : {
            answer: answerValue,
            questionId: questionId,
            subSectionId: subSectionId,
            subSectionIndex: this.subSectionIndex,
            sectionIndex: this.sectionIndex,
            questionIndex: questionIndex,
            dynamicDataId: dynamicDataId,
            linkedFileId: linkedFileIdValue,
            description: e.target.value,
          };

    // Checking answerArray for answered or not condition
    const isAnswer = this.answerArray.findIndex((ans) =>
    
      this.subSectionArray[this.subSectionIndex].childSections.length > 0
        ? ans.childSection.questionId === questionId &&
          ans.subSectionId === subSectionId
        : ans.questionId === questionId &&
          ans.subSectionId === subSectionId &&
          ans.dynamicDataId === dynamicDataId
    );

    // If new input to ans array, create new
    if (isAnswer === -1) {
      runInAction(() => {
        this.isNewVal = true;
        this.answerArray = [...this.answerArray, this.answerObj];
      });
    } else {
      this.isNewVal = true;
      this.answerArray[isAnswer] = this.answerObj;
    }
  };

  _handleCateDescriptionChange = (e, mId, monthYear, scope) => {
    let dataList = {};
    const index = this.categoryArray.findIndex(f => f._id === mId && f.monthYear === monthYear);
    if(index > -1){
      dataList = {...this.categoryArray[index], description: e.target.value};
      const catAry = [...this.categoryArray];
      catAry.splice(index, 1, dataList);
      this.categoryArray = catAry;
    } else {
      const month = monthYear.split("_")[0];
      dataList = {
        _id: mId,
        linkedFileId: toJS(this.manualDataByCategory[0]?.manualEntryData.find(v => v.month === month)?.linkedFileId),
        description: e.target.value,
        scope: scope,
        catMonth: monthYear.split("_")[0],
        category: this.subSectionArray[this.subSectionIndex]?.subSectionHeader,
        monthYear: monthYear,
      };
      this.categoryArray.push(dataList);
    }
    this.isNewVal = true;
  };

  _handleCategorySubmit = () => {
    if(this.categoryArray?.length > 0){
      const buildingId = toJS(this.buildingId);
      const frameworkId = toJS(
        this.surveyFramework[this.frameWorkIndex].framework_id
      );
      const year = this.surveyYear; 
      const category = this.subSectionArray[this.subSectionIndex]?.subSectionHeader;

      let category_data = this.manualDataByCategory?.[0].manualEntryData;
      let scope;

      for(let i=0; i<category_data.length; i++){
        for(let j=0; j<this.categoryArray.length; j++){
          if(category_data[i].month === this.categoryArray[j].catMonth){
            scope = this.categoryArray[j].scope;
            category_data[i].description = this.categoryArray[j].description;
            category_data[i].linkedFileId = this.categoryArray[j].linkedFileId;
          }
        }
      }

      const params = {
        frameworkId,
        buildingId,
        year, category, scope
      };

      updateUploadDescription(params, category_data, (err, data) => {
        if (!data.error) {
  
          this.status = "Updated Successfully!";
          this.categoryArray = [];
          this.isNewVal = false;
          setTimeout(function () {
            runInAction(() => {
              this.status = undefined;
            });
          }, 4000);
          
        const subSectionHeader =
        this.subSectionArray?.[this.subSectionIndex]?.subSectionHeader;
          if (data.success) {
            setTimeout(function () {
              this.getManualEntryDataByCategory(frameworkId, subSectionHeader);
            }, 400);
          }
        }
      });
    };
  }

  setSelectedMonth = (val) => {
    this.selectedMonth = val;
  }


   _handleFileDownload = (selectedfile , uploadedFiles, e) => { 
    const selectedFileName = uploadedFiles.filter( v=> v.value === selectedfile)[0].label
    


    console.log(selectedFileName,uploadedFiles);
    e.stopPropagation();

    if (selectedFileName) {
      const reqFilePath = uploadedFiles.filter( v=> v.value === selectedfile)[0].path?.split("/")
      const reqFile = reqFilePath[reqFilePath?.length -1]


      console.log(reqFile);
      fetchFile(reqFile, async (err, data) => {
        if (!err) {
          const blob = await data.blob();

          const fileURL = window.URL.createObjectURL(blob);
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          const fileName = selectedFileName;
          fileLink.setAttribute("download", fileName);
          fileLink.setAttribute("target", "_blank");
          fileLink.click();
          fileLink.remove();
        } else {
          toast.error("File doesn't exist.");
        }
      });
    } else {
      toast.error("File doesn't exist.");
    }
  
  }


  setEdit = (data,scope) => {
    runInAction(() => {
      this.isEdit = true;
      if(scope === 1){
        this.createEquipmentList.category = data.category;
        this.createEquipmentList.recordPeriod = data.recordPeriod;
        this.createEquipmentList.equipmentName = data.equipmentName;
        this.createEquipmentList.deviceType = data.deviceType;
        this.createEquipmentList.fuelType = data.fuelType;
        this.createEquipmentList.brand = data.brand;
        this.createEquipmentList.unit = data.unit;
        this.createEquipmentList.commissionedDate = new Date();
        this.createEquipmentList.active = data.active;
        this.createEquipmentList._id = data._id;
      }else if(scope === 2){
        this.createPurchasedList.year = String(data.year);
        this.createPurchasedList.supplier = data.supplier;
        this.createPurchasedList.calculationApproach = data.calculationApproach;
        this.createPurchasedList.emissionFactor = data.emissionFactor;
        this.createPurchasedList.unit = data.unit;
        this.createPurchasedList.active = data.active;
        this.createPurchasedList.recordPeriod = data.recordPeriod;
        this.createPurchasedList.calculateType = data.calculateType;
        this.createPurchasedList.calculateMethod = data.calculateMethod;
        this.createPurchasedList.category = data.category;
        this.createPurchasedList._id = data._id;
      }else{
        this.createTransportationList.category = data.category;
        this.createTransportationList.year = String(data.year);
        this.createTransportationList.transportMode = data.transportMode;
        this.createTransportationList.emissionFactor = data.emissionFactor;
        this.createTransportationList.vehicleType = data.vehicleType;
        this.createTransportationList.activityType = data.activityType;
        this.createTransportationList.active = true;
        this.createTransportationList.unit = data.unit;
        this.createTransportationList.name = data.name;
        this.createTransportationList._id = data._id;
      }
    })
  }
}
export default new Survey();
