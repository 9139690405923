import * as API from "./URL";

export const findPageBykeyword = async(keyword, callback) => {
    return fetch(API.findPageBykeyword+"?keyword="+keyword,{
        method : "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then((res) => res.json())
        .then((data) => callback(null,data))
        .catch((err) => callback(err.toString(),null));
}