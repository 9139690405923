import { MyButton } from "../../Tools";
import { Colors, } from "../../Assets/Config/color.config";
import { useTranslation } from 'react-i18next';
import Store from "../../Store";
import {toJS} from "mobx";

export const FrameworkDeleteConfirmationModel = (props) => {
    const { theme, _handleConfirmDelete, frameworkId, frameworkName } = props;
    const { buildingId, ansTotalPercent, sectionIndex, answerPercent } = Store.survey;
    const { t } = useTranslation("common");
    
    const progressPercent = toJS(ansTotalPercent)?.section === sectionIndex ? 
     toJS(ansTotalPercent)?.percentage : parseInt(toJS(answerPercent)?.[sectionIndex].percent);

    
    return (<div
        className={`modal fade ${theme}`}
        id="confirmFrameworkDeleteModel"
        tabIndex="-1"
        aria-labelledby="confirmFrameworkDeleteModel"
        aria-hidden="true"
    >
        <div className="modal-dialog">
            <div
                className="modal-content"
                style={{
                    background: Colors[theme].background,
                    borderRadius: 10,
                    color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]
                }}
            >
                <div className="modal-header mx-3 px-0">
                    <h5>
                        {t("Delete Framework")}
                    </h5>
                    <i
                        style={{ cursor: "pointer" }}
                        className="bi bi-x-lg "
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    // onClick={() => _handleClose()}
                    ></i>
                </div>
                <div className="modal-body mx-3 px-0">
                    <div>{t("You have answered this amount of questions in")+ " " + frameworkName + " " + t("framework") + ": "+ progressPercent +"%."}</div> 
                    <div>{t("Are you sure want to delete") + " " + frameworkName + " "+ t("framework") + "?" }</div>
                    {/* {`Your have answered ${progressPercent}% of questions in ${frameworkName} Framework. Are you sure do you want to delete ${frameworkName} Framework?`} */}
                </div>
                <div className="modal-footer mx-3 px-0 d-flex justify-content-center">
                    <span data-bs-dismiss="modal"
                        aria-label="Close">
                        <MyButton
                            style={{ width: 200 }}
                            text={t("Cancel")}
                            customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                            customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                        />
                    </span>
                    <span data-bs-dismiss="modal"
                        aria-label="Close">
                        <MyButton
                            // onClick={_handleConfirmReset}
                            style={{ width: 200 }}
                            text={t("Yes")}
                            onClick={(e) => _handleConfirmDelete(buildingId, frameworkId)}
                            customColor='#066CFF'
                            customHoverColor={Colors.water}
                        />
                    </span>
                </div>
            </div>
        </div>
    </div>)
}