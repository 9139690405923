import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { MyButton, Selector, MyInput } from "../../Tools";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from "react-i18next";
import { toJS } from 'mobx';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PlusIcon from "../../Assets/images/PlusSquareFill.svg";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { ReportComNameModal } from './ReportComNameModal';
import { ReportTempNameModal } from './ReportTempNameModal';

export const ReportSideBar = inject("store")(
    observer((props) => {
        const { t } = useTranslation("assetSetupAndGroup");
        const { theme } = props.store.common;
        const { reportConfigureList, selectedTemplate, customTempName, defaultTempNames, _setDeleteReport,
            isDeleteReport, _handleDeleteReportChecked, _cancelDeleteReport, deleteCheckedComs, handleDeleteComp,
            _handleTempNameChange, _handleSelectedIndex, deleteComp, reportCustomConfigList, isDefaultTemp,
            selectedIndex, _handleDnD,_handleCurrentAction,
            allComponentList, tempNameError } = props.store.reportConfigure;


        const [comListOptions, setComListOptions] = useState([]);

        const [showDialog, setShowDialog] = useState(false);


        useEffect(() => {
            if (reportConfigureList.length > 0 && reportCustomConfigList) {
                const defComList = reportConfigureList.filter(r => r.templateName === reportCustomConfigList.templateName)
                if (!defComList.length) return

                const list = defComList[0].componentList.map(v => {
                    return { value: v.name, label: v.name, index: v.index };
                });

                setComListOptions(list);

            }
        }, [reportConfigureList, reportCustomConfigList]);

        // const listGroup = ['Component 1', 'Component 2', 'Component 3',
        //     'Component 4', 'Component 5', 'Component 6',
        //     'Component 7', 'Component 8', 'Component 9', 'Component 10']

        // useEffect(() => {

        // }, [_handleSelectedIndex, reportConfigureList]);



        const _handleSelectGroupId = (i) => {

            _handleSelectedIndex(i)
            localStorage.setItem('cindex', i)
        };

        const handleDeleteDialog = () => {
            setShowDialog(false)
        };

        const handleDeleteComps = () => {
            handleDeleteComp()
            setShowDialog(false)
        };

        const navigate = useNavigate();

        // console.log("reportCustomConfigList >> ", toJS(reportCustomConfigList));
        // console.log("reportConfigureList >> ", toJS(reportConfigureList));
     


        return (

            <Fragment>

               
                <div className="d-flex justify-content-center fw-bold pb-3" style={{  borderBottom: `1px solid ${Colors[theme].borderColor}`}}>

                    {/* <div className="d-flex flex-wrap mx-2"> */}
                        <div className="d-flex  justify-content-between align-items-center mx-2 ps-1" style={{ color: Colors[theme].textColor, minHeight: 39, }}>
                            <div className='text-center me-1' title={selectedTemplate} style={{ fontSize: 20, color: selectedTemplate ? '' : 'gray', float: "left", maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {selectedTemplate || 'Template Name'}
                            </div>
                            {(selectedTemplate && !isDefaultTemp()) ?
                                <div
                                    data-bs-toggle="modal"
                                    data-bs-target="#ReportTempNameModal"
                                    className="px-1" style={{ float: "right" }}>
                                    <span style={{ fontSize: 18, cursor: "pointer" }}>
                                        <i className="bi bi-pencil-square  ma-2" onClick={e=> _handleCurrentAction('Save')}>
                                        </i>
                                    </span>
                                </div> 
                                : ''}
                        {/* </div> */}
                        
                    </div>
                    <ReportTempNameModal />
                </div> 

                

           


                <div className="d-flex align-items-center flex-row m-2" style={{ justifyContent: 'center' }}>
                    <span className="align-items-center fw-bold text-nowrap col-12" style={{ fontSize: 18, color: Colors[theme].textColor,textAlign:'center' }}>
                        {t("Components")}
                    </span>
                    {
                        !isDefaultTemp() && reportCustomConfigList ?
                        <div class="btn-group mt-1 ">
                            <div className="btn-group dropup" style={{ color: Colors[theme].textColor }}>
                                <i className="bi bi-gear" style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false"></i>
                                    <ul className="dropdown-menu" style={{  background: Colors[theme].active, fontSize: 13, padding: '0.5rem' }}>
                                        <li className='mb-2'>
                                            <div
                                                className="col d-flex justify-content-start align-items-center rounded"
                                                onClick={() => _setDeleteReport()}
                                                style={{
                                                    height: 26.3,
                                                    cursor: "pointer",
                                                    color: Colors[theme].textColor
                                                }}
                                            >
                                                <i style={{ color: Colors.low, fontSize: 17 }} className="bi bi-trash3 me-1"></i>{t("Delete Component")}
                                            </div>
                                        </li>
                                        <li>
                                            <div 
                                                className='col d-flex justify-content-start align-items-center rounded'
                                                data-bs-toggle="modal"
                                                data-bs-target="#updateReportComModal"
                                                style={{ cursor: 'pointer', color: Colors[theme].textColor }}
                                                >
                                            <img
                                                className='me-1'
                                                src={PlusIcon}
                                                alt="plus_icon1"
                                                style={{ height: 17, }}
                                            />{t("Create Component")}
                                            </div>
                                            
                                        </li>
                                    </ul>
                            </div>
                            <ReportComNameModal />
                        </div>
                        : null
                    }
                    {/* <div className="row  d-flex align-items-center">
                        {(reportCustomConfigList?.componentList?.length > 0 &&
                            !isDefaultTemp()) ? (
                            <div
                                className="col justify-content-center align-items-center rounded text-white"
                                onClick={() => _setDeleteReport()}
                                title={t("Delete Reports")}
                                style={{
                                    height: 26.3,
                                    width: 36.3,
                                    cursor: "pointer",
                                }}
                            >
                                <i style={{ color: Colors[theme].textColor, fontSize: 17 }} className="bi bi-trash3"></i>
                            </div>
                        ) : ''}

                        {(reportCustomConfigList && !isDefaultTemp()) ?
                            <img
                                data-bs-toggle="modal"
                                data-bs-target="#updateReportComModal"
                                className="col"
                                src={PlusIcon}
                                alt="plus_icon1"
                                style={{ cursor: "pointer", height: 21 }}
                                title={t("Create New Report")}
                            /> : ''
                        }
                        <ReportComNameModal />
                    </div> */}
                </div>


                    {/* <div className="d-flex justify-content-center mb-3">
                        <span
                            data-bs-toggle="modal"
                            data-bs-target="#addNewGroupModal"
                            className="w-75"
                        >
                            <MyButton
                                text={"+"}
                                customColor={Colors.blue}
                                style={{ width: "100%", fontSize: 20, fontWeight: "bold" }}
                            />
                        </span>
                    </div> */}

                    <div
                        style={{
                            backgroundColor: theme == "dark" ? "#D9D9D910" : "#ffffff60",
                            height: isDeleteReport ? "58vh" : "65vh",
                            justifyContent: 'center',
                            overflowY: "auto"
                        }}
                        className="d-flex pt-1"
                    >
                        {/* <DragAndDrop/> */}

                        <div>
                            {reportCustomConfigList ?

                                <DragDropContext onDragEnd={_handleDnD}>
                                    <Droppable droppableId="componentList">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {
                                                    reportCustomConfigList?.componentList?.map((v, k) => (
                                                        <Draggable key={v.title} draggableId={v.title} index={k}>
                                                            {(provided) => (

                                                                <div
                                                                    className="d-flex align-items-center"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    {(isDeleteReport && !isDefaultTemp()) ? (
                                                                        <div className="col-1" style={{ cursor: "pointer" }}>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="m-1"
                                                                                value={v._id}
                                                                                onClick={(e) =>
                                                                                    _handleDeleteReportChecked(e.target.value, e.target.checked)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : ''}

                                                                    {
                                                                        <div className="position-relative  col-12" style={{  }}>

                                                                            {selectedIndex == k ?
                                                                                (
                                                                                    <div
                                                                                        className="position-absolute"
                                                                                        style={{
                                                                                            width: 5,
                                                                                            height: "100%",
                                                                                            backgroundColor: theme === 'dark' ? Colors.blue : Colors['Dark'][2],
                                                                                            left: 0,
                                                                                            top: 0,
                                                                                            // borderTopLeftRadius : 5,
                                                                                            // borderBottomLeftRadius : 5,
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    ""
                                                                                )

                                                                            }
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}

                                                                                key={k}


                                                                                style={{
                                                                                    background: selectedIndex == k ? theme === "dark" ? "#1E254C" : "rgba(0,0,0,0.05)" : "transparent",
                                                                                    width: "100%",
                                                                                    minHeight: 58,
                                                                                    borderRadius: "none",
                                                                                    borderBottom: `0.5px solid ${theme === "dark" ? "#ffffff23" : "#00000023"}`,
                                                                                    paddingLeft: '4 !important',
                                                                                    fontSize: 15,
                                                                                    textAlign: 'center'
                                                                                }}
                                                                                className={`${theme === "dark" ?
                                                                                    selectedIndex == k ? "text-white" : "text-white-50"
                                                                                    : selectedIndex == k ? "text-black-50" : "text-black-50"
                                                                                    } fw-bold py-3`}
                                                                                onClick={() => {
                                                                                    // navigate(`/report-configure?groupId=${c}&view=${i}`, { replace: true });
                                                                                    _handleSelectGroupId(k)
                                                                                }}
                                                                            >
                                                                                <span style={{ textAlign: 'center', display: 'inline-block' ,width:'90%',marginLeft:20}}>{
                                                                                    v.title
                                                                                }</span>
                                                                            </div>
                                                                            <DragIndicatorIcon
                                                                                style={{
                                                                                    width: 15,
                                                                                    height: "100%",
                                                                                    left: 8,
                                                                                    top: 0,
                                                                                    position: 'absolute',

                                                                                    // borderTopLeftRadius : 5,
                                                                                    // borderBottomLeftRadius : 5,
                                                                                }}
                                                                                className={`${theme === "dark" ? 'text-white-50' : 'text-black-50'}`}

                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                                :
                                <span style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }} className="m-2">Please choose report template.</span>
                            }
                        </div>

                    </div>
                    {/* delete */}
                    {(isDeleteReport && !isDefaultTemp()) ? (
                        <div className="d-flex justify-content-end mt-2 p-2">
                            <MyButton
                                style={{
                                    cursor: "pointer",
                                    marginLeft: 20,
                                }}
                                text={t("Cancel")}
                                rounded
                                fontSize={15}
                                onClick={() => _cancelDeleteReport()}
                                customColor={theme === 'dark' ? Colors.lightBlue : '#888'}
                                customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                            />
                            <MyButton
                                style={{
                                    cursor: "pointer",
                                    marginLeft: 20,
                                }}
                                disabled={deleteCheckedComs.length === 0}
                                text={'Delete'}
                                rounded
                                fontSize={15}
                                onClick={() => setShowDialog(true)}
                                customColor={"rgb(255 0 0 / 50%)"}
                            />
                            <DeleteConfirmDialog showDialog={showDialog} handleDeleteDialog={handleDeleteDialog} handleDeleteComps={handleDeleteComps} />
                        </div>

                    ) : ''
                    }

            </Fragment>
        );
    })
);



const DeleteConfirmDialog = inject("store")(observer((props) => {

    const { showDialog, handleDeleteDialog, handleDeleteComps } = props;


    const { t } = useTranslation("common");

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={handleDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="py-2 text-start">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='justify-content-end my-2'>
                    <Button onClick={(e) => handleDeleteDialog()} color="primary" size="small" className="shadow">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={(e) => handleDeleteComps()} color="secondary" size="small" className="shadow">
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}))

