import { Colors } from "../../Assets/Config/color.config";
import energyIcon from "../../Assets/images/Energy.svg";
import waterIcon from "../../Assets/images/water.svg";
import plasticIcon from "../../Assets/images/plastic.png";
import plasticBlackIcon from "../../Assets/images/plastic_black.png";
import greenMaterialIcon from "../../Assets/images/greenMaterial.svg";
import rightArrow from "../../Assets/images/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import Store from "../../Store";
import { ThousandSeparator } from "../../Assets/Config/thousandSeparator.config";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import dummyData from "../../Assets/focusArea.json";
import dummyDegitalEdge from "../../Assets/focusAreaDigitalEdge.json";
import { toJS } from "mobx";

export const FocusArea = (props) => {
  const { focusArea, selectedYear, data } = props;
  const { _handleViewDetail, theme, companyName } = Store.common;

  // if(focusArea === "Energy"){
  //   const temp = toJS(data)?.reduce((r,c) => {
  //     const R = {...r};
  //     const filterYearValue = c.data.filter(m => m.year == selectedYear)?.value;
  //     // const i = R.data.findIndex(v => v.value === filterYearValue);
  //   });

  // }

  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");
  const tSurvey = useTranslation("survey");
  const setAlignment = "d-flex justify-content-between align-items-center";
  let newData = focusArea === 'Scope 3 Emission' ? data?.sort((a,b) => (a.category < b.category) || a.category === undefined ? -1 : 1) : data

  return (
    <div key={focusArea === "Waste" ? (theme === "dark" ? 'plastic' : 'plastic_dark') : focusArea}>
      <div className="cardStyle" style={{ minWidth: 300, color: Colors[theme].textColor, marginBottom: 15 }}>
        <span className={setAlignment}>
          <span className={focusArea === "Waste" ? (theme === "dark" ? 'plastic' : 'plastic_dark') : focusArea} style={{ fontSize: '15px', fontWeight: 'bold' }}>
            {
              (focusArea === 'Energy' || focusArea === 'Water' || focusArea === 'Waste' || focusArea === 'Green Material') ?
                <img
                  className="mb-1 pe-2"
                  width={"25px"}
                  src={
                    focusArea === "Energy" ? energyIcon
                      : focusArea === "Water" ? waterIcon
                        : (focusArea === "Waste" && theme === 'dark') ? plasticIcon
                          : (focusArea === "Waste" && theme === "light") ? plasticBlackIcon
                            : greenMaterialIcon
                  }
                />
                : null}
            {t(focusArea)}
          </span>
          {focusArea === "Total Emissions" ? data :
            <span
              onClick={() => _handleViewDetail(focusArea, navigate)}
              style={{ color: Colors.water, cursor: "pointer" }}
            >
              {t("More Info")}
              <img src={rightArrow} className="ps-1" />
            </span>
          }
        </span>
        {focusArea === "Total Emissions" ? "" :
          <>
            <hr className={`my-2 ${focusArea === "Waste" ? (theme === "dark" ? 'plastic' : 'plastic_dark') : focusArea}`} style={{ opacity: 0.25 }} />

            {
              //focusArea.targets ?

              newData?.map((target, key) => {
                const data = target.data.filter(m => m.year == selectedYear)[0];
                const convertedGjValue = data?.value * 0.0036;
                const percentage = focusArea === "Energy" && target.data.filter(m => m.year == selectedYear)[0]?.percentage;
                console.log(percentage);
                const isProgress = target.data.filter(m => m.year == selectedYear)[0]?.isProgress;
                return (
                  <span key={key} className={setAlignment} >
                    <span>{(focusArea === "Energy" && key !== 0) ? `${t('Percentage')} ${target.label}` : (
                      key === 0 ?

                        `${t(target.label.split(" ")[0] + " " + target.label.split(" ")[1] + " " + target.label.split(" ")[2])} ${target.label.split(" ")[3]}` :
                        focusArea === "Scope 3 Emission" ?
                          <>
                            {target.category > 0 ? (
                              <b>
                                {`CATEGORY ${target.category}: `}
                              </b>
                            ) : ''}
                            {`${tSurvey.t(target.label)}`}
                          </>
                           :
                          `${tSurvey.t(target.label)}`
                    )}</span>
                    {/* <span>{ isProgress ? (value) : (typeof value === 'number'? value:'-')}</span> */}
                    <span>
                      {data ? focusArea === "Energy" ?
                        (key !== 0 ? `${Number.isInteger(percentage) ? percentage : percentage?.toFixed(2)} %`
                          : (data?.isProgress ? data.value : ThousandSeparator(convertedGjValue.toFixed(2)))) :
                        target.label === 'Percentage of Water consumed in regions with High or Extremely High Baseline Water Stress' || target.label === 'Percentage of Water withdrawn in regions with High or Extremely High Baseline Water Stress' ?
                          // (data?.isProgress ? data.value : (data?.year == "2021" ? "26%" : data?.year == "2022" ? "11%" : ThousandSeparator((data?.value)+"%")))
                          (data?.isProgress ? data.value : ThousandSeparator((data?.value)+"%"))
                          : (data?.isProgress ? data.value : ThousandSeparator((data?.value)?.toFixed(2)))
                        :
                        '0.00'}
                    </span>
                  </span>
                )
              })
              // : companyName == 'Digital Edge' ? (dummyDegitalEdge[index]?.targets?.map((target, key) => (
              //   <span key={key} className={setAlignment} >
              //     <span>{target.name}</span>
              //     <span>{target.progress ? target.progress + '%' : target.progress}</span>
              //   </span>
              // ))) :
              //   (companyName !== 'Enterprise SG' && companyName !== "Syscom") &&

              //   (dummyData[index]?.targets?.map((target, key) => (
              //     <span key={key} className={setAlignment} >
              //       <span>{target.name}</span>
              //       <span>{target.progress}%</span>
              //     </span>
              //   )))
            }
          </>
        }
      </div>
    </div>
  );
};

export const FocusAreaKW = inject("store")(
  observer((props) => {
    const { focusArea, kingWanData, name } = props;
    const { _handleViewDetail, selectedYear, theme } = Store.common;
    const year = selectedYear;
    const navigate = useNavigate();
    const { t } = useTranslation("dashboard");
    const setAlignment = "d-flex justify-content-between align-items-center";
    return (
      <div key={focusArea.key}>
        <div className="cardStyle" style={{ minWidth: 300 }}>
          <span className={setAlignment}>
            <span className={focusArea.key}>
              <img
                className="mb-1 pe-2"
                width={"25px"}
                src={
                  focusArea.key === "energy"
                    ? energyIcon
                    : focusArea.key === "water"
                      ? waterIcon
                      : focusArea.key === "waste"
                        ? plasticIcon
                        : greenMaterialIcon
                }
              />
              {t(focusArea.title)}
            </span>
            <span
              onClick={() => _handleViewDetail(focusArea.key, navigate)}
              style={{ color: Colors.water, cursor: "pointer" }}
            >
              {t("More Info")}
              <img src={rightArrow} className="ps-1" />
            </span>
          </span>
          <hr className={`my-2 ${focusArea.key}`} style={{ opacity: 1 }} />

          {kingWanData[year].monthlyConsumption.map((month, k) =>
            name === "energy" ? (
              <span key={k} className={setAlignment}>
                <strong>{month.month}</strong>
                <span style={{ color: Colors[theme].theme }}>
                  {month.energyConsumption?.toFixed(2)}
                  <span className="ps-1" style={{ color: Colors.Dark[3] }}>
                    {kingWanData.units[0].unit}
                  </span>
                </span>
              </span>
            ) : name === "water" ? (
              <span key={k} className={setAlignment}>
                <strong>{month.month}</strong>
                <span>
                  {month.waterConsumption}
                  <span className="ps-1" style={{ color: Colors.Dark[3] }}>
                    {" "}
                    {kingWanData.units[1].unit}
                  </span>
                </span>
              </span>
            ) : null
          )}
        </div>
      </div>
    );
  })
)
