import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { MyButton } from "../../Tools";
import rightArrow from "../../Assets/images/rightArrow.svg";
import { useState } from "react";
import { baseURL } from "../../API/URL";
import { useTranslation } from 'react-i18next';
import './table.css';

export const OverAllProtocolTbl = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {_handleSummaryRowClick,selectedSummaryRow} = props.store.esg;
    const { tableHeaders, data } = props;
    const [hover,setHover] = useState(null);
    const { t } = useTranslation("summary");
    return (
      <div className="p-2" 
      style={{
        boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        marginBottom: 8,
        color: "#c6c6c6"
      }}
      >
      <div 
        className="overallTbl-scrollbar"
        style={{
          maxHeight: 180, overflow:"auto"
          // background: theme === "dark" ? Colors.darkBlue : Colors.white,
        }}
      >
        <table
          className="table mb-0"
          style={{ color: Colors[theme].textColor, fontSize: 14,
            borderColor: "transparent" }}
        >
          <tr style={{ color: '#f0f0f0' }}>
            {tableHeaders.map((v,i) => (
              <th className="p-2"
              // align='center'
                style={{
                  top:0, position: "sticky",
                  borderLeft: i!== 0 && " 1px solid",
                  borderLeftColor: i!== 0 &&
                    theme === "dark" ? "rgb(255, 255, 255,0.1)" : Colors.Dark[4],
                  textAlign:i!==0&&'center', fontSize: 15,
                  backgroundColor: Colors[theme].tableBgColor,
                }}
              >
                {v}
              </th>
            ))}
          </tr>

          {data.map((v, i) => (
            <tr onMouseLeave={()=>setHover(null)} onMouseEnter={()=>setHover(i)} style={{cursor:'pointer',background:i===selectedSummaryRow&&'rgb(255,255,255,0.1)',opacity:(hover===i||i===selectedSummaryRow)?1:0.6}} onClick={()=>_handleSummaryRowClick(i)}>
              <td align="start" className="px-2 py-3">
                <span className="bg-white me-2" style={{borderRadius:5}}>
                <img
                  width="20px"
                  // style={{ borderRadius: 10 }}
                  src={
                    i === 0
                      ? `${baseURL}upload/framework/5d6197a3-01ad-41f5-8596-cfef5f4a3ff5.jpeg`
                      : i === 1
                      ? `${baseURL}upload/framework/c8dc9b9d-eaba-43b8-a5cf-516a70ff04f4.png`
                      : `${baseURL}upload/framework/wwf.png`
                  }
                />
                </span>
                {v["protocol"]}
              </td>
              <td align="center" className=" my-1">
                {v["tracking"]}
              </td>
              <td align="center" className=" my-1">
                {v["notYetTracking"]}
              </td>
              <td align="center" className=" my-1">
                {v["notApplicable"]}
              </td>
              {/* <td align="end" className="px-3 my-1">
                
                    <span
                      style={{ padding: 5, color:Colors.water, cursor:'pointer' }}
                      data-bs-toggle="modal"
                      data-bs-target="#summaryModal"
                    >
                      {v["button"]}<img className="ps-2" src={rightArrow}/>
                    </span>
                  
              </td> */}
            </tr>
          ))}
        </table>
      </div>
      </div>
      
    );
  })
);
