import * as React from 'react';
import { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Grid,
    TableSortLabel,
    ImageListItem,
    ImageListItemBar
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";
import { Colors, invertDark } from '../../Assets/Config/color.config';
// import { SummaryDonutChart } from './SummaryDonutChart';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SummaryDonutChart } from '../AuditCompanyOverview/SummaryDonutChart';

export const GroupListTable = inject("store")(
    observer((props) => {
        const { data } = props;
        const { theme } = props.store.common;

        const [rows, setRows] = useState([]);
        const [order, setOrder] = useState("asc");
        const [orderBy, setOrderBy] = useState("");
        const summaryKeys = [
            { label: 'Verified', key: 'verifiedCount', color: '#B0D86F' },
            { label: 'Not Verified', key: 'notVerifiedCount', color: '#8CDDD7' },
            { label: 'Rejected', key: 'rejectCount', color: '#F26B74' },
            { label: 'Not Applicable', key: 'notApplicableCount', color: '#85879E' }
        ]

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        };

        const stableSort = (array, comparator) => {
            const stabilizedThis = array.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                if (order !== 0) return order;
                return a[1] - b[1];
            });
            return stabilizedThis.map((el) => el[0]);
        };

        const getComparator = (order, orderBy) => {
            return order === "desc"
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy);
        };

        const descendingComparator = (a, b, orderBy) => {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        };

        useEffect(() => {
            // const rowsdata = data.map(v => createData(v));
            setRows(data)
        }, [data])

        const useStyles = makeStyles({
            table: {
                width: "100%",
            },
        });

        const classes = useStyles();
        return (
            <TableContainer style={{ background: 'transparent', boxShadow: 'none' }} component={Paper}>
                <Table className={classes.table} aria-label="">
                    <SortableTHead
                        id={"id"}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headers}
                        theme={theme}
                    />
                    <TableBody>
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                ?.map((row, rowIndex) => (
                                    <Row key={rowIndex} row={row} theme={theme} summaryTblKeys={summaryKeys} />
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    })
)

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const { t } = useTranslation("audit");
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const styles = makeStyles({
        // Fully visible for active icons
        activeSortIcon: {
            opacity: '1!important',
            color: `${invertDark(theme)}!important`
        },
        // Half visible for inactive icons
        inactiveSortIcon: {
            opacity: '0.3!important',
            color: `${invertDark(theme)}!important`
        },
    });
    const classes = styles();
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            color: invertDark(theme),
                            fontWeight: "bold",
                            padding: "10px 13px 10px 13px",
                            textAlign: "center",
                            position: "static",
                        }}
                    >
                        {
                            headCell.id === "" ?
                                headCell.label :
                                <TableSortLabel
                                    active={true}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{
                                        color: invertDark(theme),
                                    }}
                                    classes={{
                                        icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon)
                                    }}
                                >
                                    {t(headCell.label)}
                                </TableSortLabel>
                        }

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const Row = (props) => {
    const { row, theme, summaryTblKeys } = props;
    const [open, setOpen] = React.useState(false);
    const tableCellStyle = { border: 0, color: invertDark(theme), padding: '10px 13px' };
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const companyId = params.get("companyId");
    // const { t } = useTranslation("dashboard");
    const { t } = useTranslation("audit");
    const pieChartData = summaryTblKeys.map(v => (
        {
            name: v.label,
            value: row[v.key],
            fill: v.color
        }
    ))
    return (
        <React.Fragment>
            <TableRow style={{ background: Colors[theme].secondBackground, }} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{ border: 0, padding: '10px 13px', borderTopLeftRadius: 4, borderBottomLeftRadius: open ? 0 : 4 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {
                            open ?
                                <KeyboardArrowUpIcon style={{ color: Colors.water, fontWeight: 'bold' }} /> :
                                <KeyboardArrowDownIcon style={{ color: Colors.water, fontWeight: 'bold' }} />
                        }
                    </IconButton>
                </TableCell>
                <TableCell style={{ ...tableCellStyle, minWidth: 150 }} component="th" scope="row">{row.groupName}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.totalAsset}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.verifiedCount}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.notVerifiedCount}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.rejectCount}</TableCell>
                <TableCell style={tableCellStyle} align="center">{row.attachedFileCount}</TableCell>
                <TableCell style={tableCellStyle} align="center">
                    {
                        row.isCertifed ?
                            <img
                                src="/greenCertified.svg"
                                style={{ width: "28px", height: "26px" }}
                                alt="accept"
                            />
                            : null
                    }
                    {t(row.auditStatus)}


                </TableCell>
                <TableCell onClick={() => navigate(`/audit-group-verification?companyId=${companyId}&groupName=${row.groupName}&groupId=${row._id}`)} style={{ color: Colors.blue, border: 0, padding: '10px 13px', borderTopRightRadius: 4, borderBottomRightRadius: open ? 0 : 4, cursor: 'pointer' }} align="center">{t("More Detail") + ' >>'}</TableCell>
            </TableRow>
            <TableRow style={{ background: Colors[theme].secondBackground, padding: 0, margin: 0, borderSpacing: 0 }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0, color: invertDark(theme), borderBottomRightRadius: 4, borderBottomLeftRadius: 4 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <hr />
                            <Grid container display={'flex'} alignItems={'center'} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                                <Grid justifyContent={'center'} sx={{ p: 2 }} item xs={7} md={7} lg={7} style={{ borderRight: `1px solid ${theme === 'dark' ? 'rgba(198,198,198,0.25)' : 'rgba(48,48,48,0.25)'}` }} >
                                    <Typography variant="h6" gutterBottom component="div">
                                        {t("Summary")}
                                    </Typography>
                                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                                        <Grid display={'flex'} justifyContent={'center'} item xs={11} md={4} lg={4}>
                                            <SummaryDonutChart
                                                data={pieChartData}
                                            />
                                        </Grid>
                                        <Grid display={'flex'} justifyContent={'center'} item xs={11} md={6} lg={6}>
                                            <Table sx={{ /*maxWidth: 250*/ }} size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow style={{ background: 'transparent' }}>
                                                        <TableCell></TableCell>
                                                        <TableCell style={{ color: invertDark(theme), textAlign: 'center' }}>{t('Total')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {summaryTblKeys?.map((td, td_key) => (
                                                        <React.Fragment key={td_key}>
                                                            <TableRow className='' style={{ background: Colors[theme].active, }}>
                                                                <TableCell style={{ whiteSpace: 'nowrap', alignItems: 'center', overflow: 'hidden', color: invertDark(theme), border: 0, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>
                                                                    <div
                                                                        className='me-2'
                                                                        style={{
                                                                            background: td.color,
                                                                            width: 10,
                                                                            maxWidth: 10,
                                                                            height: 10,
                                                                            borderRadius: '50%',
                                                                            display: 'inline-block'
                                                                        }}
                                                                    >
                                                                    </div>
                                                                    <div style={{ display: 'inline-block' }}>{t(td.label)}</div>
                                                                </TableCell>
                                                                <TableCell style={{ color: td.status === 'Rejected' ? td.color : invertDark(theme), textAlign: 'center', border: 0, borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                                                                    {row[td.key]}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow style={{ background: 'transparent', height: 4 }} />
                                                        </React.Fragment>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid display={'flex'} justifyContent={'center'} item xs={5} md={5} lg={5} >
                                    <ImageListItem>
                                        {
                                            row.isCertifed ?
                                                <img
                                                    src="/greenCertified.svg"
                                                    style={{ width: "70px", height: "78px" }}
                                                    alt="accept"
                                                />
                                                : null
                                        }
                                        <ImageListItemBar position="below" style={{ textAlign: 'center' }} title={t(row.auditStatus)} />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow style={{ background: 'transparent', height: 6 }} />
        </React.Fragment>
    );
}

const headers = [
    { id: "groupName", numeric: false, label: "View" },
    { id: "totalAsset", numeric: false, label: "Total Asset" },
    { id: "verifiedCount", numeric: true, label: "Verified" },
    { id: "notVerifiedCount", numeric: true, label: "Not-Verified" },
    { id: "rejectCount", numeric: true, label: "Rejected" },
    { id: "attachedFileCount", numeric: false, label: "Attached File" },
    { id: "auditStatus", numeric: false, label: "Auditor Status" },
    { id: "", numeric: false, label: "" },
]