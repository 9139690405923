import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  fetchIndividualTracking,
  fetchScopeDataByCategory,
  fetchY2yTrackingEnergy,
  fetchY2yTrackingS1Emissions,
} from "../API/api.trackingDetail";

class Tracking {
  selectedBuildingType = "All";
  buildingName = "";
  individualTrackingData = [];
  totalBaselineYr = 0;
  totalCurrentYr = 0;
  cookies = new Cookies();

  dataFillPercent = 0;
  dataFillSiteCount = 0;
  selectedTrackingTblYear = new Date().getFullYear();
  y2yTrackingEnergy = null;
  countryOptions=[];
  siteOptions=[];
  selectedCountry= "All";
  selectedSite= "All";
  all_y2yTrackingEnergy = null;
  y2yTrackingS1Emissions = null;
  all_y2yTrackingS1Emissions = null;
  selectedTrackingTblCountry= "All"

  constructor() {
    makeObservable(this, {
      selectedBuildingType: observable,
      buildingName: observable,
      individualTrackingData: observable,
      dataFillSiteCount: observable,
      dataFillPercent: observable,
      totalBaselineYr: observable,
      totalCurrentYr: observable,
      selectedTrackingTblYear: observable,
      y2yTrackingEnergy: observable,
      selectedCountry: observable,
      selectedSite: observable,
      countryOptions: observable,
      siteOptions: observable,
      all_y2yTrackingEnergy: observable,
      y2yTrackingS1Emissions: observable,
      all_y2yTrackingS1Emissions: observable,
      selectedTrackingTblCountry:observable,

      _openNewTab: action.bound,
      fetchTrackingData: action.bound,
      getScopeDataByCategory: action.bound,
      changesTotal: action.bound,
      _handleBuildingTypeChange: action.bound,
      _handleSelectTrackingTblYear: action.bound,
      getY2yTrackingEnergy: action.bound,
      _handleGetCountry_n_SiteOptions: action.bound,
      _handleSelectSelector: action.bound,
      _handleGetYeartoYearComparison: action.bound,
      getY2yTrackingS1Emissions: action.bound,
      getEmptyPieData: action.bound,
    });
  }

  _handleBuildingTypeChange = (value, key) => {  
    if(key === "building")  {
      this.selectedBuildingType = value;
    } else {
      this.selectedTrackingTblCountry = value;
    }

    let numOr0 = (n) => (isNaN(n) ? 0 : n);

let data;
let baseLineData;
let currentData;
    console.log(toJS(this.individualTrackingData));
    if (this.selectedBuildingType === 'All' && this.selectedTrackingTblCountry === "All") {
      data = this.individualTrackingData
      baseLineData = data?.map((v) => v.baseline)
      currentData = data?.map((v) => v.baseline)
    } else if (this.selectedBuildingType === "All" && this.selectedTrackingTblCountry !== "All") {
      data = this.individualTrackingData.filter(v => v.building
        .split(', ')[v.building
          .split(', ').length - 1] === this.selectedTrackingTblCountry)?.sort((a, b) => b?.progress - a?.progress)
      baseLineData = data?.map((v) => v.baseline)
      currentData = data?.map((v) => v.baseline)
    }
    else if (this.selectedBuildingType !== "All" && this.selectedTrackingTblCountry === "All") {
      data = this.individualTrackingData.filter((v) => v.buildingType === this.selectedBuildingType)?.sort((a, b) => b?.progress - a?.progress)
      baseLineData = data?.map((v) => v.baseline)
      currentData = data?.map((v) => v.baseline)
    } else if (this.selectedBuildingType !== "All" && this.selectedTrackingTblCountry !== "All") {
      data = this.individualTrackingData.filter((v) => v.buildingType === this.selectedBuildingType && v.building
        .split(', ')[v.building
          .split(', ').length - 1] === this.selectedTrackingTblCountry)?.sort((a, b) => b?.progress - a?.progress)
      baseLineData = data?.map((v) => v.baseline)
      currentData = data?.map((v) => v.baseline)
    }
    // let baseLineData = value === 'All'?this.individualTrackingData?.map((v) => v.baseline):this.individualTrackingData?.map((v) => v.buildingType === value?v.baseline:0);
    // let currentData = value === 'All'?this.individualTrackingData?.map((v) => v.current):this.individualTrackingData?.map((v) => v.buildingType === value?v.current:0)

    if(baseLineData && currentData){
      this.totalBaselineYr = baseLineData
      ?.reduce((a, b) => numOr0(a) + numOr0(b), 0)
      ?.toFixed(2)
      ?.toLocaleString("en-US");

    this.totalCurrentYr = currentData
      ?.reduce((a, b) => numOr0(a) + numOr0(b), 0)
      ?.toFixed(2)
      ?.toLocaleString("en-US");
    }    
  }

  _openNewTab = ({
    siteName,
    category,
    focusArea,
    siteImage,
    permittedBuilding,
    navigate,
  }) => {
    const buildingId = toJS(permittedBuilding)?.find(
      (v) => v.buildingName === siteName
    )?._id;
    this.buildingName = siteName;
    // window.open(`/tracking-detail?buildingId=${buildingId}`, "_blank");
    navigate(
      `/tracking-detail?buildingId=${
        siteName === "SINGAPORE AIRLINES"
          ? "62fcc46b759a686c90b3956c"
          : siteName === "EMIRATES"
          ? "62fccca6759a686c90b39581"
          : siteName === "ALL NIPPON AIRWAYS"
          ? "62fcccd6759a686c90b39582"
          : buildingId
      }&category=${category}`
    );
    this.cookies.set("siteName", siteName);
    this.cookies.set("focusArea", focusArea);
    this.cookies.set("siteImage", siteImage);
    window.location.reload();
    // navigate("/tracking-detail")
  };

  fetchTrackingData = (permittedBuilding, year) => {
    const countries = [...new Set(permittedBuilding?.map(v=> {
      const splitArr = v.buildingName?.split(', ')
      return splitArr[splitArr.length-1]
    }))]?.sort();
    const countryOpt = (countries.map(v=> ({label: v, value: v})));
    countryOpt.unshift({label: "All", value: "All"});
    this.countryOptions = countryOpt;
     fetchIndividualTracking (year,(err, data) => {
      runInAction(() => {
        if (err) {
          // toast.error(err)
        } else {
          if (!data.error) {
            // console.log("FetchTrackingData", permittedBuilding);

            let collectedList = data.payload;
            
            let unlistedBuilding = permittedBuilding
              .filter((v) => {
                // if(v.buildingName == ){

                // }
                let found = collectedList.find((v2) => {
                  // console.log("Equation",v2.building, v.buildingName);
                  if (v2.building == v.buildingName) {
                    v2.photo = v.photo;
                    v2.buildingType = v.type.building_type;
                    return true;
                  }
                  return false;
                });
                // console.log("Found", found);
                if (found) {
                  return false;
                }
                return true;
              })
              .map((v) => {
                let result = {
                  buildingId: v._id,
                  baselineYear: 0,
                  unit: null,
                  building: v.buildingName,
                  buildingType: v.type.building_type,
                  total_progress: null,
                  baseline: 0,
                  current: 0,
                  current_year: null,
                  photo: v.photo,
                };
                return result;
              });
            // console.log("unlistedBuilding", unlistedBuilding);

            collectedList.push(...unlistedBuilding);
            this.individualTrackingData = collectedList;   
            console.log("Individal", toJS(this.individualTrackingData));
            this.changesTotal(this.individualTrackingData);
            this.changesSiteCountAndPercent(this.individualTrackingData);
          }
        }
      });
    });
  };
  

  changesSiteCountAndPercent = (siteData) => {
    let totalSite = siteData.length;    
    this.dataFillSiteCount = siteData.filter(v=> v.baseline != 0).length;
    this.dataFillPercent = (this.dataFillSiteCount/totalSite)*100
  }

  getScopeDataByCategory = (focusArea) => {
    fetchScopeDataByCategory(focusArea, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        // console.log('data payload ------------------ ',data.payload)
        runInAction(() => {
          this.individualTrackingData = data.payload;
          this.changesTotal(this.individualTrackingData);
          this.changesSiteCountAndPercent(this.individualTrackingData);
        });
      }
    });
  };

  changesTotal = (data) => {
  let numOr0 = (n) => (isNaN(n) ? 0 : n);
   if (data) {
    if (data[0]?.baseline !== undefined){
      var baseLineData = data?.map((v) => v.baseline*1);
      var currentData = data?.map((v) => v.current*1);
    } else {
      var baseLineData = data?.map((v) => v.consumptionLastYr*1);
      var currentData = data?.map((v) => v.consumptionCurrentYr*1);
    }}
  
    // runInAction(() => {

      if(baseLineData && currentData){
        this.totalBaselineYr = baseLineData
        ?.reduce((a, b) => numOr0(a) + numOr0(b), 0)
        ?.toFixed(2)
        ?.toLocaleString("en-US");

      this.totalCurrentYr = currentData
        ?.reduce((a, b) => numOr0(a) + numOr0(b), 0)
        ?.toFixed(2)
        ?.toLocaleString("en-US");
      }
    // });
  };

  _handleSelectTrackingTblYear = (val) => {
    this.selectedTrackingTblYear = val
  }

  _handleGetCountry_n_SiteOptions = (permittedBuilding) => {
    const countries = [...new Set(permittedBuilding?.map(v=> {
      const splitArr = v.buildingName?.split(', ')
      return splitArr[splitArr.length-1]
    }))]?.sort();
    const countryOpt = (countries.map(v=> ({label: v, value: v})));
    countryOpt.unshift({label: "All", value: "All"});
    this.countryOptions = countryOpt;

    const sitesOpt = permittedBuilding?.map(v=> {
      const splitArr = v.buildingName?.split(', ');
      return({
        label: v.buildingName,
        value: v._id,
        country: splitArr[splitArr.length-1]
      })
    })
    sitesOpt.unshift({ label: "All", value: "All", country: "All" });
    this.siteOptions = sitesOpt
  }

  _handleSelectSelector(val,key){
    this[key] = val;
    if(key=== "selectedCountry"){
      this.selectedSite = this.siteOptions?.filter(v=> v.country === val)[0]?.value;
    }
  }

  _handleGetYeartoYearComparison (focusArea){
    if(focusArea === "Energy"){
      this.y2yTrackingEnergy = this.all_y2yTrackingEnergy?.find(v=> v.buildingId === this.selectedSite)?.data || this.getEmptyPieData(this.all_y2yTrackingEnergy)
    }else{
      this.y2yTrackingS1Emissions = this.all_y2yTrackingS1Emissions?.find(v=> v.buildingId === this.selectedSite)?.data || this.getEmptyPieData(this.all_y2yTrackingS1Emissions)
    }
  }

  getY2yTrackingEnergy() {
    this.y2yTrackingEnergy = null;
    fetchY2yTrackingEnergy((err, data) => {
      this.y2yTrackingEnergy = [];
      if (err) {
        toast.error(err);
        this.y2yTrackingEnergy = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            const result = data.payload?.map(v=> {
              let listTotalValue = v.data[0].data.reduce((p, c) => {
                p.set(c.year, c.value);
                return p;
              }, new Map());

                const percentageValues = v.data.map((v, k) => {
                  return {
                    ...v,
                    data: v.data.map((vv, kk) => {
                      //const convertedValue = vv.value*0.0036;
                      //const totalYear = v.data.filter(t=> t.year == vv.year)[0]
                      return {
                        year: vv.year,
                        value: vv.value.toFixed(2),
                        percentage:
                          (vv.value / listTotalValue.get(vv.year)) * 100,
                      };
                    }),
                  };
                });
                
                return({
                  buildingId: v.buildingId,
                  data: percentageValues
                })
             
            })
          
            this.all_y2yTrackingEnergy = result;
          }
        }
      }
    });
  }

  getY2yTrackingS1Emissions() {
    this.y2yTrackingS1Emissions = null;
    fetchY2yTrackingS1Emissions((err, data) => {
      this.y2yTrackingS1Emissions = [];
      if (err) {
        toast.error(err);
        this.y2yTrackingS1Emissions = null;
      } else {
        if (!data.error) {
          if (data.payload?.length > 0) {
            const result = data.payload?.map(dt=> {
              //////////// Convert MtCo2e from kgCo2 for Digital Edge ///////////
              const convertedValues = dt.data.map((v, k) => {
                return {
                  ...v,
                  data: v.data.map((vv, kk) => {
                    return {
                      ...vv,
                      value: vv.value * 0.001,
                    };
                  }),
                };
              });
              return ({
                  buildingId: dt.buildingId,
                    data: convertedValues
              })
            });
            this.all_y2yTrackingS1Emissions = result
          }
        }
      }
    });
  }

  getEmptyPieData(data){
    const emptyData = data?.find(v=> v.buildingId === "All")?.data.map(item => ({
      label: item.label,
      data: item.data.reduce((acc, dataItem) => {
         acc.push({ year: dataItem.year, value: 0 });
         return acc;
      }, [])
   }));

   return emptyData
  }
}

export default new Tracking();
