import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";

import { Colors, invertDark } from "../Assets/Config/color.config";
export const MyCreatableSelector = (props) => {
  const {
    options,
    value,
    label,
    defaultValue,
    _handleSelect,
    disabled,
    menuListColor,
    id,
    placeholder,
    textChange,
    getOptionLabel,
    theme
  } = props;

  const [modifiedOption, setModifiedOption] = useState(undefined);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: Colors[theme].secondBackground,
      color: theme === "dark" ? Colors.Dark[4] : Colors.Dark[1],
      borderRadius: 5,
      minHeight: 43,
      fontSize: 14,
      borderColor: theme === "dark" ? Colors.Dark[2] : Colors.Dark[4],
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: Colors.Dark[3],
        cursor: "pointer",
      },
    }),
    menu: (base) => ({
      ...base,
      fontSize: 14,
      borderBottomRadius: 10,
      zIndex: 9999
    }),
    menuList: (base) => ({
      ...base,
      color: menuListColor ? menuListColor : null,
    }),
    input: (base, state) => ({
      ...base,
      color: 'inherit'
    }),
    singleValue: (base) => ({
      ...base,
      color: invertDark(theme),
    }),
    
  };
  return (
    <div
      style={{ width: window.screen.width < 330 && 200 }}
      className={`${id === "manualSector" && "d-flex m-2"}`}
    >
      {label && (
        <>
          <label
            className={`${id === "manualSector" && "mt-2"}`}
            style={{
              fontWeight: "bold",
            }}
          >
            {label}:
          </label>
          <br />
        </>
      )}
      <CreatableSelect
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        value={value}
        styles={customStyles}
        options={options}
        defaultValue={defaultValue}
        onChange={(e) => _handleSelect(e)}
        onCreateOption={(e) => {
          textChange({ target: { value: e } }, id);
          setModifiedOption([...options, { value: id, label: e }]);
        }}
        isDisabled={disabled}
      />
    </div>
  );
};
