import {
    AppBar, Box,
    Toolbar,
    Typography, Menu,
    Container,
} from "@mui/material";
import { MyInput, MuiSelector } from "../../Tools";
import { Selector, MyButton } from "../../Tools";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { inject, observer } from "mobx-react";
import { Colors } from "../../Assets/Config/color.config";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { toJS } from "mobx";
import { ReportTempPreviewModal } from './ReportTempPreviewModal';
import { ReportTempNameModal } from './ReportTempNameModal';


import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";




import { useTranslation } from "react-i18next";

export const ReportNavBar = inject("store")(
    observer((props) => {
        const buttons = ['Save', 'Save As', 'Delete'];
        const { theme } = props.store.common;
        const { 
            analysisReportData,
            reportTblLoading,
            reportCustomConfigList,
            reportConfigureList,
            selectedTemplate,
            _handleSelectTemplate,
            _handleUpdateReportTemp,
            _handleDeleteReportTemp,
            _handleCurrentAction,
            _handleDyContents,
            defaultTempNames,
            customTempName,
            isDefaultTemp
        } = props.store.reportConfigure;
        const { t } = useTranslation("analysisReport");

        const [anchorElNav, setAnchorElNav] = useState(null);
        const [anchorElUser, setAnchorElUser] = useState(null);
        const [templateOptions, setTemplateOptions] = useState([]);
        const [CustTempOptions, setCustTempOptions] = useState([]);

        const [showDialog, setShowDialog] = useState(false);

        useEffect(() => {
            if (reportConfigureList.length) {
                const list = reportConfigureList.map(v => {
                    return { value: v.templateName, label: v.templateName, companyId: v.companyId };
                });
                // const defTemp = list.filter(l=> !l.companyId)
                // const custTemp = list.filter(l=> l.companyId)
                setTemplateOptions(list);
                // setCustTempOptions(list)
            }
        }, [reportConfigureList]);

        const handleOpenNavMenu = (event) => {
            setAnchorElNav(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            // setAnchorElNav(null);
        };

        const handleDeleteDialog = () => {
            setShowDialog(false)
        };

        const handleDeleteTemp = () => {
            setShowDialog(false)
            _handleDeleteReportTemp()
        };

        const handleActionTemp = (e, page) => {
            e.preventDefault();
            (page === "Save") ? _handleUpdateReportTemp(page) : (page === "Save As") ? _handleCurrentAction(page) : setShowDialog(true)
        };

        const StyledToolbar = withStyles((theme) => ({
            root: {
                maxHeight: "55px",
                minHeight: "0 !important",
                // backgroundColor: Colors[props.store.common.theme].active
            },
        }))(Toolbar);

        const handleAdp=() =>{
            console.log('hrereeeeeeeeeeeeee')
        }
        
        return (
            <>
                <div position="static">

                    <StyledToolbar disableGutters className="pb-3 px-0">
                        <Box sx={{ mr: 4, width: 200 }} style={{  }}>
                            <Selector
                                style={{ width: 200 }}
                                menuListColor={Colors.Dark[0]}
                                options={templateOptions}
                                placeholder={"Select Template"}
                                value={templateOptions?.filter((option) => option.value === selectedTemplate)}
                                _handleSelect={(e) => _handleSelectTemplate(e)}
                            />

                        </Box>

                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            {buttons.map((page) => (
                                <MyButton
                                    key={page}
                                    text={t(page)}
                                    onClick={e =>handleActionTemp(e,page) }
                                    id={page}
                                    style={{ width: 75, marginRight: 20 }}
                                    customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
                                    customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'}
                                    small={true}
                                    disabled={!customTempName || (page !== "Save As" && isDefaultTemp())}
                                    dataToggle={page === "Save As"?"modal":null}
                                    dataTarget={page === "Save As"?"#ReportTempNameModal":null}
                                />
                            ))}
                               
                        </Box>

                    


                        <Box sx={{ flexGrow: 0, display: 'flex' }}>

                            <MyButton
                                disabled={!reportCustomConfigList}
                                text={t('Preview')}
                                onClick={e => _handleDyContents() }
                                id={"button-pre"}
                                small={true}
                                style={{ width: 75, }}
                                customColor={theme === "dark" ? Colors.lightBlue : Colors.blue}
                                customHoverColor={theme === "dark" ? '#35469b' : '#0148AE'}

                                dataToggle={'modal'}
                                dataTarget={'#reportTempPreviewModal'}


                            />


                        </Box>
                    </StyledToolbar>
                    {reportCustomConfigList && <ReportTempPreviewModal />}
                    {reportCustomConfigList && <ReportTempNameModal />}
                    
                    <ForceDeleteConfirmDialog customTempName={customTempName} showDialog={showDialog} handleDeleteDialog={handleDeleteDialog} handleDeleteTemp={handleDeleteTemp} />
                </div>
            </>
        )
    })
)



const ForceDeleteConfirmDialog = inject("store")(observer((props) => {

    const { showDialog, handleDeleteDialog, handleDeleteTemp, customTempName } = props;


    const { t } = useTranslation("common");

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={handleDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="py-2 text-start">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete {customTempName} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='justify-content-end my-2'>
                    <Button onClick={(e) => handleDeleteDialog()} color="primary" size="small" className="shadow">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={(e) => handleDeleteTemp()} color="secondary" size="small" className="shadow">
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}))




