import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { toJS } from "mobx";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useTranslation } from "react-i18next";

export const AuditGroupMenu = inject("store")(
    observer((props) => {
        const {theme} = props.store.common;
        const {groupDetailInfo} = props.store.audit;
        const {t} = useTranslation("audit");

        return (
            <div className="d-flex flex-row p-1" style={{fontSize: 14, fontWeight:'bold', background: Colors[theme].tableBgColor,
            color: Colors.white, borderRadius: 5 }}>
               <div className="py-2 px-3" style={{borderRight: "1px solid", borderRightColor: theme === "dark" ? "rgb(255, 255, 255,0.3)": Colors.Dark[4]}}>
                {t("Total Asset: ") + groupDetailInfo?.totalAsset}
               </div>
               <div className="py-2 px-3">
                {t("Progress - ")}
               </div>
               <div className="py-2 px-3">
                {t("Total")+": " + groupDetailInfo?.progress?.total}
               </div>
               <div className="py-2 px-3">
                {t("Finished: ") + groupDetailInfo?.progress?.finished}
               </div>
               <div className="py-2 px-3">
                {t("Unfinished: ") + groupDetailInfo?.progress?.unfinished}
               </div>
            </div>
        );
    })
)