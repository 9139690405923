import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from 'react-i18next';
import { EmissionGWPView } from '../Components/GroupView/EmissionGWPView';
import { inject, observer } from "mobx-react";
import "../Components/GroupView/groupview.css";
import AssetView from '../Components/GroupView/AssetView';
import { ConsumptionTables } from '../Components/GroupView/ConsumptionTables';
import { AccordionViewHeader } from '../Components/GroupView/AccordionViewHeader';
import { BuildingTable } from '../Components/GroupView/BuildingTable';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: "#FFFFFF" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px 0px 0px 8px',

  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 10,
  backgroundColor: "#202b60",
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const GroupViewAccordion = inject("store")(
  observer((props) => {
    const { t } = useTranslation("groupView");
    const { selectedView, data, index, theme, selectedGroupId } = props;
    const { _handleTextChange, editTable, _handleEditTable, _handleSelectChange } = props.store.groupmanagement;
    const [expanded, setExpanded] = React.useState(index);
    //const [isClickDelete, setClickDelete] = React.useState(false);
    const [enableDelete, setEnableDelete] = React.useState(false);
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <div className="accordion-parent-div">          
        <Accordion expanded={expanded === index} onChange={handleChange(index)} className="main-accordion">
          <AccordionSummary aria-controls="panel1d-content" className="accordion-summary-title" >
            <AccordionViewHeader selectedGroupId={selectedGroupId} data={data} selectedView={selectedView} enableDelete={enableDelete}/>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: theme === 'dark' ? '#202b60' : '#EEE' }}>
            {
              selectedView === 'Emission Activity' ?
                <AssetView selectedGroupId={selectedGroupId} selectedView={selectedView} data={data} index={index} theme={theme} _handleTextChange={_handleTextChange} _handleSelectChange={_handleSelectChange} _handleEditTable={_handleEditTable} editTable={editTable} />
              : selectedView === 'Emission View' ?
                <EmissionGWPView selectedGroupId={selectedGroupId} selectedView={selectedView} data={data} index={index} theme={theme} />
              : selectedView === 'Consumption View' ?
                <ConsumptionTables selectedGroupId={selectedGroupId} selectedView={selectedView} data={data} index={index} theme={theme} />
              : null
            }
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }));
