import { useState, useEffect, Fragment } from "react";
import { Colors, invertDark } from "../Assets/Config/color.config";
import Store from "../Store";
import { MyButton } from "./index";
import { useTranslation } from "react-i18next";
import { TransportationModal } from "../Components/Survey/transportationModal";
import { toJS } from "mobx";
import { Box,
         Collapse, 
         IconButton,  
         Table, 
         TableBody,  
         TableCell,  
         TableContainer, 
         TableSortLabel,
         TablePagination,
         TableHead,  
         TableRow, 
         Typography,  
         Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withStyles, makeStyles } from "@material-ui/core/styles";
const { theme } = Store.common;


const Row =(props) => {

  const { d , c , h } = props;
  const [open, setOpen] = useState(false);

  const { 
    _handleShowModel , 
    isShowModel ,
    createTransportationList,
    } = Store.survey;

  const headStyle = {
    backgroundColor:
    theme === "dark" ? Colors.primaryBule : Colors.blue,
    color: invertDark(theme),
    fontWeight: "bold",
    padding: "10px 5px",
    position: "static",
    textAlign: "left",
  }

  const bodyStyle = {
      color: invertDark(theme),
      textAlign: "left",
      fontSize: 14,
      padding: "0px 5px",
      borderBottom: 'none'
  }

  const style = {
    color: invertDark(theme),
    textAlign: "left",
    fontSize: 14,
    padding: "0px 5px",
    borderBottom: `none`,
    borderTop: 'none'
  };

  const _handleNewCollapseData = (d) => {
  
    if(h === "Scope 3"){
      createTransportationList.category= d.category;
      createTransportationList.year = d.year.toString();
      createTransportationList.transportMode= d.transportMode;
      createTransportationList.emissionFactor= d.emissionFactor;
      createTransportationList.vehicleType= d.vehicleType;
      createTransportationList.activityType= d.activityType;
      createTransportationList.active= d.active;
      createTransportationList.unit= d.unit;
      createTransportationList.name= d.name;
      createTransportationList.commissionedDate = new Date();
      createTransportationList.distance = "";
      createTransportationList.carbonEmission = "";
    }
  }

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ borderBottom : `none` }}>
          <IconButton
            style={{ color: invertDark(theme) }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {Object.values(d).map((dd,k) => {
            if(typeof dd !== "object"){
                return (
                    <TableCell key={k} style={bodyStyle}>{dd}</TableCell>
                )
            }
        })}
        <TableCell style={{  borderBottom : `none` }} >
          <span
                className="pt-1"
                data-bs-toggle="modal"
                data-bs-target="#addEquipmentModal"
              >
                <MyButton
                  onClick={() => {
                    _handleNewCollapseData(d);
                    _handleShowModel()
                  }}
                  id={"addEquipment"}
                  style={{ width: "100%" }}
                  text={"+"}
                  small
                  className={"px-2"}
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                />
            </span>
        </TableCell>
      </TableRow>
      <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom : `1px solid ${Colors.Dark[2]}` }} colSpan={Object.values(d).length +2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {c.map((cc,i) =>(
                        <TableCell key={i} style={headStyle}>
                            {cc.label}
                        </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor : 'rgb(198, 198, 198,0.02)' }}>
                  {Object.values(d).find(e => typeof e == "object").map((v,k) => {
                    
                    return (
                      <TableRow key={k} style={{ borderBottom : k === Object.values(d).find(e => typeof e == "object").length-1 ? 'none' : `1px solid rgb(94 94 94 / 48%)` }}>
                        {Object.values(v).map((a,b) => {
                          return (
                            <TableCell key={b} style={style}>
                              {a}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {isShowModel && h === "Scope 3" ? (
          <TransportationModal isNewDailyData={true} />
        ) : null }
    </Fragment>
  );
}

export const MyCollapsibleTable = ({
    columns, 
    collapseColumns,
    dataList,
    header,
    createTransportationList,
    sectionIndex,surveyInfo, subSectionArray, subSectionIndex, surveyYear
}) => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const { t } = useTranslation("target_setting");
    
    // const currentSectionName =
    // surveyInfo?.surveySection[sectionIndex]?.sectionName;

    const currentSubSectionHeader = 
    subSectionArray[subSectionIndex]?.subSectionHeader;
  
    useEffect(() => {
      if(window.innerHeight > 700){
        setRowsPerPage(12);
      }
    }, []);
    const data = dataList

    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };
  
    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]).sort().reverse();
    };
  
    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (e) => {
      setRowsPerPage(e.target.value);
    };
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  
    const useStyles = makeStyles({
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: "0px 3px",
      },
    });
  
    const classes = useStyles();

  
  return (
    <>
    {
      data.length > 0 ? 
      <>
        <TableContainer 
        component={Paper}
        id="sortable_table_container"
        style={{ background: "transparent", boxShadow: "none", overflow: "auto"}}
        >
            <Table
            id="custom_sorting_tbl"
            className={classes.table}
            stickyHeader
            aria-label="customized table"
            >
        <SortableTHead
                id={"id"}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={columns}
                theme={theme}
            />
            <TableBody>
                {data?.length > 0 &&
                (stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
                .map((v,k) => 
                  <Row key={k} d={v} c={collapseColumns} h={header} />
                )
              }
            </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
        style={{ color: invertDark(theme), paddingBottom:0, marginBottom:0 }}
        rowsPerPageOptions={[]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </>
    : <EmptyComponent 
    createTransportationList={createTransportationList}
        t={t} 
        currentSubSectionHeader={currentSubSectionHeader}
        surveyYear={surveyYear}
      /> 
    }
    </>
    
    
  );
}

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
            <TableCell 
                style={{
                    backgroundColor: theme === "dark" ? Colors.primaryBule : Colors.blue,
                  }}
            />
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                backgroundColor:
                  theme === "dark" ? Colors.primaryBule : Colors.blue,
                color: invertDark(theme),
                fontWeight: "bold",
                padding: "10px 5px",
                textAlign: headCell.id === "size" ? "end" : "left",
                position: "static",
                textAlign: "left",
              }}
            >
              {headCell.sortable === true ? (<TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{
                  color: Colors.white,
                }}
              >
                {headCell.label}
              </TableSortLabel>)
                : headCell.label
                }
            </TableCell>
          ))}
          <TableCell 
                style={{
                    backgroundColor: theme === "dark" ? Colors.primaryBule : Colors.blue,
                  }}
            />
        </TableRow>
      </TableHead>
    );
  };

  const EmptyComponent = (props) => {
    const {createTransportationList, t, currentSubSectionHeader} = props;
    const addEquipmentData = () => {
      createTransportationList.category = currentSubSectionHeader;
    }
    // console.log("currentSubSectionHeader > ", toJS(currentSubSectionHeader));

    return (
      <>
      <TransportationModal />
       <div className="text-center my-2 text-secondary">
        <div className="rounded p-4" style={{ fontSize: 18 }}>
          <i className="bi bi-file-earmark-fill pe-1" />
            {"No equipment added yet."}
              <a
                data-bs-toggle="modal"
                onClick={() => addEquipmentData()}
                data-bs-target={"#addEquipmentModal"}
                href="javascript:void(0);"
                style={{ fontSize: "14px", paddingLeft: "2px" }}
              >
                {t("Add " + currentSubSectionHeader)}
              </a>
        </div>
      </div>
      </>
     
    );
  };
  


