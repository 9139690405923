import { Colors, invertDark } from "../../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import React from "react";
import { toJS } from "mobx";

export const SummaryModal = inject("store")(
  observer((props) => {
    const { data, column, row } = props;
    const { theme, companyName } = props.store.common;
    const { t } = useTranslation("summary");

    return (
      <div
        class={`modal fade ${theme}`}
        id="summaryModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-xl">
          <div
            class="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color:invertDark(theme)
            }}
          >
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("Protocol : Greenhouse Gas Protocol (GHG)")}
              </h5>
              <i
                type="button"
                class="bi-x-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body row justify-content-between">
              {data.map((v) =>
                v.module ? (
                  <div className="col-lg-6">
                    <span
                      className="fw-bold"
                      style={{ fontSize: 24, color: invertDark(theme) }}
                    >
                      {t(v.title)}
                    </span>
                    <div
                      className="p-3 rounded"
                      style={{ background: theme==='dark'? "rgba(255,255,255,0.05)" :"rgba(0,0,0,0.05)"}}
                    >
                      <ModuleCard moduleData={v.module} theme={theme} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <span
                      className="fw-bold"
                      style={{ fontSize: 24, color: invertDark(theme) }}
                    >
                      {t(v.title)}
                    </span>
                    <div className="row">
                      <CategoryCard categoryData={v.category} theme={theme}/>
                    </div>
                  </div>
                )
              )}
              <EmissionFactorTable column={column} row={row} theme={theme} />
            </div>
            {companyName!=='Syscom'&&<div class="modal-footer">
              <span>
                <strong>
                  {t("Criteria for identifying relevant scope 3 activities")} :{" "}
                </strong>
                {t("Self")}
              </span>
            </div>}
          </div>
        </div>
      </div>
    );
  })
);

const ModuleCard = ({ moduleData,theme }) => {
  const { t } = useTranslation("summary");
  return moduleData?.map((m) => (
    <div
      className="d-flex flex-row justify-content-between my-1 p-2 rounded"
      style={{
        background:theme=="dark"? Colors.darkBlue:Colors[theme].background,
        fontSize: "14px",
      }}
    >
      <span>
        {t(m.name)}
        <div style={{ opacity: 0.4 }}>{t(m.remark)}</div>
      </span>
      <span
        className="p-1 px-2 text-center"
        style={{
          color:theme==='dark'?'':'white',
          fontSize: "12px",
          borderRadius: "50px",
          background:
            m.status === "Tracking"
              ? theme==='dark'?"rgba(115,224,107,0.6)":'#0a9100' //rgba(0,210,19,0.7)
              : m.status === "Not Applicable" 
              ? theme==='dark'?"rgba(255,142,97,0.7)":'#595959' //rgba(255,92,0,0.7)
              :theme==='dark'?"rgba(178,111,255,0.5)":'#c6033e', //rgba(142,48,255,0.7)
          maxHeight: "28px",
          minWidth: "100px",
        }}
      >
        {t(m.status)}
      </span>
    </div>
  ));
};

const CategoryCard = ({ categoryData,theme }) => {
  const { t } = useTranslation("summary");
  return categoryData.map((c,i) => (
    <div key={i} className="col-lg-6">
      <div
        className="p-3 rounded"
        style={{ background: theme==='dark'?"rgba(255,255,255,0.05)":"rgba(0,0,0,0.05)" }}
      >
        <div className="fw-bold"> {t(c.name)}</div>
        {c.module.map((m,j) => (
          <div
          key={j}
            className="d-flex flex-row justify-content-between my-1 p-2 rounded"
            style={{
              background:theme=="dark"? Colors.darkBlue:Colors[theme].background,
              fontSize: "14px",
            }}
          >
            <span>
              {t(m.name)}
              <div style={{ opacity: 0.7 }}>{m.remark}</div>
            </span>
            <span
              className="p-1 px-2 text-center"
              style={{
                color:theme==='dark'?'':'white',
                fontSize: "12px",
                borderRadius: "50px",
                background:
                  m.status === "Tracking"
                    ? theme==='dark'?"rgba(115,224,107,0.6)":'#0a9100' //rgba(0,210,19,0.7)
                    : m.status === "Not Applicable"
                    ? theme==='dark'?"rgba(255,142,97,0.7)":'#595959' //rgba(255,92,0,0.7)
                    : theme==='dark'?"rgba(178,111,255,0.5)":'#c6033e', //rgba(142,48,255,0.7)
                maxHeight: "28px",
                minWidth: "100px",
              }}
            >
              {t(m.status)}
            </span>
          </div>
        ))}
      </div>
    </div>
  ));
};

const EmissionFactorTable = ({ column, row, theme }) => {

  const { t } = useTranslation("summary");
  return (
    <div>
      <span
        className="fw-bold"
        style={{ fontSize: 24, color: invertDark(theme) }}
      >
        {t("Emission Factor Used")}
      </span>
      <table className="table table-bordered" style={{ color: invertDark(theme) }}>
        <thead>
          <tr>
            {column.map((c,i) => (
              <th key={i}>{i === 0 ? t(c) : c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {row.map((r,i) => (
            <tr key={i}>
              <td>{r.country}</td>
              {r.emissionByYear.map((e,i) => (
                <td key={i}>{e}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
