import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';

import { Colors, invertDark } from '../../Assets/Config/color.config';

export const MultiLineApexChart = inject("store")(
    observer((props) => {
        const { data, reportTheme } = props;
        const { theme } = props.store.common;
        const [options, setOptions] = useState(null);
        const [series, setSeries] = useState([])

        const { t } = useTranslation("dashboard");

        function thousandSeparator(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        const THEME = reportTheme || theme;
        useEffect(() => {
            if (!data) return

            const series = [{
                name: t('Emission(KgCO2)'),
                type: 'column',
                data: data.map(d => d.totalEmissionScope)
            }, {
                name: t('Total Asset'),
                type: 'line',
                data: data.map(d => d.totalAssetScope)
            }]
            setSeries(series)

            const labels = data.map(d => 'Scope' + d.scope);


            const plotOptions = {
                plotOptions: {
                    bar: {
                        columnWidth: '20%',
                        endingShape: 'rounded'
                    },
                },

                labels: labels,
                chart: {
                    id: 'sth',
                    height: 200,
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    width: [2, 2],
                },
                // theme: {
                //     mode: THEME === 'dark' ? "dark" : "white",
                // },
                tooltip: {
                    theme: THEME === 'dark' ? "dark" : "white",
                    enabled: reportTheme ? false : true,
                    style: {
                        colors: [invertDark(THEME), invertDark(THEME), invertDark(THEME)]
                    }
                },
                title: {
                    text: t("Group Report Chart"),
                    style: {
                        color: invertDark(THEME),
                        fontSize: '13x',
                        fontWeight: 'bold',
                    }
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],

                },
                legend: {

                    labels: {
                        colors: [invertDark(THEME), invertDark(THEME)]
                    }
                },
                xaxis: {

                    labels: {

                        style: {
                            colors: [invertDark(THEME), invertDark(THEME), invertDark(THEME)]
                        }
                    }
                },
                yaxis: [{
                    title: {
                        text: t('Emission(KgCO2)'),
                        style: {
                            color: invertDark(THEME)
                        }
                    },
                    labels: {
                        formatter: function (value) {
                            return thousandSeparator((value % 1) === 0 ? value : value.toFixed(2));
                        },
                        style: {
                            colors: [invertDark(THEME)]
                        }
                    }

                }, {
                    opposite: true,
                    title: {
                        text: t('Total Asset'),
                        style: {
                            color: invertDark(THEME)
                        }
                    },
                    labels: {
                        formatter: function (value) {
                            return thousandSeparator((value % 1) === 0 ? value : value.toFixed(2));
                        },
                        style: {
                            colors: [invertDark(THEME)]
                        }
                    }
                }]
            }
            setOptions(plotOptions);


        }, [data, t, THEME])
        return (
            <div>
                {
                    options ?
                        <div style={{}}>
                            <ReactApexChart options={options} series={series} type="line" height={200} />
                        </div>
                        : null
                }
            </div>

        )
    })
)


// to fix design ref link : https://apexcharts.com/docs/options
