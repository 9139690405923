import Store from "../../Store";
import { ESGBreadCrumb } from "./ESGBreadCrumb";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import digitalEdgeIcon from "../../Assets/images/digitalEdgeIcon.png";
import { MyButton, MyInput, Selector } from "../../Tools";
import { inject, observer } from "mobx-react";
import ESGeditModal from "./ESGeditModal";
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteModel = (props) => {
    const { theme, deleteESG } = props;
    const { t } = useTranslation("esg_tracking")
    return (
        <div
            className={`modal fade ${theme}`}
            id="confirmDeleteModel"
            tabIndex="-1"
            aria-labelledby="confirmDeleteModel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div
                    className="modal-content"
                    style={{
                        background: Colors[theme].background,
                        borderRadius: 10,
                        color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]
                    }}
                >
                    <div className="modal-header mx-3 px-0">
                        <h5>
                            {t("Delete")}
                        </h5>
                        <i
                            style={{ cursor: "pointer" }}
                            className="bi bi-x-lg "
                            data-bs-dismiss="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#esgKpiModal"
                            aria-label="Close"
                        // onClick={() => _handleClose()}
                        ></i>
                    </div>
                    <div className="modal-body mx-3 px-0">
                        {('Are you sure do you want to delete this KPI Information?')}
                    </div>
                    <div className="modal-footer mx-3 px-0 d-flex justify-content-center">
                        <span data-bs-dismiss="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#esgKpiModal"
                            aria-label="Close">
                            <MyButton
                                style={{ width: 200 }}
                                text={t("Cancel")}
                                customColor={Colors.primaryBule} customHoverColor={Colors.lightBlue}
                            />
                        </span>
                        <span data-bs-dismiss="modal"
                            aria-label="Close">
                            <MyButton
                                onClick={deleteESG}
                                style={{ width: 200 }}
                                text={t("Yes")}
                                customColor='#066CFF'
                                customHoverColor={Colors.water}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>)
}