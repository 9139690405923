import { Colors } from "../../Assets/Config/color.config";
import { ProgressBar } from "../../Tools/ProgressBar";
import site from "../../Assets/site.json";
import RadarChart from "../../Assets/Charts/RadarChart";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

export const EsgTracking = inject("store")(
    observer((props) => {
        const { rightArrow } = props;
        const { permittedBuilding, theme } = props.store.common;
        const {esgTrackingData, _fetchESGTrackingData} = props.store.esg;
        const { t } = useTranslation("dashboard");
        const navigate = useNavigate();

        useEffect(()=>{
            _fetchESGTrackingData();
        },[])
        return (
            <div className="cardStyle" style={{ color: Colors[theme].textColor,marginBottom:15 }}>
                <span className="d-flex justify-content-between">
                    <span className="cardTitle">{ t("ESG Pillar Tracking") }</span>
                    <span style={{ color: Colors.water, cursor:'pointer' }} onClick={()=>navigate('/esg-tracking')}>
                        { t("Details") }
                        <img src={rightArrow} className="ps-1" />
                    </span>
                </span>
                <hr className="my-2" />
              
                    {
                        esgTrackingData ? esgTrackingData.map((esg, key) => (
                            <div className="d-flex justify-content-between py-1 me-1" key={key}>
                                <span >{esg.pillar.pillarName}</span>
                                <span >{esg.count} KPIs</span>
                            </div>
                        )) : <div className="text-center"><i className="spinner-border mt-2" /></div>
                    }
               
             
            </div>
        );
    })
);
// const esgTrackingData = [
//     { label:'Respect for Resources',value:'10 KPIs' },
//     { label:'Respect for People & Communities',value:'10 KPIs' },
//     { label:'Respect for Transparency',value:'12 KPIs' }
// ]
