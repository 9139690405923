import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { baseURLImage } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyCheckbox, MyButton } from "../../Tools";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

export const FrameworkEditModal = inject("store")(
    observer((props) => {
        const { buildingId } = props.store.survey;
        const { getBuildingList, theme, _handleCreateFramework,accessmentList, permittedBuilding } = props.store.common;
        const { getCheckedFramework, disabledSaveFramework, _handleCheckBox, allFrameworks, checkFrameworks } = props.store.building;
        const { t } = useTranslation("common");

        const activeStyle = {
            background: Colors[theme].secondBackground,
            cursor: "pointer",
            borderRadius: 5,
        };
        const noActiveStyle = {
            background: Colors[theme].secondBackground,
            cursor: "pointer",
            borderRadius: 5,
        };

        useEffect(() => {
            getBuildingList();
        },[]);

        useEffect(() => {
            if (accessmentList && permittedBuilding) {
                
                getCheckedFramework(accessmentList, permittedBuilding?.find((v1) => v1._id === buildingId)?.surveyHeader)
            }
        },[permittedBuilding, accessmentList]);

        return (
            <div
                className={`modal fade text-dark ${theme}`}
                id="frameworkEditModal"
                tabIndex="-1"
                aria-labelledby="frameworkEditModal"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: invertDark(theme),
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {t("Add New Framework")}
                            </h5>
                            <i
                                className="bi bi-x"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <div className="modal-body">
                            {checkFrameworks.length > 0 ? 
                            checkFrameworks.map((v, i) => (
                                <label
                                    onClick={() => !v.active && toast.error(v.status)}
                                    key={i}
                                    htmlFor={v._id}
                                    style={{ display: "block" }}
                                >
                                    <div
                                        className="d-flex justify-content-between align-items-center p-2 mb-2"
                                        style={v.active ? activeStyle : noActiveStyle}
                                        title={v.status}
                                    >
                                        <span>
                                            <img
                                                className="me-2"
                                                src={baseURLImage + v.imageURL}
                                                height={25}
                                                style={{ borderRadius: 2 }}
                                            />
                                            {v.name}
                                        </span>
                                        {
                                        // v.checked === true ? <i className="bi bi-check2" style={{ color: Colors.greenMaterial }} /> : 
                                        <div>
                                            <>
                                                <MyCheckbox
                                                    id={v._id}
                                                    value={v.name}
                                                    setInputs={_handleCheckBox}
                                                    checked={v.checked}
                                                />
                                            </>
                                        </div>}

                                    </div>
                                </label>
                            ))
                            : (
                                <div className="d-flex justify-content-center">
                                    {t("All frameworks have already been added")+"!"}
                                </div>
                            )
                            }
                        </div>

                        <div className="modal-footer d-flex justify-content-center w-100">
                            {checkFrameworks.length > 0 ? 
                            <button
                                type="button"
                                disabled={disabledSaveFramework}
                                className="btn btn-primary w-100"
                                data-bs-dismiss="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() => _handleCreateFramework(buildingId, allFrameworks, checkFrameworks)}
                            >
                                {t("Save")}
                            </button> : 
                            <span data-bs-dismiss="modal"
                            aria-label="Close">
                                <MyButton
                                text={t("Close")}
                                customColor='#066CFF'
                                customHoverColor={Colors.water}
                            />
                        </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    })
)