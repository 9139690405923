import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { inject, observer } from "mobx-react";



export const StepPagination = inject("store")(
  observer((props) => {
    const { setPageIndex, pageIndex } = props.store.specialOneTime;
    const { theme } = props.store.common
    const {steps} = props;
    console.log(pageIndex)
    return (
      <div className={theme==='dark' ? 'darkTheme':'lightTheme'}>
      <Box sx={{ width: '300px'}}>
        <Stepper activeStep={pageIndex} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box></div>
    );
  }))

  export const UploadFileStepPagination = inject("store")(
    observer((props) => {
      const { setPageIndex, pageIndex } = props.store.survey;
      const { theme } = props.store.common
      const {steps} = props;
      console.log(pageIndex)
      return (
        <div className={theme==='dark' ? 'darkTheme':'lightTheme'}>
        <Box sx={{ width: '300px'}}>
          <Stepper activeStep={pageIndex} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box></div>
      );
    }))