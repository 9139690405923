import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import * as c3 from 'c3'
import * as d3 from 'd3'
import './analysisChart.css'
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

export const AnalysisChart = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { analysisReportData, reportTblLoading } = props.store.analysisResult;
        const {t,i18n} = useTranslation('analysisReport');
        const tEquip = useTranslation('equipment');
        useEffect(() => {
            const data = analysisReportData.sort((a, b) => a.scope - b.scope).map(v=> ({...v, _id:v._id === "" ? ' ' : tEquip.t(v._id)}));
            const regions = data.reduce((r, c, i) => {
                const R = [...r];
                const index = R.findIndex(v => v.scope === c.scope)
                if (index === -1) {
                    R.push({
                        axis: 'x', start: i - 0.5, end: i + 0.5, class: ('region-' + theme + '-' + c.scope), label: (t('Scope')+' ' + c.scope), scope: c.scope
                    })
                } else {
                    R[index].end = i + 0.5
                }

                return R
            }, [])
            c3.generate({
                bindto: "#chart",
                size: {
                    height: 380,
                },
                data: {
                    
                    json: data,
                    keys: {
                        x: '_id',
                        value: ['total']
                    },
                    type: 'area',
                    colors: {
                        total: 'rgba(14, 156, 255, 0.1)',
                    },
                    names: {
                        total: t('Emission Source')
                      }
                },
                axis: {
                    x: {
                        x: ['_id'],
                        type: "category",
                        tick: {
                            outer: false,
                            rotate: 90,
                        },
                    },
                    y: {
                        tick: {
                            outer: false,
                            count: 5,
                            format: function (d) { return Math.ceil(d); }
                        },
                        label: {
                            text: t('Emission(kgCO2e)'),
                            position: 'outer-middle'
                        },
                        height: 400
                    }
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                legend: {
                    show: true,
                },
                padding: {
                    bottom: 10,
                    top: 10
                },
                regions: regions,
                tooltip: {
                    contents: function (d) {
                        const current = data[d[0].index]
                        return `<div style="font-size: 13px;border-radius: 4px; padding: 3px; box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25); background:#ddd; color: #000 ">
                            <span style="font-size: 14px">${t('Scope')+' ' + current?.scope}</span>
                            <br />
                            <span style="font-size: 14px">${tEquip.t(current?._id)}</span>
                            <br />
                            <span>${t('Emission')+': ' + d[0].value + ' KgC02e'}</span>
                        </div>`
                    }
                }
            });

            var rectOffset = function (a, b) {
                const ele = document.querySelectorAll(`.c3-region-${b} rect`)[0];
                const width = Number(ele.getAttribute('width'));
                return (width) / 2 - 25;
            };
            d3.selectAll(".c3-region text")
                .attr("dy", "8")
                .attr("dx", rectOffset)
                .attr("text-anchor", "start");

        }, [analysisReportData, theme,i18n.resolvedLanguage]);
        return (
            <div
             id="analysisChart"
                className={`p-3 ${theme === 'dark' ? 'dark-theme' : 'white-theme'}`}
                style={{
                    background: theme === 'dark' ? '#000534' : '#ddd',
                    borderRadius: 4
                }}
            >
                <div id="chart" ></div>
            </div>
        )
    })
)