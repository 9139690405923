import { Colors } from "../../Assets/Config/color.config";
import { ProgressBar } from "../../Tools/ProgressBar";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { type } from "@testing-library/user-event/dist/type";

export const GroupSitePerformance = inject("store")(
  observer((props) => {
    const { permittedBuilding, theme, demoSitePerformanceData } =
      props.store.common;
    const width = window.innerWidth;
    const { t } = useTranslation("dashboard");

    

    const groupBySite = (buildingList) => {
      let types = Array.from(
        new Set(buildingList.map((building) => building.type.building_type))
      );

      let result = [];
      types.forEach((t) => {
        let groupObj = {
          buildingType: t,
          buildingList: [],
        };
        buildingList.forEach((building) => {
          if (t == building.type.building_type)
            groupObj.buildingList.push(building);
        });
        result.push(groupObj);
      });
      return result;
    };

    return (
      <>
        {permittedBuilding &&
          groupBySite(permittedBuilding).map((value, key) => (
            <div
              className="cardStyle col"
              style={{ color: Colors[theme].textColor, marginBottom: 15 }}
            >
              <span className="d-flex justify-content-between">
                <span className="cardTitle">{value.buildingType}</span>
              </span>
              <hr className="my-2" />
              <div style={{ height: 170, overflowY: "scroll" }}>
                {value.buildingList &&
                  value.buildingList.map((building, key) => (
                    <div
                      className="d-flex justify-content-between py-2"
                      key={key}
                    >
                      <span className="col">{building.buildingName}</span>
                      <span className="col text-end">
                        <ProgressBar
                          width={width > 1400 ? "60%" : "67%"}
                          progress={
                            demoSitePerformanceData[building.buildingName] || 0
                          }
                        />
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </>
      // <div className="cardStyle col" style={{ color: Colors[theme].textColor,marginBottom:15 }}>
      //   <span className="d-flex justify-content-between">
      //     <span className="cardTitle">Testing Group</span>
      //   </span>
      //   <hr className="my-2" />
      //   <div style={{ height: 170, overflowY: 'scroll' }}>
      //     {
      //       permittedBuilding ? permittedBuilding.map((value, key) => (
      //         <div className="d-flex justify-content-between py-2" key={key}>
      //           <span className="col">{value.buildingName}</span>
      //           <span className="col text-end">
      //             <ProgressBar width={width > 1400 ? '60%' : '67%'} progress={demoSitePerformanceData[value.buildingName] || 0 } />
      //           </span>
      //         </div>
      //       )) : <div className="text-center"><i className="spinner-border mt-5" /></div>
      //     }
      //   </div>
      //   <div>
      //     {/* <span className="cardTitle">Average Emission</span> */}

      //     {/* <RadarChart /> */}
      //   </div>
      // </div>
    );
  })
);
