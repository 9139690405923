import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { createTheme, Input, ThemeProvider } from "@mui/material";
import {Colors} from "../Assets/Config/color.config";

export const MyDatepicker = ({ value, _handleChange, label,views, inputFormat,background }) => {

  const themeMode = localStorage.getItem("theme");

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // color: '#b1b1b1',
            color: themeMode === 'dark'? '#b1b1b1 !important' : '#3030303 !important',
            // background: background?background:'#141C48',
            backgroundColor: themeMode === 'dark'? '#141C48' : "#f1f1f1",
            height: 43
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#b1b1b1'
          }
        }
      }
    }
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <ThemeProvider theme={theme}>
          <DesktopDatePicker
          views={views?views:null}
            id="datePicker"
            label={label}
            inputFormat={inputFormat?inputFormat:"MM/dd/yyyy"}
            value={value}
            onChange={_handleChange}
            renderInput={(params) => <TextField {...params} className="myDatePicker"
              sx={{
                svg: { color: '#00D213' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'gray',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'gray',
                  },
                },
                minWidth: 150
              }}
            />}
          />
        </ThemeProvider>
      </Stack>
    </LocalizationProvider>
  );
};
