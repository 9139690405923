import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableSortLabel,
    TableRow,
    Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { format } from 'date-fns'
import '../../App.css'

import Store from "../../Store";
import SearchBar from 'material-ui-search-bar';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

const FileListTable = () => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t } = useTranslation("target_setting");

    const headCell = [
        { id: 'fileName', numeric: false, label: t("File Name"), },
        { id: 'category', numeric: false, label: t("Category"), },
        { id: 'fileType', numeric: false, label: t("Type"), },
        { id: 'fileSize', numeric: true, label: t("Size(MB)"), },
        { id: "uploadedAt", numeric: false, label: t("Date Modified"), },
        { id: "uploadedBy", numeric: false, label: t("Uploaded By"), },
    ]

    const { theme } = Store.common;

    const {
        siteList,
        _handleSetPreviewFile,
        previewFile,
    } = Store.siteRelevant;

    useEffect(() => {
        setPage(0)
        if (toJS(siteList).length > 0) {
            const rows = (toJS(siteList).map((v) => createData(v.fileName, v.category, v.fileType, v.fileSize, v.uploadedAt, v.uploadedBy, v.email, v.filePath, v._id))).reverse()
            setOriginalRows(rows)
            setRows(rows)
        }

    }, [(siteList)])
    const handleRequestSort = (event, property) => {

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = originalRows.filter((row) => {
            return (
                row.fileName?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.category?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.fileType?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.uploadedBy?.toLowerCase().includes(searchedVal.toLowerCase()) ||
                Math.round(row.fileSize * 100) / 100 == Number(searchedVal) ||
                format(new Date(row.uploadedAt), 'HH mm ss MMM dd yyyy')?.toLowerCase().includes(searchedVal.toLowerCase())
            )
        });
        setRows(filteredRows)
    };

    const cancelSearch = () => {
        setRows(originalRows)
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value)
    }

    const StyledTableRow = withStyles((defaultTheme) => ({
            root: {
                // borderBottom: '0.5px solid #e0e0e0',
                // height: '50px',
                maxHeight: '50px',
                // background: Colors.darkBlue,
                borderRadius: '10px',
                margin: '20px',
                "&:hover": {
                    backgroundColor: `${Colors[theme].active} !important` 
                }
            },

    }))(TableRow);

    function createData(fileName, category, fileType, fileSize, uploadedAt, uploadedBy, email, filePath, _id) {
        return {
            fileName,
            category,
            fileType,
            fileSize,
            uploadedAt,
            uploadedBy,
            email,
            filePath,
            _id
        };
    }

    const useStyles = makeStyles({
        table: {
            //minWidth: 800,
            width: '100%',
            borderSpacing: '0px 3px'
            // minHeight: 328
        },
    });


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    SortableTHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const classes = useStyles();

    return (
        <>
            <div className={`d-flex justify-content-between mb-2 ${theme}`}>
                <div className="text-start" style={{ fontSize: 20, color: invertDark(theme) }}>
                    { t("File List") }
                </div>
                <SearchBar
                    placeholder={ t("Search_") }
                    style={{
                        width: '30%', height: 35, 
                        background: 'transparent',
                        boxShadow: 'none', 
                        borderBottom: `1px solid ${invertDark(theme)}`,
                        borderRadius: 0, 
                    }}                    
                    // value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />
            </div>
            <TableContainer component={Paper} id='tblContainer' style={{ background: 'transparent', boxShadow: 'none' }}>
                <Table className={classes.table} stickyHeader aria-label="customized table" >
                    <SortableTHead
                        id={'id'}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCell}
                        theme={theme}
                    />
                    <TableBody >
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                // rows
                                .map((v, k) => {
                                    const style = { color: Colors[theme].textColor, textAlign: 'left', fontSize: 14, paddingLeft: '12px' }
                                    return (
                                        <StyledTableRow hover key={k} style={{ cursor: 'pointer', background: previewFile?._id === v._id ? Colors[theme].active  : '' }} onClick={() => _handleSetPreviewFile(v)} >
                                            <td style={{ ...style, cursor: 'pointer' }}>
                                                {v.fileName}
                                            </td>
                                            <td style={{ ...style }} >
                                                {v.category}
                                            </td>
                                            <td style={{ ...style }} >
                                                {v.fileType}
                                            </td>
                                            <td style={{ ...style }} >
                                                {(v.fileSize) ? Math.round(v.fileSize * 100) / 100 : 0}
                                            </td>
                                            <td style={{ ...style }} >
                                                {v.uploadedAt ? format(new Date(v.uploadedAt), 'HH:mm:ss MMM-dd-yyyy') : null}
                                            </td>
                                            <td style={{ ...style }} title={v.email} >
                                                {v.uploadedBy}
                                            </td>
                                        </StyledTableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                className={`${theme}`}
                style={{ color: invertDark(theme) }}
                rowsPerPageOptions={[]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

export default inject("store")(observer(FileListTable))

const SortableTHead = (props) => {
    const { order, orderBy, onRequestSort, headCells, theme } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            backgroundColor: theme === 'dark' ? Colors.primaryBule : Colors.blue,
                            color: invertDark(theme),
                            fontWeight: "bold",
                            padding: '8px 13px 8px 13px',
                            textAlign: headCell.id === 'size' ? 'end' : 'center',
                            position: 'static',
                            textAlign: "left"
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                color: Colors.white
                            }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
