import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "mobx-react";
import Store from "./Store";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import './i18n';
import { LoadingPage } from "./Page/Loading";
import { ThemeProvider, createTheme } from "@mui/material/styles";
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme();

root.render(
  <CookiesProvider>
    <BrowserRouter>
      <Provider store={Store}>
        <React.Suspense fallback={<LoadingPage />}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>

        </React.Suspense>
      </Provider>
    </BrowserRouter>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
