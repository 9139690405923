// export const baseURL = "http://localhost:8000/";


export const baseURL = (process.env.NODE_ENV == "development")?process.env.REACT_APP_WEB_SITE : `//${window.location.host}/url/`;  
// export const baseURL = "http://192.168.100.46:8000/";
// export const baseURL = "/url/";
// export const baseURL = window.location.origin+":8000/";
// export const baseURL = "https://co2rap.com/url/";
// export const baseURL = "http://79.133.37.189:8000/";
//
export const loginAPI = baseURL + "login";
export const signupAPI = baseURL + "signup";//
export const surveyInfo = baseURL + "survey-section-info/";
export const downloadFramework = (buildingId, frameworkId ) => baseURL + `get-question-pdf?buildingId=${buildingId}&frameworkId=${frameworkId}`;
export const frameworkAnsCount = ({ buildingId, frameworkId ,year}) =>
  baseURL +
  `frameworkAnsCount?buildingId=${buildingId}&frameworkId=${frameworkId}&year=${year}`;
export const surveyList = baseURL + "survey-header-info/";

export const equipmentSetupAPI = ({ buildingId, frameworkId, type }) =>
  baseURL +
  `siteData?buildingId=${buildingId}&frameworkId=${frameworkId}&type=${
    "scope" + type
  }`;
export const equipmentDataAPI = ({ buildingId, frameworkId, type }) =>
baseURL +
`add-equipment-data?buildingId=${buildingId}&frameworkId=${frameworkId}`;
export const equipmentActiveAPI = ({ dynamicDataId, type, active, buildingId }) => baseURL + `siteData/updateEquipmentStatus?dynamicDataId=${dynamicDataId}&type=${type}&active=${active}&buildingId=${buildingId}`;
export const getBuildingList = baseURL + "building-list/";
export const getAssetList = baseURL + "asset-list/";
export const getBuildingType = baseURL + "get-building-types/";
export const getActivityLogAPI = baseURL + "activity-log/";
export const getGroupReportsAPI = (year) => baseURL + `group/report/${year}`;
export const updateBuildingAPI = baseURL + "update-building/"

// y2yComparison
export const getY2yEnergy = baseURL + "y2yComparison/energy-consumption";
export const getY2yS1Emissions = baseURL + "y2yComparison/s1-emissions";
export const getY2yS2Emissions = baseURL + "y2yComparison/s2-emissions";
export const getY2yS3Emissions = baseURL + "y2yComparison/s3-emissions";
export const getY2yWater = baseURL + "y2yComparison/water-consumption";

export const getBaselineEmission = baseURL + "summary/getBaselineEmission";
export const getScopeAverage = baseURL + "summary/averageBuildingByScope";
export const averageFrameworkProtocol = baseURL + "summary/averageFrameworkProtocol";


export const getAccessmentFramework = baseURL + "get-assessment-framework/";
export const getAccessmentFrameworkbyBuildingId = (buildingId) =>
  baseURL + `get-assessment-framework?buildingId=${buildingId}`;
export const createNewFramework = (buidlingId) =>
  baseURL + `add-new-framework?buildingId=${buidlingId}`;
export const addNewBuilding = baseURL + "add-building";
export const updateSiteInfo = (buidlingId) =>
  baseURL + `update-builidng/${buidlingId}`;
export const userListApi = baseURL + "get-all-user";
export const editUserApi = baseURL + "edit-user";
export const deleteUserApi = (id) => baseURL + `remove-user/${id}`;

export const imageUrl = baseURL + "upload/building/";
export const noImageUrl = baseURL + "upload/building/no_image.png";

export const siteRelevantUrl = baseURL + "get-site-relevant-data";

export const baseURLImage = baseURL.slice(0, -1);

// export const question = (params) =>
//   baseURL +
//   `get-question?surveyHeaderId=${params.surveyHeaderId}&section=${params.section}&title=${params.title}`;
export const question = (params) =>
  baseURL +
  `get-question?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&sectionId=${params.section}&title=${params.title}&year=${params.year}&scope=${params.scope}`;
// export const addAnswer = (params) =>
//   baseURL +
//   `add-answer?surveyHeaderId=${params.surveyHeaderId}&section=${params.section}&title=${params.title}`;

export const addAnswer = (params) =>
  baseURL +
  `add-answer?frameworkId=${params.frameworkId}&buildingId=${params.buildingId}&year=${params.year}&scope=${params.scope}`;

export const addManualByCategoryAnswer = (params) =>
baseURL +
  `ghg-Monthly-Data?frameworkId=${params.frameworkId}&buildingId=${params.buildingId}`;

export const getScope0API =(params)=> baseURL + `siteData/equipment-count?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}`;
export const updateScope0API=(params)=> baseURL + `update-equipment-data?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}`;
export const getEqDataByDeviceAPI = (params) => baseURL + `siteData/equipment-data-by-devicetype?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}`;

export const deleteBuilding = baseURL + "remove-building";
export const verifyOTP = baseURL + "verify-otp";
export const resendOTP = baseURL + "resent-otp";
export const uploadSiteRelevantFile = (params) =>
  baseURL +
  `add-site-relevant-data?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}`;
export const removeSiteRelevantFile = (params) =>
  baseURL +
  `remove-site-relevent-data?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&uploadedId=${params.uploadedId}`;

export const getFrameworkProgressAPI = baseURL + "site-framework-progress";

export const addNewFramework = baseURL + "update-site-framework";

export const manualEntryDataAPI = (buidlingId, frameworkId, year, category) =>
  baseURL +
  `getManualEntryData?buildingId=${buidlingId}&frameworkId=${frameworkId}&year=${year}&category=${category}`;

export const checkManualEntryAPI = (buildingId, frameworkId, year, type) => baseURL + `checkManualEntry?buildingId=${buildingId}&frameworkId=${frameworkId}&year=${year}&type=${type}`;
export const calculateEmissionBaseOnUsage = (buildingId, frameworkId, year) => baseURL + `calculateEmissionBaseOnUsage?buildingId=${buildingId}&frameworkId=${frameworkId}&year=${year}`;
export const getEmissionByCalculatedMethod = (type,calculatedMethod,unit,typeOfEmission,scope,category,vehicleType) => baseURL + `get-emission?type=${type}&calculatedMethod=${calculatedMethod}&unit=${unit}&typeOfEmission=${typeOfEmission}&scope=${scope}&category=${category}&vehicleType=${vehicleType}`;
export const getS1EmissionValAPI = (params) => baseURL + `get-emission?category=${params.category}&deviceType=${params.deviceType}&unit=${params.unit}&fuelType=${params.fuelType}`;

export const addManualEntryDataAPI = (
  siteRelevantId,
  year,
  buildingId,
  frameworkId
) =>
  baseURL +
  `addManualEntryData?siteRelevantId=${siteRelevantId}&year=${year}&buildingId=${buildingId}&frameworkId=${frameworkId}`;

export const deleteScope1SetupAPI = baseURL + `siteData/equipment-data/delete`;
export const deleteScope2SetupAPI = baseURL + `siteData/purchased-data/delete`;

export const calApproachesAPI = baseURL + "cal-approach"
export const customCalApproachesAPI = baseURL + "custom-cal-approach"
export const addCustomCalAPI = baseURL + "create-custom-cal-approach"
export const updateUnitAPI = baseURL + "target-setting-update-unit"

//ESG
export const pillarSetup = baseURL + "esg/pillar-setup";
export const pillars = baseURL + "esg/pillar";
export const sub_pillars = baseURL + "esg/sub-pillar";

export const esgKpiT_R = baseURL + "esg/esgKpiT_R/";

//KPI Management
export const kpiManagement = baseURL + "esg/kpi-management/";

export const kpiOwnerTitle = baseURL + "esg/kpi-owner-title";
export const baseYears = baseURL + "esg/base-year";
export const kpiOwnerName = baseURL + "esg/kpi-owner-name";
export const unitOfMeasurement = baseURL + "esg/unit_of_measurement";
export const freqOfMeasurement = baseURL + "esg/freq_of_measurement";
export const keyDependency = baseURL + "esg/key_dependency";
export const standardAlignment = baseURL + "esg/standard_alignment";

//Tracking Page
export const scope2Tracking = baseURL + "scope2ByUserBuilding"
export const waterTracking = baseURL + "waterConsumptionByUserBuilding"
export const energyTracking = baseURL + "energyConsumptionByUserBuilding"
export const scope1Tracking = baseURL + "scope1ByCategory"
export const scope3Tracking = baseURL + "scope3ByCategory"
export const scope3BuildingTracking = baseURL + "scope3ByUserBuilding"
export const scope1TrackingByBuilding = baseURL + "scope1ByUserBuilding"

export const targetSetting = baseURL + "target-setting";
export const pillarCountByKpi = baseURL + "esg/kpiCount";
export const breakDownReport = (buildingId, year) => baseURL + `survey-report?buildingId=${buildingId}&year=${year}`;

export const getSiteModule = baseURL + "companySetting";

//excelUpload
export const excelUpload = baseURL + "siteData/transportation-data"


export const getCompanyList = baseURL + "audit/companyList";
export const getAuditOverviewList = baseURL + "audit/auditOverviewData";
export const getCompanyVerification = baseURL + "audit/companyVerification";
export const updateAuditorAction = baseURL + "audit/updateAuditActionForSurveyInfoAns";
export const getBuildingByCompany = baseURL + "audit/buildingDetails";

//group
export const getAllGroups = baseURL + "get-groups";

export const createNewGroup = baseURL + `create-group`;

export const editGroupName = baseURL + 'update-group-name';

export const setGHGBaseline = baseURL + "setTargetGHG"
export const createBaselineYear = (buildingId, year) => baseURL + `setTargetGHG?buildingId=${buildingId}&year=${year}`;

export const updateUploadedFileForAnswer = (params) =>  baseURL + `site-relevant-data?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&year=${params.year}&month=${params.month}`;

export const updateUploadedFileForCategory = (params) =>  baseURL + `manual-entry-data-category?buildingId=${params.buildingId}&frameworkId=${params.frameworkId}&year=${params.year}&category=${params.category}&scope=${params.scope}`;

export const updateUploadedFileForSurveyAnswer = (params) => baseURL + `survey-info-ans/updateLinkedFile?building_id=${params.buildingId}&framework_id=${params.frameworkId}&answer_id=${params.answer_id}&year=${params.year}`;

export const createOrganizationSetUp = (params) =>  baseURL + `organization/create-organization-set-up?moduleId=${params.moduleId}`;

export const getDataColumns = baseURL + "organization/get-data-columns"; 

export const getEnergySourceList = baseURL + "organization/get-energySource";

export const getConsumptionDataByYear = (params) => baseURL + `group/${params.groupId}/consumptionData?year=${params.year}`

export const updateMultiConsumptionAPI = (year) => baseURL + `asset/multiConsumptionData?year=${year}`

export const getEmissionDataByYear = (params) => baseURL + `group/${params.groupId}/emissionData?year=${params.year}`

export const updateMultiEmissionAPI = (year) => baseURL + `asset/multiEmissionData?year=${year}`

export const getAssetData = (groupId) => baseURL + `group/${groupId}/assetData`

export const updateAssetsData = baseURL + `asset/update-assets`

export const deleteGroupData = baseURL + `group/delete-group-data`

export const getAnalysisReportAPI = (year) => baseURL + `analysis-report?year=${year}`

export const deleteAssets = baseURL + 'company-assets'

export const companyFileUpload = baseURL + 'company-file-upload'

export const GroupListAPI = (companyId,year) =>baseURL + `audit/get-group-auditor-verification?companyId=${companyId}&year=${year}`

export const getBaseLineYear = (companyId) => baseURL + `audit/get-base-line-year?companyId=${companyId}`

export const getGroupDetail = (groupId, year) => baseURL + `audit/get-group-detail?groupId=${groupId}&year=${year}`;

export const updateAssetAction = (params) => baseURL + `audit/update-asset-audition?assetId=${params.assetId}&year=${params.year}&month=${params.month}`

export const checkUploadFile = (id,fileName) => baseURL + `asset/checkFileInAsset?assetFile=${id}&fileName=${fileName}`;

export const forceDeleteUploadFile = (id,fileName) => baseURL + `asset/deleteFileInAsset?assetFile=${id}&fileName=${fileName}`;

export const addConsumptionDataByYear = (year) => baseURL + `asset/add-consumption-data?year=${year}`;

export const getReportDyEnergyComparison = baseURL + `dynamicReport/energy-comparison`;
export const getReportDyBaselineEmission = (url) => baseURL + url;
export const getReportDyGHGVerification = baseURL + `dynamicReport/getGHGVerification`;
export const getReportDyGHGEmissionAndTarget = baseURL + `dynamicReport/getGHGEmissionAndTarget`;
export const getReportDyAssetAndEmissionFactor = baseURL + `dynamicReport/assetAndEmissionFactor`;
export const getReportDyAnalysisGHGEmission = (year) => baseURL + `dynamicReport/getAnalysisGHGEmission?year=${year}`

export const getReportConfiguration = baseURL + `get-company-templates`;
export const getDefaultTempNames = baseURL + `get-default-tempNames`;


export const getReportCustomConfig = (templateName) => baseURL + `all-custom-temps?templateName=${templateName}`;

export const editReportTemplate = baseURL + 'modify-custom-temp';

export const createReportTemplate = baseURL + 'create-custom-temp';

export const deleteReportTemplate = baseURL + 'delete-custom-temp';

export const UpdateCustomCom = baseURL + 'modify-custom-com'
export const DeleteCustomTempCom = baseURL + 'delete-custom-temp-com'

export const findPageBykeyword = baseURL + "find-page-by-keyword";

export const getLogHistory = baseURL + "detail-log";

export const getY2yTrackingEnergy = baseURL + "y2yTrackingComparison/energy-consumption";

export const getY2yTrackingS1Emissions = baseURL + "y2yTrackingComparison/s1-emissions"

export const fetchFileDownload = baseURL + "urldownload"
