import { Colors, invertDark } from "../../Assets/Config/color.config";
import { useNavigate } from "react-router-dom";
import conradLogo from "../../Assets/images/conrad.png";
import { ReductionSettingTable } from "../../Components/TargetSetting/ReductionSettingTable";
import { MyCircularSlider } from "../../Tools";
import { SubstainableUsage } from "../../Components/TargetSetting/SubstainableUsage";
import { Certification } from "../../Components/TargetSetting/Certification";
import { EsgProgramme } from "../../Components/TargetSetting/EsgProgramme";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FrameworkModal } from "../../Components/TargetSetting/FrameworkModal";
import { baseURL, baseURLImage, imageUrl, noImageUrl } from "../../API/URL";
import { toJS } from "mobx";
import Store from "../../Store";
import { Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { SetTargetModal } from "../../Components/TargetSetting/SetTargetModal";

export const TargetSettingContainer = inject("store")(
  observer((props) => {
    const { getBuildingList, permittedBuilding, theme } = props.store.common;
    const { _handlePageClick, currentBuildingId, _handleCurrentBuildingId } =
      props.store.target;

    useEffect(() => {
      getBuildingList();
      _handlePageClick(0);
    }, []);

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const buildingName = params.get("buildingName");
      if (buildingName && permittedBuilding?.length>0) {
        const buildingId = permittedBuilding?.filter(v=>v.buildingName===buildingName)[0]._id;
        _handleCurrentBuildingId(buildingId);
      }
      else {
        const buildingId = permittedBuilding?.[0]?._id;
        _handleCurrentBuildingId(buildingId);
      }
    }, [permittedBuilding]);

    return (
      <div className="m-3 mt-0">
        <TitleRow />
        <div className="row mt-3 flex-wrap">
          <div className="col-12 col-sm-12 col-lg-7 col-xl-7 col-xs-7">
            <ReductionSettingTable />
            <SubstainableUsage />
          </div>
          <div className="col-5">
            <Certification theme={theme} />
            <EsgProgramme theme={theme} />
          </div>
        </div>
        {currentBuildingId && <FrameworkModal />}
        <SetTargetModal />
      </div>
    );
  })
);

export const TargetSettingPagination = inject("store")(
  observer((props) => {
    const { permittedBuilding, addDefaultSrc, theme } = props.store.common;
    const { selectedIndex, _handlePageClick, currentBuildingId } = props.store.target;


    

    const siteList = toJS(permittedBuilding)?.map((v) => {
      return {
        value: v._id,
        label: v.buildingName,
        photo: v.photo,
      };
    });

    const defaultStyle = {
      height: 48,
      opacity: 0.8,
      lineHeight: 2.3,
      cursor: "pointer",
      transition: "0.1s",
    };
    const selectedStyle = {
      ...defaultStyle,
      opacity: 1,
      background: Colors[theme].active,
      height: 55,
      color: Colors[theme].textColor,
      lineHeight: 2.8,
      fontWeight: "bold",
      boxShadow: Colors.shadow,
      //  borde r:"3px solid #fff"
    };

    return (
      <div
        className="d-flex flex-row flex-wrap text-center"
        style={{ height: 65 }}
      >
        {permittedBuilding && permittedBuilding?.length === 1 ? (
          <div
            className="d-flex align-items-center px-2 rounded"
            style={selectedStyle}
          >
            <img
              onError={addDefaultSrc}
              style={{ borderRadius: 5 }}
              className="me-2"
              src={baseURLImage + permittedBuilding[0].photo}
              width={38}
            />
            {permittedBuilding[0].buildingName}
          </div>
        ) : (
          <Selector
            getOptionLabel={(e) => (
              <div style={{ display: "flex", textAlign: "left" }}>
                <img height={25} src={baseURLImage + e.photo} onError={addDefaultSrc} />
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            )}
            menuListColor={Colors.Dark[0]}
            options={siteList}
            value={siteList?.filter((v) => v.value === currentBuildingId)}
            _handleSelect={(e) => _handlePageClick(e.value, permittedBuilding?.findIndex((v) => v._id == e.value))} />
        )}
      </div>
    );
  })
);

const TitleRow = () => {
  const { theme } = Store.common;
  const navigate = useNavigate();
  const { t } = useTranslation("target_setting");
  return (
    <>
      <div
        className="d-flex justify-content-between py-3"
        style={{ color: Colors[theme].textColor }}
      >
        <div
          style={{
            fontSize: 25,
            alignItems: "center",
            display: "flex",
          }}
        >
          <i className="bi bi-sliders pe-3"></i>
          {t("Target Setting")}
        </div>
        <span
          style={{
            fontSize: 20,
            color: invertDark(theme),
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard")}
        >
          <i className="bi bi-arrow-left-circle-fill pe-3"></i>{t("Back to HQ View")}
        </span>
      </div>
      <TargetSettingPagination />
    </>
  );
};
