import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Colors, invertDark } from "../../Assets/Config/color.config";

export const GHGbarchart = inject("store")(
    observer((props) => {
        const { data } = props;
        const { theme } = props.store.common;
        // console.log('data ===> ', toJS(data));

        const chartData = data ?
         (
            data.reduce((r,c)=> {
                const R = [...r];
                for(let dt of c.data){
                    if(dt.year !== 'Baseline Year'){
                        const i = R.findIndex(v=> v.year === dt.year)
                        if(i<0){
                            R.push({
                                year: dt.year,
                                [c.label || c.target]: dt.value || 0
                            })
                        }else{
                            R[i][c.label || c.target] = dt.value || 0
                        }
                    }
                }
                return R;
            }, [])
         )?.sort((a,b)=> a.year - b.year)
         : [];
        //  console.log('chart data ===> ', chartData)

        const COLORS = [
            "#663567", 
            "#B04E6F", 
            "#E77866",
            "#607d8b",
            "#ffeb3b",
            "#1273DE",
            "#388e3c",
            "#7b64ff",
            "#86442d",
            "#7bdcb5",
        ];
        return (
            <div className="cardStyle my-2" style={{background: theme === "dark" ? Colors.darkBlue : Colors.white, color: invertDark(theme) }}>
                <ResponsiveContainer width={"100%"} height={300}>
                    <BarChart
                        width={500}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <XAxis dataKey="year" />
                        <YAxis />
                        <Tooltip cursor={{ fill: 'rgba(212,212,212,0.2)' }} />
                        <Legend />
                        {
                            data?.map((v,k)=> (
                                <Bar key={k} dataKey={v.label || v.target} fill={COLORS[k]} />
                            ))
                        }
                    </BarChart>
                </ResponsiveContainer>
            </div>
        )
    })
)