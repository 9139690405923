import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { MyButton, MyInput, Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';
import { toJS } from "mobx";

export const PurchasedElectricityModal = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {
      _handleCreateEquipmentSetup,
      _handleGetEmissionByCalculatedMethod,
      createPurchasedList,
      _handleChangeInput,
      _handleSelectEquip,
      calApproachOptions,
      getCalApproaches,
      isEdit,
      _handleUpdateEquipmentSetup
    } = props.store.survey;

    const { t } = useTranslation("common");

    useEffect(()=> {
      getCalApproaches("", createPurchasedList.emissionFactor, "scope2")
    }, [createPurchasedList.emissionFactor])

    useEffect(() => {
      createPurchasedList.calculationApproach = '';
      createPurchasedList.calculateMethod = '';
    },[createPurchasedList.emissionFactor])

    useEffect(() => {
      if(
        createPurchasedList.category !== '' &&
        createPurchasedList.calculationApproach !== '' &&
        createPurchasedList.emissionFactor !== '' &&
        createPurchasedList.calculateMethod !== ''
      ){
      _handleGetEmissionByCalculatedMethod('scope2');
      }
    },[createPurchasedList.category,createPurchasedList.calculationApproach,createPurchasedList.emissionFactor,createPurchasedList.calculateMethod])

    return (
      <div
        className={`modal fade ${theme}`}
        id="addEquipmentModal"
        tabIndex="-1"
        aria-labelledby="addEquipmentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme)
            }}
          >
            <div className="modal-header">
              <h4>{t("Add Data for Scope 2")}</h4>
              <i
                style={{ cursor: "pointer" }}
                className="bi bi-x-lg "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <div className="modal-body">
              <div className="row py-1">
              <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            
                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        label={t("Category")}
                        menuListColor={Colors.Dark[0]}
                        options={categoryOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                        value={
                          categoryOption.filter(
                            (v) => v.label === createPurchasedList.category
                          ) || ""
                        }
                        placeholder={t("Select Category")}
                        _handleSelect={(e) =>
                          _handleSelectEquip("category", e, "purchasedElectricity")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        label={t("Year")}
                        menuListColor={Colors.Dark[0]}
                        options={yearOption}
                        value={
                          yearOption.filter(
                            (v) => v.label === createPurchasedList.year
                          ) || ""
                        }
                        placeholder={t("Select Year")}
                        _handleSelect={(e) =>
                          _handleSelectEquip("year", e, "purchasedElectricity")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-1">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <MyInput
                        label={t("Supplier")}
                        required={true}
                        id={"supplier"}
                        type={"text"}
                        value={createPurchasedList.supplier}
                        onChange={(e) => _handleChangeInput(e, "purchasedElectricity")}
                        placeHolder={t("Supplier Name")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Emission Factor")}
                        menuListColor={Colors.Dark[0]}
                        options={emissionFactorOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                        value={
                          emissionFactorOption.filter(
                            (v) => v.label === createPurchasedList.emissionFactor
                          ) || ""
                        }
                        placeholder={t("Select Emission Factor")}
                        _handleSelect={(e) => _handleSelectEquip("emissionFactor", e, "purchasedElectricity")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-1 align-items-end">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Calculation Approach")}
                        menuListColor={Colors.Dark[0]}
                        options={purchasedElectricityOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                        value={
                          purchasedElectricityOption.filter(
                            (v) => v.label === createPurchasedList.calculationApproach
                          ) || ""
                        }
                        placeholder={t("Select Calculation Approach")}
                        _handleSelect={(e) => _handleSelectEquip("calculationApproach", e, "purchasedElectricity")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            
                            <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                          </div>
                        )}
                        label={t("Calculate Method")}
                        menuListColor={Colors.Dark[0]}
                        options={toJS(calApproachOptions)?.sort((a, b)=> b?.label > a?.label ? -1 : 0)}
                        value={
                          calApproachOptions?.filter(
                            (v) => v.label === createPurchasedList.calculateMethod
                          ) || ""
                        }
                        placeholder={t("Select Calculate Method")}
                        _handleSelect={(e) => _handleSelectEquip("calculateMethod", e, "purchasedElectricity")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-1">
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Record Period")}
                        menuListColor={Colors.Dark[0]}
                        options={recordPeriodOption}
                        value={
                          recordPeriodOption.filter(
                            (v) => v.label === createPurchasedList.recordPeriod
                          ) || ""
                        }
                        placeholder={t("Select Record Period")}
                        _handleSelect={(e) => _handleSelectEquip("recordPeriod", e, "purchasedElectricity")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6 col-sm-12 px-2 py-sm-1">
                  <div className="row d-flex">
                    <div className="col">
                      <Selector
                      getOptionLabel={(e) => (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          
                          <span style={{ marginLeft: 5 }}>{t(e.label)}</span>
                        </div>
                      )}
                        label={t("Unit")}
                        menuListColor={Colors.Dark[0]}
                        options={unitOption?.sort((a, b)=> b.label > a.label ? -1 : 0)}
                        value={
                          unitOption.filter(
                            (v) => v.label === createPurchasedList.unit
                          ) || ""
                        }
                        placeholder={t("Select Unit")}
                        _handleSelect={(e) => _handleSelectEquip("unit", e, "purchasedElectricity")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              { createPurchasedList.category &&
              createPurchasedList.calculationApproach &&
              createPurchasedList.emissionFactor &&
              createPurchasedList.calculateMethod ? (<hr className="mt-4 mb-1" />) : ''
              }

              {
                createPurchasedList.category &&
                createPurchasedList.calculationApproach &&
                createPurchasedList.emissionFactor &&
                createPurchasedList.calculateMethod ? 
                (
                  <div className="row align-items-end">
                    <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                        <div className="row d-flex">
                            <div className="col">
                            <MyInput
                                disabled={true}
                                label={"Emission Factor Value"}
                                required={true}
                                type={"number"}
                                id={"emissionFactorValue"}
                                value={createPurchasedList.emissionFactorValue}
                                onChange={(e) => _handleChangeInput(e, "purchasedElectricity")}
                                placeHolder={"Emission Factor Value"}
                            />
                            </div>
                        </div>
                    </div> 
                    {
                      createPurchasedList.emissionFactor === "Custom emission factor" ?
                      (
                        <div className="col-lg-6 col-6 col-sm-12 px-2 pt-sm-1">
                          <div className="row d-flex">
                              <div className="col">
                              <MyInput
                                  disabled={true}
                                  label={"Name"}
                                  required={true}
                                  type={"text"}
                                  id={"name"}
                                  value={createPurchasedList.name}
                                  onChange={(e) => _handleChangeInput(e, "purchasedElectricity")}
                                  placeHolder={"Name"}
                              />
                              </div>
                          </div>
                      </div>
                      ) : ''
                    }
                  </div>
                ) : ''
              }
              
            </div>
            <div className="modal-footer mx-3 d-flex justify-content-end">
              <span data-bs-dismiss="modal" aria-label="Close">
                <MyButton
                  style={{ width: 200 }}
                  text={t("Cancel")}
                  customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
                  customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
                />
              </span>
              <span id={'create_setUp'}>
                <MyButton
                  onClick={() => isEdit ? _handleUpdateEquipmentSetup("purchasedElectricity") : _handleCreateEquipmentSetup("purchasedElectricity")}
                  style={{ width: 200 }}
                  text={isEdit ? t("Update") : t("Add")}
                  customColor="#066CFF"
                  customHoverColor={Colors.water}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

const recordPeriodOption = [
  { value: 1, label: "Yearly" },
  { value: 2, label: "Monthly" },
  { value: 3, label: "Quarterly" },
];

const categoryOption = [
  { value: 1, label: "Purchased Electricity" },
  { value: 2, label: "Purchased Heat and Steam" },
];

const unitOption = [
  { value: 1, label: "kWh" },
];

const purchasedElectricityOption = [
  { value: "Location Based", label: "Purchased Electricity - Location Based" },
  { value: "Steam and Heat", label: "Heat/Steam" }
];

const emissionFactorOption = [
  { value: 1, label: "Grid Average / Location Based" },
  { value: 2, label: "Custom emission factor" },
];

const yearOption = [
  { value: 1, label: "2018" },
  { value: 2, label: "2019" },
  { value: 3, label: "2020" },
  { value: 4, label: "2021" },
  { value: 5, label: "2022" },
  { value: 6, label: "2023" },
]
