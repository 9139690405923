import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { Colors, invertDark } from "../../Assets/Config/color.config";

import { inject, observer } from "mobx-react";

import { DynamicGhGChart } from "./DynamicGhGChart";
import { DyComparisonChart } from "./DyComparisonChart";
import { DyAnalysisChart } from "./DyAnalysisChart";
import { DyVerticalChart } from "./DyVerticalChart";
import { DyScopeYearlyChart } from "./DyScopeYearlyChart";

import { DynamicTable } from "./DynamicTable";

export const DynamicComponents = (props) => {
  const { dyData } = props;

  // useEffect(() => {

  // }, [dyData]);

  return (
    <div style={{width:'100%',pageBreakInside: 'avoid'}}>
      {dyData ? (
        <>
          {dyData.type === "GHGVerificationTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "GHGEmissionAndTargetTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "GHGEmissionAndTargetChart" && (
            <p style={{ fontSize: 16, color: "#000000", fontWeight: "bold" }}>
              {dyData.chartData.title}
            </p>
          )}

          {dyData.type === "GHGEmissionAndTargetChart" ? (
            dyData.chartData.data.length ? (
              <DynamicGhGChart data={dyData} />
            ) : (
              <div>No Data!</div>
            )
          ) : (
            ""
          )}

          {dyData.type === "BaselineEmissionTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "AnalysisGHGEmissionTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "AnalysisGHGEmissionChart" && (
            <p style={{ fontSize: 16, color: "#000000", fontWeight: "bold" }}>
              {dyData.chartData.title}
            </p>
          )}
          {dyData.type === "AnalysisGHGEmissionChart" ? (
            dyData.chartData.data.length ? (
              <DyAnalysisChart data={dyData} />
            ) : (
              <div>No Data!</div>
            )
          ) : (
            ""
          )}

          {dyData.type === "EnergyConsumptionTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "EnergyConsumptionChart" && (
            <p style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
              {dyData.chartData.title}
            </p>
          )}

          {dyData.type === "EnergyConsumptionChart" ? (
            dyData.chartData.data.length ? (
              <DyComparisonChart data={dyData} />
            ) : (
              <div>No Data!</div>
            )
          ) : (
            ""
          )}

          {dyData.type === "AssetAndEmissionFactorTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "scopesTable" ? (
            <div>
              <p style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
               {dyData.title}
              </p>

              {dyData.stationary.rows.length ? (
                <DynamicTable data={dyData.stationary} />
              ) : (
                <div>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    {dyData.stationary.title}
                  </p>
                  <div>No Data!</div>
                </div>
              )}
              <br />

              {dyData.purchase.rows.length ? (
                <DynamicTable data={dyData.purchase} />
              ) : (
                <div>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {dyData.purchase.title}
                </p>
                <div>No Data!</div>
              </div>
              )}
              <br />

              {dyData.upstream.rows.length ? (
                <DynamicTable data={dyData.upstream} />
              ) : (
                <div>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {dyData.upstream.title}
                </p>
                <div>No Data!</div>
              </div>
              )}
            </div>
          ) : (
            ""
          )}

          {dyData.type === "ConEmisReportTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}

          {dyData.type === "ConEmisReportChart" && (
            <p style={{ fontSize: 16, fontWeight: "bold", color: "#000000" }}>
              {dyData.chartData.title}
            </p>
          )}

          {dyData.type === "ConEmisReportChart" ? (
            dyData.chartData.data.length ? (
              <DyVerticalChart data={dyData} />
            ) : (
              <div>No Data!</div>
            )
          ) : (
            ""
          )}

          {dyData.type === "EmisYearlyReportTable" ? (
            dyData.rows.length ? (
              <DynamicTable data={dyData} />
            ) : (
              <div>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000000",
                }}
              >
                {dyData.title}
              </p>
              <div>No Data!</div>
            </div>
            )
          ) : (
            ""
          )}
          <br />

          {dyData.type === "EmisYearlyReportChart" && (
            <p
              style={{
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              {dyData.chartData.title}
            </p>
          )}
          {dyData.type === "EmisYearlyReportChart" ? (
            dyData.chartData.data.length ? (
              <DyScopeYearlyChart data={dyData} />
            ) : (
              <div>No Data!</div>
            )
          ) : (
            ""
          )}
        </>
      ) : (
        <div
          style={{ textAlign: "center", fontSize: "14", fontWeight: "bold" }}
        >
          No Data!
        </div>
      )}
    </div>
  );
};
