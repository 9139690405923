import { Fragment, useEffect } from "react";
import { Colors } from "../../Assets/Config/color.config";
import { useNavigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import "../../App.css";
import capella from "../../Assets/images/capella.png";
import { LoadingPage } from "../../Page/Loading";
import { useTranslation } from "react-i18next";
import getNsTrans from "../../util/makeNsTrans.js";
import { baseURL } from "../../API/URL";
const Trans = getNsTrans("landing");

export const AuditContainer = inject("store")(
  observer((props) => {
    const { t } = useTranslation("landing");
    const t1 = useTranslation("audit");
    const {
      theme
    } = props.store.common;

    const {
      getCompanyList,
      companyList
    } = props.store.audit;

    useEffect(() => {
      getCompanyList();
    },[]);

    const lists = companyList?.map(v => {
      return {
        companyName : v.companyName,
        imageURL : v.imageURL
      }
    })

    //
    return (
      <Fragment>
        <div className="row m-0 justify-content-center align-items-start">
          <div
            className="w-100 mt-5 px-5 pb-3"
            style={{ color: Colors[theme].textColor }}
          >
            <div style={{ fontSize: 30, paddingBottom: 20 }}>
              {t("Welcome")+" !"}
            </div>
            <span>
              <Trans i18nkey="welcome_text_">
                {t("Please Select a Company that you want to audit.")}
              </Trans>
            </span>
          </div>

          <div className="container">
            <div className="row my-5">
              <div className="col-12">
                <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <div className="row" style={{ maxWidth : '800px' }}>
                      {
                      companyList?.length > 0 ? (
                        <CompanyCard companies={companyList} theme={theme} />
                      ) : (<h1>{t1.t("No Company To Show")}</h1>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  })
);

const CompanyCard = (props) => {
  const { companies, theme } = props;
  const navigate = useNavigate(); 

  const url = baseURL.slice(0, -1);

  return (
    <div className="row d-flex justify-content-center" style={{ minWidth : '800px' }}>
      {companies.map((c,i) => (
        <div key={i} className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-6 mb-3">
          <div style={{ 
              backgroundColor: theme === 'dark' ? 'rgb(44, 59, 138)' : '#fff', 
              borderRadius : '5px',
              boxShadow: '2px 4px 11px rgb(0 0 0 / 25%)' 
            }}
            onClick={() => !c.modules?.Group_Report ?  navigate(`/audit-company-overview/?companyName=${c.companyName}`) : navigate(`/audit-group-list/?companyId=${c._id}`)}
          >
            {/* <div style={{
              backgroundImage : `url('${capella}')`,
              backgroundSize : 'cover',
              backgroundPosition : 'center',
              backgroundRepeat : 'no-repeat',
              width : '100%',
              height : '227px'
              }}></div> */}
            <img src={url+c.imageURL} alt="company-img" className="card-img-top" />  
            <div className="card-body p-0">
              <p className="fw-bold text-center mb-0 py-2" style={{ color : 'rgb(230, 242, 253)', fontSize : '14px', background: 'rgb(44, 59, 138)', borderBottomLeftRadius : '5px', borderBottomRightRadius : '5px' }}>
                {c.companyName}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
    
  )
}