import React, { Component, useState } from "react";
import OtpInput from "react-otp-input";
import { Colors } from "../Assets/Config/color.config";
import { MyButton } from "../Tools";
import "../App.css";
import Store from "../Store";
import { MyLink } from "../Tools";
import { useTranslation } from 'react-i18next';

export const OTP_Verification_Page = () => {
  const [otpText, setOtpText] = useState("");

  const handleChange = (otp) => setOtpText(otp);
  const handleClear = (otp) => setOtpText("");
  const { _handleVerifyOTP, _handleResendOTP } = Store.common;
  const { t } = useTranslation("authentication");
  
  return (
    <div className="container position-relative" style={{ height: "100vh" }}>
      <div
        className="row w-100 justify-content-center p-4 position-absolute"
        style={{ top: "50%", marginTop: -185 }}
      >
        <div className="col-lg-6 py-4 col-12 text-center cardStyle">

          <h4>2 Step Verification</h4>
          <i className="bi bi-shield-lock-fill" style={{fontSize:35,color:Colors.Dark[3]}}></i>
          <div className="text-secondary">
            { t("Please verify OTP") }
          </div>
          <OtpInput
            containerStyle={
              "d-flex flex-row flex-wrap justify-content-center py-4"
            }
            inputStyle={{
              fontSize: 25,
              marginLeft: 5,
              marginRight: 5,
              width: 40,
              height: 40,
              outline: "none",
              color: Colors.Dark[2],
            }}
            value={otpText}
            onChange={(e) => handleChange(e)}
            numInputs={6}
            separator={<span></span>}
          />

          <MyButton
            id={"verifyotp"}
            text={ t("Verify OTP") }
            disabled={otpText.length === 6 ? false : true}
            style={{ width: 200 }}
            customColor={Colors.water}
            onClick={() => _handleVerifyOTP(otpText)}
          />
          <div className="pt-3">
            <span
              style={{ fontSize: 14, cursor: "pointer" }}
              onClick={() => _handleResendOTP()}
            >
              Resend
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
