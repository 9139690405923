import { Colors } from "../../Assets/Config/color.config"
import { inject, observer } from "mobx-react";
import { Selector, MyInput, MyButton } from "../../Tools";
import { useTranslation } from 'react-i18next';

const ESGeditModal = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { pillarOption, subPillarOption, _handlePillarChange, _handleSubPillarChange, editSubpillar, editPillar, editSubPillarOwnerTitle, editSubPillarOwnerName, _handleOwnerNameChange, _handleOwnerTitleChange, _editPillar } = props.store.esg;
        const { t } = useTranslation("esg_tracking");

        return (
            <div
                className={`modal fade ${theme}`}
                id="esgEditModal"
                tabIndex="-1"
                aria-labelledby="esgEditModal"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div
                        className="modal-content"
                        style={{
                            background: Colors[theme].background,
                            borderRadius: 10,
                            color: theme === 'dark' ? Colors.Dark[5] : Colors.Dark[0]
                        }}
                    >
                        <div className="modal-header mx-3 px-0">
                            <h5>
                                {t("Editing ESG Pillar or Sub Pillar")}
                            </h5>
                            <i
                                style={{ cursor: "pointer" }}
                                className="bi bi-x-lg "
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            // onClick={() => _handleClose()}
                            ></i>
                        </div>
                        <div className='modal-body px-4' style={{ fontSize: 14 }}>
                            <form className="mt-3">
                                <span className="row mb-3">
                                    <span className="col-5">{t("ESG Pillar")} :</span>
                                    <span className="col-7" style={{ color: Colors.Dark[0] }}>
                                        <Selector id='editPillar' _handleSelect={(e) => _handlePillarChange(e, 'edit')} options={pillarOption} value={pillarOption.filter((option) => option.value === editPillar)} />
                                    </span>
                                </span>
                                <span className="row mb-3">
                                    <span className="col-5">{t("ESG Sub-Pillar")} :</span>
                                    <span className="col-7" style={{ color: Colors.Dark[0] }}>
                                        <Selector id='editSubPillar' _handleSelect={(e) => _handleSubPillarChange(e, 'edit')} options={subPillarOption} value={subPillarOption.filter((option) => option.value === editSubpillar)} />
                                    </span>
                                </span>
                                <div className="row mb-3">
                                    <span className="col-5 d-flex align-items-center">
                                        {t("Sub-Pillar Owner Title")} :
                                    </span>
                                    <span className="col-7">
                                        <MyInput id='editSubPillarOwnerTitle' onChange={(e) => _handleOwnerTitleChange(e, 'edit')} value={editSubPillarOwnerTitle} />
                                    </span>
                                </div>
                                <div className="row">
                                    <span className="col-5 d-flex align-items-center">
                                        {t("Sub-Pillar Owner Name")} :
                                    </span>
                                    <span className="col-7">
                                        <MyInput id='editSubPillarOwnerName' onChange={(e) => _handleOwnerNameChange(e, 'edit')} value={editSubPillarOwnerName} />
                                    </span>
                                </div>
                                <span data-bs-dismiss="modal"
                                    aria-label="Close">
                                    <MyButton onClick={() => _editPillar()} customColor='#066CFF' customHoverColor={Colors.water} className='mt-5 mb-2' style={{ width: '100%' }} text={t("Save")} />
                                </span>

                            </form>

                        </div>



                    </div>
                </div>
            </div>
        )
    }))
export default ESGeditModal