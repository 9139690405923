import { observable, action, makeObservable, runInAction, toJS } from "mobx";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import Store from ".";
import { FetchEditUser, FetchRegister, FetchDeleteUser } from "../API/api.user";
import { FetchUsers } from "../API/api.user";
import { RegisterFormValidation } from "../util/formValidation";

class ManageUsers {
  cookies = new Cookies();
  theme = localStorage.getItem("theme") === "light" ? "light" : "dark";
  siteModuleData = this.cookies.get("siteModuleData");
  showUserForm = false;
  formMode = null;
  formData = {};
  visible = false;
  newUserData = {
    _id: null,
    userName: "",
    email: "",
    building: null,
    role: null,
    units: [],
    frameworks: "",
    password: "",
    companyName: "",
  };
  newAccountData = {
    _id: null,
    userName: "",
    email: "",
    building: null,
    units: [],
    password: "",
    role: "",
  };
  newUserList = [];
  allUsers = null;
  editedUser = { _id: null, name: null };
  loading = false;

  constructor() {
    makeObservable(this, {
      showUserForm: observable,
      newAccountData: observable,
      formMode: observable,
      formData: observable,
      visible: observable,
      allUsers: observable,
      newUserList: observable,
      editedUser: observable,
      loading: observable,
      getUserData: action.bound,
      _handleEditUser: action.bound,
      _handleCreateUser: action.bound,
      _handleCloseForm: action.bound,
      _handleView: action.bound,
      _handleInputChange: action.bound,
      _handleRadioChange: action.bound,
      _handleSelect: action.bound,
      _handleRoleSelect: action.bound,
      _handleSubmit: action.bound,
      _handleSubmitNewAccount: action.bound,
      _handleAddNewUser: action.bound,
      _handleDeleteUser: action.bound,
      _handleUserBySite: action.bound,
      getUnitOptions: action.bound,
    });
  }

  getUserData = (selfEditMode) => {
    this.loading = true;
    runInAction(() => {
      
      FetchUsers(selfEditMode, (err, data) => {
        
        if (data) {
          if (!data.error) {
            //this.allUsers = data.payload;
            this._handleUserBySite(data.payload);
          } else {
            toast.error(data.error);
          }
        } else {
          toast.error(err.toString());
        }
        this.loading = false;
      });
    });
  };

  _handleAddNewUser = () => {
    const dataObj = {
      ...this.newAccountData,
      userName: this.newAccountData.userName,
      eMail: this.newAccountData.email,
      password: this.newAccountData.password,
      role: this.newAccountData.role,
    };

    runInAction(() => {
      this.newAccountData = dataObj;
    });
    const validatedErr = RegisterFormValidation(dataObj);
    this.err = validatedErr;
    if (validatedErr.roleErr) {
      toast.error(this.err.roleErr);
    } else if (validatedErr.emailErr) {
      toast.error(this.err.emailErr);
    } else if (validatedErr.userNameErr) {
      toast.error(this.err.userNameErr);
    } else if (validatedErr.passwordErr) {
      toast.error(this.err.passwordErr);
    }

    if (Object.keys(validatedErr).length === 0) {
      // temp.push({ ...this.newAccountData, _id: this.newUserList.length + 1 });
      // this.newUserList = temp;
      FetchRegister(dataObj, (err, data) => {
        if (data) {
          if (!data.error) {
            toast.success("Created Successfully!");
            runInAction(() => {
              this.newAccountData = {};
              this.getUserData();
              this._handleCloseForm("newAccount");
            });
          } else {
            toast.error(data.error);
            document.getElementById("email").focus();
          }
        } else {
          toast.error(err.toString());
        }
      });
    }
  };

  _handleEditUser = (user) => {
    runInAction(() => {
      this.showUserForm = true;
      this.formMode = "Editing";
      this.editedUser = { _id: user._id, name: user.userName };
      this.formData = { ...user };
    });
  };

  _handleCreateUser = () => {
    runInAction(() => {
      this.showUserForm = true;
      this.formMode = "Create";
      this.newUserData.companyName = this.siteModuleData.companyName;
      this.formData = { ...this.newUserData };
    });
  };

  _handleCloseForm = (type) => {
    runInAction(() => {
      if (type === undefined) {
        this.showUserForm = false;
        this.formMode = null;
        this.formData = {};
        this.editedUser = { _id: null, name: null };
      } else {
        this.showUserForm = false;
        this.formData = {};
        this.newAccountData = {
          _id: null,
          userName: "",
          email: "",
          building: null,
          units: "",
          password: "",
        };
      }
    });
  };

  _handleInputChange = (e, type) => {
    const key = e.target.id;
    const val = e.target.value;
    const newObj =
      type === undefined ? { ...this.formData } : { ...this.newAccountData };
    newObj[key] = val;
    runInAction(() => {
      if (type === undefined) {
        this.formData = newObj;
      } else {
        this.newAccountData = newObj;
      }
    });
  };

  _handleRadioChange = (role) => {
    const newObj = { ...this.formData };
    newObj.role = role;
    runInAction(() => {
      this.formData = newObj;
    });
  };

  _handleView = () => {
    this.visible = !this.visible;
  };

  _handleDeleteUser = (id) => {
    FetchDeleteUser(id, (err, data) => {
      if (err) {
        toast.error(err);
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Deleted Successfully!");
          this.getUserData();
          runInAction(() => {
            this.showUserForm = false;
            this.formData = {};
          });
        }
      }
    });
    // const temp = toJS(this.newUserList)?.filter((v) => v._id !== id);
    // this.newUserList = temp;
  };

  _handleSelect = ({ data, type, key, index }) => {

    if(type !== "newAccount"){
      if(data.includes("")){
        const emptyIndex = data.findIndex((v) => v === "");
        if (emptyIndex > -1) {            
          data.splice(emptyIndex, 1);
        }
      }
    }

    runInAction(() => {
      if (key === "buildingId") {
        const newObj = { ...this.formData };
        if(data.length > 0){
          const newUnitsArray = [];
          newObj[key] = data; 
          newObj['units'].map((uv) => {
            if(toJS(data).includes(uv.buildingId)){
              newUnitsArray.push(uv);
            }            
          });
          newObj['units'] = newUnitsArray;
        }else{
          newObj[key] = [];
          newObj['units'] = [];                   
        }
        
        newObj[key] = data;
        this.formData = newObj;
      } else if (key === "units") {
        const newObj = { ...this.formData };
        
        newObj[key] = data;
        this.formData = newObj;
        // const newAcc = { ...this.newAccountData };
        // newAcc.units = data;
        // newAcc.units = data;
        // this.newAccountData = newAcc;
      } else {
        const newAcc = { ...this.newAccountData };
        // newAcc.buildingId = data.id;
        newAcc.building = data.id;
        this.newAccountData = newAcc;
      }
    });
    
  };

  _handleRoleSelect = (e) => {
    this.formData = { ...this.formData, role: e.value };
    this.newAccountData = { ...this.newAccountData, role: e.value };
  };

  _handleSubmitNewAccount = () => {
    const accData = { ...this.newAccountData };
    // FetchRegister(accData, (err, data) => {
    //     if (data) {
    //         if (!data.error) {
    //             toast.success("Created Successfully!");
    //             runInAction(() => {
    //                 this.newAccountData = {};
    //                 this.getUserData();
    //             });
    //         } else {
    //             toast.error(data.error);
    //             document.getElementById("email").focus();
    //         }
    //     } else {
    //         toast.error(err.toString());
    //     }
    // });
  };

  _handleSubmit = () => {
    const updateUser = { ...this.formData };
    const allusers = [...this.allUsers];
    const index = allusers.findIndex((v) => v._id === updateUser._id);

    const ValidatedErr = RegisterFormValidation(updateUser, this.formMode);

    this.err = ValidatedErr;
    if (ValidatedErr.roleErr) {
      toast.error(this.err.roleErr);
    } else if (ValidatedErr.emailErr) {
      toast.error(this.err.emailErr);
    }else if (ValidatedErr.companyName) {
      toast.error(this.err.companyName);
    } else if (ValidatedErr.userNameErr) {
      toast.error(this.err.userNameErr);
    } else if (this.formMode !== "Editing" && ValidatedErr.passwordErr) {
      toast.error(this.err.passwordErr);
    }
    if (Object.keys(ValidatedErr).length === 0) {
      runInAction(() => {
        this.isDisabled = true;
      });
      if (index < 0) {
        updateUser._id = allusers.length + 1;
        // allusers.push({...updateUser});
        FetchRegister(updateUser, (err, data) => {
          if (data) {
            if (!data.error) {
              toast.success("Created Successfully!");
              runInAction(() => {
                this.showUserForm = false;
                this.formData = {};
                this.getUserData();
                //this.allUsers.push(updateUser);
                //this._handleUserBySite(this.allUsers);
              });
            } else {
              toast.error(data.error);
              document.getElementById("email").focus();
            }
          } else {
            toast.error(err.toString());
          }
        });
      } else {
        // allusers.splice(index,1,{...updateUser});
        // toast.success("Updated Successfully!");
        // 
        // const bodyData = updateUser.siteInfo.map(v=> (
        //     {
        //         _id: v._id,
        //         userName: v.userName,
        //         email: v.email,
        //         password: v.password,
        //         role: v.role,
        //         buildingId: v.buildingId,
        //         frameworks: v.frameworks,
        //         unit: v["unit/floor"],
        //         companyName: v.companyName
        //     }
        // ))
        FetchEditUser(updateUser, (err, data) => {
          if (data.payload) {
            toast.success("Edited User Successfully!");
            runInAction(() => {
              this.showUserForm = false;
              this.formData = {};
              this.getUserData();
              //this.allUsers[index] = updateUser;
              //this._handleUserBySite(this.allUsers);
            });
          } else toast.error(data.error);
        });
      }
    }

    runInAction(() => {
      this.allUsers = allusers;
      // this.showUserForm = false;
      // this.formData = {};
    });
  };

  getUnitOptions = (buildingId_) => {
    const { permittedBuilding } = Store.common;
    const findUnit = permittedBuilding
      ? permittedBuilding.find((v) => v._id === buildingId_)?.unit
      : [];
    const options = findUnit?.map((v) => ({
      id: v._id,
      value: v.name || v.unitName,
      label: v.name || v.unitName,
    }));

    return options;
  };

  _handleUserBySite(data) {
    
    let users = [];
    let user = null;
    data.map((value, key) => {
      user = {
        _id: value._id,
        userName: value.userName,
        role: value.role,
        email: value.email,
        companyName: value.companyName,
        "unit/floor": value.units,
        buildingId : value.buildingId,
        units : value.units
      };
      if (value.buildingInfo.length === 0) {
        user = {
          ...user,
          buildingId: null,
          buildingInfo: [],
          buildingName: "N/A",
          frameworks: value.surveyHeader,
        };
        users.push(user);
      } else {
        value.buildingInfo.map((value1, key) => {
          user = {
            ...user,
            buildingId: value1._id,
            buildingName: value1.buildingName,
            companyName: value.companyName,
            frameworks: value1.surveyHeader,
          };
          users.push(user);
        });
      }
    });
    this.allUsers = users;
  }
}

export default new ManageUsers();
