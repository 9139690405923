import { inject, observer } from "mobx-react";
import "./groupview.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Selector, MyButton, GroupViewAccordion, MyInput } from "../../Tools";
import { useTranslation } from 'react-i18next';
import "./groupview.css";
import { Colors } from "../../Assets/Config/color.config";
import { Fragment } from "react";
import { useState } from "react";
import { toJS } from "mobx";
import { Scope1SetUpModal } from "./Scope1SetUpModal";
import { Scope2SetUpModal } from "./Scope2SetUpModal";
import { Scope3SetUpModal } from "./Scope3SetUpModal";

const GroupView = inject("store")(
    observer((props) => {
        const { t } = useTranslation("assetSetupAndGroup");
        const { theme, siteModuleData, yearOptions, _handleGetYearOptions, minBaselineYear } = props.store.common;

        const navigate = useNavigate();
        const {
            listGroup,
            selectedGroupData,
            selectedView,
            selectedGroupId,
            views,
            selectedGWPGroupData,
            selectedComsumptionData,
            _handleGroupNameChange,
            _handleSelectedViewChange,
            selectedYear,
            _handleSelectedYearChange,
            groupEditMode,
            _handleGroupEditMode,
            getConsumptionData,
            getEmissionData,
            getAssetData,
            isLoading,
            tempOrgData,
            selectedBuildingData
        } = props.store.groupmanagement;

        let count =
          selectedGroupId == -2
            ? selectedGroupData
                .map((v) => {
                  let i = 0;
                  v?.data.map((d) => {
                    if (d.group?.length == 0 || d.group?.length == undefined) {
                      i++;
                    }
                  });
                  return i;
                })
                .reduce((a, c) => a + c, 0)
            : 0;
        
        const NoGroupCount = ({count}) => {
            return (
                <div className="fw-bold mb-3" style={{color: Colors[theme].textColor}}>
                    <p className="mb-0" style={{fontSize: 16}}>{t("Assets that are not in the group are not displayed in the report.")}</p> 
                    <a 
                        className="text-danger"
                        href={`/group-view?groupId=-1&view=${selectedView}`}
                        style={{fontSize: 14}}
                    >
                        {t("Total")} <span style={{fontSize: 15}}>{count}</span> {t(`Assets are not included in any group.`)}
                    </a>
                </div>
            )
        }

        useEffect(() => {
            if(siteModuleData){
                if(siteModuleData.baselineYear){
                    _handleGetYearOptions(siteModuleData.baselineYear);
                }else{
                    _handleGetYearOptions(minBaselineYear);
                }                
            }
          }, []);

        useEffect(() => {
            if (selectedView === "Consumption View") {
                getConsumptionData();
                getAssetData();
            } else if (selectedView === 'Emission View') {
                getEmissionData()
            } else if (selectedView === "Emission Activity") {
                getAssetData()
            }
        }, [selectedView, selectedYear, selectedGroupId])

        return (
            <div className="row">
                <div className="col-12">
                    {selectedGroupId ? (
                        <Head
                            listGroup={listGroup}
                            views={views}
                            groupId={selectedGroupId}
                            groupEditMode={groupEditMode}
                            handleSelectedViewChange={_handleSelectedViewChange}
                            selectedView={selectedView}
                            selectedYear={selectedYear}
                            handleSelectedYear={_handleSelectedYearChange}
                            _handleGroupNameChange={_handleGroupNameChange}
                            _handleGroupEditMode={_handleGroupEditMode}
                            theme={theme}
                            yearOptions={yearOptions}
                        />
                    ) : null}
                    
                    {
                        selectedView === "Emission Activity" ?
                            (
                                isLoading ?
                                    <div className="d-flex justify-content-center">
                                        <i className="spinner-border mt-2" />
                                    </div>
                                    :
                                    (
                                        selectedGroupData.length === 0 ?
                                            <h6 className="text-center mt-2 text-secondary">{t("No Data.")}</h6>
                                            :
                                            <>
                                                {
                                                    selectedGroupId == -2 && count > 0 ?
                                                        <NoGroupCount count={count} />
                                                    : null
                                                }
                                                <div style={{ overflow : "scroll",maxHeight : selectedGroupId == -2 && count > 0 ? "60vh" : "67vh"}}>
                                                    {
                                                        selectedGroupData.map((v, index) => {
                                                            return (
                                                                <GroupViewAccordion selectedGroupId={selectedGroupId} selectedView={selectedView} data={v} index={index} theme={theme} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </>
                                    )
                            )

                            : selectedView === "Emission View" ?
                                (
                                    isLoading ?
                                        <div className="d-flex justify-content-center">
                                            <i className="spinner-border mt-2" />
                                        </div>
                                        :
                                        (
                                            selectedGWPGroupData.length === 0 ?
                                                <h6 className="text-center mt-2 text-secondary">{t("No Data.")}</h6>
                                                :
                                                <>
                                                    {
                                                        selectedGroupId == -2 && count > 0 ?
                                                            <NoGroupCount count={count} />
                                                        : null
                                                    }
                                                    <div style={{ overflow : "scroll",maxHeight : selectedGroupId == -2 && count > 0 ? "60vh" : "67vh"}}>
                                                        {
                                                            selectedGWPGroupData.map((v, index) => {

                                                                return (
                                                                    <GroupViewAccordion selectedGroupId={selectedGroupId} selectedView={selectedView} data={v} index={index} theme={theme} />
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                </>
                                        )
                                )

                                : selectedView === "Consumption View" ?
                                    (
                                        isLoading ?
                                            <div className="d-flex justify-content-center">
                                                <i className="spinner-border mt-2" />
                                            </div>
                                            :
                                            (
                                                selectedComsumptionData.length === 0 ?
                                                    <h6 className="text-center mt-2 text-secondary">{t("No Data.")}</h6>
                                                    :
                                                    <>
                                                        {
                                                            selectedGroupId == -2 && count > 0 ?
                                                                <NoGroupCount count={count} />  
                                                            : null
                                                        }
                                                        <div style={{ overflow : "scroll",maxHeight : selectedGroupId == -2 && count > 0 ? "60vh" : "67vh"}}>
                                                            {
                                                                selectedComsumptionData.map((v, index) => {
                                                                    return (
                                                                        <GroupViewAccordion selectedGroupId={selectedGroupId} selectedView={selectedView} data={v} index={index} theme={theme} />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                            )
                                    )
                                    : null
                    }
                    {/* <GroupViewAccordion selectedView={"buildingView"} data={selectedBuildingData} theme={theme} /> */}

                </div>
            </div>
        );

    })
);

const Head = (props) => {
    const { t } = useTranslation("assetSetupAndGroup");
    const {
        listGroup,
        views,
        groupId,
        handleSelectedViewChange,
        selectedView,
        selectedYear,
        handleSelectedYear,
        _handleGroupNameChange,
        _handleGroupEditMode,
        groupEditMode,
        theme,
        yearOptions
    } = props;
    const navigate = useNavigate();

    return (
        <>
            <div className="row">
                <div className="col-4" style={{ color: Colors[theme].textColor }}>
                    {
                        groupId == -1 ?
                        (
                            <>
                                <h4>
                                    {t("No Group Asset")}
                                </h4>
                            </>
                        ) :
                        groupId == -2 ?
                            <>
                                <h4>
                                    {t("All Group Assets")}
                                </h4>
                            </>
                            
                            : <>
                                <h4>
                                    {
                                        listGroup.map(g => {
                                            return <EditGroupText key={g.id} selectedGroupId={groupId} group={g} groupEditMode={groupEditMode} _handleGroupNameChange={_handleGroupNameChange} _handleGroupEditMode={_handleGroupEditMode} theme={theme} />
                                        })
                                    }
                                </h4>
                            </>
                    }
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-4" style={{ color: Colors[theme].textColor }}>
                    <p style={{ fontSize: 15 }} className="my-2 fw-bold">{t("Asset List")}</p>
                </div>
                <div className="col-4 text-center d-flex align-items-center justify-content-center">
                    <div className="d-flex" style={{ backgroundColor: theme === 'dark' ? "#D9D9D925" : "#0052c7", borderRadius: 10 }}>
                        {views.map((v, i) => (
                            <MyButton
                                key={i}
                                text={t(v === "Consumption View" ? "Data Entry" : v === "Emission View" ? "Emission" : v)}
                                customColor={'transparent'}
                                className={`px-3 fw-bold text-nowrap ${views.length == i + 1 ? '' : 'view-menu'} ${selectedView === v ? '' : 'text-white-50'}`}
                                onClick={() => {
                                    navigate(`/group-view?groupId=${groupId}&view=${v}`, { replace: true });
                                    handleSelectedViewChange(v)
                                }}
                            />
                        ))}
                    </div>
                </div>
                {
                    selectedView === 'Emission Activity' ? null : (
                        <div className="col-4 d-flex align-items-center justify-content-end">
                            <div className="d-flex align-items-center me-2">
                                <span style={{ fontSize: 15, color: Colors[theme].textColor }} className="me-2">{t("Year")} : </span>
                                <Selector
                                    menuListColor={Colors.Dark[0]}
                                    options={yearOptions}
                                    value={
                                        yearOptions.filter(
                                            (v) => v.label === selectedYear
                                        ) || ""
                                    }
                                    placeholder={"Select year"}
                                    _handleSelect={(e) =>
                                        handleSelectedYear(e)
                                    }
                                />
                            </div>
                        </div>
                    )
                }
            </div>
            <Scope1SetUpModal />
            <Scope2SetUpModal />
            <Scope3SetUpModal />
        </>

    )
}


const EditGroupText = ({ group, selectedGroupId, _handleGroupNameChange, _handleGroupEditMode, theme }) => {
    const [groupName, setGroupName] = useState(group.groupName);
    return <Fragment>
        {
            group._id === selectedGroupId ?
                <div className="row">
                    {group.groupEditMode ?
                        <>
                            <div className="col-8">
                                <MyInput required={true}
                                    id={"groupName"}
                                    type={"text"}
                                    height={40}
                                    value={groupName}
                                    onChange={(e) => {
                                        setGroupName(e.target.value);
                                        //_handleGroupNameChange(e, group)
                                    }}></MyInput>
                            </div>
                            <div className="col-4">
                                <span style={{ cursor: "pointer", color: Colors[theme].textColor }}><i className="bi bi bi-save ma-2" onClick={(e) => {
                                    _handleGroupNameChange(e, group, groupName)
                                    // _handleGroupEditMode(e, group) 
                                }}></i></span></div>
                        </> :
                        <>
                            <div className="d-flex">
                                <span>{group.groupName}</span>
                                <div className="px-1">
                                    <span style={{ cursor: "pointer", color: Colors[theme].textColor }}><i className="bi bi-pencil-square  ma-2" onClick={(e) => { _handleGroupEditMode(e, group) }}></i></span>
                                </div>
                            </div>
                        </>
                    }
                </div> : null
        }
    </Fragment>
}

export default GroupView;
