import React from "react";
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
} from "recharts";
import { inject, observer } from "mobx-react";

export const ComparisonChart = inject("store")(
    observer((props) => {
        const { tabIndex } = props.store.esg;
        const { theme } = props.store.common;
        const data = props.data[tabIndex].value;
        const dataKeys = data.length? Object.keys(data[0]) : [];
        const lineColors = {
            dark: [             
                "#00C6C3",
                "#2982ff",
                "#FDAFDE",
                "#F9F871",
                "#FFEFCA", 
                "#C7C7F1",      
            ],
            light: [
                "#BD5943",
                "#2982ff",
                "#005A34",
                "#0200FF",
                "#FF00F2",
                "#C7C7F1",
                
            ],
        };
        return (
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    style={{
                        background: theme === "dark" ? "rgb(0,0,0,30%)" : "rgb(0,0,0,5%)",
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                    }}
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 50,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                    className={`${theme} customTooltip`}
                >
                    <CartesianGrid stroke="rgb(255,255,255,0.3)" />
                    <defs>
                        {dataKeys?.length? dataKeys.map((v, i) =>
                            v !== "year" && i === 1 ? (
                                <linearGradient key={i} id={i} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={"#0077C5"} stopOpacity={1} />
                                    <stop offset="95%" stopColor={"#002589"} stopOpacity={1} />
                                </linearGradient>
                            ) : (
                                <linearGradient key={i} id={i} x1="0" y1="0" x2="0" y2="1">
                                    <stop
                                        offset="5%"
                                        stopColor={lineColors[theme][i]}
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor={lineColors[theme][i]}
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            )
                        ):<></>}
                    </defs>
                    {/* #2982ff */}
                    <XAxis dataKey="year" scale="auto" />
                    <YAxis width={80} />
                    <Tooltip />
                    <Legend />
                    {dataKeys?.length?dataKeys.map(
                        (v, i) =>
                            v !== "year" &&
                            (i == 1 ? (
                                <Bar key={i} dataKey={v} type="monotone"  barSize={20} fill={`url(#${i})`} />
                            ) : (
                                <Area
                                    key={i}
                                    type="monotone"
                                    dataKey={v}
                                    fillOpacity={0.15}
                                    fill={`url(#${i})`}
                                    stroke={lineColors[theme][i]}
                                ></Area>
                            ))
                    ):<></>}
                </ComposedChart>
            </ResponsiveContainer>
        );
    })
);
