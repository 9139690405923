import { action, makeObservable, observable, observe, runInAction, toJS } from "mobx";
import {
    createNewGroup,
    fetchAllGroups,
    updateGroupName,
    fetchConsumptionDataByYear,
    fetchUpdateMultiConsumption,
    fetchEmissionDataByYear,
    fetchUpdateMultiEmission,
    fetchAssetData,
    fetchUpdateAssetsData,
    fetchDeleteGroup,
    fetchDeleteAssets,
    fetchCompanyFileList,
    fetchCompanyFileUpload,
    fetchCheckUploadedFile,
    fetchDeleteUploadedFile,
    fetchAddConsumptionDataByYear
} from "../API/api.group";
import {
    CreateGroupViewEquipmentValidation,
  } from "../util/formValidation";
import { getEmissionByCalculatedMethod } from "../API/api.survey";
import { toast } from "react-toastify";
import survey from './survey'
import common from "./common";
import { addOrganizationSetUp } from "../API/api.quickOrganizationSetUp";

class GroupManagement {
    views = [
        "Emission Activity",
        "Consumption View", // change btn title in group Consumption View to Data Entry
        "Emission View"
    ]
    
    // selectedView = "Emission Activity"
    selectedView = "Emission Activity";
    selectedGroupId = new URLSearchParams(window.location.search).get('groupId');
    editRow = null;
    selectedYear = "2022"
    editTable = false;
    newGroupName = null;
    validationErr = [];
    err = {};
    companyFileList = [];
    companyFile = {
        fileName : null,
        fileType : null,
        file : null,
        year:null,
        remark:null
    };

    months = [
        { monthIndex: 1, monthName: "Jan" },
        { monthIndex: 2, monthName: "Feb" },
        { monthIndex: 3, monthName: "Mar" },
        { monthIndex: 4, monthName: "Apr" },
        { monthIndex: 5, monthName: "May" },
        { monthIndex: 6, monthName: "Jun" },
        { monthIndex: 7, monthName: "Jul" },
        { monthIndex: 8, monthName: "Aug" },
        { monthIndex: 9, monthName: "Sep" },
        { monthIndex: 10, monthName: "Oct" },
        { monthIndex: 11, monthName: "Nov" },
        { monthIndex: 12, monthName: "Dec" }
    ];

    deviceTypeOption = [
        { value: 1, label: "Aviation Gasoline Aircraft", category: "vehicleType" },
        { value: 2, label: "Biodiesel Passenger Cars", category: "vehicleType" },
        { value: 3, label: "Diesel Passenger Cars", category: "vehicleType" },
        { value: 4, label: "Heavy Goods Vehicle", category: "vehicleType" },
        { value: 5, label: "Domestic Refrigeration", category: "equipmentType" },
        { value: 6, label: "Stand-alone Commercial Applications", category: "equipmentType" },
        { value: 7, label: "Medium & Large Commercial Refrigeration", category: "equipmentType" },
        { value: 8, label: "Transport Refrigeration", category: "equipmentType" },
        { value: 9, label: "Industrial Refrigeration including Food Processing and Cold Storage", category: "equipmentType" },
        { value: 10, label: "Chillers", category: "equipmentType" },
        { value: 11, label: "Residential and Commercial", category: "equipmentType" },
        { value: 12, label: "including Heat Pumps", category: "equipmentType" },
        { value: 13, label: "Mobile Air Conditioning", category: "equipmentType" },
        { value: 14, label: "CH2", category: "fuelType" },
        { value: 15, label: "CFC", category: "fuelType" },
        { value: 16, label: "Disel", category: "fuelType" },
        { value: 17, label: "kg", category: "unit" },
        { value: 18, label: "g", category: "unit" },
        { value: 19, label: "l", category: "unit" },
        { value: 20, label: "kWh", category: "unit" },
        { value: 21, label: "Custom Emission Factor", category: "emissionFactor" },
        { value: 22, label: "Grid Average / Location Based", category: "emissionFactor" },
        { value: 23, label: "Grid Average/Location Based", category: "emissionFactor" },
        { value: 24, label: "2019", category: "year" },
        { value: 25, label: "2020", category: "year" },
        { value: 26, label: "2021", category: "year" },
        { value: 27, label: "2022", category: "year" },
        { value: 28, label: "AKGD", category: "calculatedMethod" },
        { value: 29, label: "AKMS", category: "calculatedMethod" },
        { value: 30, label: "ERCT", category: "calculatedMethod" },
        { value: 31, label: "Alberta", category: "calculatedMethod" },
        { value: 32, label: "Austria", category: "calculatedMethod" },
        { value: 33, label: "Belgium", category: "calculatedMethod" },
        { value: 34, label: "Head/Stream", category: "calculationApproach" },
        { value: 35, label: "Purchased Electricity - Location Based", category: "calculationApproach" },
        { value: 36, label: "Purchased Electricity - Market Based", category: "calculationApproach" },
    ];

    groups = [
        { id: 1, label: 'Group1' },
        { id: 2, label: 'Group2' },
        { id: 3, label: 'Group3' }
    ]

    tableColumns = [
        {
            cat: "Stationary Combustion",
            dataFields: [
                { "label": "Equipment Name", "key": "assetName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Fuel Type", "key": "fuelType", "dataType": "selectInput" },
                { "label": "Equipment Type", "key": "equipmentType", "dataType": "selectInput" },
                { "label": "Brand", "key": "brand", "dataType": "textInput", "fieldType": "text" },
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "Commissioned Date", "key": "commissionedDate", "dataType": "dateInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Refrigerants and Fugitives",
            dataFields: [
                { "label": "Asset Name", "key": "assetName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Gas Type", "key": "fuelType", "dataType": "selectInput" },
                { "label": "Equipment Type", "key": "equipmentType", "dataType": "selectInput" },
                { "label": "Brand", "key": "brand", "dataType": "textInput", "fieldType": "text" },
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "Commissioned Date", "key": "commissionedDate", "dataType": "dateInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Mobile Combustion",
            dataFields: [
                { "label": "Asset Name", "key": "assetName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Fuel Type", "key": "fuelType", "dataType": "selectInput" },
                { "label": "Vehicle Type", "key": "vehicleType", "dataType": "selectInput" },
                { "label": "Activity Type", "key": "activityType", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Model", "key": "model", "dataType": "textInput", "fieldType": "text" },
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "Commissioned Date", "key": "commissionedDate", "dataType": "dateInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Purchased Electricity",
            dataFields: [
                { "label": "Year", "key": "year", "dataType": "selectInput" },
                { "label": "Supplier Name", "key": "supplierName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Emission Factor", "key": "emissionFactor", "dataType": "selectInput" },
                { "label": "Calculation Approach", "key": "calculationApproach", "dataType": "selectInput" },
                { "label": "Calculated Method", "key": "calculateMethod", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "Commissioned Date", "key": "commissionedDate", "dataType": "dateInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Imported energy (steam, heating, cooling and compressed air)",
            dataFields: [
                { "label": "Year", "key": "year", "dataType": "selectInput" },
                { "label": "Supplier Name", "key": "supplierName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Emission Factor", "key": "emissionFactor", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Calculation Approach", "key": "calculationApproach", "dataType": "selectInput" },
                { "label": "Calculate Type", "key": "calculateType", "dataType": "selectInput" },
                { "label": "Calculated Method", "key": "calculateMethod", "dataType": "selectInput" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "Commissioned Date", "key": "commissionedDate", "dataType": "dateInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Upstream T&D",
            dataFields: [
                { "label": "Name", "key": "transportationName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Year", "key": "year", "dataType": "selectInput" },
                { "label": "Transport Mode", "key": "transportMode", "dataType": "selectInput" },
                { "label": "Vehicle Type", "key": "vehicleType", "dataType": "selectInput" },
                { "label": "Activity Type", "key": "activityType", "dataType": "selectInput" },
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "Commissioned Date", "key": "commissionedDate", "dataType": "dateInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Waste",
            dataFields: [
                { "label": "Name", "key": "name", "dataType": "textInput", "fieldType": "text" },
                { "label": "Year", "key": "year", "dataType": "selectInput" },
                { "label": "Waste Treatment Method", "key": "wasteTreatmentMethod", "dataType": "selectInput" },               
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Emission Removal & Mitigation",
            dataFields: [
                { "label": "Name", "key": "name", "dataType": "textInput", "fieldType": "text" },
                { "label": "Year", "key": "year", "dataType": "selectInput" },               
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        ,
        {
            cat: "Purchased Goods",
            dataFields: [
                { "label": "Supplier Name", "key": "supplierName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Year", "key": "year", "dataType": "selectInput" },               
                { "label": "Unit", "key": "unit", "dataType": "selectInput" },
                { "label": "Purchased Good", "key": "purchasedGood", "dataType": "selectInput" },
                { "label": "Emission Factor Value", "key": "emissionFactorValue", "dataType": "textInput", "fieldType": "number" },
                { "label": "Specification File", "key": "refFileName", "dataType": "actionBtn" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        },
        {
            cat: "Building",
            dataFields: [
                { "label": "Building Name", "key": "buildingName", "dataType": "textInput", "fieldType": "text" },
                { "label": "Building Type", "key": "buildingType", "dataType": "selectInput" },
                { "label": "Country", "key": "country", "dataType": "textInput", "fieldType": "text" },
                { "label": "City", "key": "city", "dataType": "textInput", "fieldType": "text" },
                { "label": "Framework", "key": "frameWork", "dataType": "multiSelectInput" },
                { "label": "View", "key": "group", "dataType": "multiSelectInput" },
            ]
        }
    ]

    listGroup = [];

    selectedGroupData = [];
    assetDetailsData = [
        {
            "assetName": "Chiller 1 - CFC",
            "equipmentType": "Chillers",
            "emissionFactor": "18.2",
            "brand": "Samsung"
        },
        {
            "assetName": "Chiller 2 - CH2",
            "equipmentType": "Chillers",
            "emissionFactor": "18.2",
            "brand": "Samsung"
        },
        {
            "assetName": "Chiller 3 - CH2",
            "equipmentType": "Chillers",
            "emissionFactor": "18.2",
            "brand": "Samsung"
        },
        {
            "assetName": "YP5201Z - Diesel",
            "vehicleType": "Heavy Goods Vehicle",
            "emissionFactor": "18.2",
            "model": "XZU710R-HKFMS3"
        },
        {
            "assetName": "GBL3208L - Petrol",
            "vehicleType": "Heavy Goods Vehicle",
            "emissionFactor": "18.2",
            "model": "XZU710R-HKFMS3"
        },
        {
            "assetName": "YP5201Z - Diesel",
            "vehicleType": "Heavy Goods Vehicle",
            "emissionFactor": "18.2",
            "model": "XZU710R-HKFMS3"
        }
    ];

    // selectedBuildingData = {
    //     "title": "Building",
    //     "icon": "bi bi-building",
    //     "data": [
    //         {
    //             "_id": "62e0fb01d0d8805854124e74",
    //             "buildingName": "SINA1, Singapore",
    //             "buildingType": "Office",
    //             "photo": "/upload/building/2ee6b247-a9ef-4bfe-ad39-0910541eaf8e.png",
    //             "country": "Singapore",
    //             "city": "Singapore",
    //             "active": true,
    //             "frameWork": [
    //                 "WWF",
    //                 "TCFD",
    //                 "GHG"
    //             ],
    //             "group": [
    //                 "6398352b41785b58a65c64c3"
    //             ]
    //         },
    //         {
    //             "_id": "62e0fb44d0d8805854124f06",
    //             "buildingName": "SHAA1, Shanghai, China",
    //             "buildingType": "Office",
    //             "photo": "/upload/building/2ee6b247-a9ef-4bfe-ad39-0910541eaf8e.png",
    //             "country": "China",
    //             "city": "Shanghai",
    //             "active": true,
    //             "frameWork": [
    //                 "GHG"
    //             ],
    //             "group": [
    //                 "6398352b41785b58a65c64c3"
    //             ]
    //         },
    //         {
    //             "_id": "635767e99613f82ad8a26316",
    //             "buildingName": "ttttttt",
    //             "buildingType": "Industrial Building",
    //             "photo": "/upload/building/no_image.png",
    //             "country": "Afghanistan",
    //             "city": "ttttttt",
    //             "active": true,
    //             "frameWork": [
    //                 "TCFD",
    //                 "GHG"
    //             ],
    //             "group": [
    //                 "6398352b41785b58a65c64c3"
    //             ]
    //         }
    //     ]
    // }

    selectedComsumptionData = [];

    selectedGWPGroupData = [];

    editTypes = [];

    isClickedDeleteGroup = false;
    deleteCheckedArray = [];
    calcMethodOptions = [];
    dialogActions = [];
    showDialog = "scope1";
    activeDialog = "";
    companyAssetData = [
        {
            category: "",
            companyAsset: [
                {
                    title: "",
                    scope: "",
                    scopeName: "",
                    assets: [{}]
                }
            ]
        }
    ];

    resetCompanyAssetData = [
        {
            category: "",
            companyAsset: [
                {
                    title: "",
                    scope: "",
                    scopeName: "",
                    assets: [
                        {
                            assetName: "",
                            recordPeriod: "",
                            equipmentType: "",
                            activityType: "",
                            vehicleType: "",
                            fuelType: "",
                            brand: "",
                            model: "",
                            unit: "",
                            commissionedDate: new Date(),
                            emissionFactorValue: 0,
                            calculationApproach: "",
                            emissionFactor: "",
                            calculateType: "",
                            transportMode: "",
                            group: ""
                        }
                    ]
                }
            ]
        }
    ];

    assetEmissionFactorValue = 0;
    assetEmissionFactorName = "";
    editConsumptionData = [];
    editEmissionData = [];
    isLoading = false;
    tempOrgData = [];
    deleteAssetTbls = [];
    deleteAssetRows = [];
    selectedRowIndexForUploadedFile = null;
    selectedTitleForUploadedFile = null;
    showForceDeleteConfirmDialog = false;
    forceDeleteConfirmMsg = null;
    forceDeleteFile = {};
    dataEntry = {
        assetId: null,
        selectedFile : null,
        value : null,
        date : new Date()
    }

    constructor(props) {
        makeObservable(this, {
            listGroup: observable,
            selectedGroupData: observable,
            selectedView: observable,
            views: observable,
            selectedGroupId: observable,
            selectedComsumptionData: observable,
            editRow: observable,
            selectedYear: observable,
            editTypes: observable,
            selectedGWPGroupData: observable,
            editTable: observable,
            isClickedDeleteGroup: observable,
            deleteCheckedArray: observable,
            assetDetailsData: observable,
            calcMethodOptions: observable,
            newGroupName: observable,
            activeDialog: observable,
            companyAssetData: observable,
            assetEmissionFactorValue: observable,
            assetEmissionFactorName: observable,
            editConsumptionData: observable,
            isLoading: observable,
            tempOrgData: observable,
            validationErr: observable,
            deleteAssetTbls: observable,
            deleteAssetRows: observable,
            err: observable,
            companyFileList: observable,
            companyFile: observable,
            selectedRowIndexForUploadedFile: observable,
            selectedTitleForUploadedFile: observable,
            showForceDeleteConfirmDialog: observable,
            forceDeleteConfirmMsg: observable,
            forceDeleteFile: observable,
            dataEntry: observable,

            _handleSelectGroupId: action.bound,
            _handleSelectedGroupChange: action.bound,
            _handleGroupNameChange: action.bound,
            _handleEditComsumption: action.bound,
            _handleComsumptionChange: action.bound,
            _handleSaveConsumption: action.bound,
            _handleTextChange: action.bound,
            _handleSelectChange: action.bound,
            _handleSelectedViewChange: action.bound,
            _handleSelectedYearChange: action.bound,
            _handleEdits: action.bound,
            _handleEmissionValueChange: action.bound,
            _handleEditTable: action.bound,
            setClickedDeleteGroup: action.bound,
            _cancelDeleteGroup: action.bound,
            setDeleteGroupChecked: action.bound,
            _handleDeleteGroup: action.bound,
            _handleGroupEditMode: action.bound,
            getCalcMethodOptions: action.bound,
            _handleNewGroupName: action.bound,
            addNewGroup: action.bound,
            getAllGroups: action.bound,
            _handleDialog: action.bound,
            _handleCreateCompanySetup: action.bound,
            _handleChangeAssetData: action.bound,
            getConsumptionData: action.bound,
            getEmissionData: action.bound,
            _handleSaveEmission: action.bound,
            getAssetData: action.bound,
            _handleSaveAssets: action.bound,
            _handleCancelEdits: action.bound,
            _handleSetDelete: action.bound,
            _handleCancelDelete: action.bound,
            _handleDeleteAssets: action.bound,
            _handleSelectDeleteRows: action.bound,
            _getCompanyFileList: action.bound,
            clearData: action.bound,
            _handleChangeCompanyFile: action.bound,
            _handleChangeCompanyFileName: action.bound,
            _handleChangeRemark: action.bound,
            _handleChangeCompanyYear: action.bound,
            _handleCompanyFileUpload: action.bound,
            _handleChangeCompanyFileType: action.bound,
            _handleSelectedRowIndex: action.bound,
            _handleChangeSelectedFile: action.bound,
            _handleCheckUploadedFile: action.bound,
            _handleCancelForceDelete: action.bound,
            _handleForceDeleteUploadedFile: action.bound,
            _handleChangeDataEntrySelectedFile: action.bound,
            _handleChangeDataEntryValue: action.bound,
            _handleChangeDataEntryDate: action.bound,
            _handleChangeDataEntryAssetId: action.bound,
            addConsumptionData: action.bound
        });
        this.initializeDialogAction();
    }

    clearData = () =>{        
        this.companyAssetData = this.resetCompanyAssetData;
    }

    _handleSelectedRowIndex = (rowIndex, title) => {
        this.selectedRowIndexForUploadedFile = rowIndex;
        this.selectedTitleForUploadedFile = title;
    }

    _handleChangeSelectedFile = (fileId, fileName, rowIndex, title) => {
        let newArr = [...toJS(this.selectedGroupData)];
        let index = newArr.findIndex(v => v.title === title);
        newArr[index].data[rowIndex].assetFile = fileId;
        newArr[index].data[rowIndex].refFileName = fileName;
        this.selectedGroupData = newArr;
        if (this.selectedView === "Consumption View") {
            let dataList = [...this.selectedComsumptionData];
            const index1 = dataList.findIndex(f => f.title === title);
            dataList[index1].data[rowIndex].assetFile = fileId;
            this.selectedComsumptionData = dataList;
            const editIndex = this.editConsumptionData.findIndex(v => v.assetId === dataList[index1].data[rowIndex].assetId);
            const editRow = {
                assetId: dataList[index1].data[rowIndex].assetId,
                assetFile: fileId,
                monthlyData: toJS(dataList[index1].data[rowIndex].consumptionData)
            }
            if (editIndex > -1) {
                this.editConsumptionData.splice(editIndex, 1, editRow)
            } else {
                this.editConsumptionData.push(editRow)
            }
        }
    }

    _handleChangeAssetData = (scope, groupId, scopeName, e, keyField) => {

        this.companyAssetData[0].category = scopeName;
        this.companyAssetData[0].companyAsset[0].scope = scope;
        this.companyAssetData[0].companyAsset[0].scopeName = scopeName;
        this.companyAssetData[0].companyAsset[0].assets[0].group = groupId;
        this.companyAssetData[0].companyAsset[0].assets[0].recordPeriod = "Monthly";
        if (keyField === 'commissionedDate') {
            this.companyAssetData[0].companyAsset[0].assets[0][keyField] = new Date(e);
        } else {
            this.companyAssetData[0].companyAsset[0].assets[0][keyField] = e;
        }
        this.companyAssetData[0].companyAsset[0].title = (this.companyAssetData[0].companyAsset[0].assets[0].calculateMethod) ? (this.companyAssetData[0].companyAsset[0].assets[0].calculateMethod).split(" - ")[0] : this.companyAssetData[0].companyAsset[0].assets[0].fuelType;
        this.companyAssetData[0].companyAsset[0].assets[0].category = scopeName;
        this.companyAssetData[0].companyAsset[0].assets[0].emissionFactorValue = this.assetEmissionFactorValue;

        if(this.companyAssetData[0].companyAsset[0].assets[0].calculationApproach){
            if(this.companyAssetData[0].companyAsset[0].assets[0].calculationApproach === "Purchased Electricity(Location Based)"){
                this.companyAssetData[0].companyAsset[0].assets[0].calculateType = "Location Based";
            }else if(this.companyAssetData[0].companyAsset[0].assets[0].calculationApproach === "Purchased Electricity(Market Based)"){
                this.companyAssetData[0].companyAsset[0].assets[0].calculateType = "Market Based";
            }else{
                this.companyAssetData[0].companyAsset[0].assets[0].calculateType = "Steam and Heat";
            }
        }
    }

    _handleGetEmissionByCalculatedMethod = (scope, category) => {
        let type = null;
        let calculatedMethod = null;
        let unit = this.companyAssetData[0].companyAsset[0].assets[0].unit;
        let emissionFactor = null;
        let vehicleType = null;
        if(scope === 'scope1'){
            if(category === 'Refrigerants and Fugitives'){
                type = "Refrigerants";
            }else if(category === "Mobile Combustion"){
                type = "Mobile Combustion";
                vehicleType = this.companyAssetData[0].companyAsset[0].assets[0].vehicleType;
            }else{
                type = "Fuel Type";
            }

            calculatedMethod = this.companyAssetData[0].companyAsset[0].assets[0].fuelType;
            emissionFactor = null;
        }else if(scope === 'scope2'){
            if(this.companyAssetData[0].companyAsset[0].assets[0].calculationApproach === "Purchased Electricity(Location Based)"){
                type = "Location Based";
            }else if(this.companyAssetData[0].companyAsset[0].assets[0].calculationApproach === "Purchased Electricity(Market Based)"){
                type = "Market Based";
            }else{
                type = "Steam and Heat";
            }

            calculatedMethod = (this.companyAssetData[0].companyAsset[0].assets[0].calculateMethod).split(" - ")[0];
            emissionFactor = this.companyAssetData[0].companyAsset[0].assets[0].emissionFactor;
        }else{
            type = "Lookup name";
            calculatedMethod = this.companyAssetData[0].companyAsset[0].assets[0].vehicleType;
            emissionFactor = this.companyAssetData[0].companyAsset[0].assets[0].emissionFactor;
        }

        getEmissionByCalculatedMethod(
            type,            
            calculatedMethod,
            unit,
            emissionFactor,
            scope,
            category,
            vehicleType,
            (err, data) => {
                if (data) {
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        runInAction(() => {
                            this.assetEmissionFactorValue = data.payload;
                        });
                    }
                }
            }
        );
    };


    _handleCreateCompanySetup = (moduleId, modalId) => {
        if (!this.companyAssetData[0].companyAsset[0].assets[0].commissionedDate) {
            this.companyAssetData[0].companyAsset[0].assets[0].commissionedDate = new Date();
        }

        if ((this.companyAssetData[0].companyAsset[0].assets[0].group) === -2 || (this.companyAssetData[0].companyAsset[0].assets[0].group).includes("-1") || (this.companyAssetData[0].companyAsset[0].assets[0].group).includes(-1) || (this.companyAssetData[0].companyAsset[0].assets[0].group).includes("-2") || (this.companyAssetData[0].companyAsset[0].assets[0].group).includes(-2)) {
            this.companyAssetData[0].companyAsset[0].assets[0].group = [];
        }

        const dataBody = this.companyAssetData;

        dataBody.map((d,i) => {
            d.companyAsset.map(c => {
                if(c.scope == ""){
                    this.err.emptyData = "Please Fill Datas";
                    toast.error(this.err.emptyData);
                }else{
                    c.assets.map(a => {
                        const ValidatedErr = CreateGroupViewEquipmentValidation(a, c.scope);
                        this.err = ValidatedErr;
                        if(Object.keys(this.err).length > 0){
                            toast.error(Object.values(this.err)[0])
                        }

                        switch(Object.keys(this.err)[0]){
                            case 'equipmentNameErr':
                                document.getElementById("assetName").focus();
                                break;
                            case 'brandErr':
                                if(a.category === "Mobile Combustion"){
                                    document.getElementById("model").focus();
                                };
                                if(a.category === "Stationary Combustion"){
                                    document.getElementById("brand").focus();
                                };
                                break;
                            case 'supplierErr':
                                document.getElementById("supplier").focus();
                                break;
                            case 'modelErr':
                                document.getElementById('model').focus();
                                break;
                            case 'nameErr':
                                document.getElementById('assetName').focus();
                                break;
                            case 'calculateMethodErr':
                                document.getElementById('calculateMethod').focus();
                                break;                            
                            case 'transportationNameErr':
                                document.getElementById('transportationName').focus();
                                break;
                        }
                        
                    })
                }
            })
        })

        if(Object.keys(this.err).length === 0){
            addOrganizationSetUp({ moduleId: moduleId }, dataBody, (err, data) => {
                if (data && data.success) {

                    if (this.selectedView === 'Emission Activity') {
                        this.getAssetData();
                    } else if (this.selectedView === 'Emission View') {
                        this.getEmissionData();
                    } else if (this.selectedView === 'Consumption View') {
                        this.getConsumptionData();
                    }
                    toast.success("Successfully Save!");
                    document
                        .getElementById(modalId)
                        .setAttribute("data-bs-dismiss", "modal");
                    let clickEvent = new MouseEvent("click", {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                    });
                    document.getElementById(modalId).dispatchEvent(clickEvent);
                    document
                        .getElementById(modalId)
                        .removeAttribute("data-bs-dismiss");
                    runInAction(() => {
                        this.companyAssetData = this.resetCompanyAssetData;
                    });
                } else {
                    toast.error(err);
                }
            });
        }
        
    }

    _handleEdits = (type, title) => {
        this.validationErr = [];
        if (this.selectedView === "Emission Activity" && this.editTypes.length !== 0) {
            const scope = (common.tableColumns[0].dataColumns).filter(c => c.cat === title)[0]?.scope;
            const updateData = toJS(this.selectedGroupData).filter(v => v.title === title);
            updateData[0].scope = scope;
            for (let j = 0; j < updateData[0].data.length; j++) {
                const asset = (updateData[0].data)[j];
                const index = (updateData[0].data)[j]._id;
                if (title === "Stationary Combustion") {
                    if (
                        !asset.assetName || asset.assetName === "" ||
                        !asset.fuelType || asset.fuelType === "" ||
                        !asset.equipmentType || asset.equipmentType === "" ||
                        !asset.unit || asset.unit === "" ||
                        asset.emissionFactorValue === ""
                    ) {
                        this.validationErr.push((title + '_' + index))
                    }
                } else if (title === "Mobile Combustion") {
                    if (
                        !asset.assetName || asset.assetName === "" ||
                        !asset.fuelType || asset.fuelType === "" ||
                        !asset.activityType || asset.activityType === "" ||
                        !asset.vehicleType || asset.vehicleType === "" ||
                        !asset.unit || asset.unit === "" ||
                        asset.emissionFactorValue === ""
                    ) {
                        this.validationErr.push((title + '_' + index))
                    }
                } else if (title === "Refrigerants and Fugitives") {
                    if (
                        !asset.assetName || asset.assetName === "" ||
                        !asset.fuelType || asset.fuelType === "" ||
                        !asset.equipmentType || asset.equipmentType === "" ||
                        !asset.unit || asset.unit === "" ||
                        asset.emissionFactorValue === ""
                    ) {
                        this.validationErr.push((title + '_' + index))
                    }
                } else if (title === "Purchased Electricity") {
                    if (
                        !asset.year || asset.year === "" ||
                        !asset.supplierName || asset.supplierName === "" ||
                        !asset.emissionFactor || asset.emissionFactor === "" ||
                        !asset.calculationApproach || asset.calculationApproach === "" ||
                        !asset.calculateMethod || asset.calculateMethod === "" ||
                        !asset.unit || asset.unit === "" ||
                        asset.emissionFactorValue === ""
                    ) {
                        this.validationErr.push((title + '_' + index))
                    }
                } else if (title === "Upstream T&D") {
                    if (
                        !asset.year || asset.year === "" ||
                        !asset.transportationName || asset.transportationName === "" ||
                        !asset.transportMode || asset.transportMode === "" ||
                        !asset.vehicleType || asset.vehicleType === "" ||
                        !asset.activityType || asset.activityType === "" ||
                        !asset.unit || asset.unit === "" ||
                        asset.emissionFactorValue === ""
                    ) {
                        this.validationErr.push((title + '_' + index))
                    }
                }

            }
        } else {
            this.validationErr = [];
        }

        if (this.validationErr.length === 0) {
            let newEditTypesArr = [...this.editTypes]
            const index = newEditTypesArr.indexOf(type);
            if (newEditTypesArr.length > 0 && index > -1) {
                const tempOrgIndex = [...this.tempOrgData].findIndex(v => v.title === title && v.view === this.selectedView)
                if (tempOrgIndex > -1) this.tempOrgData.splice(tempOrgIndex, 1);
                newEditTypesArr.splice(index, 1);
                if (this.selectedView === "Consumption View") {
                    this._handleSaveConsumption(title)
                } else if (this.selectedView === "Emission View") {
                    this._handleSaveEmission(title)
                } else {
                    this._handleSaveAssets(title);
                }
            } else {
                newEditTypesArr.push(type);
                const selectedData = this.selectedView === "Emission View" ? [...this.selectedGWPGroupData]
                    : this.selectedView === "Consumption View" ? [...this.selectedComsumptionData]
                        : this.selectedView === "Emission Activity" ? [...this.selectedGroupData]
                            : null
                const data = JSON.parse(JSON.stringify(selectedData)).find(v => v.title === title)
                this.tempOrgData.push({
                    data: [...data.data],
                    title: data.title,
                    icon: data.icon,
                    view: this.selectedView
                })
            }
            this.editTypes = newEditTypesArr;
        }else{
            toast.error("Please fill data completely!")
        }

    }

    _handleEmissionValueChange = (e, index, dataIndex, monthIndex, title, assetId) => {
        let tempGWPGroupData = [...this.selectedGWPGroupData];
        ((tempGWPGroupData[index].data)[dataIndex].emissionData)[monthIndex].value = e.target.value;
        this.selectedGWPGroupData = tempGWPGroupData;
        let temp_editEmissionData = [...this.editEmissionData];
        const editIndex = this.editEmissionData.findIndex(v => v.assetId === assetId);
        const editRow = {
            assetId,
            monthlyData: toJS((tempGWPGroupData[index].data)[dataIndex].emissionData)
        }
        if (editIndex > -1) {
            temp_editEmissionData.splice(editIndex, 1, editRow)
        } else {
            temp_editEmissionData.push(editRow)
        }

        this.editEmissionData = temp_editEmissionData;
    }

    _handleSelectGroupId = (value) => {
        runInAction(() => {
            this.selectedGroupId = value;
        })
    }

    _handleSelectedViewChange = (value) => {
        runInAction(() => {
            this.selectedView = value;
            this.companyFile = {
                fileName : null,
                fileType : null,
                file : null,
                year:null,
                remark:null
            }
        })
    }

    _handleSelectedGroupChange = (e, selectedObj) => {

    }

    _handleGroupNameChange = (e, selectedGroup, newGroupName) => {
        selectedGroup.groupName = newGroupName;
        this.listGroup = [...this.listGroup]
        const groupData = {
            groupId: selectedGroup._id,
            newGroupName: newGroupName
        }
        updateGroupName(groupData, (err, data) => {
            if (!data.error) {
                toast.success(data.payload);
            }
        })
        this._handleGroupEditMode(e, selectedGroup);
    }

    _handleGroupEditMode = (e, selectedGroup) => {

        //this.groupEditMode = changeValue;
       
        selectedGroup.groupEditMode = !selectedGroup.groupEditMode
        
        this.listGroup = [...this.listGroup]

    }

    _handleEditComsumption = (title) => {
        this.editRow = title;
    }

    _handleComsumptionChange = (e, title, name, month, assetId,index, dataIndex) => {
        let dataList = [...this.selectedComsumptionData];
        const index1 = dataList.findIndex(f => f.title === title);

        dataList[index1].data[index].consumptionData[dataIndex].value = e.target.value;
        dataList[index1].data[index].consumptionData[dataIndex].month = month;
        this.selectedComsumptionData = dataList;

        let temp_editConsumptionData = [...this.editConsumptionData];
        const editIndex = this.editConsumptionData.findIndex(v => v.assetId === assetId);
        const editRow = {
            assetId,
            assetFile:dataList[index1].data[index].assetFile,
            monthlyData: toJS(dataList[index1].data[index].consumptionData)
        }
        if (editIndex > -1) {
            temp_editConsumptionData.splice(editIndex, 1, editRow)
        } else {
            temp_editConsumptionData.push(editRow)
        }

        this.editConsumptionData = temp_editConsumptionData;
    }

    _handleSaveConsumption = (title) => {
        this.editRow = null;
        const ids = this.selectedComsumptionData.find(v => v.title === title)?.data.map(v1 => v1.assetId);
        const bodyData = this.editConsumptionData.filter(v => ids.includes(v.assetId));
        fetchUpdateMultiConsumption(bodyData, this.selectedYear, (err, data) => {
            if (!data.error) {
                toast.success(data.payload);
                this.getConsumptionData();
            } else {
                toast.error(err);
            }
        })
    }

    _handleTextChange = (e, headVal, title, rowIndex) => {
        let newArr = [...toJS(this.selectedGroupData)];
        let index = newArr.findIndex(v => v.title === title);

        if(headVal === 'commissionedDate'){
            newArr[index].data[rowIndex][headVal] = new Date(e);
        }else{
            newArr[index].data[rowIndex][headVal] = e.target.value;
        }

        this.selectedGroupData = newArr;
    }

    _handleSelectChange = (e, headVal, title, rowIndex) => {
        let newArr = [...toJS(this.selectedGroupData)];
        let index = newArr.findIndex(v => v.title === title);
        let scopeNo = (common.tableColumns[0].dataColumns).filter(c => c.cat === title)[0]?.scope;
        if(headVal === "year"){
            newArr[index].data[rowIndex][headVal] = Number(e.target.value);
        }else{
            newArr[index].data[rowIndex][headVal] = e.target.value;
        }
        if(scopeNo === 1){
            if (headVal === 'fuelType' && title === "Mobile Combustion") {
                newArr[index].data[rowIndex]['vehicleType'] = null;                
            }   
            
            if(title === "Mobile Combustion"){
                if(newArr[index].data[rowIndex]['fuelType'] && newArr[index].data[rowIndex]['vehicleType']){
                    this.getEmissionFactorValueForAssetUpdate('scope' + scopeNo, null, title, newArr[index].data[rowIndex]['fuelType'], newArr[index].data[rowIndex]['unit'], null, index, rowIndex, title, newArr[index].data[rowIndex]['vehicleType']);
                }
            }else{
                if(newArr[index].data[rowIndex]['fuelType']){
                    this.getEmissionFactorValueForAssetUpdate('scope' + scopeNo, null, title, newArr[index].data[rowIndex]['fuelType'], newArr[index].data[rowIndex]['unit'], null, index, rowIndex, title, null);
                }
            }                 
        }else if(scopeNo === 2){
        
            if (headVal === 'calculationApproach') {
                newArr[index].data[rowIndex]['calculateMethod'] = null;

                newArr[index].data[rowIndex].calculateType = newArr[index].data[rowIndex].calculationApproach === "Purchased Electricity(Location Based)" ? "Location Based" 
                : newArr[index].data[rowIndex].calculationApproach === "Purchased Electricity(Market Based)" ? "Market Based" : "Steam and Heat";

                const all_calcApproaches = this.selectedGroupData
                    .find(v => v.title === "Purchased Electricity")
                    ?.data.map(v1 => v1.calculationApproach);

                const isContain = all_calcApproaches.includes(e.target.value);
                if (!isContain) {
                    const approach = purchasedElectricityOption.find(v => v.label === e.target.value)?.value;
                    survey.getCalApproaches(approach, 'emissionFactor', 'scope2')
                        .then(resData => {
                            this.calcMethodOptions.push(...resData.map(res => ({
                                ...res,
                                category: e.target.value
                            }))
                            )
                        })
                }
            }

            if(newArr[index].data[rowIndex]['calculateMethod'] && newArr[index].data[rowIndex]['calculationApproach']){
                this.getEmissionFactorValueForAssetUpdate('scope' + scopeNo, newArr[index].data[rowIndex]['calculationApproach'], title, newArr[index].data[rowIndex]['calculateMethod'], newArr[index].data[rowIndex]['unit'], newArr[index].data[rowIndex]['emissionFactor'], index, rowIndex, title, null);
            }
        }else{
            if(newArr[index].data[rowIndex]['vehicleType']){
                this.getEmissionFactorValueForAssetUpdate('scope' + scopeNo, null, title, newArr[index].data[rowIndex]['vehicleType'], newArr[index].data[rowIndex]['unit'], null, index, rowIndex, title, newArr[index].data[rowIndex]['vehicleType']);
            }
        }

        this.selectedGroupData = newArr;
    }

    getEmissionFactorValueForAssetUpdate = (scope, calculationApproach, title, calculateMethod, unit, emissionFactor, index, rowIndex, category, vehicleType) => {
        
        let type = scope === 'scope1' ?
            (
                title === 'Refrigerants and Fugitives' ? "Refrigerants"
                : title === "Mobile Combustion" ? "Mobile Combustion"
                : "Stationary Combustion"
            )
            : scope === 'scope2' ?
            (
                calculationApproach === "Purchased Electricity(Location Based)" ? "Location Based"
                : calculationApproach === "Purchased Electricity(Market Based)" ? "Market Based"
                : "Steam and Heat"
            )
            : "Lookup name";

         getEmissionByCalculatedMethod(
            type,
            calculateMethod,
            unit,
            emissionFactor,
            scope,
            category,
            vehicleType,
            (err, data) => {
                if (data) {
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        runInAction(() => {
                            this.selectedGroupData[index].data[rowIndex].emissionFactorValue = data.payload;
                        });
                    }
                }
            }
        );
    };

    _handleSelectedYearChange = (e) => {
        runInAction(() => {
            this.selectedYear = e.label
        })
    }

    _handleEditTable = () => {
        runInAction(() => {
            this.editTable = !this.editTable;
        })
    }

    setClickedDeleteGroup = (val) => {
        this.isClickedDeleteGroup = val;
    }

    _cancelDeleteGroup() {
        this.setClickedDeleteGroup(false);
        this.deleteCheckedArray = [];
    }

    setDeleteGroupChecked = (id, isChecked) => {
        var index = this.deleteCheckedArray.indexOf(
            this.deleteCheckedArray.find((v) => v === id)
        );
        if (isChecked) {
            this.deleteCheckedArray.push(id);
        } else this.deleteCheckedArray.splice(index, 1);

    }

    _handleDeleteGroup = () => {
        let newArr = [...this.listGroup].reduce((r, c) => {
            const R = [...r];
            const i = this.deleteCheckedArray.findIndex(v => String(v) === String(c._id));
            if (i === -1) {
                R.push(c)
            }

            return R
        }, [])

        fetchDeleteGroup(this.deleteCheckedArray, (err, data) => {
            if (!data.error) {
                runInAction(() => {
                    this.listGroup = newArr;
                    this.deleteCheckedArray = [];
                    this.setClickedDeleteGroup(false);
                });
            } else {
                toast.error(err);
            }
        })
    }

    getCalcMethodOptions = () => {
        this.calcMethodOptions = [];
        const all_calcApproaches = this.selectedGroupData
            .find(v => v.title === "Purchased Electricity")
            ?.data.map(v1 => v1.calculationApproach);
        const unique_calcApproaches = [...new Set(all_calcApproaches)];

        for (let i = 0; i < unique_calcApproaches.length; i++) {
            const data = unique_calcApproaches[i]
            const approach = purchasedElectricityOption.find(v => v.label === data)?.value;
            survey.getCalApproaches(approach, 'emissionFactor', 'scope2')
                .then(resData => {
                    this.calcMethodOptions.push(...resData.map(res => ({
                        ...res,
                        category: data
                    }))
                    )
                })
        }
    }

    _handleNewGroupName = (e) => {
        runInAction(() => {
            this.newGroupName = e.target.value;
        })
    }

    getAllGroups = () => {
        fetchAllGroups((err, data) => {
            if (data.payload) {
                runInAction(() => {
                    const groups = data.payload.map(g => ({
                        _id: g._id,
                        groupName: g.groupName,
                        company: g.company,
                        groupEditMode: false
                    }))
                    groups.push(
                        // {
                        //     "_id": -1,
                        //     "groupName": "No Group Assets",
                        //     "groupEditMode": false
                        // },
                        {
                            "_id": -2,
                            "groupName": "All Assets",
                            "groupEditMode": false
                        }
                    )
                    this.listGroup = groups;
                })
            }
        })
    }

    addNewGroup = () => {
        const groupData = {
            groupName: this.newGroupName,
            company: common.siteModuleData._id
        }

        if (this.newGroupName?.trim() === "" || this.newGroupName === null) {
            toast.error("Group Name can't be blank.");
            this.newGroupName = null;
            document.getElementById('groupName').focus();
        } else {
            createNewGroup(groupData, (err, data) => {
                if (!data.error) {

                    document
                        .getElementById("add_group")
                        .setAttribute("data-bs-dismiss", "modal");
                    let clickEvent = new MouseEvent("click", {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                    });
                    document
                        .getElementById("add_group")
                        .dispatchEvent(clickEvent);

                    document
                        .getElementById("add_group")
                        .removeAttribute("data-bs-dismiss");

                    runInAction(() => {
                        this.newGroupName = null
                    })
                    this.getAllGroups();

                    toast.success(
                        "Successfully created new group."
                    );
                }
            })
        }
    }

    _handleDialog = (type) => {
        // this.dialogActions[type]();
        this.activeDialog = type;
    }

    initializeDialogAction = () => {
        this.dialogActions['Refrigerants and Fugitives'] = () => {
            this.showDialog = "scope1";
            
        }
        this.dialogActions['Mobile Combustion'] = () => {
            this.showDialog = "scope2";
            
        }
        this.dialogActions['Purchased Electricity'] = () => {
            this.showDialog = "scope3";
            
        }
    }

    getConsumptionData = () => {
        this.isLoading = true
        const params = {
            groupId: this.selectedGroupId,
            year: this.selectedYear
        }
        fetchConsumptionDataByYear(params, (err, data) => {
            if (err) {
                toast.error(err);
            } else {
                const consumptionDt = data.payload.reduce((r, c) => {
                    const R = [...r];
                    const data = c.data.map(v => ({
                        assetName: v.assetName,
                        name: v.name,
                        supplierName: v.supplierName,
                        transportationName: v.transportationName,
                        unit: v.unit,
                        assetId: v.consumption_yearly_data.assetId,
                        assetFile: v.consumption_yearly_data.assetFile,
                        consumptionData: v.consumption_yearly_data.monthlyData.map((v2, k2) => (
                            {
                                value: v2.value,
                                month: months[k2],
                                linkedFileId: v2.linkedFileId,
                                description: v2.description,
                                date: v2.date,
                                audition: v2.audition,
                                _id: v2._id
                            }
                        ))
                    }));

                    R.push({
                        data: data,
                        title: c.title,
                        icon: tableIcons.find(i => i.title === c.title)?.icon
                    })

                    return R
                }, []);
                this.selectedComsumptionData = consumptionDt;
            }
            this.isLoading = false;
        })
    }

    getEmissionData = () => {
        this.isLoading = true
        const params = {
            groupId: this.selectedGroupId,
            year: this.selectedYear
        }
        fetchEmissionDataByYear(params, (err, data) => {
            if (err) {
                toast.error(err);
            } else {
                const emissionDt = data.payload.reduce((r, c) => {
                    const R = [...r];
                    const data = c.data.map(v => ({
                        assetName: v.assetName,
                        supplierName: v.supplierName,
                        transportationName: v.transportationName,
                        unit: v.unit,
                        assetId: v.emission_yearly_data?.assetId,
                        assetFile: v.emission_yearly_data?.assetFile,
                        emissionData: v.emission_yearly_data?.monthlyData?.map((v2, k2) => (
                            {
                                value: v2.value,
                                month: months[k2],
                                linkedFileId: v2.linkedFileId,
                                description: v2.description,
                                date: v2.date,
                                audition: v2.audition,
                                _id: v2._id
                            }
                        ))
                    }));

                    R.push({
                        data: data,
                        title: c.title,
                        icon: tableIcons.find(i => i.title === c.title)?.icon
                    })

                    return R
                }, []);
                this.selectedGWPGroupData = emissionDt;
            }
            this.isLoading = false
        })
    }

    _handleSaveEmission = (title) => {
        this.editRow = null;
        const ids = this.selectedGWPGroupData.find(v => v.title === title)?.data.map(v1 => v1.assetId);
        const bodyData = this.editEmissionData.filter(v => ids.includes(v.assetId));
        fetchUpdateMultiEmission(bodyData, this.selectedYear, (err, data) => {
            if (!data.error) {
                toast.success(data.payload);
                this.getEmissionData()
            } else {
                toast.error(err);
            }
        })
    }

    getAssetData = () => {
        this.isLoading = true
        common.getBuildingList();
        fetchAssetData(this.selectedGroupId, (err, data) => {
            if (err) {
                toast.error(err);
            } else {
                const assetDt = data.payload.map(v => ({
                    title: v.title,
                    icon: tableIcons.find(i => i.title === v.title)?.icon,
                    data: v.data
                }));
                this.selectedGroupData = assetDt;
            }
            this.isLoading = false
        })
    }

    _handleSaveAssets = (title) => {
        const scope = (common.tableColumns[0].dataColumns).filter(c => c.cat === title)[0]?.scope;
        const updateData = toJS(this.selectedGroupData).filter(v => v.title === title);
        updateData[0].scope = scope;
        fetchUpdateAssetsData(updateData[0], scope, (err, data) => {
            if (data.success) {
                toast.success('Update Success.');
                this.getAssetData();
            } else {
                toast.error(err);
            }
        })
    }

    _handleCancelEdits = (type, title) => {
        let newEditTypesArr = [...this.editTypes]
        const index = newEditTypesArr.indexOf(type);
        newEditTypesArr.splice(index, 1);
        this.editTypes = newEditTypesArr;

        const orginalData = this.tempOrgData.find(v => v.title === title && v.view === this.selectedView)
        const selectedData = this.selectedView === "Emission View" ? this.selectedGWPGroupData :
            this.selectedView === "Consumption View" ? this.selectedComsumptionData :
                this.selectedView === "Emission Activity" ? this.selectedGroupData : null;

        const selectedIndex = selectedData.findIndex(v => v.title === title);
        const ids = orginalData.data.map(v => v.assetId);
        if (this.selectedView === "Emission View") {
            this.selectedGWPGroupData.splice(selectedIndex, 1, orginalData);
            this.editEmissionData = this.editEmissionData.filter(v => !ids.some(n2 => v.assetId === n2))

        } else if (this.selectedView === "Consumption View") {
            this.selectedComsumptionData.splice(selectedIndex, 1, orginalData)
        } else if (this.selectedView === "Emission Activity") {
            this.selectedGroupData.splice(selectedIndex, 1, orginalData)
        }

        const tempOrgIndex = (this.tempOrgData).findIndex(v => v.title === title && v.view === this.selectedView)
        this.tempOrgData.splice(tempOrgIndex, 1)
    }

    _handleSetDelete = (type) => {
        this.deleteAssetTbls.push(type)
    }

    _handleCancelDelete = (type, title) => {
        const index = this.deleteAssetTbls.findIndex(v => v === type);
        this.deleteAssetTbls.splice(index, 1);
        const ids = this.selectedGroupData.find(v => v.title === title)?.data?.map(v1 => v1._id);

        const temp_deleteAssetRows = this.deleteAssetRows?.filter(v => !ids.includes(v));
        this.deleteAssetRows = temp_deleteAssetRows;
    }

    _handleDeleteAssets = (type, title) => {
        if(this.deleteAssetRows.length > 0){
            const ids = this.selectedGroupData.find(v => v.title === title)?.data?.map(v1 => v1._id);
            const bodyData = this.deleteAssetRows?.filter(v => ids.includes(v));
            fetchDeleteAssets(bodyData, (err, data) => {
                if (err) {
                    toast.error(err);
                } else {
                    toast.success(data.payload.message);
                    const index = this.deleteAssetTbls.findIndex(v => v === type);
                    this.deleteAssetTbls.splice(index, 1);
    
                    const temp_deleteAssetRows = this.deleteAssetRows?.filter(v => !ids.includes(v));
                    this.deleteAssetRows = temp_deleteAssetRows;
    
                    this.getAssetData()
                }
            })
        }
    }

    _handleSelectDeleteRows = (id, checked) => {
        if (!checked) {
            const i = this.deleteAssetRows.findIndex(v => v === id)
            this.deleteAssetRows.splice(i, 1);
        } else {
            this.deleteAssetRows.push(id);
        }
    }

    _getCompanyFileList = () => {
        this.loading = true;
        fetchCompanyFileList((err,data) => {
            if(err){
                toast.error(err);
            }else{
                runInAction(() => {
                    this.companyFileList = data.payload;
                })
            }
            this.loading = false
        })
    }

    _handleCompanyFileUpload = () => {
        if(this.companyFile.file === null){
            this.err = "Please select file"
        }else if(this.companyFile.fileName === null){
            this.err = "Please fill file name!"
        }else if(this.companyFile.remark === null){
            this.err = "Please fill remark name!"
        }else if(this.companyFile.fileType === null){
            this.err = "Please select file type"
        }else{
            this.err = null
        }
        if(!this.err){
            
            let formData = new FormData();
            const { fileName, fileType, file, year,remark } = this.companyFile
            formData.append("remark", remark);
            formData.append("fileName", fileName);
            formData.append("fileType", fileType);
            formData.append("file", file);
            formData.append("year", (fileType === 'Consumption Data' || fileType === 'Emission Data') ? year : '');

            fetchCompanyFileUpload(formData,(err,data) => {
                if(!data.error){
                    toast.success(data.payload);
                    this._getCompanyFileList();
                    this.companyFile = {
                        year,
                        fileName: null,
                        fileType,
                        file: null,
                        remark: null
                    }
                }
            })
        }else{
            toast.error(this.err);
        }
    }

    _handleChangeCompanyFile(e) {
        runInAction(() => {
            this.companyFile.file = e.target.files[0];
        });
    }

    _handleChangeCompanyFileName(e) {
        runInAction(() => {
            this.companyFile.fileName = e.target.value;
        })
    }

    _handleChangeRemark(e) {
        runInAction(() => {
            this.companyFile.remark = e.target.value;
        })
    }

    _handleChangeCompanyYear(value) {
        runInAction(() => {
            this.companyFile.year = value;
        })
    }

    _handleChangeCompanyFileType(value) {
        runInAction(() => {
            this.companyFile.fileType = value;
        })
    }

    _handleCheckUploadedFile(id,fileName){
        fetchCheckUploadedFile(id,fileName,(err,data) => {
            this.forceDeleteFile = { id, fileName };
            if(!data?.error){
                if (data.payload?.deleteFile) {
                    this.showForceDeleteConfirmDialog = true;
                    const msgArr = (data.payload?.assetdatasMsg?.map(v => v.split("exists")[1]))
                        .concat(data.payload?.companyAssetsMsg?.map(v => v.split("exists")[1]));
                    
                    const msg = [msgArr.slice(0, -1).join(','), msgArr.slice(-1)[0]].join(msgArr.length < 2 ? '' : ' and ').replaceAll('.', '')
                    this.forceDeleteConfirmMsg = msg;
                } else {
                    this._handleForceDeleteUploadedFile()
                }
            }else{
                toast.error(err)
            }
        })
    }

    _handleForceDeleteUploadedFile(){
        console.log("force delete file", toJS(this.forceDeleteFile));
        const { id, fileName } = this.forceDeleteFile;
        fetchDeleteUploadedFile(id,fileName,(err,data) => {
            if(!data?.error){
                this.showForceDeleteConfirmDialog = false;
                this.forceDeleteConfirmMsg = null;
                this.forceDeleteFile = {};
                toast.success(data.payload)
                this._getCompanyFileList();
            }else{
                toast.error(err)
            }
        })
    }

    _handleCancelForceDelete(){
        this.showForceDeleteConfirmDialog = false;
        this.forceDeleteConfirmMsg = null;
        this.forceDeleteFile = {};
    }

    _handleChangeDataEntrySelectedFile(value){
        console.log("value====>",value)
        runInAction(() => {
            this.dataEntry.selectedFile = value
        })
    }

    _handleChangeDataEntryValue(value){
        runInAction(() => {
            this.dataEntry.value = value
        })
    }

    _handleChangeDataEntryDate(e){
        runInAction(() => {
            this.dataEntry.date = new Date(e)
        })
    }
    _handleChangeDataEntryAssetId(value){
        console.log(value);
        runInAction(() => {
            this.dataEntry.assetId = value
        })
    }

    addConsumptionData(){
        const data = {
            "assetId": "63d220be90db7175c9361521",
            "consumptionValue": Number(this.dataEntry.value),
            "linkedFileId": this.dataEntry.selectedFile,
            "usedDate": this.dataEntry.date,
            "audition": null
        }
        fetchAddConsumptionDataByYear(data,new Date(this.dataEntry.date).getFullYear(),(err,data)=>{
            if(!err){
                console.log(data)
                document.getElementById("addConsumptionModalClose").click();
                this.getConsumptionData();
            }
        })
    }
}

export default new GroupManagement();

const purchasedElectricityOption = [
    { value: "Location Based", label: "Purchased Electricity - Location Based" },
    { value: "Market Based", label: "Purchased Electricity - Market Based" },
    { value: "Steam and Heat", label: "Heat/Steam" }
];


const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const tableIcons = [
    { title: "Stationary Combustion", icon: "bi bi-truck" },
    { title: "Refrigerants and Fugitives", icon: "bi bi-fan" },
    { title: "Mobile Combustion", icon: "bi bi-truck" },
    { title: "Purchased Electricity", icon: "bi bi-lightning-charge" },
    { title: "Vehicle", icon: "bi bi-truck-front" },
]