import Spinner from "../Assets/images/loading.gif";

export const LoadingPage = (props) => {
  const {height} = props;
  return (
    <div className="text-center text-secondary" style={{ height: height || "100vh", paddingTop: "10%" }}>
      <img src={Spinner} alt="loading" width={80} /><br/>
      Loading ...
    </div>
  );
};
