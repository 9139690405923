import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { ManualEntryDetailForm } from "../Survey/ManualEntryDetailForm";

export const CheckManualEntryModal = inject("store")(
  observer((props) => {
    const { t } = useTranslation("common");
    const { theme } = props.store.common;
    const {
      disabled,
      manualEntryData,
      _handleManualInputChange,
      _handleAddManualEntry,
      _handleCalculateEmissionBaseOnUsage,
      selectedField,
      surveyYear,
      selectedCarbonTab
    } = props.store.survey;

    return (
      <div
        className={`modal fade text-dark ${theme}`}
        id="calculateModal"
        tabIndex="-1"
        aria-labelledby="calculateManualEntryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content"
            style={{
              background: Colors[theme].background,
              borderRadius: 10,
              color: invertDark(theme),
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="setTargetModalLabel">
                Check Manual Entry
              </h5>
              <i
                className="bi bi-x"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="modal-body">
              <ManualEntryDetailForm
                disabled={true}
                theme={theme}
                manualEntryData={manualEntryData}
                selectedYear={surveyYear}
                selectedField={selectedField}
                selectedCarbonTab={selectedCarbonTab}
                uploadedFiles={[]}
              />
              <div className="my-1">
                {`You've already inserted Carbon Emission value for ${surveyYear} from
                manual entry. If you saved now, inserted values will be
                replaced. Are you sure you want to save new values?`}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"                
              >
                {t("Close")}
              </button>
              <button 
              type="button" 
              data-bs-dismiss="modal"
              onClick={_handleCalculateEmissionBaseOnUsage}
              className="btn btn-primary">
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  })
);
