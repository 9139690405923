import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { addNewBuilding } from "../../API/URL";
import { Colors, invertDark } from "../../Assets/Config/color.config";
import { IconCard, MyButton, MyInput, Selector } from "../../Tools";
import { useTranslation } from 'react-i18next';

export const FinalPage = inject("store")(
  observer((props) => {
    const { getAccessmentFramework, getAllBuildingType, theme } = props.store.common;
    const { setPageIndex, pageIndex } = props.store.specialOneTime;
    const { t } = useTranslation("one_time");
    useEffect(() => {
      getAccessmentFramework();
      getAllBuildingType();
    }, []);
    return (
      <div className="cardStyle px-4" style={{ backgroundColor: Colors[theme].secondBackground }}>
        <Header theme={theme} step={3} />
        <hr />
        <Body theme={theme} />
        <div className="text-end pt-2">
          <MyButton
            onClick={() => setPageIndex(pageIndex - 1)}
            style={{ width: 150, marginRight: 10 }}
            leftIcon={<i className="bi bi-arrow-left"></i>}
            text={ t("BACK") }
            customColor={theme === 'dark' ? Colors.primaryBule : '#888'}
            customHoverColor={theme === 'dark' ? Colors.lightBlue : '#aaa'}
          />
          <MyButton
            onClick={() => (window.location.pathname = "/home")}
            style={{ width: 150 }}
            text={ t("FINISH") }
            customColor={Colors.water}
          />
        </div>
      </div>
    );
  })
);

const Header = ({ step,theme }) => {
  const { t } = useTranslation("one_time");
  return (
    <div style={{ fontSize: 25, color:invertDark(theme) }}>
      <strong>{ t("Step", {count: step}) } :</strong> { t("Completed Setup") }
    </div>
  );
};
const Body = ({theme}) => {
  const { t } = useTranslation("one_time");
  return (
    <div style={{ borderBottom: " 1px solid rgb(255, 255, 255,0.1)", color:invertDark(theme) }}>
      <h3 className="text-center py-5">{ t("You are all set") }</h3>
      {/* <h6 className="py-5 text-center">
        You have created 1 Building and 2 User.
      </h6> */}
    </div>
  );
};
