import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { Colors,invertDark } from "../Assets/Config/color.config";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from '@mui/material/OutlinedInput';


export const MuiMultiSelector = (props) => {
  const { t } = useTranslation("common");
  const {
    options,
    value,
    style,
    disabled,
    placeholder = t("Select_"),
    background,
    _handleChange,
  } = props;
  const [open, setOpen] = useState(false);

  const theme = localStorage.getItem("theme");

  const customStyle = {
    ...style,
    background: background ? background : Colors[theme].secondBackground,
    color: invertDark(theme),
    minHeight: 43,
    fontSize: 14,
    border: "1px solid",
    borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    cursor: disabled ? 'not-allowed !important' : 'default',
    '.MuiFormControl-root': {
      borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    },
    '&.Mui-focused .MuiFormControl-root': {
      borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    },
    '&:hover .MuiFormControl-root': {
      borderColor: theme === "dark"?Colors.Dark[2]:Colors.Dark[4],
    },
    '.MuiSvgIcon-root ': {
      fill: 'rgb(4, 197, 0)',
    },
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {
      paddingTop : 0,
      paddingBottom: 0
    },
    '.css-1d3z3hw-MuiOutlinedInput-notchedOutline':{
      border:'none'
    },
    '.MuiSelect-select': {
      cursor : disabled ? 'not-allowed !important' : 'default',
    }
  };


  const Icon = ({ innerRef, innerProps }) => (
    <span
      className="px-2"
      role="img"
      aria-label="Dropdown emoji"
      ref={innerRef}
      {...innerProps}
    >
      <i
        className="bi bi-caret-down-fill"
        style={{ color: Colors.greenMaterial }}
      />
    </span>
  );
  const components = {
    DropdownIndicator: Icon,
  };

  return (
    <Box
    sx={{ display: "flex", flexWrap: "wrap"}}
    >
      <FormControl sx={{ m: 1, margin: "8px 0px", width: "100%" }} variant="outlined" fullWidth>
        {/* <InputLabel
          id="demo-simple-select-label"
          style={{
            color: disabled ? "#808080" : Colors.Dark[4],
            // lineHeight: "1em",
            // overflow: "hidden",
            // fontSize: "12px",
            // width: "80%",
            // whiteSpace: "nowrap",
            // textOverflow: "ellipsis",
          }}
        >
          {value.length > 0 ? "" : t("Select_")}
        </InputLabel> */}
        <Select
          value={value}
          disabled={disabled}
          sx={customStyle}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={_handleChange}
          components={components}
          multiple
          renderValue={(selected) => {
            let selects = [];
            selected.map((s) => {
              if (options.find((o) => o.id === s))
                selects.push(options.find((o) => o.id === s).label);
            });
            return selects.join(", ");
          }}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                <Checkbox checked={value.indexOf(option.id) > -1} />
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
