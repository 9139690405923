
export const MyConstants = {
    YEAR: "Year",
    ACTIVITY_TYPE_OPTIONS: [
        { value: 2, label: "Distance Activity", category: "Mobile Combustion" },
        { value: 3, label: "Fuel Use", category: "Mobile Combustion" },
        { value: 4, label: "Vehicle Distance", category: "Upstream Raw Material Transport Related Emission" },
        { value: 5, label: "Weight Distance", category: "Upstream Raw Material Transport Related Emission" },
        { value: 6, label: "Distance", category: "Upstream T&D" },
        { value: 7, label: "Passenger Distance", category: "Upstream T&D" },
        { value: 8, label: "Vehicle Distance", category: "Upstream T&D" },
        { value: 9, label: "Weight Distance", category: "Upstream T&D" },
    ],
    YEAR_OPTIONS: [
        { value: 2018, label: "2018" },
        { value: 2019, label: "2019" },
        { value: 2020, label: "2020" },
        { value: 2021, label: "2021" },
        { value: 2022, label: "2022" },
    ],
    VEHICLE_TYPE_OPTIONS: [
        { value: 1, label: "Gasoline Passenger Cars", fuelType: "Motor Gasoline" },
        { value: 2, label: "Gasoline Light-duty Trucks (Vans, Pickup Trucks, SUVs)", fuelType: "Motor Gasoline" },
        { value: 3, label: "Gasoline Heavy-duty Vehicles", fuelType: "Motor Gasoline" },
        { value: 4, label: "Hybrid (Gasoline) Passenger Cars", fuelType: "Motor Gasoline" },
        { value: 5, label: "Gasoline Agricultural Equipment", fuelType: "Motor Gasoline" },
        { value: 6, label: "Gasoline Ships and Boats", fuelType: "Motor Gasoline" },
        { value: 7, label: "Gasoline Motorcycles", fuelType: "Motor Gasoline" },
        { value: 8, label: "Other Gasoline Non-Road Vehicles", fuelType: "Motor Gasoline" },
        { value: 9, label: "Diesel Passenger Cars", fuelType: "Diesel Fuel" },
        { value: 10, label: "Diesel Light-duty Trucks", fuelType: "Diesel Fuel" },
        { value: 11, label: "Diesel Medium- and Heavy-duty Vehicles", fuelType: "Diesel Fuel" },
        { value: 12, label: "Diesel Agricultural Equipment", fuelType: "Diesel Fuel" },
        { value: 13, label: "Diesel Ships and Boats", fuelType: "Diesel Fuel" },
        { value: 14, label: "Heavy-duty Vehicles", fuelType: "Ethanol (100%) - Ethanol Medium" },
        { value: 15, label: "Other Diesel Non-Road Vehicles", fuelType: "Diesel Fuel" },
        { value: 16, label: "Biodiesel Passenger Cars", fuelType: "Biodiesel (100%)" },
        { value: 17, label: "Biodiesel Light-duty Vehicles", fuelType: "Biodiesel (100%)" },
        { value: 18, label: "Biodiesel Medium- and Heavy-duty Vehicles", fuelType: "Biodiesel (100%)" },
        { value: 19, label: "CNG Light-duty Vehicles", fuelType: "Compressed Natural Gas" },
        { value: 20, label: "CNG Medium- and Heavy-duty Vehicles", fuelType: "Compressed Natural Gas" },
        { value: 21, label: "Ethanol Light-duty Vehicles", fuelType: "Ethanol (100%)" },
        { value: 22, label: "Ethanol Medium- and Heavy-duty Vehicles", fuelType: "Ethanol (100%)" },
        { value: 23, label: "Jet Fuel Aircraft", fuelType: "Jet Fuel" },
        { value: 24, label: "Aviation Gasoline Aircraft", fuelType: "Aviation Gasoline" },
        { value: 25, label: "General", fuelType: "Diesel oil" },
        { value: 26, label: "General", fuelType: "Petrol" },
        { value: 27, label: "Electric Vehicle", fuelType: "Electricity - Mobile" },
        { value: 28, label: "CNG Light-duty Vehicles", fuelType: "Compressed Natural Gas - CNG Medium" },
        { value: 29, label: "Heavy-duty Vehicles", fuelType: "Biodiesel (100%) - Biodiesel Medium" },
        { value: 30, label: "Heavy-duty Vehicles", fuelType: "Diesel Fuel - Diesel Medium" },
    ],
    EMISSION_FACTOR_OPTIONS: [
        { value: 1, label: "Grid Average / Location Based", category: "Purchased Electricity" },
        { value: 2, label: "Grid Average / Location Based", category: "Imported energy (steam, heating, cooling and compressed air)" },
        { value: 3, label: "Singapore", category: "Emission Removal & Mitigation"},
        { value: 4, label: "Plastic (PS)", category: "Purchased Goods"},
        { value: 4, label: "Plastic (ABS)", category: "Purchased Goods"},
        { value: 4, label: "PET (film)", category: "Purchased Goods"},
        { value: 4, label: "Aluminium", category: "Purchased Goods"},
        { value: 4, label: "Steel", category: "Purchased Goods"},
        { value: 4, label: "Cyclohexane", category: "Purchased Goods"},
        { value: 4, label: "Epoxy resin", category: "Purchased Goods"},
        { value: 4, label: "Copper", category: "Purchased Goods"},
        { value: 4, label: "Glass", category: "Purchased Goods"}
    ],
    DEVICE_TYPE_OPTIONS: [
        { value: 1, label: "Generator", category: "Stationary Combustion" },
        { value: 2, label: "Boiler", category: "Stationary Combustion" },
        { value: 3, label: "Convector", category: "Stationary Combustion" },
        { value: 4, label: "Kiln", category: "Stationary Combustion" },
        { value: 5, label: "Dryer", category: "Stationary Combustion" },
        { value: 6, label: "Backup generator", category: "Stationary Combustion" },
        { value: 7, label: "Combustion Turbine", category: "Stationary Combustion" },
        { value: 8, label: "Gas Stove", category: "Stationary Combustion" },
        { value: 9, label: "Pulverized-coal fired boiler", category: "Stationary Combustion" },
        { value: 10, label: "Incinerator", category: "Stationary Combustion" },
        { value: 11, label: "Flare Tower", category: "Stationary Combustion" },
        { value: 12, label: "Regenerative Thermal Oxidizer (RTO)", category: "Stationary Combustion" },
        { value: 13, label: "Chillers", category: "Stationary Combustion" },
        { value: 14, label: "Stand-alone Commercial Applications", category: "Refrigerants and Fugitives" },
        { value: 15, label: "GCB/GIS", category: "Stationary Combustion" },
        { value: 16, label: "Chillers", category: "Fugitive Emission" },
        { value: 17, label: "Domestic Refrigeration", category: "Fugitive Emission" },
        { value: 18, label: "Medium & large Commericial Refrigeration", category: "Fugitive Emission" },
        { value: 19, label: "Mobile Air Conditioning", category: "Fugitive Emission" },
        { value: 20, label: "Residential & Commercial", category: "Fugitive Emission" },
        { value: 21, label: "Standalone Commercial Application", category: "Fugitive Emission" },
        { value: 22, label: "Transport Refrigeration", category: "Fugitive Emission" },
        { value: 23, label: "Including Heat Pumps", category: "Fugitive Emission" },
        { value: 24, label: "Industrial Refrigeration Including Food Processing and Cold Storage", category: "Fugitive Emission" },
        { value: 25, label: "Medium & Large Commercial Refrigeration", category: "Refrigerants and Fugitives" },
        { value: 26, label: "Transport Refrigeration", category: "Refrigerants and Fugitives" },
        { value: 27, label: "Industrial Refrigeration including Food Processing and Cold Storage", category: "Refrigerants and Fugitives" },
        { value: 28, label: "Chillers", category: "Refrigerants and Fugitives" },
        { value: 29, label: "Residential and Commercial", category: "Refrigerants and Fugitives" },
        { value: 30, label: "including Heat Pumps", category: "Refrigerants and Fugitives" },
        { value: 31, label: "Mobile Air Conditioning", category: "Refrigerants and Fugitives" },
        { value: 32, label: "Domestic Refrigeration", category: "Refrigerants and Fugitives" },
    ],
    CALCULATION_APPROACH_OPTIONS: [
        { value: 1, label: "Purchased Electricity(Location Based)", category: "Purchased Electricity" },
        { value: 2, label: "Heat/Steam", category: "Purchased Electricity" }
    ],
    TRANSPORT_MODE_OPTIONS: [
        { value: 1, label: "Air", category: "Upstream T&D" },
        { value: 2, label: "Bus", category: "Upstream T&D" },
        { value: 3, label: "Car", category: "Upstream T&D" },
        { value: 4, label: "Ferry", category: "Upstream T&D" },
        { value: 5, label: "Rail", category: "Upstream T&D" },
        { value: 6, label: "Ship", category: "Upstream T&D" },
        { value: 7, label: "Motorcycle", category: "Upstream T&D" }
    ],
    CALCULATE_TYPE_OPTIONS: [
        { value: 1, label: "Location Based", category: "Purchased Electricity" },
        { value: 2, label: "Market Based", category: "Purchased Electricity" },
        { value: 3, label: "Steam and Heat", category: "Purchased Electricity" }
    ],
    UNIT_OPTIONS: [
        { value: 1, label: "L", activityType: 'all' },
        { value: 2, label: "gal (US)", activityType: 'all' },
        { value: 3, label: "gal (US)", activityType: 'Fuel Use' },
        { value: 4, label: "L", activityType: 'Fuel Use' },
        { value: 5, label: "bbl", activityType: 'Fuel Use' },
        { value: 6, label: "scf", activityType: 'Fuel Use' },
        { value: 7, label: "ccf", activityType: 'Fuel Use' },
        { value: 8, label: "m³", activityType: 'Fuel Use' },
        { value: 9, label: "nautical mile", activityType: 'Distance Activity' },
        { value: 10, label: "mile", activityType: 'Distance Activity' },
        { value: 11, label: "km", activityType: 'Distance Activity' },
        { value: 12, label: "kWh", activityType: 'scope2' },
        { value: 13, label: "mile", activityType: 'Upstream T&D' },
        { value: 14, label: "km", activityType: 'Upstream T&D' },
        { value: 15, label: "passenger-mile", activityType: 'Upstream T&D' },
        { value: 16, label: "passenger-km", activityType: 'Upstream T&D' },
        { value: 17, label: "kWh", activityType: 'Fuel Use' },
        { value: 18, label: "Btu", activityType: 'Stationary Combustion' },
        { value: 19, label: "mmBtu", activityType: 'Stationary Combustion' },
        { value: 20, label: "Kg", activityType: 'Refrigerants and Fugitives' },
        { value: 21, label: "therm", activityType: 'Stationary Combustion' },
        { value: 22, label: "kWh", activityType: 'Refrigerants and Fugitives' },
        { value: 23, label: "kWh", activityType: 'Stationary Combustion' },
        { value: 24, label: "MWh", activityType: 'Stationary Combustion' },
        { value: 25, label: "MJ", activityType: 'Stationary Combustion' },
        { value: 26, label: "GJ", activityType: 'Stationary Combustion' },
        { value: 27, label: "L", activityType: 'Stationary Combustion' },
        { value: 28, label: "Kg", activityType: 'Purchased Goods' },
        { value: 29, label: "Kg", activityType: 'Waste' },
        { value: 30, label: "Kg", activityType: 'Emission Removal & Mitigation' },
    ]
}