import { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { Colors, invertDark } from '../../Assets/Config/color.config'
import Conrad from '../../Assets/images/conrad.png'
import { MyButton } from '../../Tools'
import Store from '../../Store';
import { toJS } from 'mobx';
import { baseURLImage } from '../../API/URL';
import { useTranslation } from 'react-i18next';


const TitleBar = (props) => {
    const { permittedBuilding, getBuildingList, addDefaultSrc, getAccessmentFramework, accessmentList, certificateArray, theme } = Store.common;
    const { buildingId,frameworkId } = props;

    useEffect(()=> {
        getBuildingList();
    },[]);

    const currentBuildingData = toJS(permittedBuilding)?.find(v=> v._id === buildingId);
    const currentFramework = toJS(certificateArray)?.find(v => v.framework_id === frameworkId);
    const { t } = useTranslation("target_setting");

    return (
        <div className="flex-row d-flex align-items-center justify-content-between">
            <div className='d-flex'>
                <div>
                    <img
                        onError={addDefaultSrc}
                        src={baseURLImage + currentBuildingData?.photo}
                        alt={ t("Site Logo") }
                        width={55}
                        className="rounded me-2"
                    />
                </div>
                <div className='ms-2'>
                    <div className='' style={{ fontSize: 22, color: Colors[theme].textColor }}>{currentBuildingData?.buildingName}</div>
                    <div style={{ color: invertDark(theme) }}><strong className='fw-bold'>{ t("Framework") } : </strong>{currentFramework?.name}</div>
                </div>
            </div>
            <span
                data-bs-toggle="modal"
                data-bs-target="#siteRelevantUpload"
                style={{
                    cursor: "pointer"
                }}
            >
                <MyButton
                    text={ t("Upload New File") }
                    customColor={theme ===  'dark'? Colors.lightBlue : Colors.blue}
                    leftIcon={<i className="bi bi-upload me-2"></i>}
                />
            </span>
        </div>
    )
}

export default inject('store')(observer(TitleBar))